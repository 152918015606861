import Opportunity from '@/models/Opportunity'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import { buildColorPool } from '@/models/Util'
import moment from 'moment'
import numeral from 'numeral'
import PipelineSold from '../../filters/PipelineSold'
import ProductDashboardFilter from '../../filters/ProductDashboardFilter'

export default class NewThisWeekOverviewWidget extends SystemDashboardWidget {
  public title: string = 'New This Week Overview'

  public key = 'new-this-week-overview'

  public view: string = 'chart'

  public subView: string = 'bar_stacked'

  public size = {
    lg: 4,
    md: 12,
  }

  public dimensions = ['quarter', 'product']

  public metrics = ['amount']

  constructor(args: any = null) {
    super()

    if (args && args.dimensions) {
      this.dimensions = args.dimensions
    }

    if (args && args.metrics) {
      this.metrics = args.metrics
    }

    if (args && args.filters) {
      this.filters = Object.assign(this.filters, args.filters)
    }
    if (args && args.internalFilters) {
      this.internalFilters = Object.assign(this.internalFilters, args.internalFilters)
    }

    if (args && args.rightCol) {
      this.rightCol = args.rightCol
    }

    if (args && args.leftCol) {
      this.leftCol = args.leftCol
    }

    if (args && args.size) {
      this.size = args.size
    }

    if (args && args.title) {
      this.title = args.title
    }

    if (args && args.key) {
      this.key = args.key
    }

    if (args && args.noPagination) {
      this.noPagination = args.noPagination
    }

    if (args && args.updateFields) {
      this.fields = args.updateFields(this.fields)
    }

    if (args && args.internalQuery) {
      this.internalQuery = args.internalQuery
    }

    if (args && args.internalQueryPush) {
      if (!this.internalQuery) this.internalQuery = []
      this.internalQuery.push(args.internalQueryPush)
    }

    if (args && args.noPagination) {
      this.noPagination = args.noPagination
    }
    if (args && args.perPage) {
      this.perPage = args.perPage
    }

    if (args && args.fields) {
      this.fields = args.fields
    }

    if (args && args.noTotal) {
      this.noTotal = args.noTotal
    }

    if (args && args.allowAll) {
      this.allowAll = args.allowAll
    }
    if (args && args.bordered) {
      this.bordered = args.bordered
    }
  }

  public internalQuery = ['is:new_this_week', 'scope:agency']

  public rightCol = [
    new PipelineSold({
      options: [
        { text: 'Pipeline', value: 'is:pipeline' },
        { text: 'Sold', value: 'is:sold' },
      ],
    }),
  ]

  public filters = {
    product: 'all',
    region: 'all',
  }

  public internalFilters = {
    dynamic_filter: 'is:pipeline',
    year: moment().format('YYYY'),
  }

  public get filterToQuery() {
    let query = []

    if (this.filters.product !== 'all') {
      query.push(`product:${this.filters.product}`)
    }

    if (this.filters.region !== 'all') {
      query.push(`region:${this.filters.region}`)
    }

    query.push(`year:${this.internalFilters.year}`)

    return query
  }

  public get payload() {
    const query = [...new Set([...this.query, ...this.internalQuery, ...this.filterToQuery])]

    const filters = {
      ...this.internalFilters,
    }

    return {
      query,
      filters,
      metrics: this.metrics,
      dimensions: this.dimensions,
    }
  }

  public async dataSource() {
    return Opportunity.buildAdvancedQuery(this.payload).then((res: any) =>
      res.data.result.sort((a: any, b: any) =>
        (a[this.dimensions[0]] > b[this.dimensions[0]] ? 1 : -1)))
  }

  public formatters: any = {
    yaxis: (_: SystemDashboardWidget, value: any) => `${numeral(value).format('$0,00a')}`,
    label: (_: SystemDashboardWidget, value: any) => `${numeral(value).format('$0,00a')}`,
    tooltip: (_: SystemDashboardWidget, value: any) => [numeral(value).format('$0,00a')],
  }

  // TODO check all notes if they are correct
  public get notes(): string {
    return 'All YOY analisys represent final 2023 revenue amount'
  }

  protected dataMapper(data: any): Promise<any> {
    const series: number[] = []
    const data_series: any[] = []
    const labels: any[] = []
    const colors: string[] = []
    const series_ids: string[] = []
    let total = 0
    let total_cumulative: any[] = []

    const groups: string[] = []

    let x_series: string[] = []

    // order data by quarter
    // data.sort((a: any, b: any) => {
    //   return a.quarter > b.quarter ? 1 : -1
    // })
    // console.log('data--', data)
    // Init Series
    data.forEach((item: any, idx: number) => {
      const label = item[this.dimensions[1]]
      const group_index = groups.indexOf(label)
      if (group_index === -1) {
        groups.push(label)
        data_series.push({ name: label, data: [] })
      }

      const x_label = item[this.dimensions[0]]

      if (x_series.indexOf(x_label) === -1) {
        x_series.push(x_label)
      }
    })

    const color_pool = buildColorPool(groups.length)

    data_series.forEach((item: any, idx: number) => {
      x_series.forEach(() => {
        item.data.push(0)
      })
      if (item.name === 'CCL') {
        colors.push('#555c6e')
      } else if (item.name === 'SSL') {
        colors.push('#00C1DE')
      } else {
        colors.push(color_pool(idx))
      }
    })

    data.forEach((item: any, idx: number) => {
      const label = item[this.dimensions[0]]
      const group_label = item[this.dimensions[1]]
      const group_index = groups.indexOf(group_label)
      const x_index = x_series.indexOf(label)

      let val = Number(item[this.metrics[0]])
      if (isNaN(val)) {
        val = 0
      }
      if (!total_cumulative[group_index]) {
        total_cumulative[group_index] = 0
      }

      total += val
      total_cumulative[group_index] += val

      series.push(val)

      data_series[group_index].data[x_index] += val

      if (['product', 'region', 'deal_type'].includes(this.dimensions[1])) {
        series_ids[group_index] = item[this.dimensions[1]].toLowerCase().replaceAll(' ', '_')
      } else if (this.dimensions[2]) {
        series_ids[group_index] = item[this.dimensions[2]]
      }
    })

    // Udpate Settings
    this.chartSettings.colors = colors
    this.chartSettings.xaxis = {
      categories: x_series,
    }
    this.chartSettings.labels = labels

    return Promise.resolve(data_series)
  }
}
