import moment from 'moment'
import numeral from 'numeral'

export default [
  {
    title: 'Delivery Status Report',
    source: 'company_goal_metrics',
    key: 'directv-delivery-status',
    type: 'table',
    size: {
      lg: 8,
      md: 12,
    },
    dimensions: ['quarter'],
    capitalize_labels: true,
    config: {
      noPagination: true,
      noTotal: true,
      fields: [
        {
          key: 'period',
          label: 'Period',
          class: 'text-center align-middle',
          type: 'uppercase',
          show: true,
          thClass: 'align-middle text-center',
          sortable: false,
        },
        {
          key: 'impressions',
          label: 'Impressions',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'abbreviate',
          sortable: false,
          total: 'abbreviate',
        },
        {
          key: 'cpm',
          label: 'DirecTV CPM',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency',
          sortable: false,
          total: 'currency',
        },
        {
          key: 'earned_revenue',
          label: 'DirecTV Earned Revenue',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: false,
          total: 'currency_abbreviate',
        },
        // {
        //   key: 'sold',
        //   label: 'Total CIM Sold Impressions',
        //   class: 'text-center align-middle',
        //   show: true,
        //   thClass: 'align-middle text-center',
        //   type: 'abbreviate',
        //   sortable: false,
        //   total: 'abbreviate',
        // },
      ],
    },
    metrics: [
      'goal',
      'goal_pipeline',
      'goal_sold',
      'goal_achieved',
      'last_year_goal',
      'last_year_goal_pipeline',
      'last_year_goal_sold',
      'last_year_goal_achieved',
    ],
    filters: {
      company_id: '96ed6c23-1824-4aa8-bfb6-10b1c98473c8',
    },
  },
  {
    title: 'Delivered vs Goal',
    key: 'directv-gauge',
    source: 'company_goal_status',
    type: 'gauge_number',
    size: {
      lg: 4,
      md: 12,
    },
    dimensions: [],
    capitalize_labels: true,
    metrics: ['delivered', 'goal'],
    filters: {
      company_id: '96ed6c23-1824-4aa8-bfb6-10b1c98473c8',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => {
        const delivered = numeral(data[0].delivered).format('0.00a')
        const goal = numeral(data[0].goal).format('0.00a')
        const start_at = data[0].start_at ? moment(data[0].start_at).format('MMM DD, YYYY') : ''
        const end_at = data[0].end_at ? moment(data[0].end_at).format('MMM DD, YYYY') : ''

        const date = start_at && end_at ? `${start_at} - ${end_at}` : ''

        return `<div class="row text-center">
          <div class="col">
            <h6>Delivered</h6>
            <h4>${delivered}</h4>
          </div>
          <div class="col">
            <h6>Goal</h6>
            <h4>${goal}</h4>
          </div>
        </div>
        <div class="text-center text-muted">
          ${date}
        </div>
        `
      },
    },
  },
  {
    title: 'Last 30 days',
    key: 'last-30-days',
    source: 'company_goal_30_days',
    type: 'bar',
    size: {
      lg: 12,
      md: 12,
    },
    capitalize_labels: false,
    config: {
      date_dimension_format: 'YYYY-MM-DD',
      date_format_labels: 'MMM DD, YYYY',
      custom_label: 'Impressions',
    },
    dimensions: ['date'],
    metrics: ['impressions'],
    filters: {
      company_id: '96ed6c23-1824-4aa8-bfb6-10b1c98473c8',
    },
  },
] as any[]
