import Api from '@/models/Api'
import MediaPlan from '@/models/MediaPlan'
import SystemDashboard from '@/models/SystemDashboard'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import BaseFilter from '../filters/BaseFilter'
import ColumnSelector from '../filters/ColumnSelector'
import DateRangeFilter from '../filters/DateRangeFilter'
import PeriodGroup from '../filters/PeriodGroup'
import PlannedActualSwitch from '../filters/PlannedActualSwitch'
import RegionSalesRepGroup from '../filters/RegionSalesRepGroup'
import { FILTERS } from '../helpers'
import TodayWidget from '../widgets/finance/TodayWidget'

export default class SSLOrderAnalysis extends SystemDashboard {
  public title: string = 'SSL Order Analysis'

  public key: string = 'ssl_order_analysis'

  public filters = {}

  public querySettings = {
    company_fields: [
      {
        name: 'agency',
        key: 'agency_id',
        type: 'agency',
      },
      {
        name: 'advertiser',
        key: 'advertiser_id',
        type: 'advertiser',
      },
    ],
    order_fields: [
      {
        name: 'order',
        key: 'order_id',
      },
    ],
  }

  public get periodGroups() {
    return new PeriodGroup({
      concatOptions: [
        { text: 'Year', value: 'year' },
        { text: 'Day', value: 'day' },
      ],
    })
  }

  public get viewGroup() {
    return [
      new BaseFilter({
        target: 'internalFilters',
        key: 'view',
        options: [
          { text: 'Advertiser', value: 'advertiser_id' },
          { text: 'Agency', value: 'agency_id' },
        ],
      }),
    ]
  }

  public widgets: SystemDashboardWidget[] = [
    new TodayWidget({
      title: 'Order Details',
      key: 'order-details',
      noPagination: false,
      perPage: 50,
      size: {
        lg: 12,
        md: 12,
      },
      conditionalFields: {
        planned: ['name', 'impressions', 'planned_cost', 'revenue', 'profit', 'contracted_cpm'],
        actual: [
          'name',
          'delivered_impressions',
          'delivered_revenue',
          'actual_cost',
          'effective_rate',
          'actual_profit',
          'delivered_cpm',
          'vcr',
          'live',
          'fep',
          'ctv',
          'desktop',
          'mobile',
        ],
      },
      groups: {
        view_by: 'planned',
      },
      orderBy: 'name',
      internalFilters: {
        ...FILTERS.MONTH,
        select_by: null,
      },
      fields: [
        {
          key: 'name', // media plan name
          label: 'Order',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'impressions',
          label: 'Impressions',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'separator',
        },
        {
          key: 'revenue',
          label: 'Revenue',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
        },

        {
          key: 'planned_cost',
          label: 'Planned Cost',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
        },
        {
          key: 'profit',
          label: 'Profit %',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'percentage',
        },
        {
          key: 'contracted_cpm',
          label: 'Contracted CPM',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
        },
        // actual
        // delivered_revenue
        {
          key: 'delivered_revenue',
          label: 'Delivered Revenue',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
        },
        {
          key: 'delivered_impressions',
          label: 'Impressions',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'actual_cost',
          label: 'Actual Cost',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
        },
        {
          key: 'effective_rate',
          label: 'Effective Rate',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
        },
        {
          key: 'actual_profit',
          label: 'Actual Profit',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
        },
        {
          key: 'delivered_cpm',
          label: 'Delivered CPM',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
        },

        {
          key: 'vcr',
          label: 'VCR %',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'percentage',
        },
        {
          key: 'live',
          label: 'LIVE %',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'percentage',
        },
        {
          key: 'fep',
          label: 'FEP %',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'percentage',
        },
        {
          key: 'ctv',
          label: 'CTV %',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'percentage',
        },
        {
          key: 'desktop',
          label: 'DESK %',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'percentage',
        },
        {
          key: 'mobile',
          label: 'MOB %',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'percentage',
        },
      ],
      leftCol: [new PlannedActualSwitch()],
      prependRightCol: [new DateRangeFilter({ target: 'internalFilters' })],
      dataSourceRequest: (payload: any) => {
        let api = new Api()
        return api.get('ssl-order-analysis/order', payload).then(response => ({
          data: response.data.result.media_plans,
          records: response.data.result.records,
        }))
      },
    }),

    new TodayWidget({
      title: 'Inventory Details',
      key: 'inventory-details',
      noPagination: false,
      perPage: 50,
      size: {
        lg: 12,
        md: 12,
      },

      groups: {
        view_by: 'planned',
      },
      orderBy: 'name',
      internalFilters: {
        ...FILTERS.MONTH,
        select_by: null,
      },
      fields: [
        {
          key: 'name',
          label: 'Inventory',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'impressions',
          label: 'Impressions',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'revenue',
          label: 'Revenue',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
        },

        {
          key: 'cost',
          label: 'Cost',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
        },
        {
          key: 'profit',
          label: 'Profit %',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'percentage',
        },

        {
          key: 'vcr',
          label: 'VCR %',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'percentage',
        },
        {
          key: 'live',
          label: 'LIVE %',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'percentage',
        },
        {
          key: 'fep',
          label: 'FEP %',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'percentage',
        },
        {
          key: 'ctv',
          label: 'CTV %',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'percentage',
        },
        {
          key: 'desktop',
          label: 'DESK %',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'percentage',
        },
        {
          key: 'mobile',
          label: 'MOB %',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'percentage',
        },
      ],

      prependRightCol: [new DateRangeFilter({ target: 'internalFilters' })],
      dataSourceRequest: (payload: any) => {
        let api = new Api()
        return api.get('ssl-order-analysis/inventory', payload).then(response => ({
          data: response.data.result.inventory,
          records: response.data.result.records,
        }))
      },
    }),

    new TodayWidget({
      title: 'Publisher Details',
      key: 'publisher-details',
      noPagination: false,
      perPage: 50,
      size: {
        lg: 12,
        md: 12,
      },

      groups: {
        view_by: 'planned',
      },
      orderBy: 'name',
      internalFilters: {
        ...FILTERS.MONTH,
        select_by: null,
      },
      fields: [
        {
          key: 'name',
          label: 'Publisher',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'impressions',
          label: 'Impressions',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'revenue',
          label: 'Revenue',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
        },

        {
          key: 'cost',
          label: 'Cost',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
        },
        {
          key: 'profit',
          label: 'Profit %',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'percentage',
        },

        {
          key: 'vcr',
          label: 'VCR %',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'percentage',
        },
        {
          key: 'live',
          label: 'LIVE %',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'percentage',
        },
        {
          key: 'fep',
          label: 'FEP %',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'percentage',
        },
        {
          key: 'ctv',
          label: 'CTV %',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'percentage',
        },
        {
          key: 'desktop',
          label: 'DESK %',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'percentage',
        },
        {
          key: 'mobile',
          label: 'MOB %',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'percentage',
        },
      ],

      prependRightCol: [new DateRangeFilter({ target: 'internalFilters' })],
      dataSourceRequest: (payload: any) => {
        let api = new Api()
        return api.get('ssl-order-analysis/publisher', payload).then(response => ({
          data: response.data.result.inventory,
          records: response.data.result.records,
        }))
      },
    }),
  ]
}
