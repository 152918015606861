import { buildColorPool } from '@/models/Util'
import numeral from 'numeral'
import { clone, startCase } from 'lodash'
import moment from 'moment'

const normalizeLabel = (name: string, settings: any) => {
  if (!name) return 'N/A'
  if (settings.config.rawLables) {
    return name
  }
  if (settings.capitalizeLabels) {
    name = startCase(name)
  } else if (settings.dateFormatLabels) {
    // Check if the date is valid
    if (moment(name, settings.dateFormatLabels).isValid()) {
      name = moment(name).format(settings.dateFormatLabels)
    }
  }
  return name
}

export default {
  funnel: {
    base_options: {
      chart: {
        type: 'bar',
        height: '100',
        width: '200',
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: true,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        dropShadow: {
          enabled: true,
        },
      },
      tooltip: {
        enabled: true,
        y: {},
      },
      legend: {
        position: 'bottom',
        show: true,
        showForSingleSeries: true,
      },
      xaxis: {},
      plotOptions: {
        bar: {
          borderRadius: 0,
          distributed: true,
          horizontal: true,
          barHeight: '80%',
          isFunnel: true,
        },
      },
    },
    dataLabelFormatter: (
      value: number,
      labels: string[],
      series: number[],
      __: number,
      opt: any,
      settings: any,
    ) => {
      const raw_value = series[opt.dataPointIndex]
      const label = labels[opt.dataPointIndex]

      let format = '$0.00a'
      if (settings.metrics[0] === 'count') {
        format = '0,0'
      }

      return [`${label}: ${numeral(raw_value).format(format)}`]
    },
    tooltipFormatter: (
      value: number,
      labels: string[],
      series: number[],
      total: number,
      opt: any,
      settings: any,
    ) => {
      const raw_value = series[opt.dataPointIndex]
      const label = labels[opt.dataPointIndex]

      let format = '$0.00a'

      if (settings.metrics[0] === 'count') {
        format = '0,0'
      }

      return [
        `${numeral(raw_value).format(format)}`,
        ` ${numeral(raw_value / total).format('0.00%')}`,
      ]
    },
    mapper: (data: any[], settings: any) => {
      const data_series: any[] = []
      const series_raw: any[] = []
      const series: number[] = []
      const labels: string[] = []
      const sub_labels: string[] = []
      const colors: any[] = []
      let total = 0

      // data = data.sort((a: any, b: any) => {
      //   if (a[settings.metrics[0]] < b[settings.metrics[0]]) {
      //     return 1
      //   }
      //   if (a[settings.metrics[0]] > b[settings.metrics[0]]) {
      //     return -1
      //   }
      //   return 0
      // })

      const { dimensions, metrics, config } = settings

      const color_pool = buildColorPool(data.length, true)

      // Init Series
      let count = 0
      const total_count = data.length
      data.forEach((item: any, idx: number) => {
        const val = Number(item[metrics[0]])
        total += val
        let label = normalizeLabel(item[dimensions[0]], settings)
        labels.push(label)
        colors.push(color_pool(count++))
        series_raw.push(Number(val.toFixed(2)))
        data_series.push(config.reverse ? count + 1 : total_count - count + 1)
      })

      return {
        series_raw,
        series: [
          {
            name: startCase(metrics[0]),
            data: data_series,
          },
        ],
        labels,
        sub_labels,
        colors,
        total,
      }
    },
  },
  // TODO Check pie settings
  pie: {
    base_options: {
      chart: {
        height: 350,
        type: 'pie',
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: true,
        },
      },
      dataLabels: {
        dropShadow: {
          enabled: true,
        },
      },
      labels: [],
      tooltip: {
        enabled: true,
        y: {},
      },
      legend: {
        show: true,
        position: 'bottom',
      },
      plotOptions: {
        pie: {
          startAngle: 0,
          endAngle: 360,
          expandOnClick: true,
        },
      },
    },
    dataLabelFormatter: (
      value: number,
      labels: string[],
      series: number[],
      __: number,
      opt: any,
    ) => [`${numeral(value).format('$0.00a')}`],
    tooltipFormatter: (value: number, labels: string[], series: number[], __: number, opt: any) => {
      let total = series.reduce((acc: number, item: any) => (acc += item[opt.dataPointIndex]), 0)
      return [`${numeral(value).format('$0.00a')}`, ` ${numeral(value / total).format('0.00%')}`]
    },
    mapper: (data: any[], settings: any) => {
      const series: number[] = []
      const labels: string[] = []
      const colors: string[] = []
      let total = 0

      const { dimensions, metrics } = settings

      const color_pool = buildColorPool(data.length)

      data.forEach((item: any, idx: number) => {
        const val = Number(item[metrics[0]])
        total += val
        series.push(val)
        labels.push(normalizeLabel(item[dimensions[0]], settings))
        colors.push(color_pool(idx))
      })

      return {
        series_raw: series,
        series,
        labels,
        colors,
        total,
      }
    },
  },
  treemap: {
    base_options: {
      chart: {
        type: 'treemap',
        toolbar: false,
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: true,
        },
        events: {},
      },
      dataLabels: {
        dropShadow: {
          enabled: true,
        },
      },
      labels: [],
      tooltip: {
        enabled: true,
        y: {},
      },
      legend: {
        show: false,
      },
      plotOptions: {
        treemap: {
          distributed: true,
          enableShades: false,
          dataLabels: {
            format: 'scale',
          },
        },
      },
    },
    dataLabelFormatter: (_: number, labels: string[], series: number[], __: number, opt: any) => [
      `${labels[opt.dataPointIndex]}`,
      `${numeral(series[opt.dataPointIndex]).format('$0.00a')}`,
    ],
    tooltipFormatter: (value: number, _: string[], __: number[], total: number, ___: any) => [
      `${numeral(value).format('$0.00a')}`,
      ` ${numeral(value / total).format('0.00%')}`,
    ],
    mapper: (data: any[], settings: any) => {
      const series: number[] = []
      const data_series: any[] = []
      const labels: any[] = []
      const colors: string[] = []
      const series_ids: string[] = []
      let total = 0

      const { dimensions, metrics } = settings

      const color_pool = buildColorPool(data.length)

      data.forEach((item: any, idx: number) => {
        const val = Number(item[metrics[0]])
        if (val === 0) {
          return
        }
        total += val
        series.push(val)
        const label = normalizeLabel(item[dimensions[0]], settings)
        data_series.push({
          x: label,
          y: val,
        })
        labels.push(label)
        colors.push(color_pool(idx))

        if (item[dimensions[1]]) {
          series_ids.push(item[dimensions[1]])
        }
      })

      return {
        series_raw: series,
        series: [
          {
            data: data_series,
          },
        ],
        labels,
        colors,
        total,
        series_ids,
      }
    },
  },
  line: {
    base_options: {
      chart: {
        type: 'line',
        toolbar: false,
        stacked: false,
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: true,
        },
      },
      dataLabels: {
        dropShadow: {
          enabled: true,
        },
      },
      yaxis: {
        labels: {
          show: true,
          formatter: (value: any) => `${numeral(value).format('$0.00a')}`,
        },
      },
      labels: [],
      tooltip: {
        enabled: true,
        y: {},
      },
      xaxis: {
        categories: [],
      },
      plotOptions: {
        bar: {
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: '13px',
                fontWeight: 900,
                color: '#000',
              },
            },
          },
        },
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'right',
        showForSingleSeries: true,
      },
    },
    dataLabelFormatter: (
      value: number,
      labels: string[],
      series: number[],
      __: number,
      opt: any,
    ) => [`${numeral(value).format('$0.00a')}`],
    tooltipFormatter: (value: number, _: string[], series: number[], __: number, opt: any) => {
      let total = opt.series.reduce(
        (acc: number, item: any) => (acc += item[opt.dataPointIndex]),
        0,
      )
      return [`${numeral(value).format('$0.00a')}`, ` ${numeral(value / total).format('0.00%')}`]
    },
    mapper: (data: any[], settings: any) => {
      const series: number[] = []
      const data_series: any[] = []
      const labels: any[] = []
      const colors: string[] = []
      const series_ids: string[] = []
      let total = 0
      let total_cumulative: any[] = []

      const { dimensions, metrics, config } = settings

      const groups: string[] = []

      let x_series: string[] = []

      // Sort if applicable
      if (config.date_dimension_format && !config.noSort) {
        data = data.sort(
          (a, b) =>
            moment(a[dimensions[0]], config.date_dimension_format).unix()
            - moment(b[dimensions[0]], config.date_dimension_format).unix(),
        )
      }

      // Init Series
      data.forEach((item: any, idx: number) => {
        const label = normalizeLabel(item[dimensions[1]], settings)
        const group_index = groups.indexOf(label)
        if (group_index === -1) {
          groups.push(label)
          data_series.push({ name: label, data: [] })
        }

        const x_label = normalizeLabel(item[dimensions[0]], settings)

        if (x_series.indexOf(x_label) === -1) {
          x_series.push(x_label)
        }
      })

      const color_pool = buildColorPool(groups.length)

      data_series.forEach((item: any, idx: number) => {
        x_series.forEach(() => {
          item.data.push(0)
        })
        if (item.name === 'CCL') {
          colors.push('#555c6e')
        } else if (item.name === 'SSL') {
          colors.push('#00C1DE')
        } else if (config.colors && config.colors[idx]) {
          colors.push(config.colors[idx])
        } else {
          colors.push(color_pool(idx))
        }
      })

      data.forEach((item: any, idx: number) => {
        const label = normalizeLabel(item[dimensions[0]], settings)
        const group_label = normalizeLabel(item[dimensions[1]], settings)
        const group_index = groups.indexOf(group_label)
        const x_index = x_series.indexOf(label)

        let val = Number(item[metrics[0]])
        if (isNaN(val)) {
          val = 0
        }
        if (!total_cumulative[group_index]) {
          total_cumulative[group_index] = 0
        }

        total += val
        total_cumulative[group_index] += val

        if (settings.config.cumulative) {
          data_series[group_index].data[x_index] = clone(total_cumulative[group_index])
          series.push(total_cumulative[group_index])
        } else {
          series.push(val)

          data_series[group_index].data[x_index] += val
        }

        if (['product', 'region', 'deal_type'].includes(dimensions[1])) {
          series_ids[group_index] = item[dimensions[1]].toLowerCase().replaceAll(' ', '_')
        } else if (dimensions[2]) {
          series_ids[group_index] = item[dimensions[2]]
        }
      })

      return {
        series_raw: series,
        series: data_series,
        labels: x_series,
        series_ids,
        colors,
        total,
      }
    },
  },
  bar: {
    base_options: {
      chart: {
        type: 'bar',
        toolbar: false,
        stacked: true,
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: true,
        },
      },
      dataLabels: {
        dropShadow: {
          enabled: true,
        },
      },
      yaxis: {
        labels: {
          show: true,
          formatter: (value: any) => `${numeral(value).format('0.00a')}`,
        },
      },
      labels: [],
      tooltip: {
        enabled: true,
        y: {},
      },
      xaxis: {
        categories: [],
      },
      plotOptions: {
        bar: {
          dataLabels: {
            total: {
              enabled: false,
              style: {
                fontSize: '13px',
                fontWeight: 900,
                color: '#000',
              },
            },
          },
          isFunnel: false,
        },
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'right',
        showForSingleSeries: true,
      },
    },
    dataLabelFormatter: (
      value: number,
      labels: string[],
      series: number[],
      __: number,
      opt: any,
    ) => [`${numeral(value).format('0.00a')}`],
    tooltipFormatter: (value: number, _: string[], series: number[], __: number, opt: any) => {
      let total = opt.series.reduce(
        (acc: number, item: any) => (acc += item[opt.dataPointIndex]),
        0,
      )
      return [`${numeral(value).format('0.00a')}`]
    },
    mapper: (data: any[], settings: any) => {
      const series: number[] = []
      const data_series: any[] = []
      const labels: any[] = []
      const colors: string[] = []
      const series_ids: string[] = []
      let total = 0

      const { dimensions, metrics, config } = settings

      const groups: string[] = []

      let x_series: string[] = []

      // Init Series
      data.forEach((item: any, idx: number) => {
        let label = normalizeLabel(item[dimensions[1]], settings)
        if (settings.config.custom_label) {
          label = settings.config.custom_label
        }
        const group_index = groups.indexOf(label)
        if (group_index === -1) {
          groups.push(label)
          data_series.push({ name: label, data: [] })
        }

        const x_label = normalizeLabel(item[dimensions[0]], settings)
        if (x_series.indexOf(x_label) === -1) {
          x_series.push(x_label)
        }
      })

      const color_pool = buildColorPool(groups.length)

      data_series.forEach((item: any, idx: number) => {
        x_series.forEach(() => {
          item.data.push(0)
        })
        colors.push(color_pool(idx))
      })

      if (config.date_dimension_format) {
        x_series = x_series.sort((a, b) => {
          if (config.sort_asc) {
            return (
              moment(b, config.date_dimension_format).unix()
              - moment(a, config.date_dimension_format).unix()
            )
          }
          return (
            moment(a, config.date_dimension_format).unix()
            - moment(b, config.date_dimension_format).unix()
          )
        })
      }

      data.forEach((item: any, idx: number) => {
        const val = Number(item[metrics[0]])
        total += val
        series.push(val)
        const label = normalizeLabel(item[dimensions[0]], settings)

        let group_label = normalizeLabel(item[dimensions[1]], settings)
        if (settings.config.custom_label) {
          group_label = settings.config.custom_label
        }
        const group_index = groups.indexOf(group_label)
        const x_index = x_series.indexOf(label)

        data_series[group_index].data[x_index] += val

        if (['product', 'region', 'deal_type'].includes(dimensions[1])) {
          series_ids[group_index] = item[dimensions[1]].toLowerCase().replaceAll(' ', '_')
        } else if (dimensions[2]) {
          series_ids[group_index] = item[dimensions[2]]
        }
      })
      return {
        series_raw: series,
        series: data_series,
        labels: x_series,
        series_ids,
        colors,
        total,
      }
    },
  },
  bar_stacked: {
    base_options: {
      chart: {
        type: 'bar',
        toolbar: false,
        stacked: true,
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: true,
        },
      },
      dataLabels: {
        dropShadow: {
          enabled: true,
        },
      },
      yaxis: {
        labels: {
          show: true,
          formatter: (value: any) => `${numeral(value).format('$0.00a')}`,
        },
      },
      labels: [],
      tooltip: {
        enabled: true,
        y: {},
      },
      xaxis: {
        categories: [],
      },
      plotOptions: {
        bar: {
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: '13px',
                fontWeight: 900,
                color: '#000',
              },
            },
          },
        },
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'right',
        showForSingleSeries: true,
      },
    },
    dataLabelFormatter: (
      value: number,
      labels: string[],
      series: number[],
      __: number,
      opt: any,
    ) => [`${numeral(value).format('$0.00a')}`],
    tooltipFormatter: (value: number, _: string[], series: number[], __: number, opt: any) => {
      let total = opt.series.reduce(
        (acc: number, item: any) => (acc += item[opt.dataPointIndex]),
        0,
      )
      return [`${numeral(value).format('$0.00a')}`, ` ${numeral(value / total).format('0.00%')}`]
    },
    mapper: (data: any[], settings: any) => {
      const series: number[] = []
      const data_series: any[] = []
      const labels: any[] = []
      const colors: string[] = []
      const series_ids: string[] = []
      let total = 0

      const { dimensions, metrics, config } = settings

      const groups: string[] = []

      let x_series: string[] = []

      // Init Series
      data.forEach((item: any, idx: number) => {
        const label = normalizeLabel(item[dimensions[1]], settings)
        const group_index = groups.indexOf(label)
        if (group_index === -1) {
          groups.push(label)
          data_series.push({ name: label, data: [] })
        }

        const x_label = normalizeLabel(item[dimensions[0]], settings)
        if (x_series.indexOf(x_label) === -1) {
          x_series.push(x_label)
        }
      })

      const color_pool = buildColorPool(groups.length)

      data_series.forEach((item: any, idx: number) => {
        x_series.forEach(() => {
          item.data.push(0)
        })

        if (item.name === 'CCL') {
          colors.push('#555c6e')
        } else if (item.name === 'SSL') {
          colors.push('#00C1DE')
        } else {
          colors.push(color_pool(idx))
        }
      })

      if (config.date_dimension_format) {
        x_series = x_series.sort((a, b) => {
          if (config.sort_asc) {
            return (
              moment(b, config.date_dimension_format).unix()
              - moment(a, config.date_dimension_format).unix()
            )
          }
          return (
            moment(a, config.date_dimension_format).unix()
            - moment(b, config.date_dimension_format).unix()
          )
        })
      }

      data.forEach((item: any, idx: number) => {
        const val = Number(item[metrics[0]])
        total += val
        series.push(val)
        const label = normalizeLabel(item[dimensions[0]], settings)
        const group_label = normalizeLabel(item[dimensions[1]], settings)
        const group_index = groups.indexOf(group_label)
        const x_index = x_series.indexOf(label)

        data_series[group_index].data[x_index] += val

        if (['product', 'region', 'deal_type'].includes(dimensions[1])) {
          series_ids[group_index] = item[dimensions[1]].toLowerCase().replaceAll(' ', '_')
        } else if (dimensions[2]) {
          series_ids[group_index] = item[dimensions[2]]
        }
      })
      return {
        series_raw: series,
        series: data_series,
        labels: x_series,
        series_ids,
        colors,
        total,
      }
    },
  },
  bar_horizontal: {
    base_options: {
      chart: {
        type: 'bar',
        toolbar: false,
        stacked: true,
        // stackType: '100%',
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: true,
        },
      },
      dataLabels: {
        dropShadow: {
          enabled: true,
        },
      },
      yaxis: {
        labels: {
          show: true,
          // formatter: (value: any) => `${numeral(value).format('$0.00a')}`,
        },
      },
      labels: [],
      tooltip: {
        enabled: true,
        y: {},
      },
      xaxis: {
        categories: [],
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: '13px',
                fontWeight: 900,
                color: '#000',
              },
            },
          },
        },
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'right',
        showForSingleSeries: true,
      },
    },
    dataLabelFormatter: (
      value: number,
      labels: string[],
      series: number[],
      __: number,
      opt: any,
      settings: any,
    ) => {
      let format = '$0.00a'

      if (settings.metrics[0] === 'count') {
        format = '0,0'
      }
      return [`${numeral(value).format(format)}`]
    },
    tooltipFormatter: (
      value: number,
      _: string[],
      series: number[],
      __: number,
      opt: any,
      settings: any,
    ) => {
      let total = opt.series.reduce(
        (acc: number, item: any) => (acc += item[opt.dataPointIndex]),
        0,
      )

      let format = '$0.00a'

      if (settings.metrics[0] === 'count') {
        format = '0,0'
      }

      return [`${numeral(value).format(format)}`, ` ${numeral(value / total).format('0.00%')}`]
    },
    mapper: (data: any[], settings: any) => {
      const series: number[] = []
      const data_series: any[] = []
      const labels: any[] = []
      const colors: string[] = []
      const series_ids: string[] = []
      let total = 0

      const { dimensions, metrics, config } = settings

      const groups: string[] = []

      let x_series: string[] = []

      // Init Series
      data.forEach((item: any, idx: number) => {
        if (item[dimensions[1]]) {
          const label = normalizeLabel(item[dimensions[1]], settings)
          const group_index = groups.indexOf(label)
          if (group_index === -1) {
            groups.push(label)
            data_series.push({ name: label, data: [] })
          }
        }

        if (item[dimensions[0]]) {
          const x_label = normalizeLabel(item[dimensions[0]], settings)
          if (x_series.indexOf(x_label) === -1) {
            x_series.push(x_label)
          }
        }
      })

      const color_pool = buildColorPool(groups.length, true)

      data_series.forEach((item: any, idx: number) => {
        x_series.forEach(() => {
          item.data.push(0)
        })
        colors.push(color_pool(idx))
      })

      if (config.date_dimension_format) {
        x_series = x_series.sort(
          (a, b) =>
            moment(a, config.date_dimension_format).unix()
            - moment(b, config.date_dimension_format).unix(),
        )
      }

      data.forEach((item: any, idx: number) => {
        const val = Number(item[metrics[0]])
        total += val
        series.push(val)
        const label = normalizeLabel(item[dimensions[0]], settings)
        const group_label = normalizeLabel(item[dimensions[1]], settings)
        const group_index = groups.indexOf(group_label)
        const x_index = x_series.indexOf(label)

        data_series[group_index].data[x_index] += val

        if (['product', 'region', 'deal_type'].includes(dimensions[1])) {
          series_ids[group_index] = item[dimensions[1]].toLowerCase().replaceAll(' ', '_')
        } else if (dimensions[2]) {
          series_ids[group_index] = item[dimensions[dimensions.length - 1]]
        }
      })

      return {
        series_raw: series,
        series: data_series,
        labels: x_series,
        series_ids,
        colors,
        total,
      }
    },
  },
  bar_marker: {
    base_options: {
      chart: {
        type: 'bar',
        toolbar: false,
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: true,
        },
      },
      dataLabels: {
        dropShadow: {
          enabled: true,
        },
      },
      xaxis: {
        labels: {
          show: true,
          formatter: (value: any) => `${numeral(value).format('$0.00a')}`,
        },
      },
      labels: [],
      tooltip: {
        enabled: true,
        y: {},
      },
      // xaxis: {
      //   categories: [],
      // },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'right',
        showForSingleSeries: true,
        customLegendItems: ['Sold', 'Plan'],
        markers: {
          fillColors: ['#00C1DE', '#b0ca36'],
        },
      },
    },
    dataLabelFormatter: (
      value: number,
      labels: string[],
      series: number[],
      __: number,
      opt: any,
      settings: any,
    ) => {
      const raw_value = series[opt.dataPointIndex]
      const label = labels[opt.dataPointIndex]

      let format = '$0.00a'
      if (settings.metrics[0] === 'count') {
        format = '0,0'
      }

      return [`${numeral(raw_value).format(format)}`]
    },
    tooltipFormatter: (
      value: number,
      labels: string[],
      series: number[],
      total: number,
      opt: any,
      settings: any,
    ) => {
      const raw_value = series[opt.dataPointIndex]
      const label = labels[opt.dataPointIndex]

      let format = '$0.00a'

      if (settings.metrics[0] === 'count') {
        format = '0,0'
      }

      return [`${numeral(value).format(format)}`]
    },
    mapper: (data: any[], settings: any) => {
      const series: number[] = []
      const data_series: any[] = []
      const labels: any[] = []
      // const colors: string[] = []
      const series_ids: string[] = []
      let total = 0

      const { dimensions, metrics, config } = settings

      const groups: string[] = []

      let x_series: string[] = []

      // Init Series
      data.forEach((item: any, idx: number) => {
        if (item[dimensions[0]]) {
          const label = normalizeLabel(item[dimensions[0]], settings)
          const group_index = groups.indexOf(label)
          if (group_index === -1) {
            groups.push(label)
            data_series.push({
              x: label,
              y: 0,
              goals: [],
            })
          }
        }
      })

      const colors = ['#00C1DE', '#b0ca36']

      data.forEach((item: any, idx: number) => {
        const val = Number(item[metrics[0]])
        const goal = Number(item[metrics[1]])
        total += val
        series.push(val)
        const group_label = normalizeLabel(item[dimensions[0]], settings)
        const group_index = groups.indexOf(group_label)

        data_series[group_index].y = val
        data_series[group_index].goals.push({
          name: 'Goal',
          value: goal,
          strokeWidth: 3,
          strokeHeight: 10,
          strokeColor: '#b0ca36',
        })

        if (['product', 'region', 'deal_type'].includes(dimensions[1])) {
          series_ids[group_index] = item[dimensions[1]].toLowerCase().replaceAll(' ', '_')
        } else if (dimensions[2]) {
          series_ids[group_index] = item[dimensions[dimensions.length - 1]]
        }
      })

      return {
        series_raw: series,
        series: [{ name: normalizeLabel(metrics[0], settings), data: data_series }],
        labels: x_series,
        series_ids,
        colors,
        total,
      }
    },
  },
  bar_proportional: {
    base_options: {
      chart: {
        type: 'bar',
        toolbar: false,
        stacked: true,
        stackType: '100%',
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: true,
        },
      },
      dataLabels: {
        dropShadow: {
          enabled: true,
        },
      },
      yaxis: {
        labels: {
          show: true,
          formatter: (value: any) => `${numeral(value / 100).format('0%')}`,
        },
      },
      labels: [],
      tooltip: {
        enabled: true,
        y: {},
      },
      xaxis: {
        categories: [],
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: '13px',
                fontWeight: 900,
                color: '#000',
              },
            },
          },
        },
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'right',
        showForSingleSeries: true,
      },
    },
    totalBarFormatter: (
      value: number,
      labels: string[],
      series: number[],
      __: number,
      opt: any,
      settings: any,
    ) => `${numeral(value).format(settings.config.totalFormat ?? '$0.00a')}`,
    dataLabelFormatter: (
      value: number,
      labels: string[],
      series: number[],
      __: number,
      opt: any,
      settings: any,
    ) => {
      let format = '$0.00a'

      if (settings.metrics[0] === 'count' || settings.metrics[0] === 'impressions') {
        format = '0,0'
      }

      return [
        `${numeral(opt.config.series[opt.seriesIndex].data[opt.dataPointIndex]).format(format)}`,
      ]
    },
    tooltipFormatter: (
      value: number,
      _: string[],
      series: number[],
      __: number,
      opt: any,
      settings: any,
    ) => {
      let total = opt.series.reduce(
        (acc: number, item: any) => (acc += item[opt.dataPointIndex]),
        0,
      )

      let format = '$0.00a'

      if (settings.metrics[0] === 'count' || settings.metrics[0] === 'impressions') {
        format = '0,0'
      }

      return [`${numeral(value).format(format)}`, ` ${numeral(value / total).format('0.00%')}`]
    },
    mapper: (data: any[], settings: any) => {
      const series: number[] = []
      const data_series: any[] = []
      const labels: any[] = []
      const colors: string[] = []
      const series_ids: string[] = []
      let total = 0

      const { dimensions, metrics, config } = settings

      const groups: string[] = []

      let x_series: string[] = []

      // Init Series
      data.forEach((item: any, idx: number) => {
        if (item[dimensions[1]]) {
          const label = normalizeLabel(item[dimensions[1]], settings)
          const group_index = groups.indexOf(label)
          if (group_index === -1) {
            groups.push(label)
            data_series.push({ name: label, data: [] })
          }
        }

        if (item[dimensions[0]]) {
          const x_label = normalizeLabel(item[dimensions[0]], settings)
          if (x_series.indexOf(x_label) === -1) {
            x_series.push(x_label)
          }
        }
      })

      const color_pool = buildColorPool(groups.length)

      data_series.forEach((item: any, idx: number) => {
        x_series.forEach(() => {
          item.data.push(0)
        })
        colors.push(color_pool(idx))
      })

      if (config.date_dimension_format) {
        x_series = x_series.sort(
          (a, b) =>
            moment(a, config.date_dimension_format).unix()
            - moment(b, config.date_dimension_format).unix(),
        )
      }
      data.forEach((item: any, idx: number) => {
        const val = Number(item[metrics[0]])
        total += val
        series.push(val)
        const label = normalizeLabel(item[dimensions[0]], settings)
        const group_label = normalizeLabel(item[dimensions[1]], settings)
        const group_index = groups.indexOf(group_label)
        const x_index = x_series.indexOf(label)

        if (group_index > 0) {
          data_series[group_index].data[x_index] += val

          if (['product', 'region', 'deal_type'].includes(dimensions[1])) {
            series_ids[group_index] = item[dimensions[1]].toLowerCase().replaceAll(' ', '_')
          } else if (dimensions[2]) {
            series_ids[group_index] = item[dimensions[dimensions.length - 1]]
          }
        }
      })

      return {
        series_raw: series,
        series: data_series,
        labels: x_series,
        series_ids,
        colors,
        total,
      }
    },
  },
  radialBar: {
    base_options: {
      chart: {
        type: 'radialBar',
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: true,
        },
      },
      dataLabels: {
        dropShadow: {
          enabled: true,
        },
      },
      labels: [],
      tooltip: {
        enabled: true,
        y: {},
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 5,
            size: '30%',
            background: 'transparent',
            image: undefined,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            },
          },
        },
      },
      legend: {
        show: true,
        floating: true,
        fontSize: '16px',
        position: 'left',
        offsetX: 0,
        offsetY: 5,
        showForSingleSeries: true,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          size: 0,
        },
        itemMargin: {
          vertical: 3,
        },
      },
    },
    legendFormatter: (_: number, labels: string[], series: number[], __: number, opt: any) => [
      `${labels[opt.seriesIndex]}`,
      `${numeral(series[opt.seriesIndex]).format('$0.00a')}`,
    ],
    tooltipFormatter: (value: number, _: string[], series: number[], total: number, opt: any) => [
      `${numeral(series[opt.seriesIndex]).format('$0.00a')}`,
      ` (${numeral(value / 100).format('0.00%')})`,
    ],
    mapper: (data: any[], settings: any) => {
      const series: number[] = []
      const series_raw: number[] = []
      const labels: string[] = []
      const colors: string[] = []
      let total = 0

      const { dimensions, metrics } = settings

      const color_pool = buildColorPool(data.length)

      total = data.reduce((acc: number, item: any) => (acc += Number(item[metrics[0]])), 0)

      data.forEach((item: any, idx: number) => {
        const val = Number(item[metrics[0]])
        const target = Number(item[metrics[1]])
        series.push((val / target) * 100)
        series_raw.push(val)
        labels.push(normalizeLabel(item[dimensions[0]], settings))
        colors.push(color_pool(idx))
      })

      return {
        series,
        series_raw,
        labels,
        colors,
        total,
      }
    },
  },
  gauge: {
    base_options: {
      chart: {
        type: 'radialBar',
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: true,
        },
      },
      dataLabels: {
        dropShadow: {
          enabled: true,
        },
        value: {
          fontSize: '30px',
          show: true,
        },
        style: {
          colors: ['#000000'],
        },
      },
      fill: {
        // type: 'gradient',
        // gradient: {
        //   shade: 'dark',
        //   shadeIntensity: 0.5,
        //   inverseColors: false,
        //   opacityFrom: 1,
        //   opacityTo: 1,
        //   type: 'horizontal',
        //   gradientToColors: ['#323E48'],
        //   stops: [0, 100],
        // },
        type: 'image',
        image: {
          src: ['/images/gradient_gauge_final.png'],
          width: 3812,
        },
      },
      stroke: {
        lineCap: 'butt',
      },
      labels: [],
      tooltip: {
        enabled: true,
        y: {
          title: {
            formatter: (seriesName: any) => '',
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          hollow: {
            margin: 5,
            size: '30%',
            background: 'transparent',
          },
          dataLabels: {
            name: {
              show: true,
              color: '#29323a',
            },
            value: {
              show: true,
            },
          },
        },
      },
      legend: {
        show: false,
        floating: true,
        fontSize: '16px',
        position: 'left',
        offsetX: 0,
        offsetY: 5,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          size: 0,
        },
        itemMargin: {
          vertical: 3,
        },
      },
    },
    plotFormatter: (value: number, labels: string[], series: number[], total: number, opt: any) => [
      // `${numeral(series.reduce((acc: number, item: number) => (acc += item), 0)).format('$0.00a')}`,
      // 'vs',
      // `${numeral(total).format('$0.00a')}`,
    ],
    tooltipFormatter: (
      value: number,
      labels: string[],
      series: number[],
      total: number,
      opt: any,
    ) => [
      `${numeral(series[opt.seriesIndex]).format('$0.0a')}`,
      ` (${numeral(value / 100).format('0.0%')})`,
    ],
    mapper: (data: any[], settings: any) => {
      const series: number[] = []
      const series_raw: number[] = []
      let labels: string[] = []
      const colors: string[] = []
      let total = 0

      const { dimensions, metrics, config } = settings

      // const color_pool = buildColorPool(5)

      total = data.reduce((acc: number, item: any) => (acc += Number(item[metrics[1]])), 0)

      data.forEach((item: any, idx: number) => {
        const val = Number(item[metrics[0]])
        const target = Number(item[metrics[1]])

        series.push((val / target) * 100)

        series_raw.push(val)
        labels.push(normalizeLabel(item[dimensions[0]], settings))
        colors.push('#323E48')
      })

      // ['#ff5135', '#313d49']

      if (config.set_label_to_percentage) {
        if (!data || !data[0] || !data[0][metrics[1]] || !data[0][metrics[0]]) {
          labels = [numeral(0).format('0.0%')]
        } else {
          labels = [
            numeral(Number(data[0][metrics[0]]) / Number(data[0][metrics[1]])).format('0.0%'),
          ]
        }
      }

      return {
        series,
        series_raw,
        labels,
        colors,
        total,
      }
    },
  },
  gauge_number: {
    base_options: {
      chart: {
        type: 'radialBar',
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: true,
        },
      },
      dataLabels: {
        dropShadow: {
          enabled: true,
        },
        value: {
          fontSize: '30px',
          show: true,
        },
        style: {
          colors: ['#000000'],
        },
      },
      fill: {
        type: 'image',
        image: {
          src: ['/images/gradient_gauge_final.png'],
          width: 3812,
        },
      },
      stroke: {
        lineCap: 'butt',
      },
      labels: [],
      tooltip: {
        enabled: true,
        y: {
          title: {
            formatter: (seriesName: any) => '',
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          hollow: {
            margin: 5,
            size: '30%',
            background: 'transparent',
          },
          dataLabels: {
            name: {
              show: true,
              color: '#29323a',
            },
            value: {
              show: true,
            },
          },
        },
      },
      legend: {
        show: false,
        floating: true,
        fontSize: '16px',
        position: 'left',
        offsetX: 0,
        offsetY: 5,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          size: 0,
        },
        itemMargin: {
          vertical: 3,
        },
      },
    },
    plotFormatter: (value: number, labels: string[], series: number[], total: number, opt: any) => [
      // `${numeral(series.reduce((acc: number, item: number) => (acc += item), 0)).format('$0.00a')}`,
      // 'vs',
      // `${numeral(total).format('$0.00a')}`,
    ],
    tooltipFormatter: (
      value: number,
      labels: string[],
      series: number[],
      total: number,
      opt: any,
    ) => [
      `${numeral(series[opt.seriesIndex]).format('0.00a')}`,
      ` (${numeral(value / 100).format('0.00%')})`,
    ],
    mapper: (data: any[], settings: any) => {
      const series: number[] = []
      const series_raw: number[] = []
      let labels: string[] = []
      const colors: string[] = []
      let total = 0

      const { dimensions, metrics, config } = settings

      const color_pool = buildColorPool(data.length)

      total = data.reduce((acc: number, item: any) => (acc += Number(item[metrics[1]])), 0)

      data.forEach((item: any, idx: number) => {
        const val = Number(item[metrics[0]])
        const target = Number(item[metrics[1]])

        series.push((val / target) * 100)

        series_raw.push(val)
        labels.push(normalizeLabel(item[dimensions[0]], settings))
        colors.push(color_pool(idx))
      })

      // ['#ff5135', '#313d49']

      if (config.set_label_to_percentage) {
        if (!data || !data[0] || !data[0][metrics[1]] || !data[0][metrics[0]]) {
          labels = [numeral(0).format('0.00%')]
        } else {
          labels = [
            numeral(Number(data[0][metrics[0]]) / Number(data[0][metrics[1]])).format('0.00%'),
          ]
        }
      }

      return {
        series,
        series_raw,
        labels,
        colors,
        total,
      }
    },
  },
  area: {
    base_options: {
      chart: {
        type: 'area',
        height: '100',
        width: '200',
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: true,
        },
        toolbar: {
          show: false,
        },
        stacked: true,
        stackType: '100%',
      },
      dataLabels: {
        dropShadow: {
          enabled: true,
        },
        formatter(val: any, opts: any) {
          return numeral(val).format('0.00a')
        },
      },
      stroke: {
        curve: 'smooth',
      },

      tooltip: {
        enabled: true,
        y: {},
        x: {
          show: false,
        },
      },
      legend: {
        position: 'bottom',
        show: true,
        showForSingleSeries: true,
      },
      plotOptions: {},
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        min: 0,
        max: 1,
        labels: {
          show: true,
          formatter: (value: any) => `${numeral(value / 100).format('0.00%')}`,
        },
      },
    },
  },
}
