import Api from './Api'

type Context = {
  perPage: any
  currentPage: number
  sortBy: any
  sortDesc: boolean
  filter: any
  query: any
}

export default class LeadershipDashboardModule {
  public queryAutomatedFilters(module: any) {
    let automated_filters: any = {}
    let query: any = []

    if (module.query && module.query.length) {
      query = [...module.query]
    }

    if (module.local_filters) {
      Object.entries(module.local_filters).forEach(([key, value]) => {
        if (key === 'dynamic_filter') {
          automated_filters.start = module.local_filters.dynamic_filter.start
          automated_filters.end = module.local_filters.dynamic_filter.end
          automated_filters.shortcut = module.local_filters.dynamic_filter.shortcut
        } else {
          automated_filters[key] = value
        }
      })
    }
    let metrics: any = []
    let dimensions: any = []

    if (module.local_query && module.local_query.length) {
      query = module.local_query
    }
    if (module.metrics) {
      metrics = module.metrics
    }
    if (module.dimensions) {
      dimensions = module.dimensions
    }

    if (module.region) {
      query.push(`region:${module.region}`)
    }

    if (module.sales_rep) {
      query.push(`sr:${module.sales_rep}`)
    }

    query = [...new Set(query)]

    return {
      query,
      metrics,
      dimensions,
      automated_filters,
    }
  }

  public async financialPlanOverview({ module }: any) {
    const { query, automated_filters } = this.queryAutomatedFilters(module)

    let api = new Api()

    return api
      .get('leadership-dashboard/overview', { query, ...automated_filters })
      .then((response: any) => response.data)
      .catch(err => ({ data: [] }))
  }

  public async yearOverview({ module }: any) {
    const {
      query, automated_filters, metrics, dimensions,
    } = this.queryAutomatedFilters(module)

    let api = new Api()

    return api
      .get('leadership-dashboard/year-overview', {
        query,
        metrics,
        dimensions,
        ...automated_filters,
      })
      .then((response: any) => response.data)
      .catch(err => ({ data: [] }))
  }
}
