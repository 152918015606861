import SelectOption from '@/models/interface/SelectOption'
import SystemDashboardAction from '@/models/SystemDashboardAction'

export default class RegionSalesRepGroup extends SystemDashboardAction {
  // Target Property to be changed or event name
  public target: string = 'groups'

  // Unique key for the action
  public key: string = 'view'

  // Label for the action
  public label: string = 'View By'

  // Options for the action if applicable
  public options: SelectOption[] = [
    new SelectOption('Region', 'region'),
    new SelectOption('Sales Rep', 'sales_rep'),
  ]

  constructor(args: any = null) {
    super()
    if (args && args.options) {
      this.options = args.options.map((option: any) => new SelectOption(option.text, option.value))
    }
    if (args && args.pushOption) {
      this.options.push(new SelectOption(args.pushOption.text, args.pushOption.value))
    }
  }
}
