import { buildColorPool } from '@/models/Util'
import Opportunity from '@/models/Opportunity'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import { startCase } from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import FinancialPlan from '@/models/FinancialPlan'

export default class InvoicedToPlanYear extends SystemDashboardWidget {
  public title: string = `Invoiced to Plan ${moment().format('YYYY')}`

  public key: string = 'financial_plan'

  public view: string = 'chart'

  public subView: string = 'radialBar'

  public loaderSize: string = '350px'

  public dimensions: string[] = ['quarter']

  public metrics: string[] = ['goal_achieved', 'goal']

  public size: any = {
    sm: 12,
    md: 4,
    lg: 4,
  }

  // API Filters
  public filters: any = {
    region: 'all',
    product: 'all',
  }

  public internalFilters: any = {
    year: moment().format('YYYY'),
    type: 'finance_goal',
  }

  public formatters: any = {
    label: (widget: SystemDashboardWidget, value: any, opt: any) => {
      const raw_value = widget.rawData[opt.dataPointIndex].amount
      const label = widget.chartSettings.labels[opt.dataPointIndex]
      return [`${label}: ${numeral(raw_value).format('$0.00a')}`]
    },
    tooltip: (widget: SystemDashboardWidget, value: any, opt: any) => [
      `${numeral(widget.chartSettings.series_raw[opt.dataPointIndex]).format('$0.00a')}`,
      ` (${numeral(value / 100).format('0.00%')})`,
    ],
    tooltipYTitle: (widget: SystemDashboardWidget, value: any, opt: any) => `${widget.chartSettings.raw_labels[opt.seriesIndex]}:`,
  }

  public internalQuery: any = ['scope:finance_goal']

  public get filterToQuery() {
    let query = []

    if (this.filters.product !== 'all') {
      query.push(`product:${this.filters.product}`)
    }

    if (this.filters.region !== 'all') {
      query.push(`region:${this.filters.region}`)
    }

    query.push(`year:${this.internalFilters.year}`)

    return query
  }

  public get payload() {
    const query = [...new Set([...this.query, ...this.internalQuery, ...this.filterToQuery])]
    const filters = {
      ...this.internalFilters,
    }
    return {
      dimensions: this.dimensions,
      metrics: this.metrics,
      filters,

      order: {
        percentage: 'asc',
      },
      query,
      snapshot: this.filters.snapshot || '',
    }
  }

  public dataSource() {
    // return Promise.resolve([
    //   {
    //     quarter: 'Q1',
    //     goal_achieved: '13455268.52',
    //     goal: '15552000.00',
    //     plan_name: 'Plan',
    //   },
    //   {
    //     quarter: 'Q2',
    //     goal_achieved: '14741082.38',
    //     goal: '17222135.00',
    //     plan_name: 'Plan',
    //   },
    //   {
    //     quarter: 'Q3',
    //     goal_achieved: '9233292.86',
    //     goal: '23921987.00',
    //     plan_name: 'Plan',
    //   },
    //   {
    //     quarter: 'Q4',
    //     goal_achieved: '0.00',
    //     goal: '26303878.00',
    //     plan_name: 'Plan',
    //   },
    // ])
    return FinancialPlan.getPlanDashboard(this.payload).then((res: any) =>
      res.data.result.sort((a: any, b: any) => b[this.metrics[0]] - a[this.metrics[0]]))
  }

  protected dataMapper(data: any): Promise<any> {
    const series: number[] = []
    const series_raw: number[] = []
    const labels: string[] = []
    const colors: string[] = []
    let total = 0
    let raw_labels: string[] = []
    const color_pool = buildColorPool(data.length)

    total = data.reduce((acc: number, item: any) => (acc += Number(item[this.metrics[0]])), 0)
    let count = 0
    data.forEach((item: any, idx: number) => {
      const val = Number(item[this.metrics[0]])
      const target = Number(item[this.metrics[1]])
      series.push((val / target) * 100)
      series_raw.push(val)
      const formatted_val = numeral(val).format('$0.00a')
      let label = startCase(item[this.dimensions[0]])
      raw_labels.push(label)
      // labels.push(label)
      labels.push(`${label} ${formatted_val}`)
      colors.push(color_pool(count++))
      total += val

      colors.push(color_pool(count++))
    })

    this.chartSettings.colors = colors

    this.chartSettings.total = total

    this.chartSettings.xaxis = {
      categories: labels,
    }
    this.chartSettings.labels = labels

    this.chartSettings.raw_labels = raw_labels

    this.chartSettings.series_raw = series_raw
    return Promise.resolve(series)
  }

  // protected dataMapper(data: any): Promise<any> {
  //   const data_series: any[] = []
  //   const labels: string[] = []
  //   const colors: string[] = []
  //   let total = 0

  //   const color_pool = buildColorPool(data.length, true)

  //   // Init Series
  //   let count = 0
  //   const total_count = data.length
  //   data.forEach((item: any, idx: number) => {
  //     const val = Number(item[this.metrics[0]])
  //     total += val
  //     const formatted_val = numeral(val).format('$0.00a')

  //     let label = startCase(item[this.dimensions[0]])

  //     labels.push(`${label} ${formatted_val}`)
  //     colors.push(color_pool(count++))
  //     data_series.push(val)
  //   })

  //   // TODO continue here
  //   this.chartSettings.colors = colors

  //   this.chartSettings.total = total

  //   this.chartSettings.xaxis = {
  //     categories: labels,
  //   }
  //   this.chartSettings.labels = labels
  //   return Promise.resolve(data_series)
  // }
}
