import Opportunity from '@/models/Opportunity'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import { buildColorPool } from '@/models/Util'
import { normalizeLabel } from '@/pages/ManagementDashboard/shared/helpers'
import { startCase } from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import PipelineSold from '../../filters/PipelineSold'

export default class MonthlyProductDistribution extends SystemDashboardWidget {
  public title: string = `Monthly Product Distribution ${moment().format('YYYY')}`

  public key = 'monthly-product-revenue'

  public view: string = 'chart'

  public subView: string = 'bar_stacked'

  public dimensions = ['month', 'product']

  public metrics = ['amount']

  public loaderSize: string = '350px'

  public size: any = {
    lg: 12,
    md: 12,
  }

  constructor(args: any = null) {
    super()

    if (args && args.dimensions) {
      this.dimensions = args.dimensions
    }

    if (args && args.metrics) {
      this.metrics = args.metrics
    }

    if (args && args.filters) {
      this.filters = Object.assign(this.filters, args.filters)
    }
    if (args && args.internalFilters) {
      this.internalFilters = Object.assign(this.internalFilters, args.internalFilters)
    }

    if (args && args.rightCol) {
      this.rightCol = args.rightCol
    }

    if (args && args.leftCol) {
      this.leftCol = args.leftCol
    }

    if (args && args.size) {
      this.size = args.size
    }

    if (args && args.title) {
      this.title = args.title
    }

    if (args && args.key) {
      this.key = args.key
    }

    if (args && args.custom_formatters) {
      this.custom_formatters = Object.assign(this.custom_formatters, args.custom_formatters)
    }

    if (args && args.internalQuery) {
      this.internalQuery = args.internalQuery
    }

    if (args && args.internalQueryPush) {
      this.internalQuery.push(args.internalQueryPush)
    }
  }

  public filters: any = {
    product: 'all',
    region: 'all',
  }

  public internalFilters: any = {
    year: parseInt(moment().format('YYYY')),
    dynamic_filter: 'is:sold',
  }

  public rightCol: any = [new PipelineSold()]

  public events: any = {
    click: (widget: MonthlyProductDistribution, event: any, chartContext: any, opts: any) => {
      let name = startCase(widget.data[opts.seriesIndex].name)
      let key = ''

      if (widget.dimensions[1] === 'deal_type') {
        key = name === 'Direct' ? 'direct' : '3rd_party'
        widget.viewComponent.applyFilter(
          `${widget.dimensions[1]}:${name}`,
          `${widget.dimensions[1]}:${key}`,
          'query',
        )

        return
      }

      if (widget.dimensions[1] === 'product' || widget.dimensions[1] === 'region') {
        widget.viewComponent.applyFilter(
          `${widget.dimensions[1]}:${name}`,
          name.toLowerCase().replaceAll(' ', '_'),
          'filters',
          widget.dimensions[1],
        )
      }
    },
  }

  public get filterToQuery() {
    let query = []

    if (this.filters.product !== 'all') {
      query.push(`product:${this.filters.product}`)
    }

    if (this.filters.region !== 'all') {
      query.push(`region:${this.filters.region}`)
    }

    query.push(`year:${this.internalFilters.year}`)

    return query
  }

  public internalQuery: any = ['scope:agency']

  public get payload() {
    const query = [...new Set([...this.query, ...this.internalQuery, ...this.filterToQuery])]

    return {
      metrics: this.metrics,
      dimensions: this.dimensions,
      query,
      filters: {
        ...this.internalFilters,
      },
    }
  }

  public async dataSource() {
    return Opportunity.buildAdvancedQuery(this.payload).then((res: any) => res.data.result)
  }

  public custom_formatters: any = {
    yaxis: null,
    label: null,
    tooltip: null,
  }

  public getCustomFilters({
    formatter_name, widget, value, return_as_array, opt,
  }: any) {
    let formatterd = numeral(value).format('$0,00a')

    if (this.custom_formatters[formatter_name]) {
      formatterd = this.custom_formatters[formatter_name]({ widget, value, opt })
    }

    if (return_as_array) {
      return [formatterd]
    }

    return formatterd
  }

  public formatters: any = {
    yaxis: (widget: SystemDashboardWidget, value: any, opt: any) =>
      this.getCustomFilters({
        formatter_name: 'yaxis',
        widget,
        value,
        opt,
      }),
    label: (widget: SystemDashboardWidget, value: any, opt: any) =>
      this.getCustomFilters({
        formatter_name: 'label',
        widget,
        value,
        opt,
      }),
    tooltip: (widget: SystemDashboardWidget, value: any, opt: any) =>
      this.getCustomFilters({
        formatter_name: 'tooltip',
        widget,
        value,
        return_as_array: true,
        opt,
      }),
  }

  protected dataMapper(data: any): Promise<any> {
    const series: number[] = []
    const data_series: any[] = []
    const labels: any[] = []
    const colors: string[] = []
    const series_ids: string[] = []
    let total = 0
    let total_cumulative: any[] = []

    const groups: string[] = []

    let x_series: string[] = []

    // Init Series
    data.forEach((item: any, idx: number) => {
      const label = item[this.dimensions[1]]
      const group_index = groups.indexOf(label)
      if (group_index === -1) {
        groups.push(label)
        data_series.push({ name: label, data: [] })
      }

      const x_label = item[this.dimensions[0]]

      if (x_series.indexOf(x_label) === -1) {
        x_series.push(x_label)
      }
    })

    const color_pool = buildColorPool(groups.length)

    data_series.forEach((item: any, idx: number) => {
      x_series.forEach(() => {
        item.data.push(0)
      })
      if (item.name === 'CCL') {
        colors.push('#555c6e')
      } else if (item.name === 'SSL') {
        colors.push('#00C1DE')
      } else {
        colors.push(color_pool(idx))
      }
    })

    data.forEach((item: any, idx: number) => {
      const label = item[this.dimensions[0]]
      const group_label = item[this.dimensions[1]]
      const group_index = groups.indexOf(group_label)
      const x_index = x_series.indexOf(label)

      let val = Number(item[this.metrics[0]])
      if (isNaN(val)) {
        val = 0
      }
      if (!total_cumulative[group_index]) {
        total_cumulative[group_index] = 0
      }

      total += val
      total_cumulative[group_index] += val

      series.push(val)

      data_series[group_index].data[x_index] += val

      if (['product', 'region', 'deal_type'].includes(this.dimensions[1])) {
        series_ids[group_index] = item[this.dimensions[1]].toLowerCase().replaceAll(' ', '_')
      } else if (this.dimensions[2]) {
        series_ids[group_index] = item[this.dimensions[2]]
      }
    })

    // Udpate Settings
    this.chartSettings.colors = colors

    x_series = x_series.map(x => startCase(x))

    this.chartSettings.xaxis = {
      categories: x_series,
    }

    this.chartSettings.labels = labels

    return Promise.resolve(data_series)
  }
}
