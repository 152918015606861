import SalesActivity from '@/models/SalesActivity'
import { buildColorPool } from '@/models/Util'
import { FILTERS, normalizeLabel } from '@/pages/ManagementDashboard/shared/helpers'

import numeral from 'numeral'

export default {
  title: 'Sold',
  key: 'sold-per',
  source: 'request',
  request: {
    module: new SalesActivity(),
    exec: 'soldPer',
  },
  pre_load: {},
  type: 'line',
  size: {
    lg: 8,
    md: 8,
    sm: 12,
  },
  config: {
    dynamic_date: true,
    totalFormat: '$0.00a',
    rawLables: true,
  },
  dimensions: ['date', 'activity'],
  capitalize_labels: true,
  metrics: ['amount'],
  filters: {
    dynamic_filter: {
      ...FILTERS.LAST_4_WEEKS,
    },
    group_mode: 'week',
    group_role: 'team',
  },
  query: [],
  order: {},
  dynamic_options: [
    { target: 'local_filters', sub_target: 'dynamic_filter' },
    {
      target: 'filters',
      sub_target: 'group_mode',
      options: [
        { text: 'Week', value: 'week' },
        { text: 'Month', value: 'month' },
        { text: 'Quarter', value: 'quarter' },
      ],
    },

    {
      target: 'filters',
      sub_target: 'group_role',
      options: [
        { text: 'Region', value: 'team' },
        { text: 'Sales Rep', value: 'individual' },
      ],
    },
  ],
  formatters: {
    yaxis: {
      labels: {
        formatter: (value: number, labels: string[], series: number[], __: number, opt: any) =>
          `${numeral(value).format('$0.00a')}`,
      },
    },
    dataLabelFormatter: (value: number, labels: string[], series: number[], __: number, opt: any) =>
      `${numeral(value).format('$0.00a')}`,
    tooltipFormatter: (value: number, labels: string[], series: any[], total: number, opt: any) => [
      numeral(value).format('$0.00a'),
    ],
  },
}
