import HamiltonModule from '@/models/HamiltonModule'
import { FILTERS } from '@/pages/ManagementDashboard/shared/helpers'
import numeral from 'numeral'

export default {
  title: 'Publisher share',
  key: 'publisher-share',
  source: 'request',
  request: {
    module: new HamiltonModule(),
    exec: 'publisherShare',
  },
  pre_load: {},
  type: 'bar_proportional',
  size: {
    lg: 12,
    md: 12,
  },
  dimensions: ['created_at', 'publisher'],
  metrics: ['impressions'],
  config: {
    dynamic_date: true,
    date_dimension_format: 'YYYY-MM-DD',
    date_format_labels: 'MMM Do',
    totalFormat: '0.00a',
  },
  filters: {
    dynamic_filter: {
      ...FILTERS.MONTH,
    },
  },
  query: [],
  order: {},
  labels: ['name'],
  dynamic_options: [{ target: 'local_filters', sub_target: 'dynamic_filter' }],
  formatters: {
    yaxis: {
      labels: {
        formatter: (value: number, labels: string[], series: number[], __: number, opt: any) =>
          `${numeral(value * 0.01).format('0%')}`,
      },
    },
    dataLabelFormatter: (value: number, labels: string[], series: number[], __: number, opt: any) =>
      `${numeral(value * 0.01).format('0.00%')}`,
    tooltipFormatter: (value: number, labels: string[], series: any[], total: number, opt: any) => [
      numeral(value).format('0.00a'),
    ],
  },
}
