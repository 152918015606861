import Api from '@/models/Api'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import moment from 'moment'

export default class PipelineDeficitWidget extends SystemDashboardWidget {
  public title: string = 'Pipeline Deficit to FYE'

  public key: string = 'pipeline_deficit'

  public view: string = 'card'

  public size: any = {
    sm: 12,
    md: 6,
    lg: 3,
  }

  // API Filters
  public filters: any = {
    region: 'all',
    product: 'all',
  }

  public internalFilters: any = {
    start: moment().startOf('year').format('YYYY-MM-DD'),
    end: moment().endOf('year').format('YYYY-MM-DD'),
  }

  public flattenPayload: boolean = true

  public dataSource() {
    let api = new Api()

    return api
      .get('leadership-dashboard/overview', this.payload)
      .then((response: any) => response.data.result)
      .catch((err: any) => ({ data: [] }))
  }

  protected dataMapper(data: any): Promise<any> {
    const color = data.pipeline_deficit_to_FYE.startsWith('-') ? 'text-danger' : 'text-success'
    return Promise.resolve(` <div class="text-center p-4">
      <div class="d-flex justify-content-center">
       <h4 class="${color}">${data.pipeline_deficit_to_FYE}</h4>
        </div>
      </div>`)
  }
}
