import Company from '@/models/Company'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import SystemtModule from '@/store/SystemModule'
import moment from 'moment'
import numeral from 'numeral'
import { getModule } from 'vuex-module-decorators'

export default class RealTimeTodayWidget extends SystemDashboardWidget {
  public title: string = 'Real-time Today'

  public key = 'realtime'

  public view = 'custom'

  public size = {
    lg: 12,
    md: 12,
  }

  public dimensions: string[] = []

  public metrics: string[] = ['delivered', 'goal']

  constructor(args: any = null) {
    super()

    if (args && args.dimensions) {
      this.dimensions = args.dimensions
    }

    if (args && args.metrics) {
      this.metrics = args.metrics
    }

    if (args && args.filters) {
      this.filters = Object.assign(this.filters, args.filters)
    }
    if (args && args.internalFilters) {
      this.internalFilters = Object.assign(this.internalFilters, args.internalFilters)
    }
    if (args && args.internalQuery) {
      this.internalQuery = Object.assign(this.internalQuery, args.internalQuery)
    }

    if (args && args.rightCol) {
      this.rightCol = args.rightCol
    }

    if (args && args.leftCol) {
      this.leftCol = args.leftCol
    }

    if (args && args.size) {
      this.size = args.size
    }

    if (args && args.title) {
      this.title = args.title
    }

    if (args && args.key) {
      this.key = args.key
    }
  }

  public show() {
    const system = getModule(SystemtModule)

    return !system.user?.isSystem
  }

  public get payload() {
    // const query = [...new Set([...this.query, ...this.internalQuery])]

    return { id: this.filters.company_id }
  }

  public dataSource() {
    return Company.realtimeMetrics(this.payload).then((res: any) => res.result)
  }

  public get customData() {
    const data = this.data

    const impressions = numeral(data.impressions).format('0.00a')

    const hits = numeral(data.hits).format('0.00a')

    const requests = numeral(data.requests).format('0.00a')

    const miss = numeral(data.miss).format('0.00a')

    const rate_fill = numeral(
      data.miss > 0 && data.requests > 0 ? data.hits / data.requests : 1,
    ).format('0.00%')

    const rate_303 = numeral(
      data.impressions > 0 && data.miss > 0 ? data.miss / data.impressions : 0,
    ).format('0.00%')

    const rate_delivery = numeral(
      data.impressions > 0 && data.requests > 0 ? data.impressions / data.requests : 0,
    ).format('0.00%')

    const updated_at = moment(data.updated_at).format('MMM DD, YYYY hh:mm A')

    return `<div class="row text-center mt-5">
      <div class="col">
        <h6>Requests</h6>
        <h4>${requests}</h4>
      </div>
      <div class="col">
        <h6>303s</h6>
        <h4>${miss}</h4>
      </div>
      <div class="col">
        <h6>Impressions</h6>
        <h4>${impressions}</h4>
      </div>
      <div class="col">
        <h6>Fill Rate</h6>
        <h4>${rate_fill}</h4>
      </div>
      <div class="col">
        <h6>Delivery Rate</h6>
        <h4>${rate_delivery}</h4>
      </div>
      <div class="col">
      <h6>303 Rate</h6>
      <h4>${rate_303}</h4>
    </div>
    </div>
    <div class="text-center text-muted mt-3">
      Updated at: ${updated_at}
    </div>
    `
  }
}
