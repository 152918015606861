import { FILTERS } from '@/pages/ManagementDashboard/shared/helpers'
import OpportunityActivity from '@/models/OpportunityActivity'
import numeral from 'numeral'
import SalesActivityDriver from './drivers/SalesActivityDriver'

const ops = [
  {
    title: 'Weekly Summary',
    key: 'weekly-summary',
    source: 'request',
    request: {
      module: new SalesActivityDriver(),
      exec: 'salesRepWeeklyActivity',
    },
    type: 'table',
    size: {
      lg: 12,
      md: 12,
    },
    filters: {},
    capitalize_labels: true,
    config: {
      noPagination: true,
      fields: [
        {
          key: 'sales_rep',
          label: 'Sales Rep',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'sold',
          label: 'Total Sold',
          class: 'text-center align-middle',
          show: true,
          type: 'currency_abbreviate',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
          total: 'currency_abbreviate',
        },
        {
          key: 'pitched',
          label: 'Total Pitched',
          class: 'text-center align-middle',
          show: true,
          type: 'currency_abbreviate',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
          total: 'currency_abbreviate',
        },
        {
          key: 'meetings_this_week',
          label: 'Total Meetings This Week',
          class: 'text-center align-middle',
          show: true,
          type: 'separator',
          thClass: 'align-middle text-center',
          sortable: true,
          total: 'sum',
        },
        {
          key: 'meetings_scheduled',
          label: 'Scheduled',
          class: 'text-center align-middle',
          show: true,
          type: 'separator',
          thClass: 'align-middle text-center',
          sortable: true,
          total: 'sum',
        },
      ],
    },
    dimensions: ['sales_rep'],
    metrics: ['amount'],
    query: [],
  },
  {
    title: 'Closed Business This Week',
    key: 'closed-business-this-week',
    source: 'opportunity-advanced',
    type: 'table',
    size: {
      lg: 12,
      md: 12,
    },
    filters: {},
    capitalize_labels: true,
    config: {
      noPagination: true,
      fields: [
        {
          key: 'sales_rep',
          label: 'Sales Rep',
          class: 'text-center align-middle',
          show: true,
          type: 'date',
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'agency',
          label: 'Agency',
          class: 'text-center align-middle',
          show: true,
          type: 'capitalize_replace',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },
        {
          key: 'advertiser',
          label: 'Advertiser',
          class: 'text-center align-middle',
          show: true,
          type: 'capitalize_replace',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },

        {
          key: 'amount',
          label: 'Amount',
          class: 'text-center align-middle',
          show: true,
          type: 'currency',
          thClass: 'align-middle text-center',
          sortable: true,
          total: 'currency_abbreviate',
        },
        {
          key: 'revenue_period',
          label: 'Revenue Period',
          class: 'text-center align-middle',
          show: true,
          type: 'text',
          thClass: 'align-middle text-center',
          sortable: true,
        },
      ],
    },
    dimensions: ['sales_rep', 'agency', 'advertiser'],
    metrics: ['raw_amount', 'revenue_period'],
    query: ['is:closed_this_week'],
  },
  {
    title: 'Pitched This Week',
    key: 'pitched-this-week',
    source: 'opportunity-advanced',
    type: 'table',
    size: {
      lg: 12,
      md: 12,
    },
    filters: {},
    capitalize_labels: true,
    config: {
      noPagination: true,
      fields: [
        {
          key: 'sales_rep',
          label: 'Sales Rep',
          class: 'text-center align-middle',
          show: true,
          type: 'text',
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'agency',
          label: 'Agency',
          class: 'text-center align-middle',
          show: true,
          type: 'text',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },
        {
          key: 'advertiser',
          label: 'Advertiser',
          class: 'text-center align-middle',
          show: true,
          type: 'text',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },
        // {
        //   key: 'contacts',
        //   label: 'Contacts',
        //   class: 'text-center align-middle',
        //   show: true,
        //   thClass: 'align-middle text-center',
        //   sortable: true,
        //   formatter: (_: any, __: any, activity: OpportunityActivity) => {
        //     if (activity.contacts && activity.contacts.length > 0) {
        //       return activity.contacts.map(contact => contact.first_name).join(', ')
        //     }

        //     return 'N/A'
        //   },
        // },
        {
          key: 'amount',
          label: 'Amount',
          class: 'text-center align-middle',
          show: true,
          type: 'currency',
          thClass: 'align-middle text-center',
          sortable: true,
          total: 'currency_abbreviate',
        },
        {
          key: 'revenue_period',
          label: 'Revenue Period',
          class: 'text-center align-middle',
          show: true,
          type: 'text',
          thClass: 'align-middle text-center',
          sortable: true,
        },
      ],
    },
    dimensions: ['sales_rep', 'agency', 'advertiser'],
    metrics: ['raw_amount', 'revenue_period'],
    query: ['is:pitched_this_week'],
  },
  {
    title: 'Meetings This Week',
    key: 'meetings-this-week',
    source: 'request',
    request: {
      module: new SalesActivityDriver(),
      exec: 'salesRepActivity',
    },
    type: 'table',
    size: {
      lg: 12,
      md: 12,
    },
    filters: {},
    capitalize_labels: true,
    config: {
      perPage: 100,
      fields: [
        {
          key: 'sales_rep',
          label: 'Sales Rep',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          formatter: (_: any, __: any, activity: OpportunityActivity) =>
            activity.owner_model.sales_rep.name,
        },
        {
          key: 'sub_type',
          label: 'Meeting Sub-Type',
          class: 'text-center align-middle',
          show: true,
          type: 'capitalize_replace',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },
        {
          key: 'agency',
          label: 'Agency',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          formatter: (_: any, __: any, activity: OpportunityActivity) =>
            activity.owner_model.agency.name,
        },
        {
          key: 'advertiser',
          label: 'Advertiser',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          formatter: (_: any, __: any, activity: OpportunityActivity) =>
            activity.owner_model.advertiser.name,
        },
        {
          key: 'name',
          label: 'Subject',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'contacts',
          label: 'Contacts',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          formatter: (_: any, __: any, activity: OpportunityActivity) => {
            if (activity.contacts && activity.contacts.length > 0) {
              if (activity.contacts.length > 3) {
                let remaining = activity.contacts.length - 3
                return `${activity.contacts
                  .slice(0, 3)
                  .map((contact: any) => contact.first_name)
                  .join(', ')} +${remaining} more`
              }
              return activity.contacts.map((contact: any) => contact.first_name).join(', ')
            }

            return 'N/A'
          },
        },
        {
          key: 'scheduled_at',
          label: 'Date',
          class: 'text-center align-middle',
          show: true,
          type: 'date',
          thClass: 'align-middle text-center',
          sortable: true,
        },
      ],
    },
    dimensions: [],
    metrics: [],
    query: ['is:this_week', 'type:meeting'],
  },
  {
    title: 'Meetings Scheduled',
    key: 'meetings-scheduled',
    source: 'request',
    request: {
      module: new SalesActivityDriver(),
      exec: 'salesRepActivity',
    },
    type: 'table',
    size: {
      lg: 12,
      md: 12,
    },
    filters: {},
    capitalize_labels: true,
    config: {
      perPage: 100,
      fields: [
        {
          key: 'sales_rep',
          label: 'Sales Rep',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          formatter: (_: any, __: any, activity: OpportunityActivity) =>
            activity.owner_model.sales_rep.name,
        },
        {
          key: 'sub_type',
          label: 'Meeting Sub-Type',
          class: 'text-center align-middle',
          show: true,
          type: 'capitalize_replace',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },
        {
          key: 'agency',
          label: 'Agency',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          formatter: (_: any, __: any, activity: OpportunityActivity) =>
            activity.owner_model.agency.name,
        },
        {
          key: 'advertiser',
          label: 'Advertiser',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          formatter: (_: any, __: any, activity: OpportunityActivity) =>
            activity.owner_model.advertiser.name,
        },
        {
          key: 'name',
          label: 'Subject',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'contacts',
          label: 'Contacts',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          formatter: (_: any, __: any, activity: OpportunityActivity) => {
            if (activity.contacts && activity.contacts.length > 0) {
              if (activity.contacts.length > 3) {
                let remaining = activity.contacts.length - 3
                return `${activity.contacts
                  .slice(0, 3)
                  .map(contact => contact.first_name)
                  .join(', ')} +${remaining} more`
              }
              return activity.contacts.map(contact => contact.first_name).join(', ')
            }

            return 'N/A'
          },
        },
        {
          key: 'scheduled_at',
          label: 'Date',
          class: 'text-center align-middle',
          show: true,
          type: 'date',
          thClass: 'align-middle text-center',
          sortable: true,
        },
      ],
    },
    dimensions: [],
    metrics: [],
    query: ['is:scheduled', 'type:meeting'],
  },
  {
    title: 'Pitched Amount',
    key: 'pitched-amount',
    source: 'request',
    request: {
      module: new SalesActivityDriver(),
      exec: 'pitchedKPI',
    },
    type: 'line',
    size: {
      lg: 12,
      md: 12,
    },
    config: {
      dynamic_date: true,
      rawLables: true,
      custom_data: (data: any) => `
      `,
    },
    dimensions: ['date', 'activity'],
    capitalize_labels: true,
    metrics: ['amount'],
    filters: {
      dynamic_filter: {
        ...FILTERS.LAST_4_WEEKS,
      },
      group_mode: 'group_mode:week',
    },
    dynamic_options: [
      { target: 'local_filters', sub_target: 'dynamic_filter' },
      {
        target: 'filters',
        sub_target: 'group_mode',
        options: [
          { text: 'Day', value: 'group_mode:day' },
          { text: 'Week', value: 'group_mode:week' },
          { text: 'Month', value: 'group_mode:month' },
          { text: 'Quarter', value: 'group_mode:quarter' },
        ],
      },
    ],
    formatters: {
      yaxis: {
        labels: {
          formatter: (value: number, labels: string[], series: number[], __: number, opt: any) =>
            `${numeral(value).format('$0.00a')}`,
        },
      },
      dataLabelFormatter: (
        value: number,
        labels: string[],
        series: number[],
        __: number,
        opt: any,
      ) => `${numeral(value).format('$0.00a')}`,
      tooltipFormatter: (
        value: number,
        labels: string[],
        series: any[],
        total: number,
        opt: any,
      ) => [numeral(value).format('$0.00a')],
    },
  },
  {
    title: 'Closed Amount',
    key: 'closed-amount',
    source: 'request',
    request: {
      module: new SalesActivityDriver(),
      exec: 'closedKPI',
    },
    type: 'line',
    size: {
      lg: 12,
      md: 12,
    },
    config: {
      dynamic_date: true,
      colors: ['#00C1DE'],
      rawLables: true,
    },
    dimensions: ['date', 'activity'],
    capitalize_labels: true,
    metrics: ['amount'],
    filters: {
      dynamic_filter: {
        ...FILTERS.LAST_4_WEEKS,
      },
      group_mode: 'group_mode:week',
    },
    dynamic_options: [
      { target: 'local_filters', sub_target: 'dynamic_filter' },
      {
        target: 'filters',
        sub_target: 'group_mode',
        options: [
          { text: 'Day', value: 'group_mode:day' },
          { text: 'Week', value: 'group_mode:week' },
          { text: 'Month', value: 'group_mode:month' },
          { text: 'Quarter', value: 'group_mode:quarter' },
        ],
      },
    ],
    formatters: {
      yaxis: {
        labels: {
          formatter: (value: number, labels: string[], series: number[], __: number, opt: any) =>
            `${numeral(value).format('$0.00a')}`,
        },
      },
      dataLabelFormatter: (
        value: number,
        labels: string[],
        series: number[],
        __: number,
        opt: any,
      ) => `${numeral(value).format('$0.00a')}`,
      tooltipFormatter: (
        value: number,
        labels: string[],
        series: any[],
        total: number,
        opt: any,
      ) => [numeral(value).format('$0.00a')],
    },
  },
  {
    title: 'Activity KPIs',
    key: 'activity-kpis',
    source: 'request',
    request: {
      module: new SalesActivityDriver(),
      exec: 'activityKPI',
    },
    type: 'line',
    size: {
      lg: 12,
      md: 12,
    },
    config: {
      dynamic_date: true,
      totalFormat: '0.00a',
      rawLables: true,
    },
    dimensions: ['date', 'activity'],
    capitalize_labels: true,
    metrics: ['amount'],
    filters: {
      dynamic_filter: {
        ...FILTERS.LAST_4_WEEKS,
      },
      group_mode: 'group_mode:week',
    },
    dynamic_options: [
      { target: 'local_filters', sub_target: 'dynamic_filter' },
      {
        target: 'filters',
        sub_target: 'group_mode',
        options: [
          { text: 'Day', value: 'group_mode:day' },
          { text: 'Week', value: 'group_mode:week' },
          { text: 'Month', value: 'group_mode:month' },
          { text: 'Quarter', value: 'group_mode:quarter' },
        ],
      },
    ],
    formatters: {
      yaxis: {
        labels: {
          formatter: (value: number, labels: string[], series: number[], __: number, opt: any) =>
            `${numeral(value).format('0,0')}`,
        },
      },
      dataLabelFormatter: (
        value: number,
        labels: string[],
        series: number[],
        __: number,
        opt: any,
      ) => `${numeral(value).format('0,0')}`,
      tooltipFormatter: (
        value: number,
        labels: string[],
        series: any[],
        total: number,
        opt: any,
      ) => [numeral(value).format('0,0')],
    },
  },
] as any[]
export default ops
// export default ops.filter((op) => op.key === 'pitched-amount')
