import SalesActivity from '@/models/SalesActivity'
import numeral from 'numeral'

export default {
  title: 'Pipeline Movment This Week',
  key: 'pipeline-movement-weekly',
  source: 'request',
  request: {
    module: new SalesActivity(),
    exec: 'pipelineMovementWeekly',
  },
  type: 'funnel',
  size: {
    lg: 4,
    md: 12,
    sm: 12,
  },
  config: {
    dynamic_date: true,
    totalFormat: '$0.00a',
    custom_data: (data: any) => {
      let total = numeral(data.reduce((acc:number, item:any) => acc + item.amount, 0)).format('$0.00a')

      let performance = numeral(data.reduce((acc:number, item:any) => {
        if (item.status === 'won' || item.status === 'processing' || item.status === 'repeat_business' || item.status === 'consideration') {
          return acc + item.amount
        } if (item.status === 'pitched') {
          return acc + (item.amount * 0.1)
        }
        return acc
      }, 0)).format('$0.00a')

      return `<div class="row text-center mt-3">
      <div class="col">
        <h6>Total Movement</h6>
        <h4>${total}</h4>
      </div>
    </div>
    <div style="font-size: x-small; bottom: 1rem; position: absolute" class="text-muted mt-5">
      * Pipeline movement can count multiple times for the same opportunity if it moves multiple stages in the same week
    </div>
    `
    },
  },
  capitalize_labels: true,
  metrics: ['amount'],
  dimensions: ['status'],
  filters: {},
  query: [],
  order: {
    percentage: 'desc',
  },
  dynamic_options: [],
  status: ['targeting', 'pitched', 'consideration', 'io_sent', 'won'],
  formatters: {},
}
