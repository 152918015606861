import Api from '@/models/Api'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import { buildColorPool } from '@/models/Util'
import numeral from 'numeral'
import SalesActivity from '@/models/SalesActivity'
import HamiltonModule from '@/models/HamiltonModule'
import { clone } from 'lodash'
import moment from 'moment'
import { FILTERS } from '../../helpers'
import RegionSalesRepGroup from '../../filters/RegionSalesRepGroup'
import PeriodGroup from '../../filters/PeriodGroup'
import DateRangeFilter from '../../filters/DateRangeFilter'

export default class ImpressionConcolidationWidget extends SystemDashboardWidget {
  public title: string = 'Impressions'

  public key: string = 'impression-consolidation'

  public view: string = 'chart'

  public subView: string = 'line'

  public dimensions: string[] = ['created_at', 'agency']

  public metrics: string[] = ['impressions']

  public loaderSize: string = '350px'

  public size: any = {
    lg: 6,
    md: 6,
    sm: 12,
  }

  public dataSourceRequest: any = null

  public format_totals: any = '0.00a'

  public cumulative: boolean = true

  public date_dimension_format: string = 'YYYY-MM-DD'

  public noSort: boolean = false

  constructor(args: any = null) {
    super()

    if (args && args.dimensions) {
      this.dimensions = args.dimensions
    }

    if (args && args.metrics) {
      this.metrics = args.metrics
    }

    if (args && args.filters) {
      this.filters = Object.assign(this.filters, args.filters)
    }
    if (args && args.internalFilters) {
      this.internalFilters = Object.assign(this.internalFilters, args.internalFilters)
    }
    if (args && args.internalQuery) {
      this.internalQuery = Object.assign(this.internalQuery, args.internalQuery)
    }

    if (args && args.rightCol) {
      this.rightCol = args.rightCol
    }

    if (args && args.leftCol) {
      this.leftCol = args.leftCol
    }

    if (args && args.size) {
      this.size = args.size
    }

    if (args && args.title) {
      this.title = args.title
    }

    if (args && args.key) {
      this.key = args.key
    }

    if (args && args.dataSourceRequest) {
      this.dataSourceRequest = args.dataSourceRequest
    }

    // format_totals
    if (args && args.format_totals) {
      this.format_totals = args.format_totals
    }

    // cumulative its a boolean so use hasOwnProperty
    if (args && args.hasOwnProperty('cumulative')) {
      this.cumulative = args.cumulative
    }
  }

  // API Filters
  public filters: any = {
    region: 'all',
    product: 'all',
  }

  public internalFilters: any = {
    ...FILTERS.MONTH,
  }

  public rightCol: any = [new DateRangeFilter({ target: 'internalFilters' })]

  public get filterToQuery() {
    let query = []

    query.push(this.internalFilters)

    return query
  }

  public get payload() {
    const query = [...new Set([...this.query, ...this.internalQuery, ...this.filterToQuery])]

    return {
      query,
    }
  }

  public formatters: any = {
    yaxis: (_: SystemDashboardWidget, value: any) => `${numeral(value).format(this.format_totals)}`,
    label: (_: SystemDashboardWidget, value: any) => `${numeral(value).format(this.format_totals)}`,
    tooltip: (_: SystemDashboardWidget, value: any) => [numeral(value).format(this.format_totals)],
  }

  public dataSource() {
    if (this.dataSourceRequest) {
      const res = this.dataSourceRequest(this.payload)

      return res
    }
    let request = new HamiltonModule()

    return request
      .impressionsConsolidation({ module: this.payload, ignore_logic: true })
      .then((res: any) => res.result)
  }

  protected dataMapper(data: any): Promise<any> {
    const series: number[] = []
    const data_series: any[] = []
    const labels: any[] = []
    const colors: string[] = []
    const series_ids: string[] = []
    let total = 0
    let total_cumulative: any[] = []

    const groups: string[] = []

    let x_series: string[] = []

    // Sort if applicable
    if (this.date_dimension_format && !this.noSort) {
      data = data.sort(
        (a: any, b: any) =>
          moment(a[this.dimensions[0]], this.date_dimension_format).unix()
          - moment(b[this.dimensions[0]], this.date_dimension_format).unix(),
      )
    }

    // Init Series
    data.forEach((item: any, idx: number) => {
      const label = item[this.dimensions[1]]
      const group_index = groups.indexOf(label)
      if (group_index === -1) {
        groups.push(label)
        data_series.push({ name: label, data: [] })
      }

      const x_label = item[this.dimensions[0]]

      if (x_series.indexOf(x_label) === -1) {
        x_series.push(x_label)
      }
    })

    const color_pool = buildColorPool(groups.length)

    data_series.forEach((item: any, idx: number) => {
      x_series.forEach(() => {
        item.data.push(0)
      })
      if (item.name === 'CCL') {
        colors.push('#555c6e')
      } else if (item.name === 'SSL') {
        colors.push('#00C1DE')
      } else {
        colors.push(color_pool(idx))
      }
    })

    data.forEach((item: any, idx: number) => {
      const label = item[this.dimensions[0]]
      const group_label = item[this.dimensions[1]]
      const group_index = groups.indexOf(group_label)
      const x_index = x_series.indexOf(label)

      let val = Number(item[this.metrics[0]])
      if (isNaN(val)) {
        val = 0
      }
      if (!total_cumulative[group_index]) {
        total_cumulative[group_index] = 0
      }

      total += val
      total_cumulative[group_index] += val

      if (this.cumulative) {
        data_series[group_index].data[x_index] = clone(total_cumulative[group_index])
        series.push(total_cumulative[group_index])
      } else {
        series.push(val)

        data_series[group_index].data[x_index] += val
      }

      if (['product', 'region', 'deal_type'].includes(this.dimensions[1])) {
        series_ids[group_index] = item[this.dimensions[1]].toLowerCase().replaceAll(' ', '_')
      } else if (this.dimensions[2]) {
        series_ids[group_index] = item[this.dimensions[2]]
      }
    })

    // Udpate Settings
    this.chartSettings.colors = colors
    this.chartSettings.xaxis = {
      categories: x_series,
    }

    this.chartSettings.labels = labels

    return Promise.resolve(data_series)
  }
}
