import Api from '@/models/Api'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import { buildColorPool } from '@/models/Util'
import numeral from 'numeral'
import { FILTERS } from '../../helpers'
import RegionSalesRepGroup from '../../filters/RegionSalesRepGroup'
import PeriodGroup from '../../filters/PeriodGroup'
import DateRangeFilter from '../../filters/DateRangeFilter'

export default class PitchedRevenueWidget extends SystemDashboardWidget {
  public title: string = 'Pitched Revenue'

  public key: string = 'pitched_revenue'

  public view: string = 'chart'

  public subView: string = 'line'

  public dimensions: string[] = ['date', 'activity']

  public metrics: string[] = ['amount']

  public loaderSize: string = '350px'

  public size: any = {
    sm: 12,
    md: 8,
    lg: 8,
  }

  // API Filters
  public filters: any = {
    region: 'all',
    product: 'all',
    ...FILTERS.LAST_4_WEEKS,
  }

  public groups = {
    view: 'region',
    period: 'week',
  }

  public leftCol: any = [new RegionSalesRepGroup(), new PeriodGroup()]

  public rightCol: any = [new DateRangeFilter()]

  public get payload() {
    let query = []

    if (this.filters.product !== 'all') {
      query.push(`product:${this.filters.product}`)
    }

    if (this.filters.region !== 'all') {
      query.push(`region:${this.filters.region}`)
    }

    let group_mode = this.groups.period
    let group_role = this.groups.view === 'region' ? 'team' : 'individual'

    return {
      start: this.filters.start,
      end: this.filters.end,
      query,
      group_mode,
      group_role,
    }
  }

  public formatters: any = {
    yaxis: (_: SystemDashboardWidget, value: any) => `${numeral(value).format('$0,00a')}`,
    label: (_: SystemDashboardWidget, value: any) => `${numeral(value).format('$0,00a')}`,
    tooltip: (_: SystemDashboardWidget, value: any) => [numeral(value).format('$0,00a')],
  }

  public dataSource() {
    let api = new Api()

    return api
      .get('sales-activity/pitched-revenue', this.payload)
      .then((response: any) => response.data.result)
      .catch(err => ({ data: [] }))
  }

  protected dataMapper(data: any): Promise<any> {
    const series: number[] = []
    const data_series: any[] = []
    const labels: any[] = []
    const colors: string[] = []
    const series_ids: string[] = []
    let total = 0
    let total_cumulative: any[] = []

    const groups: string[] = []

    let x_series: string[] = []

    // Init Series
    data.forEach((item: any, idx: number) => {
      const label = item[this.dimensions[1]]
      const group_index = groups.indexOf(label)
      if (group_index === -1) {
        groups.push(label)
        data_series.push({ name: label, data: [] })
      }

      const x_label = item[this.dimensions[0]]

      if (x_series.indexOf(x_label) === -1) {
        x_series.push(x_label)
      }
    })

    const color_pool = buildColorPool(groups.length)

    data_series.forEach((item: any, idx: number) => {
      x_series.forEach(() => {
        item.data.push(0)
      })
      if (item.name === 'CCL') {
        colors.push('#555c6e')
      } else if (item.name === 'SSL') {
        colors.push('#00C1DE')
      } else {
        colors.push(color_pool(idx))
      }
    })

    data.forEach((item: any, idx: number) => {
      const label = item[this.dimensions[0]]
      const group_label = item[this.dimensions[1]]
      const group_index = groups.indexOf(group_label)
      const x_index = x_series.indexOf(label)

      let val = Number(item[this.metrics[0]])
      if (isNaN(val)) {
        val = 0
      }
      if (!total_cumulative[group_index]) {
        total_cumulative[group_index] = 0
      }

      total += val
      total_cumulative[group_index] += val

      series.push(val)

      data_series[group_index].data[x_index] += val

      if (['product', 'region', 'deal_type'].includes(this.dimensions[1])) {
        series_ids[group_index] = item[this.dimensions[1]].toLowerCase().replaceAll(' ', '_')
      } else if (this.dimensions[2]) {
        series_ids[group_index] = item[this.dimensions[2]]
      }
    })

    // Udpate Settings
    this.chartSettings.colors = colors
    this.chartSettings.xaxis = {
      categories: x_series,
    }
    this.chartSettings.labels = labels

    return Promise.resolve(data_series)
  }
}
