import Opportunity from '@/models/Opportunity'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import User from '@/models/User'
import router from '@/Routes'
import SystemtModule from '@/store/SystemModule'
import moment from 'moment'
import { getModule } from 'vuex-module-decorators'

export default class ClosedBusinessThisWeekWidget extends SystemDashboardWidget {
  public title: string = 'Closed Business This Week'

  public key: string = 'closed-business-this-week'

  public view: string = 'table'

  public loaderSize: string = '350px'

  public noPagination: boolean = true

  public fields: any = [
    {
      key: 'sales_rep',
      label: 'Sales Rep',
      class: 'text-center align-middle',
      show: true,
      type: 'date',
      thClass: 'align-middle text-center',
      sortable: true,
    },
    {
      key: 'agency',
      label: 'Agency',
      class: 'text-center align-middle',
      show: true,
      type: 'capitalize_replace',
      thClass: 'align-middle text-center',
      sortable: true,
      filter: true,
    },
    {
      key: 'advertiser',
      label: 'Advertiser',
      class: 'text-center align-middle',
      show: true,
      type: 'capitalize_replace',
      thClass: 'align-middle text-center',
      sortable: true,
      filter: true,
    },

    {
      key: 'amount',
      label: 'Amount',
      class: 'text-center align-middle',
      show: true,
      type: 'currency',
      thClass: 'align-middle text-center',
      sortable: true,
      total: 'currency_abbreviate',
    },
    {
      key: 'revenue_period',
      label: 'Revenue Period',
      class: 'text-center align-middle',
      show: true,
      type: 'text',
      thClass: 'align-middle text-center',
      sortable: true,
    },
  ]

  public dimensions: string[] = ['sales_rep', 'agency', 'advertiser']

  public metrics: string[] = ['raw_amount', 'revenue_period']

  public size: any = {
    lg: 12,
    md: 12,
  }

  constructor(args: any = null) {
    super()

    if (args && args.dimensions) {
      this.dimensions = args.dimensions
    }

    if (args && args.metrics) {
      this.metrics = args.metrics
    }

    if (args && args.filters) {
      this.filters = Object.assign(this.filters, args.filters)
    }
    if (args && args.internalFilters) {
      this.internalFilters = Object.assign(this.internalFilters, args.internalFilters)
    }

    if (args && args.rightCol) {
      this.rightCol = args.rightCol
    }

    if (args && args.leftCol) {
      this.leftCol = args.leftCol
    }

    if (args && args.size) {
      this.size = args.size
    }

    if (args && args.title) {
      this.title = args.title
    }

    if (args && args.key) {
      this.key = args.key
    }

    if (args && args.noPagination) {
      this.noPagination = args.noPagination
    }

    if (args && args.updateFields) {
      this.fields = args.updateFields(this.fields)
    }

    if (args && args.internalQuery) {
      this.internalQuery = args.internalQuery
    }
  }

  public local_user: User = new User()

  public auth_user: any = getModule(SystemtModule).user

  public internalQuery: any = ['is:closed_this_week', 'scope:agency']

  public internalFilters: any = {
    year: moment().format('YYYY'),
  }

  public filters: any = {
    region: 'all',
    product: 'all',
  }

  public get has_cross_region_access() {
    return this.local_user!.canOptions('management_dashboard', 'cross_region')
  }

  public get filterToQuery() {
    let query = []

    if (this.filters.product !== 'all') {
      query.push(`product:${this.filters.product}`)
    }

    if (this.filters.region !== 'all') {
      query.push(`region:${this.filters.region}`)
    }

    query.push(`year:${this.internalFilters.year}`)

    return query
  }

  public get payload() {
    const query = [...new Set([...this.query, ...this.internalQuery, ...this.filterToQuery])]
    const filters = {
      ...this.internalFilters,
    }
    return {
      dimensions: this.dimensions,
      metrics: this.metrics,
      filters,
      query,
      snapshot: this.filters.snapshot || '',
    }
  }

  public async beforeDataReques() {
    let _router = router.currentRoute

    const { query } = _router

    if (query.target && typeof query.target === 'string') {
      this.local_user = await User.find(query.target)
    } else {
      // @ts-ignore
      this.local_user = getModule(SystemtModule).user
    }

    if (this.auth_user.region) {
      this.filters.region = this.auth_user.region
    } else if (!this.has_cross_region_access) {
      this.filters.region = 'all'
    }

    if (this.local_user.manager_id) {
      this.internalQuery.push(`sr:${this.local_user.id}`)
    }

    return new Promise(resolve => {
      setTimeout(() => {
        resolve(true)
      }, 500)
    })
  }

  public async dataSource() {
    await this.beforeDataReques()
    return await Opportunity.buildAdvancedQuery(this.payload).then((res: any) =>
      res.data.result.sort((a: any, b: any) => b[this.metrics[0]] - a[this.metrics[0]]))
  }
}
