import SystemDashboard from '@/models/SystemDashboard'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import moment from 'moment'
import numeral from 'numeral'
import BaseFilter from '../filters/BaseFilter'
import ActivityThisWeekWidget from '../widgets/finance/ActivityThisWeekWidget'
import AgencyDistributionYear from '../widgets/finance/AgencyDistributionYear'
import NewThisWeekOverviewWidget from '../widgets/finance/NewThisWeekOverviewWidget'
import NewThisWeekWidget from '../widgets/finance/NewThisWeekWidget'
import TouchPointSummaryWidget from '../widgets/finance/TouchPointSummaryWidget'
import RegionProductFilterWidget from '../widgets/others/RegionProductFilterWidget'
import SoldToPlanWidget from '../widgets/finance/SoldToPlanWidget'
import PerformanceToReforecastYear from '../widgets/finance/PerformanceToReforecastYear'

import PipelineToPlanYearWidget from '../widgets/finance/PipelineToPlanYearWidget'
import SalesRepOverviewWidget from '../widgets/finance/SalesRepOverviewWidget'
import WeeklyOverview from '../widgets/finance/WeeklyOverview'
import MonthlyProductDistribution from '../widgets/finance/MonthlyProductDistribution'
import SalesRepRevenueVSPlanWidget from '../widgets/finance/SalesRepRevenueVSPlanWidget'

import OpportunityStageBySalesRepWidget from '../widgets/finance/OpportunityStageBySalesRepWidget'
import CountByStageWidget from '../widgets/finance/CountByStageWidget'
import AmountByStageYearWidget from '../widgets/finance/AmountByStageYearWidget'

export default class SalesManagementDashboardModule extends SystemDashboard {
  public title = 'Sales Management'

  public key: string = 'management_dashboard'

  public filters = {
    product: 'all',
    region: 'all',
  }

  public querySettings = {
    company_fields: [
      {
        name: 'agency',
        key: 'agency',
        type: 'agency',
      },
      {
        name: 'advertiser',
        key: 'advertiser',
        type: 'advertiser',
      },
    ],
    custom_fields: [
      {
        name: 'deal_type:direct',
        value: 'deal_type:direct',
      },
      {
        name: 'deal_type:3rd Party',
        value: 'deal_type:3rd_party',
      },
    ],
    user_fields: [
      {
        name: 'sr',
        key: 'sales_rep_id',
      },
      {
        name: 'sm',
        key: 'sales_manager_id',
      },
    ],
  }

  public get soldToPlanWidgets() {
    return [
      new SoldToPlanWidget(),
      new SoldToPlanWidget({
        title: 'Q1 Sold to Plan',
        key: 'sold-to-goal-q1',
        size: {
          lg: 2,
          md: 6,
        },
        dimensions: ['quarter'],
        internalFilters: {
          quarter: 1,
          type: 'finance_goal',
        },
      }),
      new SoldToPlanWidget({
        title: 'Q2 Sold to Plan',
        key: 'sold-to-goal-q2',
        size: {
          lg: 2,
          md: 6,
        },
        dimensions: ['quarter'],
        internalFilters: {
          quarter: 2,
          type: 'finance_goal',
        },
      }),
      new SoldToPlanWidget({
        title: 'Q3 Sold to Plan',
        key: 'sold-to-goal-q3',
        size: {
          lg: 2,
          md: 6,
        },
        dimensions: ['quarter'],
        internalFilters: {
          quarter: 3,
          type: 'finance_goal',
        },
      }),
      new SoldToPlanWidget({
        title: 'Q4 Sold to Plan',
        key: 'sold-to-goal-q4',
        size: {
          lg: 2,
          md: 6,
        },
        dimensions: ['quarter'],
        internalFilters: {
          quarter: 4,
          type: 'finance_goal',
        },
      }),
    ]
  }

  public get soldToReforecastYearWidgets() {
    return [
      new SoldToPlanWidget({
        title: `Sold to Reforecast ${moment().format('YYYY')}`,
        key: 'sold-to-reforecast-year',
        dimensions: ['year'],
        metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
        internalFilters: {
          type: 'finance_goal',
          reforecast: 'latest',
        },
        internalQuery: ['scope:finance_goal'],
        custom_data_fields: {
          target: 'sold',
          period: 'year',
          secondary_label: 'Reforecast',
        },
      }),

      new SoldToPlanWidget({
        title: 'Q1 Sold to Reforecast',
        key: 'sold-to-reforecast-q1',
        dimensions: ['quarter'],
        metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
        internalFilters: {
          type: 'finance_goal',
          reforecast: 'latest',
          quarter: 1,
        },
        internalQuery: ['scope:finance_goal'],
        custom_data_fields: {
          target: 'sold',
          period: 'Q1',
          secondary_label: 'Reforecast',
        },
        size: {
          lg: 2,
          md: 6,
        },
      }),

      new SoldToPlanWidget({
        title: 'Q2 Sold to Reforecast',
        key: 'sold-to-reforecast-q2',
        dimensions: ['quarter'],
        metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
        internalFilters: {
          type: 'finance_goal',
          reforecast: 'latest',
          quarter: 2,
        },
        internalQuery: ['scope:finance_goal'],
        custom_data_fields: {
          target: 'sold',
          period: 'Q2',
          secondary_label: 'Reforecast',
        },
        size: {
          lg: 2,
          md: 6,
        },
      }),

      new SoldToPlanWidget({
        title: 'Q3 Sold to Reforecast',
        key: 'sold-to-reforecast-q3',
        dimensions: ['quarter'],
        metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
        internalFilters: {
          type: 'finance_goal',
          reforecast: 'latest',
          quarter: 3,
        },
        internalQuery: ['scope:finance_goal'],
        custom_data_fields: {
          target: 'sold',
          period: 'Q3',
          secondary_label: 'Reforecast',
        },
        size: {
          lg: 2,
          md: 6,
        },
      }),

      new SoldToPlanWidget({
        title: 'Q4 Sold to Reforecast',
        key: 'sold-to-reforecast-q4',
        dimensions: ['quarter'],
        metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
        internalFilters: {
          type: 'finance_goal',
          reforecast: 'latest',
          quarter: 4,
        },
        internalQuery: ['scope:finance_goal'],
        custom_data_fields: {
          target: 'sold',
          period: 'Q4',
          secondary_label: 'Reforecast',
        },
        size: {
          lg: 2,
          md: 6,
        },
      }),
    ]
  }

  public get PerformanceToReforecastWidgets() {
    return [
      new PerformanceToReforecastYear(),
      new PerformanceToReforecastYear({
        title: 'Q1 Performance to Forecast',
        key: 'performance-to-reforecast-q1',
        dimensions: ['quarter'],
        size: {
          lg: 2,
          md: 6,
        },
        internalFilters: {
          quarter: 1,
        },
      }),
      new PerformanceToReforecastYear({
        title: 'Q2 Performance to Forecast',
        key: 'performance-to-reforecast-q2',
        dimensions: ['quarter'],
        size: {
          lg: 2,
          md: 6,
        },
        internalFilters: {
          quarter: 2,
        },
      }),
      new PerformanceToReforecastYear({
        title: 'Q3 Performance to Forecast',
        key: 'performance-to-reforecast-q3',
        dimensions: ['quarter'],
        size: {
          lg: 2,
          md: 6,
        },
        internalFilters: {
          quarter: 3,
        },
      }),
      new PerformanceToReforecastYear({
        title: 'Q4 Performance to Forecast',
        key: 'performance-to-reforecast-q4',
        dimensions: ['quarter'],
        size: {
          lg: 2,
          md: 6,
        },
        internalFilters: {
          quarter: 4,
        },
      }),
    ]
  }

  public widgets: SystemDashboardWidget[] = [
    new RegionProductFilterWidget(),
    new NewThisWeekOverviewWidget(),
    new NewThisWeekWidget(),
    new NewThisWeekWidget({
      title: 'Open Deals',
      key: 'large-deals',
      size: {
        lg: 12,
        md: 12,
      },
      internalFilters: {
        dynamic_filter: 'is:large_deal',
      },
      internalQuery: ['is:open'],
      rightCol: [
        new BaseFilter({
          options: [
            { text: 'Large Deals', value: 'is:large_deal' },
            { text: 'All', value: '' },
          ],
        }),
      ],
      cellSlots: [
        {
          key: 'name',
          component: 'TableColLink',
          slotProps: (data: any) => ({
            link: `/app/opportunity/${data.item.id}`,
            innerText: data.item.name,
          }),
        },
      ],
    }),

    new TouchPointSummaryWidget(),
    new TouchPointSummaryWidget({
      title: 'Activity Summary',
      key: 'this-week-summary',
      dimensions: ['activity'],
      metrics: ['count'],
    }),

    new ActivityThisWeekWidget(),
    new ActivityThisWeekWidget({
      title: 'Activity Next Week',
      key: 'activity-next-week',
      internalQuery: ['is:next_week'],
    }),

    ...this.soldToPlanWidgets,

    ...this.soldToReforecastYearWidgets,

    ...this.PerformanceToReforecastWidgets,

    new PipelineToPlanYearWidget(),
    new SalesRepOverviewWidget(),

    new WeeklyOverview(),
    new AmountByStageYearWidget(),

    new MonthlyProductDistribution({
      title: `Product Distribution ${moment().format('YYYY')}`,
      key: 'monthly-product-distribution',
      dimensions: ['quarter', 'product'],
      size: {
        lg: 8,
        md: 8,
        sm: 12,
      },
      internalFilters: {
        dynamic_filter: 'is:pipeline',
      },
      rightCol: [
        new BaseFilter({
          type: 'radio',
          target: 'internalFilters',
          key: 'dynamic_filter',
          options: [
            { text: 'Pipeline', value: 'is:pipeline' },
            { text: 'Sold', value: 'is:sold' },
          ],
        }),
        new BaseFilter({
          label: 'Group by',
          type: 'radio',
          target: 'dimensions',
          key: '0',
          options: [
            { text: 'Quarter', value: 'quarter' },
            { text: 'Month', value: 'month' },
          ],
        }),
      ],
      custom_formatters: {
        tooltip: ({ widget, value, opt }: any) => {
          const raw_value = widget.rawData[opt.dataPointIndex].amount
          const total = widget.rawData.reduce(
            (acc: number, item: any) => acc + item[widget.metrics[0]],
            0,
          )
          /**
           * TODO @vini
           * In Product Distribution V1 there are some bugs compare them to this formatting
           */
          return [
            `${numeral(raw_value).format('$0.00a')}`,
            ` ${numeral(raw_value / total).format('0.00%')}`,
          ]
        },
      },
    }),

    new MonthlyProductDistribution({
      title: `Sales Rep Distribution ${moment().format('YYYY')}`,
      key: 'monthly-sales-rep-distribution',
      dimensions: ['quarter', 'sales_rep', 'sales_rep_id'],
      size: {
        lg: 8,
        md: 12,
      },
      internalFilters: {
        dynamic_filter: 'is:pipeline',
      },
      rightCol: [
        new BaseFilter({
          type: 'radio',
          target: 'internalFilters',
          key: 'dynamic_filter',
          options: [
            { text: 'Pipeline', value: 'is:pipeline' },
            { text: 'Sold', value: 'is:sold' },
          ],
        }),
        new BaseFilter({
          label: 'Group by',
          type: 'radio',
          target: 'dimensions',
          key: '0',
          options: [
            { text: 'Quarter', value: 'quarter' },
            { text: 'Month', value: 'month' },
          ],
        }),
      ],
      custom_formatters: {
        tooltip: ({ widget, value, opt }: any) => {
          const raw_value = widget.rawData[opt.dataPointIndex].amount
          const total = widget.rawData.reduce(
            (acc: number, item: any) => acc + item[widget.metrics[0]],
            0,
          )
          /**
           * TODO @vini
           * In Product Distribution V1 there are some bugs compare them to this formatting
           */
          return [
            `${numeral(raw_value).format('$0.00a')}`,
            ` ${numeral(raw_value / total).format('0.00%')}`,
          ]
        },
      },
    }),

    new SalesRepRevenueVSPlanWidget(),
    new OpportunityStageBySalesRepWidget(),
    new CountByStageWidget(),
    new AgencyDistributionYear({
      dimensions: ['agency', 'agency_id'],
      metrics: ['amount'],
      internalFilters: {
        dynamic_filter: 'is:pipeline',
      },
      rightCol: [
        new BaseFilter({
          type: 'radio',
          options: [
            { text: 'Pipeline', value: 'is:pipeline' },
            { text: 'Sold', value: 'is:sold' },
          ],
        }),
      ],
    }),
    new AgencyDistributionYear({
      title: `Advertiser Distribution ${moment().format('YYYY')}`,
      key: 'advertiser-revenue-distribution',
      dimensions: ['advertiser', 'advertiser_id'],
      metrics: ['amount'],
      internalFilters: {
        dynamic_filter: 'is:pipeline',
      },
      rightCol: [
        new BaseFilter({
          type: 'radio',
          options: [
            { text: 'Pipeline', value: 'is:pipeline' },
            { text: 'Sold', value: 'is:sold' },
          ],
        }),
      ],
    }),
  ]
}
