
import ViewModel from '@/models/ViewModel'
import { Component, Vue } from 'vue-property-decorator'
import FormInput from '@/components/FormInput/FormInput.vue'
import WebMessage from '@/models/WebMessage'
import Api from '@/models/Api'
import User from '@/models/User'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'

@Component({
  components: {
    FormInput,
  },
})
export default class MFARequiredPage extends ViewModel {
  public mfa_key: string = ''

  public mfa_qr: string = ''

  public busy: boolean = false

  public is_requesting: boolean = false

  public backup_codes: number[] = []

  public success: boolean = false

  public request_status: any = null

  public code_error: any = null

  public enableMFA() {
    const api = new Api()
    this.busy = true
    api
      .post('user/mfa/enable')
      .then(response => {
        this.mfa_qr = response.data.result.qr_code
        this.busy = false
      })
      .catch(error => {
        WebMessage.success('We were not able to process your request, please try again later.')
      })
  }

  public submitCode() {
    const api = new Api()
    this.busy = true
    this.is_requesting = true
    this.request_status = 'ENABLING'
    api
      .post('user/mfa/confirm', {
        code: this.mfa_key,
      })
      .then(response => {
        this.mfa_key = ''
        this.mfa_qr = ''
        this.backup_codes = response.data.result.backup_codes

        // Update user
        const user = User.toObject(response.data.result.user)
        const system = getModule(SystemtModule)

        system.updateState({
          name: 'user',
          data: user,
        })
        this.request_status = 'SAVED'
        this.success = true

        setTimeout(() => {
          location.replace('/app/account')
        }, 2000)
      })
      .catch(error => {
        this.mfa_key = ''
        this.busy = false
        this.is_requesting = false
        this.success = false
        this.code_error = error.response.data.message
        this.request_status = 'INVALID_CODE'
        setTimeout(() => {
          this.request_status = null
        }, 3000)
      })
  }

  public mounted() {
    this.enableMFA()
  }
}
