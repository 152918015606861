import Api from './Api'

export default class MarketingRequest {
  public dueRequests(payload: any) {
    let api = new Api()
    return api
      .get('marketing-requests/due-requests', payload)
      .then((response: any) => response.data)
  }

  public async notCompleted(payload: any) {
    let api = new Api()
    return await api
      .get('marketing-requests/requests-uncompleted', payload)
      .then((response: any) => response.data.result)
  }
}
