import Opportunity from '@/models/Opportunity'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import { buildColorPool } from '@/models/Util'
import { normalizeLabel } from '@/pages/ManagementDashboard/shared/helpers'
import { startCase } from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import DashboardWidgetView from '@/pages/ManagementDashboardV2/components/DashboardWidgetView.vue'
import PipelineSold from '../../filters/PipelineSold'

export default class AgencyDistributionYear extends SystemDashboardWidget {
  public title: string = `Agency Distribution ${moment().format('YYYY')}`

  public key = 'agency-revenue-distribution'

  public view: string = 'chart'

  public subView: string = 'treemap'

  public dimensions = ['agency', 'agency_id']

  public metrics = ['amount']

  public loaderSize: string = '350px'

  public size: any = {
    lg: 12,
    md: 12,
  }

  public filters: any = {
    region: 'all',
    product: 'all',
  }

  public internalFilters: any = {
    year: moment().format('YYYY'),
    dynamic_filter: 'is:sold',
  }

  public rightCol: any = [new PipelineSold()]

  public events: any = {
    click: (widget: AgencyDistributionYear, event: any, chartContext: any, opts: any) => {
      let name = widget.data[opts.seriesIndex].data[opts.dataPointIndex].x
      let key = ''
      widget.rawData.forEach((item: any) => {
        if (item[widget.dimensions[0]] === name) {
          key = item[widget.dimensions[1]]
        }
      })
      widget.viewComponent.applyFilter(
        `${widget.dimensions[0]}:${name}`,
        `${widget.dimensions[0]}:${key}`,
        'query',
      )
    },
  }

  constructor(args: any = null) {
    super()

    if (args && args.dimensions) {
      this.dimensions = args.dimensions
    }

    if (args && args.metrics) {
      this.metrics = args.metrics
    }

    if (args && args.filters) {
      this.filters = Object.assign(this.filters, args.filters)
    }
    if (args && args.internalFilters) {
      this.internalFilters = Object.assign(this.internalFilters, args.internalFilters)
    }

    if (args && args.rightCol) {
      this.rightCol = args.rightCol
    }

    if (args && args.leftCol) {
      this.leftCol = args.leftCol
    }

    if (args && args.size) {
      this.size = args.size
    }

    if (args && args.title) {
      this.title = args.title
    }

    if (args && args.key) {
      this.key = args.key
    }
    if (args && args.internalQueryPush) {
      this.internalQuery.push(args.internalQueryPush)
    }
  }

  public internalQuery: string[] = ['scope:agency']

  public get notes() {
    return '<small>* All YOY analisys represent final 2023 revenue amount</small>'
  }

  public get filterToQuery() {
    let query = []

    if (this.filters.product !== 'all') {
      query.push(`product:${this.filters.product}`)
    }

    if (this.filters.region !== 'all') {
      query.push(`region:${this.filters.region}`)
    }

    query.push(`year:${this.internalFilters.year}`)

    return query
  }

  public get payload() {
    const query = [...new Set([...this.query, ...this.internalQuery, ...this.filterToQuery])]

    return {
      dimensions: this.dimensions,
      metrics: this.metrics,
      filters: {
        ...this.internalFilters,
      },

      query,
      snapshot: this.filters.snapshot || '',
    }
  }

  public async dataSource() {
    return Opportunity.buildAdvancedQuery(this.payload).then((res: any) =>
      res.data.result.sort((a: any, b: any) => b[this.metrics[0]] - a[this.metrics[0]]))
  }

  public formatters: any = {
    label: (widget: SystemDashboardWidget, value: any, opt: any) => {
      const raw_value = widget.rawData[opt.dataPointIndex].amount

      return [`${value}`, `${numeral(raw_value).format('$0.00a')}`]
    },
    tooltip: (widget: SystemDashboardWidget, value: any, opt: any) => {
      const raw_value = widget.rawData[opt.dataPointIndex].amount
      const total = widget.rawData.reduce(
        (acc: number, item: any) => acc + item[widget.metrics[0]],
        0,
      )

      return [
        `${numeral(raw_value).format('$0.00a')}`,
        ` ${numeral(raw_value / total).format('0.00%')}`,
      ]
    },
  }

  protected dataMapper(data: any): Promise<any> {
    const series: number[] = []
    const data_series: any[] = []
    const labels: any[] = []
    const colors: string[] = []
    const series_ids: string[] = []
    let total = 0

    const color_pool = buildColorPool(data.length)

    data.forEach((item: any, idx: number) => {
      const val = Number(item[this.metrics[0]])
      if (val === 0) {
        return
      }
      total += val
      series.push(val)
      const label = item[this.dimensions[0]]
      data_series.push({
        x: label,
        y: val,
      })
      labels.push(label)
      colors.push(color_pool(idx))

      if (item[this.dimensions[1]]) {
        series_ids.push(item[this.dimensions[1]])
      }
    })
    this.chartSettings.colors = colors

    this.chartSettings.labels = labels

    return Promise.resolve([
      {
        name: startCase(this.metrics[0]),
        data: data_series,
      },
    ])
  }
}
