import SystemDashboardWidget from './SystemDashboardWidget'

export default class SystemDashboard {
  // Dashboard Title
  public title: string = ''

  // Dashboard Key, used for access control
  public key: string = ''

  // List of widget within a dashboard
  public widgets: SystemDashboardWidget[] = []

  // API query filters
  public query: string[] = []

  // API Filters
  public filters: any = {}

  // Data group methods
  public groups: any = {}

  // Search Bar Settings
  public querySettings: any = {}

  // Loading indicator getter, check if all widgets are not loading
  public get loading() {
    return this.widgets.every(widget => widget.loading === false)
  }

  // Ready indicator
  protected _ready: boolean = false

  // Ready indicator getter
  public get ready() {
    return this._ready
  }

  /**
   * Hook to make dashboard changes prior to initialization
   */
  protected setup() {}

  public init(query: string[] | null = null, filters: any = null, groups: any = null) {
    this.widgets = this.widgets.map(widget => {
      // Overwrite the internals of the widget
      // widget.overwrideInternals(query, filters)

      widget.init(query, filters, groups)

      return widget
    })

    this.setup()

    this._ready = true
  }
}
