import OpportunityActivity from '@/models/OpportunityActivity'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import moment from 'moment'
import numeral from 'numeral'

export default class TouchPointSummaryWidget extends SystemDashboardWidget {
  public title: string = 'Touch Point Summary'

  public key: string = 'touch-point-summary'

  public view: string = 'custom'

  public size = {
    lg: 6,
    md: 12,
  }

  public dimensions: string[] = ['activity', 'task']

  public metrics: string[] = ['count']

  constructor(args: any = null) {
    super()

    if (args && args.dimensions) {
      this.dimensions = args.dimensions
    }

    if (args && args.metrics) {
      this.metrics = args.metrics
    }

    if (args && args.filters) {
      this.filters = Object.assign(this.filters, args.filters)
    }
    if (args && args.internalFilters) {
      this.internalFilters = Object.assign(this.internalFilters, args.internalFilters)
    }

    if (args && args.rightCol) {
      this.rightCol = args.rightCol
    }

    if (args && args.leftCol) {
      this.leftCol = args.leftCol
    }

    if (args && args.size) {
      this.size = args.size
    }

    if (args && args.title) {
      this.title = args.title
    }

    if (args && args.key) {
      this.key = args.key
    }

    if (args && args.noPagination) {
      this.noPagination = args.noPagination
    }

    if (args && args.updateFields) {
      this.fields = args.updateFields(this.fields)
    }

    if (args && args.internalQuery) {
      this.internalQuery = args.internalQuery
    }

    if (args && args.internalQueryPush) {
      this.internalQuery.push(args.internalQueryPush)
    }
  }

  public get notes() {
    return '<small>* All YOY analisys represent final 2023 revenue amount</small>'
  }

  public internalFilters: any = {
    year: parseInt(moment().format('YYYY')),
  }

  public filters: any = {
    product: 'all',
    region: 'all',
  }

  public internalQuery: any = ['scope:agency']

  public get filterToQuery() {
    let query = []

    if (this.filters.product !== 'all') {
      query.push(`product:${this.filters.product}`)
    }

    if (this.filters.region !== 'all') {
      query.push(`region:${this.filters.region}`)
    }

    query.push(`year:${this.internalFilters.year}`)

    return query
  }

  public get payload() {
    const query = [...new Set([...this.query, ...this.internalQuery, ...this.filterToQuery])]
    const filters = {
      ...this.internalFilters,
    }

    return {
      query,
      filters,
      metrics: this.metrics,
      dimensions: this.dimensions,
    }
  }

  public async dataSource() {
    return OpportunityActivity.amountSummary(this.payload).then((res: any) => res.data.result)
  }

  public get customData() {
    let data = this.data
    const last_week = numeral(data.last_week).format('0,0')
    const last_week_color = data.last_week > data.previous_week ? 'text-success' : 'text-danger'
    const last_week_icon = data.last_week > data.previous_week ? '▴' : '▾'
    const last_week_percentage = numeral(
      data.previous_week <= 0 ? 0 : (data.previous_week - data.last_week) / data.previous_week,
    ).format('0%')

    const this_week = numeral(data.this_week).format('0,0')
    const this_week_color = data.this_week > data.last_week ? 'text-success' : 'text-danger'
    const this_week_icon = data.this_week > data.last_week ? '▴' : '▾'
    const this_week_percentage = numeral(
      data.last_week <= 0 ? 0 : (data.last_week - data.this_week) / data.last_week,
    ).format('0%')

    const next_week = numeral(data.next_week).format('0,0')
    const next_week_color = data.next_week > data.this_week ? 'text-success' : 'text-danger'
    const next_week_icon = data.next_week > data.this_week ? '▴' : '▾'
    const next_week_percentage = numeral(
      data.this_week <= 0 ? 0 : (data.this_week - data.next_week) / data.this_week,
    ).format('0%')

    const last_month = numeral(data.last_month).format('0,0')
    const last_month_color = data.last_month > data.previous_month ? 'text-success' : 'text-danger'
    const last_month_icon = data.last_month > data.previous_month ? '▴' : '▾'
    const last_month_percentage = numeral(
      data.previous_month <= 0 ? 0 : (data.previous_month - data.last_month) / data.previous_month,
    ).format('0%')

    const this_month = numeral(data.this_month).format('0,0')
    const this_month_color = data.this_month > data.last_month ? 'text-success' : 'text-danger'
    const this_month_icon = data.this_month > data.last_month ? '▴' : '▾'
    const this_month_percentage = numeral(
      data.last_month <= 0 ? 0 : (data.last_month - data.this_month) / data.last_month,
    ).format('0%')

    const next_month = numeral(data.next_month).format('0,0')
    const next_month_color = data.next_month > data.this_month ? 'text-success' : 'text-danger'
    const next_month_icon = data.next_month > data.this_month ? '▴' : '▾'
    const next_month_percentage = numeral(
      data.this_month <= 0 ? 0 : (data.this_month - data.next_month) / data.this_month,
    ).format('0%')

    return `  
    <div class="row text-center mt-5 pt-3">
      <div class="col">
        <h6>Last Week</h6>
        <h4>${last_week}</h4>
        <p class="${last_week_color}">${last_week_icon} ${last_week_percentage} <br />Previous Week</p>
      </div>
      <div class="col">
        <h6>This Week</h6>
        <h4>${this_week}</h4>
        <p class="${this_week_color}">${this_week_icon} ${this_week_percentage} <br />Last Week</p>
      </div>
      <div class="col">
        <h6>Next Week</h6>
        <h4>${next_week}</h4>
        <p class="${next_week_color}">${next_week_icon} ${next_week_percentage} <br />This Week</p>
      </div>
    </div>   
    <div class="row text-center mt-3">
      <div class="col">
        <h6>Last Month</h6>
        <h4>${last_month}</h4>
        <p class="${last_month_color}">${last_month_icon} ${last_month_percentage} <br />Previous Month</p>
      </div>
      <div class="col">
        <h6>This Month</h6>
        <h4>${this_month}</h4>
        <p class="${this_month_color}">${this_month_icon} ${this_month_percentage} <br />Last Month</p>
      </div>
      <div class="col">
        <h6>Next Month</h6>
        <h4>${next_month}</h4>
        <p class="${next_month_color}">${next_month_icon} ${next_month_percentage} <br />This Month</p>
      </div>
    </div>
    `
  }
}
