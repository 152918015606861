import FinancialPlan from '@/models/FinancialPlan'
import Opportunity from '@/models/Opportunity'
import OpportunityActivity from '@/models/OpportunityActivity'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import { buildColorPool } from '@/models/Util'
import moment from 'moment'

export default class SalesRepOverviewWidget extends SystemDashboardWidget {
  public title: string = 'Sales Rep Overview'

  public key: string = 'sales-rep-goal'

  public view: string = 'table'

  public size: any = {
    lg: 4,
    md: 12,
  }

  public fields: any = [
    {
      key: 'sales_rep',
      label: 'Sales Rep',
      class: 'text-center align-middle',
      show: true,
      type: 'capitalize',
      thClass: 'align-middle text-center',
      sortable: true,
    },
    {
      key: 'goal',
      label: 'Plan',
      class: 'text-center align-middle',
      show: true,
      thClass: 'align-middle text-center',
      type: 'currency_abbreviate',
      sortable: true,
      total: 'currency_abbreviate',
    },
    {
      key: 'goal_forecast',
      label: 'Forecast',
      class: 'text-center align-middle',
      show: true,
      thClass: 'align-middle text-center',
      type: 'currency_abbreviate',
      sortable: true,
      total: 'currency_abbreviate',
    },
    {
      key: 'goal_sold',
      label: 'Sold',
      class: 'text-center align-middle',
      show: true,
      thClass: 'align-middle text-center',
      type: 'currency_abbreviate',
      sortable: true,
      total: 'currency_abbreviate',
    },
    {
      key: 'goal_achieved',
      label: 'Invoiced',
      class: 'text-center align-middle',
      show: true,
      thClass: 'align-middle text-center',
      type: 'currency_abbreviate',
      sortable: true,
      total: 'currency_abbreviate',
    },
  ]

  public dimensions = ['sales_rep']

  public metrics = [
    'goal',
    'goal_forecast',
    'goal_pipeline',
    'goal_sold',
    'goal_achieved',
    'last_year_goal',
    'last_year_goal_pipeline',
    'last_year_goal_sold',
    'last_year_goal_achieved',
  ]

  public last_year_props: any = {
    // used for getting last year values
    goal: 'last_year_goal',
    goal_forecast: 'last_year_goal_pipeline',
    goal_pipeline: 'last_year_goal_pipeline',
    goal_sold: 'last_year_goal_sold',
    goal_achieved: 'last_year_goal_achieved',
  }

  public get notes() {
    return '<small>* All YOY analisys represent final 2023 revenue amoun</small>'
  }

  constructor(args: any = null) {
    super()

    if (args && args.dimensions) {
      this.dimensions = args.dimensions
    }

    if (args && args.metrics) {
      this.metrics = args.metrics
    }

    if (args && args.filters) {
      this.filters = Object.assign(this.filters, args.filters)
    }
    if (args && args.internalFilters) {
      this.internalFilters = Object.assign(this.internalFilters, args.internalFilters)
    }

    if (args && args.rightCol) {
      this.rightCol = args.rightCol
    }

    if (args && args.leftCol) {
      this.leftCol = args.leftCol
    }

    if (args && args.size) {
      this.size = args.size
    }

    if (args && args.title) {
      this.title = args.title
    }

    if (args && args.key) {
      this.key = args.key
    }

    if (args && args.noPagination) {
      this.noPagination = args.noPagination
    }

    if (args && args.updateFields) {
      this.fields = args.updateFields(this.fields)
    }

    if (args && args.internalQuery) {
      this.internalQuery = args.internalQuery
    }

    if (args && args.cellSlots) {
      this.cellSlots = args.cellSlots
    }
    if (args && args.footerCellSlots) {
      this.footerCellSlots = args.footerCellSlots
    }
    if (args && args.internalQueryPush) {
      this.internalQuery.push(args.internalQueryPush)
    }
  }

  /**
   * Renders cells slots in the table body
   */
  public cellSlots: any[] = Object.keys(this.last_year_props).map((key: any) => ({
    key,
    component: 'TableCellIndicator', // also import this component in parent location
    slotProps: (data: any) => ({
      value: data.value,
      lastYearValue: parseFloat(data.item[this.last_year_props[key]]),
      widgetKey: this.key,
      index: data.index,
      prefix: this.last_year_props[key],
    }),
  }))

  /**
   * Renders cells slots in the table footer
   */
  public footerCellSlots: any[] = Object.keys(this.last_year_props).map((key: any) => ({
    key,
    component: 'TableCellIndicator',
    slotProps: (data: any, getFieldFoot: any) => ({
      value: getFieldFoot(key),
      lastYearValue: getFieldFoot(this.last_year_props[key]),
      widgetKey: this.key,
      index: data.index,
      prefix: this.last_year_props[key],
    }),
  }))

  public filters = {
    region: 'all',
    product: 'all',
  }

  public internalFilters = {
    type: 'sales_rep_consolidated_goal',
    year: moment().format('YYYY'),
  }

  public internalQuery = ['scope:sales_rep_consolidated_goal']

  public get filterToQuery() {
    let query = []

    if (this.filters.product !== 'all') {
      query.push(`product:${this.filters.product}`)
    }

    if (this.filters.region !== 'all') {
      query.push(`region:${this.filters.region}`)
    }

    query.push(`year:${this.internalFilters.year}`)

    return query
  }

  public get payload() {
    const query = [...new Set([...this.query, ...this.internalQuery, ...this.filterToQuery])]

    return {
      filters: {
        ...this.internalFilters,
      },
      query,
      metrics: this.metrics,
      dimensions: this.dimensions,
    }
  }

  public async dataSource() {
    // return Promise.resolve([
    //   {
    //     sales_rep: 'John Doe',
    //     goal: 1000,
    //     goal_forecast: 800,
    //     goal_sold: 700,
    //     goal_achieved: 600,
    //   },
    //   {
    //     sales_rep: 'Jane Doe',
    //     goal: 2000,
    //     goal_forecast: 1800,
    //     goal_sold: 1700,
    //     goal_achieved: 1600,
    //   },
    //   {
    //     sales_rep: 'John Smith',
    //     goal: 3000,
    //     goal_forecast: 2800,
    //     goal_sold: 2700,
    //     goal_achieved: 2600,
    //   },
    // ])
    return FinancialPlan.getPlanDashboard(this.payload).then((res: any) =>
      res.data.result.sort((a: any, b: any) => b[this.metrics[0]] - a[this.metrics[0]]))
  }
}
