import SalesActivity from '@/models/SalesActivity'
import SystemDashboard from '@/models/SystemDashboard'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import { FILTERS } from '../helpers'
import SalesActivitySummaryWidget from '../widgets/finance/SalesActivitySummaryWidget'
import TargetOpportuntyWidget from '../widgets/finance/TargetOpportuntyWidget'
import RegionProductFilterWidget from '../widgets/others/RegionProductFilterWidget'
import PipelineStatusFunnelWidget from '../widgets/sales/PipelineStatusFunnelWidget'

export default class SalesActivityDashboardModule extends SystemDashboard {
  public title = 'Sales Activity'

  public key: string = 'sales_activities'

  public querySettings = {
    company_fields: [
      {
        name: 'agency',
        key: 'agency',
        type: 'agency',
      },
      {
        name: 'advertiser',
        key: 'advertiser',
        type: 'advertiser',
      },
    ],
    custom_fields: [
      {
        name: 'product:ssl',
        value: 'product:ssl',
      },
      {
        name: 'product:ccl',
        value: 'product:ccl',
      },
      {
        name: 'deal_type:direct',
        value: 'deal_type:direct',
      },
      {
        name: 'deal_type:3rd Party',
        value: 'deal_type:3rd_party',
      },
    ],
    user_fields: [
      {
        name: 'sr',
        key: 'sales_rep_id',
      },
    ],
  }

  public pipeline_export_mode: string = 'consolidated'

  public filters = {
    product: 'all',
    region: 'all',
  }

  public groups = {
    view: 'region',
  }

  public get pipelineWidgets() {
    return [
      new PipelineStatusFunnelWidget({
        title: 'Existing Business Pipeline',
        key: 'existing-business-pipeline',
        internalQuery: [
          'is:returning_business',
          'use:pipeline_only',
          'scope:agency',
          'is:forecast',
        ],
        resultsMapper: (item: any) => {
          if (item.status === 'pitched') {
            item.amount *= 0.1
          }
          return item
        },
      }),
      new PipelineStatusFunnelWidget({
        title: 'New Business Pipeline',
        key: 'new-business-pipeline',
        internalQuery: ['is:new_business', 'use:pipeline_only', 'scope:agency', 'is:forecast'],
        resultsMapper: (item: any) => {
          if (item.status === 'pitched') {
            item.amount *= 0.1
          }
          return item
        },
      }),
      new PipelineStatusFunnelWidget({
        title: 'Pipeline Movment This Week',
        key: 'pipeline-movement-weekly',
        internalQuery: ['is:new_business', 'use:pipeline_only'],
        resultsMapper: (item: any) => {
          if (item.status === 'pitched') {
            item.amount *= 0.1
          }
          return item
        },
        dataSourceRequest: (payload: any) => {
          let request = new SalesActivity()
          return request
            .pipelineMovementWeekly({ module: payload, ignore_logic: true })
            .then((res: any) => res.result)
        },
        internalFilters: {
          year: null,
        },
      }),
    ]
  }

  public widgets: SystemDashboardWidget[] = [
    new RegionProductFilterWidget(),

    ...this.pipelineWidgets,
    new TargetOpportuntyWidget(),
    new SalesActivitySummaryWidget({
      internalGroups: {
        group_mode: 'month',
        group_role: 'individual',
      },
      internalFilters: {
        ...FILTERS.YEAR,
      },
    }),
    new TargetOpportuntyWidget({
      title: 'Meetings',
      key: 'meetings',
      dimensions: ['date', 'activity'],
      metrics: ['amount'],
      internalFilters: {
        ...FILTERS.YTD_THROUGH_MONTH,
      },
      dataSourceRequest: (payload: any) => {
        let request = new SalesActivity()
        return request
          .meetings({ module: payload, ignore_logic: true })
          .then((res: any) => res.result)
      },
    }),

    new SalesActivitySummaryWidget({
      title: 'Meetings',
      key: 'meetings-summary',
      dataSourceRequest: (payload: any) => {
        let request = new SalesActivity()
        return request
          .meetings({ module: payload, ignore_logic: true })
          .then((res: any) => res.result)
      },
      internalGroups: {
        group_mode: 'month',
        group_role: 'individual',
      },
    }),
    new TargetOpportuntyWidget({
      title: 'Pitched Revenue',
      key: 'pitched-revenue',
      dimensions: ['date', 'activity'],
      metrics: ['amount'],
      dataSourceRequest: (payload: any) => {
        let request = new SalesActivity()
        return request
          .pitchedRev({ module: payload, ignore_logic: true })
          .then((res: any) => res.result)
      },

      internalFilters: {
        ...FILTERS.YTD_THROUGH_MONTH,
      },
      format_totals: '$0,00a',
    }),

    new SalesActivitySummaryWidget({
      title: 'Pitched Revenue',
      key: 'pitched-revenue-summary',
      dataSourceRequest: (payload: any) => {
        let request = new SalesActivity()
        return request
          .pitchedRev({ module: payload, ignore_logic: true })
          .then((res: any) => res.result)
      },
      internalGroups: {
        group_mode: 'month',
        group_role: 'individual',
      },
      dimensions: ['date', 'activity'],
      metrics: ['amount'],
      internalFilters: {
        ...FILTERS.YEAR,
      },
      format_totals: '$0,00a',
    }),

    new TargetOpportuntyWidget({
      title: 'Pitched Count',
      key: 'total-pitched',
      dimensions: ['date', 'activity'],
      metrics: ['amount'],

      dataSourceRequest: (payload: any) => {
        let request = new SalesActivity()
        return request
          .numberOfPitched({ module: payload, ignore_logic: true })
          .then((res: any) => res.result)
      },

      internalFilters: {
        ...FILTERS.YTD_THROUGH_MONTH,
      },
    }),

    new SalesActivitySummaryWidget({
      title: 'Pitched',
      key: 'total-pitched-summary',
      dataSourceRequest: (payload: any) => {
        let request = new SalesActivity()
        return request
          .numberOfPitched({ module: payload, ignore_logic: true })
          .then((res: any) => res.result)
      },
      internalGroups: {
        group_mode: 'month',
        group_role: 'individual',
      },
      dimensions: ['date', 'activity'],
      metrics: ['amount'],
      internalFilters: {
        ...FILTERS.YEAR,
      },
      format_totals: '0,0',
    }),
    new TargetOpportuntyWidget({
      title: 'Sold',
      key: 'sold-per',
      dimensions: ['date', 'activity'],
      metrics: ['amount'],
      dataSourceRequest: (payload: any) => {
        let request = new SalesActivity()
        return request
          .soldPer({ module: payload, ignore_logic: true })
          .then((res: any) => res.result)
      },

      internalFilters: {
        ...FILTERS.YTD_THROUGH_MONTH,
      },
      format_totals: '$0.00a',
    }),

    new SalesActivitySummaryWidget({
      title: 'Sold',
      key: 'sold-per-summary',
      dataSourceRequest: (payload: any) => {
        let request = new SalesActivity()
        return request
          .soldPer({ module: payload, ignore_logic: true })
          .then((res: any) => res.result)
      },
      internalGroups: {
        group_mode: 'month',
        group_role: 'individual',
      },
      dimensions: ['date', 'activity'],
      metrics: ['amount'],
      internalFilters: {
        ...FILTERS.YEAR,
      },
      format_totals: '$0.00a',
    }),

    new TargetOpportuntyWidget({
      title: 'Touch Points',
      key: 'touch-points',
      dimensions: ['date', 'activity'],
      metrics: ['amount'],
      dataSourceRequest: (payload: any) => {
        let request = new SalesActivity()
        return request
          .touchpoints({ module: payload, ignore_logic: true })
          .then((res: any) => res.result)
      },

      internalFilters: {
        ...FILTERS.YTD_THROUGH_MONTH,
      },
      internalGroups: {
        group_mode: 'week',
        group_role: 'sales_rep',
      },
      format_totals: '0a',
    }),

    new SalesActivitySummaryWidget({
      title: 'Touchpoints',
      key: 'touch-points-summary',
      dataSourceRequest: (payload: any) => {
        let request = new SalesActivity()
        return request
          .touchpoints({ module: payload, ignore_logic: true })
          .then((res: any) => res.result)
      },
      internalGroups: {
        group_mode: 'month',
        group_role: 'individual',
      },
      dimensions: [],
      metrics: [],
      internalFilters: {
        ...FILTERS.YEAR,
      },
      format_totals: '0,0',
    }),

    new TargetOpportuntyWidget({
      title: 'Pipeline Movment',
      key: 'pipeline-movement',
      metrics: ['amount'],
      dimensions: ['date', 'status'],
      size: {
        lg: 12,
        md: 12,
        sm: 12,
      },
      dataSourceRequest: (payload: any) => {
        let request = new SalesActivity()
        return request
          .pipelineMovement({ module: payload, ignore_logic: true })
          .then((res: any) => res.result)
      },

      internalFilters: {
        ...FILTERS.YTD_THROUGH_MONTH,
      },
      internalGroups: {
        group_mode: 'week',
        group_role: 'sales_rep',
      },
      format_totals: '0.00a',
    }),
  ]
}
