import Api from '@/models/Api'
import Opportunity from '@/models/Opportunity'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import { buildColorPool } from '@/models/Util'
import { clone, startCase } from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import PipelineSold from '../../filters/PipelineSold'

export default class ProductRevenueCumulativeYear extends SystemDashboardWidget {
  public title: string = `Product Revenue Cumulative ${moment().format('YYYY')}`

  public key: string = 'product_revenue_cumulative'

  public view: string = 'chart'

  public subView: string = 'line'

  public loaderSize: string = '350px'

  public dimensions: string[] = ['month', 'product']

  public metrics: string[] = ['amount']

  public cumulative: boolean = true

  public size: any = {
    lg: 4,
    md: 12,
  }

  public filters: any = {}

  public internalFilters: any = {
    year: moment().startOf('year').format('YYYY'),

    dynamic_filter: 'is:sold',
  }

  public rightCol: any = [new PipelineSold()]

  public flattenPayload: boolean = true

  public date_dimension_format: string = 'MMMM YYYY'

  public noSort: boolean = false

  constructor(args: any = null) {
    super()

    if (args && args.dimensions) {
      this.dimensions = args.dimensions
    }

    if (args && args.metrics) {
      this.metrics = args.metrics
    }

    if (args && args.filters) {
      this.filters = Object.assign(this.filters, args.filters)
    }
    if (args && args.internalFilters) {
      this.internalFilters = Object.assign(this.internalFilters, args.internalFilters)
    }

    if (args && args.rightCol) {
      this.rightCol = args.rightCol
    }

    if (args && args.leftCol) {
      this.leftCol = args.leftCol
    }

    if (args && args.size) {
      this.size = args.size
    }

    if (args && args.title) {
      this.title = args.title
    }

    if (args && args.key) {
      this.key = args.key
    }
  }

  public formatters: any = {
    yaxis: (_: SystemDashboardWidget, value: any) => `${numeral(value).format('$0.00a')}`,
    label: (_: SystemDashboardWidget, value: any) => `${numeral(value).format('0,0')}`,
    tooltip: (widget: SystemDashboardWidget, value: any, opt: any) => {
      let total = opt.series.reduce(
        (acc: number, item: any) => (acc += item[opt.dataPointIndex]),
        0,
      )
      return [`${numeral(value).format('$0.00a')}`, ` (${numeral(value / total).format('0.0%')})`]
    },
  }

  public internalQuery: any = ['scope:agency']

  public get filterToQuery() {
    let query = []

    if (this.filters.product !== 'all') {
      query.push(`product:${this.filters.product}`)
    }

    if (this.filters.region !== 'all') {
      query.push(`region:${this.filters.region}`)
    }

    query.push(`year:${this.internalFilters.year}`)

    return query
  }

  public get payload() {
    const query = [...new Set([...this.query, ...this.internalQuery, ...this.filterToQuery])]
    const filters = {
      ...this.internalFilters,
    }
    return {
      dimensions: this.dimensions,
      metrics: this.metrics,
      filters,
      order: {},
      query,
      snapshot: this.filters.snapshot || '',
    }
  }

  public dataSource() {
    return Opportunity.buildAdvancedQuery(this.payload)
      .then((response: any) =>
        // response.data.result
        response.data.result.sort((a: any, b: any) => b[this.metrics[0]] - a[this.metrics[0]]))
      .catch((err: any) => ({ data: [] }))
  }

  protected dataMapper(data: any): Promise<any> {
    const series: number[] = []
    const data_series: any[] = []
    const labels: any[] = []
    const colors: string[] = []
    const series_ids: string[] = []
    let total = 0
    let total_cumulative: any[] = []

    const groups: string[] = []

    let x_series: string[] = []

    // Sort if applicable
    if (this.date_dimension_format && !this.noSort) {
      data = data.sort(
        (a: any, b: any) =>
          moment(a[this.dimensions[0]], this.date_dimension_format).unix()
          - moment(b[this.dimensions[0]], this.date_dimension_format).unix(),
      )
    }

    // Init Series
    data.forEach((item: any, idx: number) => {
      const label = item[this.dimensions[1]]
      const group_index = groups.indexOf(label)
      if (group_index === -1) {
        groups.push(label)
        data_series.push({ name: startCase(label), data: [] })
      }

      const x_label = item[this.dimensions[0]]

      if (x_series.indexOf(x_label) === -1) {
        x_series.push(x_label)
      }
    })

    const color_pool = buildColorPool(groups.length)

    data_series.forEach((item: any, idx: number) => {
      x_series.forEach(() => {
        item.data.push(0)
      })
      if (item.name === 'CCL') {
        colors.push('#555c6e')
      } else if (item.name === 'SSL') {
        colors.push('#00C1DE')
      } else {
        colors.push(color_pool(idx))
      }
    })

    data.forEach((item: any, idx: number) => {
      const label = item[this.dimensions[0]]
      const group_label = item[this.dimensions[1]]
      const group_index = groups.indexOf(group_label)
      const x_index = x_series.indexOf(label)

      let val = Number(item[this.metrics[0]])
      if (isNaN(val)) {
        val = 0
      }
      if (!total_cumulative[group_index]) {
        total_cumulative[group_index] = 0
      }

      total += val
      total_cumulative[group_index] += val

      if (this.cumulative) {
        data_series[group_index].data[x_index] = clone(total_cumulative[group_index])
        series.push(total_cumulative[group_index])
      } else {
        series.push(val)

        data_series[group_index].data[x_index] += val
      }

      if (['product', 'region', 'deal_type'].includes(this.dimensions[1])) {
        series_ids[group_index] = item[this.dimensions[1]].toLowerCase().replaceAll(' ', '_')
      } else if (this.dimensions[2]) {
        series_ids[group_index] = item[this.dimensions[2]]
      }
    })

    // Udpate Settings
    this.chartSettings.colors = colors
    this.chartSettings.xaxis = {
      categories: x_series,
    }

    this.chartSettings.labels = labels

    return Promise.resolve(data_series)
  }
}
