
import { Component, Vue, Prop } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import Api from '@/models/Api'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import WebMessage from '@/models/WebMessage'
import SalesAtivities from './views/SalesActivityDashboard.vue'
import GlobalDashboardView from './views/GlobalDashboardView.vue'
import SalesManagerView from './views/SalesManagerView.vue'
import SalesView from './views/SalesView.vue'
import MLBView from './views/MLBView.vue'
import DirecTVView from './views/DirecTVView.vue'
import HamiltonDashboardView from './views/HamiltonDashboardView.vue'
import SalesWeekly from './views/SalesWeekly.vue'
import LeadershipDashboardView from './views/LeadershipDashboardView.vue'
import ActiveInitialTermDashboard from './views/ActiveInitialTerm/ActiveInitialTermDashboard.vue'
import CIMWorkloadDashboardView from './views/CIMWorkload/CIMWorkloadDashboardView.vue'

@Component({
  components: {
    GlobalDashboardView,
    SalesManagerView,
    SalesView,
    MLBView,
    DirecTVView,
    HamiltonDashboardView,
    SalesAtivities,
    SalesWeekly,
    LeadershipDashboardView,
    ActiveInitialTermDashboard,
    CIMWorkloadDashboardView,
  },
})
export default class ManagementDashboard extends ViewModel {
  public tabIndex: number = 0

  public get singleView() {
    return this.tabOptions[0]
  }

  public get tabOptions() {
    let ret = []

    if (this.user.canOptions('management_dashboard', 'leadership') || this.user.isSystem) {
      ret.push({
        key: 'leadership',
        title: 'Leadership Dashboard',
        type: 'leadership',
      })
    }

    if (this.user.canOptions('management_dashboard', 'overview_dashboard') || this.user.isSystem) {
      ret.push({
        key: 'global',
        title: 'Overview',
        type: 'global',
      })
    }

    if (
      this.user.canOptions('management_dashboard', 'management_dashboard')
      || this.user.isSystem
    ) {
      ret.push({
        key: 'sales_manager',
        title: 'Sales Management',
        type: 'sales_manager',
      })
    }

    if (this.user.canOptions('management_dashboard', 'sales_dashboard') || this.user.isSystem) {
      ret.push({
        key: 'sales_rep',
        title: 'Sales',
        type: 'sales_rep',
      })
    }
    if (this.user.canOptions('management_dashboard', 'weekly_sales') || this.user.isSystem) {
      ret.push({
        key: 'weekly_sales',
        title: 'Weekly Sales',
        type: 'weekly_sales',
      })
    }

    if (this.user.canOptions('management_dashboard', 'sales_activities') || this.user.isSystem) {
      ret.push({
        key: 'sales_activities',
        title: 'Sales Activity',
        type: 'sales_activities',
      })
    }

    if (this.user.canOptions('management_dashboard', 'hamilton_dashboard') || this.user.isSystem) {
      ret.push({
        key: 'hamilton',
        title: 'Hamilton Overview',
        type: 'hamilton',
      })
    }

    if (this.user.canOptions('management_dashboard', 'mlb_dashboard') || this.user.isSystem) {
      ret.push({
        key: 'mlb',
        title: 'MLB',
        type: 'mlb',
      })
    }

    if (this.user.canOptions('management_dashboard', 'directv_dashboard') || this.user.isSystem) {
      ret.push({
        key: 'directv',
        title: 'DirecTV',
        type: 'directv',
      })
    }
    if (this.user.canOptions('management_dashboard', 'international_term') || this.user.isSystem) {
      ret.push({
        key: 'active-international',
        title: 'Active International',
        type: 'active-international',
      })
    }

    let { query } = this.$route

    if (this.user.isSystem && query.tab) {
      let idx = ret.findIndex(t => t.key === query.tab)
      if (idx !== -1) {
        return [ret[idx]]
      }
    }

    return ret
  }

  public mounted() {
    let { query } = this.$route

    let tab = 0
    if (query.tab) {
      let idx = this.tabOptions.findIndex(t => t.key === query.tab)

      if (idx !== -1) {
        tab = idx
      }
    }

    setTimeout(() => {
      this.tabIndex = tab
    }, 250)
  }

  public beforeDestroy() {
    this.tabIndex = 0
  }

  public changeTab(tabIndex: number) {
    this.tabIndex = tabIndex
  }

  public async download(payload: any) {
    let api = new Api()
    const instance_id = getModule(SystemtModule)._uuid
    const key = payload.key
    return api
      .post(`dashboard-view/export/${key}`, {
        instance_id,
        target: this.user.id,
        ...payload,
      })
      .then(() => WebMessage.success('Generating Export, do not close this window!'))
  }

  /** works but the font doesnt, can be deleted, but leave it here for ref and it can be used for simple things */
  public async fronend_download() {
    let el = document.getElementById('screenshot-me')
    // the el should be the body
    // let el = document.body.cloneNode(true)

    // from el delete nav.navbar and nav.sidebar nodes
    let navBar = el.querySelector('nav.navbar')
    if (navBar) {
      navBar.remove()
    }

    navBar = el.querySelector('nav.sidebar')
    if (navBar) {
      navBar.remove()
    }

    function getStyles() {
      let css = ''
      for (const sheet of document.styleSheets) {
        try {
          for (const rule of sheet.cssRules) {
            css += rule.cssText
          }
        } catch (e) {
          // console.warn('Access to stylesheet %s is restricted due to CORS policy', sheet.href)
        }
      }
      return css
    }

    function getInlineStyles(element: HTMLElement): string {
      const compStyles = window.getComputedStyle(element)
      let inlineStyles = ''
      for (let i = 0; i < compStyles.length; i++) {
        const propName = compStyles[i]
        const propValue = compStyles.getPropertyValue(propName)
        inlineStyles += `${propName}:${propValue};`
      }
      return inlineStyles
    }
    async function htmlToCanvas(element) {
      const styles = getStyles(element)
      const bodyStyles = getInlineStyles(document.body)

      return new Promise((resolve, reject) => {
        const svg = new Blob(
          [
            `<svg xmlns='http://www.w3.org/2000/svg' width='${element.scrollWidth}' height='${
              element.scrollHeight
            }'>
                               
              <style>
               @import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700,800'); 
               @import url('https://maxst.icons8.com/vue-static/landings/line-awesome/line-awesome/1.3.0/css/line-awesome.min.css'); 
               body {
                font-family: industry, 'Montserrat', sans-serif; 
                ${bodyStyles}
               }
              ${styles}
              </style>
                                <foreignObject width='100%' height='100%'>
                                    ${new XMLSerializer().serializeToString(element)}
                                </foreignObject>
                            </svg>`,
          ],
          { type: 'image/svg+xml;charset=utf-8' },
        )

        const url = URL.createObjectURL(svg)
        const reader = new FileReader()

        // blob to file
        let file = new File([svg], 'screenshot.svg', { type: 'image/svg+xml;charset=utf-8' })

        reader.readAsDataURL(file)

        reader.onloadend = () => {
          let img = document.getElementById('screenshot')

          img.src = reader.result
        }

        // const img = new Image()
        // img.setAttribute('crossOrigin', 'anonymous')
        // img.onload = () => {
        //   const canvas = document.createElement('canvas')
        //   canvas.width = element.scrollWidth
        //   canvas.height = element.scrollHeight
        //   const context = canvas.getContext('2d')
        //   context.drawImage(img, 0, 0)
        //   URL.revokeObjectURL(url)
        //   resolve(canvas)
        // }
        // img.onerror = (err) => {
        //   console.log('Image load error:', err)
        //   reject(new Error('Image load error: ' + err))
        // }
        // img.src = url
      })
    }

    const offscreenContainer = document.getElementById('offscreenContainer')
    offscreenContainer.innerHTML = ''
    const clonedElement = el.cloneNode(true)
    offscreenContainer.appendChild(clonedElement)

    const canvas = await htmlToCanvas(clonedElement)

    let img = document.getElementById('screenshot')

    img.src = canvas.toDataURL('image/png')

    // Clean up
    offscreenContainer.innerHTML = ''
  }
}
