import ActiveInitialTermModule from '@/models/ActiveInitialTermModule'
import Company from '@/models/Company'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import simpleCard from '@/pages/ManagementDashboard/scripts/simple-card'
import moment from 'moment'
import numeral from 'numeral'

export default class InitialOverviewWidget extends SystemDashboardWidget {
  // public title: string = 'Initial Overview'

  public key: string = 'initial-overview'

  public view = 'custom bg-transparent shadow-none px-0'

  public size = {
    lg: 12,
    md: 12,
  }

  public get payload() {
    return {
      start: this.filters.start,
      end: this.filters.end,
      agency_id: this.filters.agency_id,
    }
  }

  public dataSource() {
    let request = new ActiveInitialTermModule()
    return request
      .overview({ module: this.payload, ignore_logic: true })
      .then((data: any) => data.result)
  }

  public get customData() {
    let data = this.rawData
    let revenue = numeral(data.revenue || 0).format('$0.00a')
    let cost = numeral(data.cost || 0).format('$0.00a')
    let profit = numeral(data.profit || 0).format('$0.00a')
    let net_av = numeral(data.net_added_value || 0).format('$0.00a')
    let av_to_goal = numeral(data.av_percentage_to_goal || 0).format('0.0%')

    return `
    <div class="row text-center mt-5">
      <div class="col">
        <h6>Revenue</h6>
        <h4>${revenue}</h4>
      </div>
      <div class="col">
        <h6>Cost</h6>
        <h4 class='text-danger'>${cost}</h4>
      </div>
      <div class="col">
        <h6>Profit</h6>
        <h4 class='text-primary'>${profit}</h4>
      </div>
      <div class="col">
        <h6>Net Av</h6>
        <h4>${net_av}</h4>
      </div>
      <div class="col">
        <h6>av % to goal</h6>
        <h4>${av_to_goal}</h4>
      </div>
    </div>
    `
  }
}
