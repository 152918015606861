import Api from './Api'

type Context = {
  perPage: any
  currentPage: number
  sortBy: any
  sortDesc: boolean
  filter: any
  query: any
}

export default class ActiveInitialTermModule {
  public queryAutomatedFilters(module: any) {
    let automated_filters: any = {}
    let query: any = []

    if (module.query && module.query.length) {
      query = [...module.query]
    }

    if (module.local_filters) {
      Object.entries(module.local_filters).forEach(([key, value]) => {
        if (key === 'dynamic_filter') {
          automated_filters.start = module.local_filters.dynamic_filter.start
          automated_filters.end = module.local_filters.dynamic_filter.end
          automated_filters.shortcut = module.local_filters.dynamic_filter.shortcut
        } else {
          automated_filters[key] = value
        }
      })
    }
    let metrics: any = []
    let dimensions: any = []

    if (module.local_query && module.local_query.length) {
      query = module.local_query
    }
    if (module.metrics) {
      metrics = module.metrics
    }
    if (module.dimensions) {
      dimensions = module.dimensions
    }

    if (module.region) {
      query.push(`region:${module.region}`)
    }

    if (module.sales_rep) {
      query.push(`sr:${module.sales_rep}`)
    }

    query = [...new Set(query)]

    return {
      query,
      metrics,
      dimensions,
      automated_filters,
    }
  }

  public pagination_settings(context: Context) {
    return {
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...context.query],
      mode: 'exclusive',
    }
  }

  public async overview({ module, ignore_logic }: any) {
    let params = null
    if (!ignore_logic) {
      const {
        query, automated_filters, metrics, dimensions,
      } = this.queryAutomatedFilters(module)
      params = {
        query,
        metrics,
        dimensions,
        ...automated_filters,
      }
    }

    let api = new Api()

    return api
      .get('active-international-dashboard/overview', ignore_logic ? module : params)
      .then((response: any) => response.data)
      .catch(err => ({ data: [] }))
    return {
      total_net_amount: '$836.59K',
      total_media_cost: '- $4.16M',
      profit: '$3.32M',
      net_added_value: '$3.32M',
      avrage_to_goal: '$3.32M',
    }
  }

  public async mediaPlans({ module, context, ignore_logic }: any) {
    let api = new Api()
    if (ignore_logic) {
      return api
        .get('active-international-dashboard/mediaplans', module)
        .then((response: any) => ({
          data: response.data.result.media_plans,
          records: response.data.result.records,
        }))
        .catch(err => ({ data: [] }))
    }
    const {
      query, automated_filters, metrics, dimensions,
    } = this.queryAutomatedFilters(module)

    if (!context.query) context.query = []

    context.query = context.query.concat([...query])
    // context.query = make unique
    context.query = [...new Set(context.query)]

    let options = this.pagination_settings(context)

    if (module.local_filters.order_by) {
      options.order_by = module.local_filters.order_by
    }

    return api
      .get('active-international-dashboard/mediaplans', {
        metrics,
        dimensions,
        ...automated_filters,
        ...options,
      })
      .then((response: any) => ({
        data: response.data.result.media_plans,
        records: response.data.result.records,
      }))
      .catch(err => ({ data: [] }))
  }
}
