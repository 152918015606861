import Api from '@/models/Api'
import OpportunityActivity from '@/models/OpportunityActivity'
import SystemtModule from '@/store/SystemModule'
import { getModule } from 'vuex-module-decorators'

type Context = {
  perPage: any
  currentPage: number
  sortBy: any
  sortDesc: boolean
  filter: any
  query: any
}

export default class SalesActivityDriver {
  public pagination_settings(context: Context) {
    return {
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...context.query],
      mode: 'exclusive',
    }
  }

  public salesRepWeeklyActivity({
    context,
    module,
    ignore_logic,
  }: {
    context: any
    module: any
    ignore_logic: boolean
  }) {
    let options = null
    if (!ignore_logic) {
      const automated_filters = [...module.local_query]

      if (module.region) {
        automated_filters.push(`region:${module.region}`)
      }

      if (module.sales_rep) {
        automated_filters.push(`sr:${module.sales_rep}`)
      }

      if (module.local_filters.dynamic_filter) {
        automated_filters.push(module.local_filters.dynamic_filter)
      }
      if (module.local_filters.widget_filter) {
        automated_filters.push(module.local_filters.widget_filter)
      }

      let query = [...context.filter, ...module.query, ...automated_filters]
      query = [...new Set(query)]
      if (!context.query) context.query = []

      context.query = context.query.concat([...query])
      // context.query = make unique
      context.query = [...new Set(context.query)]

      options = this.pagination_settings(context)

      if (module.local_filters.order_by) {
        options.order_by = module.local_filters.order_by
      }
    } else {
      options = context
    }

    const api = new Api(false)
    return api
      .get('opportunities/sales-rep-weekly-activity', options)
      .then(response => ({
        records: response.data.result.records,
        data: response.data.result.data,
      }))
      .catch(e => {
        if (getModule(SystemtModule).enviroment !== 'production') {
          // eslint-disable-next-line no-console
          console.error(e)
        }
        return {
          records: 0,
          data: [],
        }
      })
  }

  public salesRepActivity({
    context,
    module,
    ignore_logic,
  }: {
    context: Context
    module: any
    ignore_logic: boolean
  }) {
    let options: any = null
    if (!ignore_logic) {
      const automated_filters = [...module.local_query]

      if (module.region) {
        automated_filters.push(`region:${module.region}`)
      }

      if (module.sales_rep) {
        automated_filters.push(`sr:${module.sales_rep}`)
      }

      if (module.local_filters.dynamic_filter) {
        automated_filters.push(module.local_filters.dynamic_filter)
      }
      if (module.local_filters.widget_filter) {
        automated_filters.push(module.local_filters.widget_filter)
      }

      let query = [...context.filter, ...module.query, ...automated_filters]
      query = [...new Set(query)]
      if (!context.query) context.query = []

      context.query = context.query.concat([...query])
      // context.query = make unique
      context.query = [...new Set(context.query)]

      options = this.pagination_settings(context)

      if (module.local_filters.order_by) {
        options.order_by = module.local_filters.order_by
      }
    } else {
      options = context
    }

    return OpportunityActivity.paginate(options)
  }

  public closedKPI({
    context,
    module,
    ignore_logic,
  }: {
    context: Context
    module: any
    ignore_logic: boolean
  }) {
    return this.getKPIs(context, module, 'kpi-amount-chart/closed', ignore_logic)
  }

  public pitchedKPI({
    context,
    module,
    ignore_logic,
  }: {
    context: Context
    module: any
    ignore_logic: boolean
  }) {
    return this.getKPIs(context, module, 'kpi-amount-chart/pitched', ignore_logic)
  }

  public amountKPI({ context, module }: { context: Context; module: any }) {
    return this.getKPIs(context, module, 'kpi-amount-chart')
  }

  public activityKPI({
    context,
    module,
    ignore_logic,
  }: {
    context: Context
    module: any
    ignore_logic: boolean
  }) {
    return this.getKPIs(context, module, 'kpi-activity-chart', ignore_logic)
  }

  public getKPIs(context: Context, module: any, path: string, ignore_logic = false) {
    let options: any = null
    if (!ignore_logic) {
      if (!context) {
        context = {
          perPage: 10,
          currentPage: 1,
          sortBy: 'created_at',
          sortDesc: true,
          filter: [],
          query: [],
        }
      }

      const automated_filters = [...module.local_query]

      if (module.region) {
        automated_filters.push(`region:${module.region}`)
      }

      if (module.sales_rep) {
        automated_filters.push(`sr:${module.sales_rep}`)
      }

      if (module.local_filters.dynamic_filter) {
        automated_filters.push(module.local_filters.dynamic_filter)
      }
      if (module.local_filters.widget_filter) {
        automated_filters.push(module.local_filters.widget_filter)
      }

      if (module.local_filters.group_mode) {
        automated_filters.push(`${module.local_filters.group_mode}`)
      }

      let filter = context?.filter || []
      let query = [...filter, ...module.query, ...automated_filters]
      query = [...new Set(query)]
      if (!context.query) context.query = []

      context.query = context.query.concat([...query])
      // context.query = make unique
      context.query = [...new Set(context.query)]

      options = this.pagination_settings(context)

      if (module.local_filters.order_by) {
        options.order_by = module.local_filters.order_by
      }
    } else {
      options = context
    }

    const api = new Api(false)
    return api
      .get(`opportunities/${path}`, options)
      .then(response => ({
        records: response.data.result.records,
        data: response.data.result,
      }))
      .catch(e => {
        if (getModule(SystemtModule).enviroment !== 'production') {
          // eslint-disable-next-line no-console
          console.error(e)
        }
        return {
          records: 0,
          data: [],
        }
      })
  }
}
