import HamiltonModule from '@/models/HamiltonModule'
import Opportunity from '@/models/Opportunity'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import { buildColorPool } from '@/models/Util'
import { startCase } from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import { FILTERS } from '../../helpers'

export default class TodayTotalWidget extends SystemDashboardWidget {
  public title: string = 'Today'

  public key = 'today-total'

  public view: string = 'chart'

  public subView: string = 'pie'

  public size = {
    lg: 3,
    md: 3,
  }

  public dimensions = ['device_type']

  public metrics = ['impressions']

  public internalQuery = []

  public format_totals: any = '0,0'

  public dataSourceRequest: any = null

  public orderBy: any = 'impressions'

  public set_label_to_percentage: boolean = false

  constructor(args: any = null) {
    super()

    if (args && args.dimensions) {
      this.dimensions = args.dimensions
    }

    if (args && args.metrics) {
      this.metrics = args.metrics
    }

    if (args && args.filters) {
      this.filters = Object.assign(this.filters, args.filters)
    }
    if (args && args.internalFilters) {
      this.internalFilters = Object.assign(this.internalFilters, args.internalFilters)
    }
    if (args && args.internalQuery) {
      this.internalQuery = Object.assign(this.internalQuery, args.internalQuery)
    }

    if (args && args.rightCol) {
      this.rightCol = args.rightCol
    }

    if (args && args.leftCol) {
      this.leftCol = args.leftCol
    }

    if (args && args.size) {
      this.size = args.size
    }

    if (args && args.title) {
      this.title = args.title
    }

    if (args && args.key) {
      this.key = args.key
    }

    if (args && args.dataSourceRequest) {
      this.dataSourceRequest = args.dataSourceRequest
    }

    // group
    if (args && args.groups) {
      this.groups = Object.assign(this.groups, args.groups)
    }

    // internalGroups
    if (args && args.internalGroups) {
      this.internalGroups = Object.assign(this.internalGroups, args.internalGroups)
    }
    // format_totals
    if (args && args.format_totals) {
      this.format_totals = args.format_totals
    }
  }

  public filters = {
    product: 'all',
    region: 'all',
  }

  public internalFilters = {
    ...FILTERS.TODAY,
  }

  public get filterToQuery() {
    let query = []

    // query.push({ select_by: 'publisher_vcr' })

    query.push(this.internalFilters)

    return query
  }

  public get payload() {
    const query = [...new Set([...this.query, ...this.internalQuery, ...this.filterToQuery])]

    return {
      query,
    }
  }

  public async dataSource() {
    if (this.dataSourceRequest) {
      const res = this.dataSourceRequest(this.payload)

      return res
    }

    let request = new HamiltonModule()

    return request
      .todayTotal({ module: this.payload, ignore_logic: true })
      .then((res: any) => res.data)
  }

  public formatters: any = {
    yaxis: (_: SystemDashboardWidget, value: any) => `${numeral(value).format('0,0a')}`,
    label: (widget: SystemDashboardWidget, value: any, opt: any) =>
      `${numeral(widget.data[opt.seriesIndex] / widget.chartSettings.total).format('0.00%')}`,
    tooltip: (_: SystemDashboardWidget, value: any, opt: any) => {
      let total = opt.config.series.reduce((acc: number, item: any) => (acc += item), 0)

      return [`${numeral(value).format('0a')}`, `  ${numeral(value / total).format('0.00%')}`]
    },
  }

  protected dataMapper(data: any): Promise<any> {
    let data_series: any[] = []
    let labels: string[] = []
    const series_raw: number[] = []
    let colors: string[] = []
    let total = 0

    const color_pool = buildColorPool(data.length)

    data.forEach((item: any, idx: number) => {
      const val = Number(item[this.metrics[0]])
      total += val
      data_series.push(val)
      labels.push(item[this.dimensions[0]])
      colors.push(color_pool(idx))
    })

    if (this.set_label_to_percentage) {
      if (!data || !data[0] || !data[0][this.metrics[1]] || !data[0][this.metrics[0]]) {
        labels = [numeral(0).format('0.00%')]
      } else {
        labels = [
          numeral(Number(data[0][this.metrics[0]]) / Number(data[0][this.metrics[1]])).format(
            '0.00%',
          ),
        ]
      }
    }

    this.chartSettings.colors = colors

    this.chartSettings.xaxis = {
      categories: labels,
    }

    this.chartSettings.total = total

    this.chartSettings.labels = labels

    return Promise.resolve(data_series)
  }
}
