import Opportunity from '@/models/Opportunity'
import OpportunityActivity from '@/models/OpportunityActivity'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import { buildColorPool } from '@/models/Util'

export default class ActivityThisWeekWidget extends SystemDashboardWidget {
  public title: string = 'Activity This Week'

  public key: string = 'activity-this-week'

  public view: string = 'table'

  public size: any = {
    lg: 6,
    md: 12,
  }

  public fields: any = [
    {
      key: 'scheduled_at',
      label: 'Date',
      class: 'text-center align-middle',
      show: true,
      type: 'date',
      thClass: 'align-middle text-center',
      sortable: true,
      filter: true,
    },
    {
      key: 'formOpportunitySalesRep',
      label: 'Sales Rep',
      class: 'text-center align-middle',
      show: true,
      type: 'text',
      thClass: 'align-middle text-center',
      sortable: false,
    },
    {
      key: 'name',
      label: 'Description',
      class: 'text-center align-middle',
      show: true,
      type: 'text',
      thClass: 'align-middle text-center',
      sortable: true,
      filter: true,
    },
    {
      key: 'type',
      label: 'Activity',
      class: 'text-center align-middle',
      show: true,
      type: 'capitalize',
      thClass: 'align-middle text-center',
      sortable: true,
      filter: true,
    },
    {
      key: 'sub_type',
      label: 'Type',
      class: 'text-center align-middle',
      show: true,
      type: 'capitalize_replace',
      thClass: 'align-middle text-center',
      sortable: true,
      filter: true,
    },
    {
      key: 'formOpportunityStatus',
      label: 'Stage',
      class: 'text-center align-middle',
      show: true,
      type: 'capitalize_replace',
      thClass: 'align-middle text-center',
      sortable: true,
      filter: true,
    },
    {
      key: 'formOpportunityAdvertiser',
      label: 'Advertiser',
      class: 'text-center align-middle',
      show: true,
      type: 'text',
      thClass: 'align-middle text-center',
      sortable: true,
    },
    {
      key: 'formOpportunityAgency',
      label: 'Agency',
      class: 'text-center align-middle',
      show: true,
      type: 'text',
      thClass: 'align-middle text-center',
      sortable: true,
    },
    {
      key: 'formOpportunityName',
      label: 'Opportunity',
      class: 'text-center align-middle',
      show: true,
      type: 'text',
      thClass: 'align-middle text-center',
      sortable: true,
      filter: true,
    },
    {
      key: 'formOpportunityAmount',
      label: 'Amount',
      class: 'text-center align-middle',
      show: true,
      type: 'currency_abbreviate',
      thClass: 'align-middle text-center',
      sortable: true,
      total: 'currency_abbreviate',
    },
  ]

  public dataSourceRequest: any = null

  constructor(args: any = null) {
    super()

    if (args && args.dimensions) {
      this.dimensions = args.dimensions
    }

    if (args && args.metrics) {
      this.metrics = args.metrics
    }

    if (args && args.filters) {
      this.filters = Object.assign(this.filters, args.filters)
    }
    if (args && args.internalFilters) {
      this.internalFilters = Object.assign(this.internalFilters, args.internalFilters)
    }

    if (args && args.rightCol) {
      this.rightCol = args.rightCol
    }

    if (args && args.leftCol) {
      this.leftCol = args.leftCol
    }

    if (args && args.size) {
      this.size = args.size
    }

    if (args && args.title) {
      this.title = args.title
    }

    if (args && args.key) {
      this.key = args.key
    }

    if (args && args.noPagination) {
      this.noPagination = args.noPagination
    }

    if (args && args.updateFields) {
      this.fields = args.updateFields(this.fields)
    }

    if (args && args.internalQuery) {
      this.internalQuery = args.internalQuery
    }

    if (args && args.dataSourceRequest) {
      this.dataSourceRequest = args.dataSourceRequest
    }

    if (args && args.noPagination) {
      this.noPagination = args.noPagination
    }
    if (args && args.perPage) {
      this.perPage = args.perPage
    }

    if (args && args.fields) {
      this.fields = args.fields
    }

    if (args && args.noTotal) {
      this.noTotal = args.noTotal
    }

    if (args && args.allowAll) {
      this.allowAll = args.allowAll
    }
    if (args && args.bordered) {
      this.bordered = args.bordered
    }

    if (args && args.cellSlots) {
      this.cellSlots = args.cellSlots
    }

    if (args && args.internalQueryPush) {
      this.internalQuery.push(args.internalQueryPush)
    }
  }

  public internalQuery = ['is:this_week']

  public internalFilters = {
    region: 'all',
    product: 'all',
  }

  public get filterToQuery() {
    let query = []

    if (this.filters.product !== 'all') {
      query.push(`product:${this.filters.product}`)
    }

    if (this.filters.region !== 'all') {
      query.push(`region:${this.filters.region}`)
    }

    return query
  }

  public get payload() {
    const query = [...new Set([...this.query, ...this.internalQuery, ...this.filterToQuery])]

    return {
      query,
      ...this.pagination,
    }
  }

  public async dataSource() {
    if (this.dataSourceRequest) {
      const res = this.dataSourceRequest(this.payload)
      return res.then((r: any) => {
        this.records = r.records

        if (r.result) return r.result

        return r.data
      })
    }
    // @ts-ignore
    return OpportunityActivity.paginate(this.payload).then((res: any) => {
      this.records = res.records
      return res.data
    })
  }

  public cellSlots: any[] = []
}
