import SelectOption from '@/models/interface/SelectOption'
import SystemDashboardAction from '@/models/SystemDashboardAction'

export default class HeaderFilter extends SystemDashboardAction {
  // Target Property to be changed or event name
  public target: string = 'filters'

  // Unique key for the action
  public key: string = 'start'

  public secondaryKey: string = 'end'

  // Label for the action
  public label: string = 'View By'

  public type: string = 'header-filter'
}
