import ActiveInitialTermModule from '@/models/ActiveInitialTermModule'
import Company from '@/models/Company'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import simpleCard from '@/pages/ManagementDashboard/scripts/simple-card'
import moment from 'moment'
import numeral from 'numeral'

export default class CustomWidgetWrapper extends SystemDashboardWidget {
  // public title: string = 'Initial Overview'

  // public widgetClass: string = 'unstyled-widget'

  public key: string = 'custom-widget'

  public view = 'custom'
  // public view = 'custom'

  // public type = 'custom-widget'

  public size = {
    lg: 8,
    md: 8,
    sm: 12,
  }

  public groups = {
    view: 'region',
  }

  public dataSourceRequest: any = null

  constructor(args: any = null) {
    super()

    if (args && args.dimensions) {
      this.dimensions = args.dimensions
    }

    if (args && args.metrics) {
      this.metrics = args.metrics
    }

    if (args && args.filters) {
      this.filters = Object.assign(this.filters, args.filters)
    }
    if (args && args.internalFilters) {
      this.internalFilters = Object.assign(this.internalFilters, args.internalFilters)
    }

    if (args && args.rightCol) {
      this.rightCol = args.rightCol
    }

    if (args && args.leftCol) {
      this.leftCol = args.leftCol
    }

    if (args && args.size) {
      this.size = args.size
    }

    if (args && args.title) {
      this.title = args.title
    }

    if (args && args.key) {
      this.key = args.key
    }

    if (args && args.updateFields) {
      this.fields = args.updateFields(this.fields)
    }

    if (args && args.internalQuery) {
      this.internalQuery = args.internalQuery
    }

    if (args && args.internalQueryPush) {
      if (!this.internalQuery) this.internalQuery = []
      this.internalQuery.push(args.internalQueryPush)
    }

    if (args && args.dataSourceRequest) {
      this.dataSourceRequest = args.dataSourceRequest
    }
  }

  public get filterToQuery() {
    let query = []

    if (this.filters.region !== 'all') {
      query.push(`region:${this.filters.region}`)
    }

    // query.push(`year:${this.internalFilters.year}`)

    return query
  }

  public get payload() {
    const query = [...new Set([...this.query, ...this.internalQuery, ...this.filterToQuery])]

    return {
      query,
      start: this.filters.start,
      end: this.filters.end,
    }
  }

  public dataSource() {
    if (this.dataSourceRequest) {
      const res = this.dataSourceRequest(this.payload)
      return res.then((r: any) => {
        if (r.hasOwnProperty('records')) {
          this.records = r.records
        }

        if (r.result) return r.result

        return r.data
      })
    }

    return Promise.resolve({})
  }

  public get customData() {
    let data = this.data
    // let revenue = numeral(data.revenue || 0).format('$0.00a')
    // let cost = numeral(data.cost || 0).format('$0.00a')
    // let profit = numeral(data.profit || 0).format('$0.00a')
    // let net_av = numeral(data.net_added_value || 0).format('$0.00a')
    // let av_to_goal = numeral(data.av_percentage_to_goal || 0).format('0.0%')

    let {
      incomplete_request_due_next_week,
      incomplete_request_due_this_week,
      incomplete_request_due_today,
      request_due_next_week,
      request_due_this_week,
      request_due_today,
      request_not_completed,
    } = data

    return `
      <div class="row text-center h-100 " style="margin-top:4em">
        <div class="col-sm-12 col-md-3 h-100 ">
          <div class="p-5 mb-5">
            <h5 class="text-capitalize">Request Due Today</h5>
            <h4 class="mt-4">${request_due_today}</h4>
          </div>
          <div class="p-5">
            <h5 class="text-capitalize">Incomplete Requests</h5>
            <small class="text-info">Due today</small>
            <h4 class="mt-4">${incomplete_request_due_today}</h4>
          </div>
        </div>
        
        <div class="col-sm-12 col-md-3 h-100">
          <div class="p-5 mb-5">
            <h5 class="text-capitalize">Total Due This Week</h5>
            <h4 class="mt-4">${request_due_this_week}</h4>
          </div>
          <div class="p-5">
            <h5 class="text-capitalize">Incomplete Requests</h5>
            <small class="text-info">Due this week</small>
            <h4 class="mt-4">${incomplete_request_due_this_week}</h4>
          </div>
        </div>
        
        <div class="col-sm-12 col-md-3 h-100">
          <div class="p-5 mb-5">
            <h5 class="text-capitalize">Total Due next Week</h5>
            <h4 class="mt-4">${request_due_next_week}</h4>
          </div>
          <div class="p-5">
            <h5 class="text-capitalize">Incomplete Requests</h5>
            <small class="text-info">Due next week</small>
            <h4 class="mt-4">${incomplete_request_due_next_week}</h4>
          </div>
        </div>
        
        <div class="col-sm-12 col-md-3 ">
          <div class="p-5 h-100">
            <h5 class="text-capitalize">Requests Not Complete</h5>
            <div class="d-flex h-100 align-items-center justify-content-center">
              <h1 class="mt-4 text-danger" style="font-size: 80px">${request_not_completed}</h1>
            </div>
          </div>
        </div>
      </div>
    `
  }
}
