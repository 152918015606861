import CimWorkloadModule from '@/models/FORREFCimWorkloadModule'
import dashboardSharedOptions from '@/pages/ManagementDashboard/dashboard-shared-options'
import { FILTERS } from '@/pages/ManagementDashboard/shared/helpers'

export default {
  title: 'Pending Tasks',
  key: 'pending-tasks',
  source: 'request',
  request: {
    module: new CimWorkloadModule(),
    exec: 'pendingTask',
  },
  pre_load: {},
  type: 'table',
  size: {
    lg: 6,
    md: 6,
    sm: 12,
  },
  capitalize_labels: true,
  config: {
    perPage: 100,
    noPagination: false,
    bordered: true,
    allowAll: true,
    noTotal: true,
    fields: [
      {
        key: 'title',
        label: 'Title',
        class: 'text-center align-middle',
        show: true,
        thClass: 'align-middle text-center',
        sortable: true,
      },

      {
        key: 'owner',
        label: 'Owner',
        class: 'text-center align-middle',
        show: true,
        thClass: 'align-middle text-center',
        sortable: true,
      },

      {
        key: 'status',
        label: 'Status',
        class: 'text-center align-middle',
        show: true,
        thClass: 'align-middle text-center',
        sortable: true,
      },

      {
        key: 'created_at',
        label: 'Created At',
        class: 'text-center align-middle',
        show: true,
        thClass: 'align-middle text-center',
        sortable: true,
      },
      {
        key: 'due_at',
        label: 'Due At',
        class: 'text-center align-middle',
        show: true,
        thClass: 'align-middle text-center',
        sortable: true,
      },
    ],
  },
  metrics: ['pipeline'],
  dimensions: [],
  query: [],
  filters: {
    dynamic_filter: {
      ...FILTERS.YEAR,
    },
    region: '',
    product: 'all',
    group_mode: 'quarter',
  },
  order: {},
  dynamic_options: [
    {
      target: 'filters',
      sub_target: 'region',
      options: dashboardSharedOptions.region_filters,
    },
    {
      target: 'filters',
      sub_target: 'product',
      options: dashboardSharedOptions.product_filter_options,
    },
  ],
}
