import CimWorkloadModule from '@/models/FORREFCimWorkloadModule'
import { FILTERS } from '@/pages/ManagementDashboard/shared/helpers'
import numeral from 'numeral'

export default {
  title: 'Number of Media Plans',
  key: 'number-of-media-plans',
  source: 'request',
  request: {
    module: new CimWorkloadModule(),
    exec: 'numberOfMediaplans',
  },
  pre_load: {},
  type: 'line',
  size: {
    lg: 4,
    md: 4,
    sm: 12,
  },
  config: {
    dynamic_date: true,
    totalFormat: '0.00a',
    rawLables: true,
  },
  dimensions: ['date', 'name'],
  capitalize_labels: true,
  metrics: ['count'],
  filters: {
    dynamic_filter: {
      ...FILTERS.YEAR,
    },
    group_mode: 'week',
    group_role: 'team',
  },
  query: [],
  order: {},
  dynamic_options: [
    { target: 'local_filters', sub_target: 'dynamic_filter' },
    {
      target: 'filters',
      sub_target: 'group_mode',
      options: [
        { text: 'Week', value: 'week' },
        { text: 'Month', value: 'month' },
        { text: 'Quarter', value: 'quarter' },
        { text: 'Year', value: 'year' },
        { text: 'Day', value: 'day' },
      ],
    },

    {
      target: 'filters',
      sub_target: 'group_role',
      options: [
        { text: 'Region', value: 'team' },
        { text: 'Sales Rep', value: 'individual' },
        { text: 'Account Manager', value: 'account_manager' },
      ],
    },
  ],
  formatters: {
    yaxis: {
      labels: {
        formatter: (value: number, labels: string[], series: number[], __: number, opt: any) =>
          `${numeral(value).format('0,0')}`,
      },
    },
    dataLabelFormatter: (value: number, labels: string[], series: number[], __: number, opt: any) =>
      `${numeral(value).format('0,0')}`,
    tooltipFormatter: (value: number, labels: string[], series: any[], total: number, opt: any) => [
      numeral(value).format('0,0'),
    ],
  },
}
