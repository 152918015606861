export default {
  group_roles: [
    { text: 'Region', value: 'team' },
    { text: 'Sales Rep', value: 'individual' },
  ],

  product_filter_options: [
    { text: 'SSL', value: 'ssl' },
    { text: 'CCL', value: 'ccl' },
    { text: 'All', value: 'all' },
  ],

  region_filters: [
    { text: 'National', value: 'national' },
    { text: 'East', value: 'east' },
    { text: 'West', value: 'west' },
    { text: 'Midwest', value: 'midwest' },
    { text: 'All', value: '' },
  ],

  company_creation_types: [
    { text: 'New', value: 'new' },
    { text: 'Existing', value: 'existing' },
    { text: 'All', value: 'all' },
  ],

  table_group_modes: (selected: any = null) => {
    let opts = [
      { text: 'Week', value: 'week' },
      { text: 'Month', value: 'month' },
      { text: 'Quarter', value: 'quarter' },
    ]
    if (!selected) return opts

    return opts.filter(o => selected.includes(o.value))
  },
}
