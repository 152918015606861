import HamiltonModule from '@/models/HamiltonModule'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import { buildColorPool } from '@/models/Util'
import { startCase } from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import DateRangeFilter from '../../filters/DateRangeFilter'
import { FILTERS } from '../../helpers'

/**
 * This widget can be used multiple times, this should be a default generic widget for bar and bar_proportional
 */
export default class PublisherShareWidget extends SystemDashboardWidget {
  public title: string = 'Publisher share'

  public key: string = 'publisher-share'

  public view: string = 'chart'

  public subView: string = 'bar_proportional'

  public size = {
    lg: 12,
    md: 12,
  }

  public dimensions = ['created_at', 'publisher']

  public metrics = ['impressions']

  public dataSourceRequest: any = null

  public custom_label: any = null

  public date_dimension_format: any = null

  public date_format_labels: any = null

  public totalFormat: any = null

  public sort_asc: any = null

  constructor(args: any = null) {
    super()

    if (args && args.dimensions) {
      this.dimensions = args.dimensions
    }

    if (args && args.metrics) {
      this.metrics = args.metrics
    }

    if (args && args.filters) {
      this.filters = Object.assign(this.filters, args.filters)
    }
    if (args && args.internalFilters) {
      this.internalFilters = Object.assign(this.internalFilters, args.internalFilters)
    }
    if (args && args.internalQuery) {
      this.internalQuery = Object.assign(this.internalQuery, args.internalQuery)
    }

    if (args && args.rightCol) {
      this.rightCol = args.rightCol
    }

    if (args && args.leftCol) {
      this.leftCol = args.leftCol
    }

    if (args && args.size) {
      this.size = args.size
    }

    if (args && args.title) {
      this.title = args.title
    }

    if (args && args.key) {
      this.key = args.key
    }

    if (args && args.dataSourceRequest) {
      this.dataSourceRequest = args.dataSourceRequest
    }

    // group
    if (args && args.groups) {
      this.groups = Object.assign(this.groups, args.groups)
    }

    // internalGroups
    if (args && args.internalGroups) {
      this.internalGroups = Object.assign(this.internalGroups, args.internalGroups)
    }

    if (args && args.updateFields) {
      this.fields = args.updateFields(this.fields)
    }

    // custom_label
    if (args && args.custom_label) {
      this.custom_label = args.custom_label
    }

    if (args && args.subView) {
      this.subView = args.subView
    }

    if (args && args.date_dimension_format) {
      this.date_dimension_format = args.date_dimension_format
    }
    if (args && args.date_format_labels) {
      this.date_format_labels = args.date_format_labels
    }
    if (args && args.custom_label) {
      this.custom_label = args.custom_label
    }
    if (args && args.totalFormat) {
      this.totalFormat = args.totalFormat
    }
  }

  public internalFilters = {
    ...FILTERS.MONTH,
  }

  public rightCol: any = [new DateRangeFilter({ target: 'internalFilters' })]

  public formatters: any = {
    totalBarFormatter: (widget: SystemDashboardWidget, value: any, opt: any) =>
      `${numeral(value).format(this.totalFormat ?? '$0.00a')}`,
    yaxis: (_: SystemDashboardWidget, value: any, opt: any) => {
      if (this.subView === 'bar_proportional') {
        return `${numeral(value * 0.01).format('0%')}`
      }

      return `${numeral(value).format('0.00a')}`
    },
    label: (_: SystemDashboardWidget, value: any, opt: any) => {
      if (this.subView === 'bar_proportional') {
        return `${numeral(value * 0.01).format('0.0%')}`
      }
      return [`${numeral(value).format('0.00a')}`]
    },
    tooltip: (_: SystemDashboardWidget, value: any, opt: any) => {
      if (this.subView === 'bar_proportional') {
        return [numeral(value).format('0.00a')]
      }

      return [numeral(value).format('0.00a')]
    },
  }

  public get filterToQuery() {
    let query = []

    query.push(this.internalFilters)

    return query
  }

  public get payload() {
    const query = [...new Set([...this.query, ...this.internalQuery, ...this.filterToQuery])]

    return {
      query,
    }
  }

  public async dataSource() {
    if (this.dataSourceRequest) {
      const res = this.dataSourceRequest(this.payload)
      return res
    }

    let request = new HamiltonModule()

    return await request
      .publisherShare({ module: this.payload, ignore_logic: true })
      .then((res: any) => res.result)
  }

  protected dataMapperBar(data: any): Promise<any> {
    const series: number[] = []
    const data_series: any[] = []
    const labels: any[] = []
    const colors: string[] = []
    const series_ids: string[] = []
    let total = 0

    const groups: string[] = []

    let x_series: string[] = []

    // Init Series
    data.forEach((item: any, idx: number) => {
      let label = startCase(item[this.dimensions[1]])
      if (this.custom_label) {
        label = this.custom_label
      }
      const group_index = groups.indexOf(label)
      if (group_index === -1) {
        groups.push(label)
        data_series.push({ name: label, data: [] })
      }

      const x_label = startCase(item[this.dimensions[0]])
      if (x_series.indexOf(x_label) === -1) {
        x_series.push(x_label)
      }
    })

    const color_pool = buildColorPool(groups.length)

    data_series.forEach((item: any, idx: number) => {
      x_series.forEach(() => {
        item.data.push(0)
      })
      colors.push(color_pool(idx))
    })

    if (this.date_dimension_format) {
      x_series = x_series.sort((a, b) => {
        if (this.sort_asc) {
          return (
            moment(b, this.date_dimension_format).unix()
            - moment(a, this.date_dimension_format).unix()
          )
        }
        return (
          moment(a, this.date_dimension_format).unix()
          - moment(b, this.date_dimension_format).unix()
        )
      })
    }

    data.forEach((item: any, idx: number) => {
      const val = Number(item[this.metrics[0]])
      total += val
      series.push(val)
      const label = startCase(item[this.dimensions[0]])

      let group_label = startCase(item[this.dimensions[1]])
      if (this.custom_label) {
        group_label = this.custom_label
      }
      const group_index = groups.indexOf(group_label)
      const x_index = x_series.indexOf(label)

      data_series[group_index].data[x_index] += val

      if (['product', 'region', 'deal_type'].includes(this.dimensions[1])) {
        series_ids[group_index] = item[this.dimensions[1]].toLowerCase().replaceAll(' ', '_')
      } else if (this.dimensions[2]) {
        series_ids[group_index] = item[this.dimensions[2]]
      }
    })
    // Udpate Settings
    this.chartSettings.colors = colors

    // x_series = x_series.map((x) => startCase(x))
    x_series = x_series.map(x => startCase(moment(x).format(this.date_format_labels)))

    this.chartSettings.xaxis = {
      categories: x_series,
    }

    this.chartSettings.labels = labels

    return Promise.resolve(data_series)
  }

  // bar_proportional
  protected dataMapperBarProportional(data: any): Promise<any> {
    const series: number[] = []
    const data_series: any[] = []
    const labels: any[] = []
    const colors: string[] = []
    const series_ids: string[] = []
    let total = 0

    const groups: string[] = []

    let x_series: string[] = []

    // Init Series
    data.forEach((item: any, idx: number) => {
      if (item[this.dimensions[1]]) {
        const label = startCase(item[this.dimensions[1]])
        const group_index = groups.indexOf(label)
        if (group_index === -1) {
          groups.push(label)
          data_series.push({ name: label, data: [] })
        }
      }

      if (item[this.dimensions[0]]) {
        const x_label = startCase(item[this.dimensions[0]])
        if (x_series.indexOf(x_label) === -1) {
          x_series.push(x_label)
        }
      }
    })

    const color_pool = buildColorPool(groups.length)

    data_series.forEach((item: any, idx: number) => {
      x_series.forEach(() => {
        item.data.push(0)
      })
      colors.push(color_pool(idx))
    })

    if (this.date_dimension_format) {
      x_series = x_series.sort(
        (a, b) =>
          moment(a, this.date_dimension_format).unix()
          - moment(b, this.date_dimension_format).unix(),
      )
    }

    data.forEach((item: any, idx: number) => {
      const val = Number(item[this.metrics[0]])
      total += val
      series.push(val)
      const label = startCase(item[this.dimensions[0]])
      const group_label = startCase(item[this.dimensions[1]])
      const group_index = groups.indexOf(group_label)
      const x_index = x_series.indexOf(label)

      data_series[group_index].data[x_index] += val

      if (['product', 'region', 'deal_type'].includes(this.dimensions[1])) {
        series_ids[group_index] = item[this.dimensions[1]].toLowerCase().replaceAll(' ', '_')
      } else if (this.dimensions[2]) {
        series_ids[group_index] = item[this.dimensions[this.dimensions.length - 1]]
      }
    })

    // Udpate Settings
    this.chartSettings.colors = colors

    x_series = x_series.map(x => startCase(moment(x).format(this.date_format_labels)))

    this.chartSettings.xaxis = {
      categories: x_series,
    }

    this.chartSettings.labels = labels

    this.chartSettings.total = total

    return Promise.resolve(data_series)
  }

  protected dataMapper(data: any): Promise<any> {
    if (this.subView === 'bar_proportional') {
      return this.dataMapperBarProportional(data)
    }
    return this.dataMapperBar(data)
  }
}
