import SystemDashboardAction from '@/models/SystemDashboardAction'

export default class DateRangeFilter extends SystemDashboardAction {
  // Target Property to be changed or event name
  public target: string = 'filters'

  // Unique key for the action
  public key: string = 'start'

  public secondaryKey: string = 'end'

  public type: string = 'date_range'

  constructor(args: any = null) {
    super()
    if (args && args.target) {
      this.target = args.target
    }

    if (args && args.key) {
      this.key = args.key
    }

    if (args && args.secondaryKey) {
      this.secondaryKey = args.secondaryKey
    }
  }
}
