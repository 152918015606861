import Api from '@/models/Api'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import numeral from 'numeral'
import { FILTERS } from '../../helpers'

export default class SoldRevenuSummaryeWidget extends SystemDashboardWidget {
  public title: string = 'Sold Revenue Summary'

  public key: string = 'sold_revenue_summary'

  public view: string = 'card'

  public internalQuery: string[] = ['summary:30,7']

  public loaderSize: string = '350px'

  public size: any = {
    sm: 12,
    md: 4,
    lg: 4,
  }

  public internalFilters: any = {
    ...FILTERS.YTD_THROUGH_MONTH,
  }

  public get payload() {
    let query = [...this.internalQuery]

    if (this.filters.product !== 'all') {
      query.push(`product:${this.filters.product}`)
    }

    if (this.filters.region !== 'all') {
      query.push(`region:${this.filters.region}`)
    }

    let group_mode = this.groups.period
    let group_role = this.groups.view === 'region' ? 'team' : 'individual'

    return {
      start: this.internalFilters.start,
      end: this.internalFilters.end,
      query,
      group_mode,
      group_role,
    }
  }

  public dataSource() {
    let api = new Api()

    return api
      .get('sales-activity/sold-per', this.payload)
      .then((response: any) => response.data.result)
      .catch(err => ({ data: [] }))
  }

  protected dataMapper(data: any): Promise<any> {
    const last_30 = data.last_30 ? numeral(data.last_30).format('$0.00a') : 0
    const last_7 = data.last_7 ? numeral(data.last_7).format('$0.00a') : 0
    const this_quarter = data.this_quarter ? numeral(data.this_quarter).format('$0.00a') : 0
    const last_quarter = data.last_quarter ? numeral(data.last_quarter).format('$0.00a') : 0

    const ret = ` 
    <div class="row text-center h-50 align-items-center" style="margin-top:150px;">
      <div class="col">
        <h4>Last 7 days</h4>
        <h4>${last_7}</h4>
      </div>
      <div class="col">
        <h4>Last 30 Days <small>(AVG/week)</small></h4>
        <h4>${last_30}</h4>
      </div>
    </div>
    <div class="row text-center align-items-center mt-5">
      <div class="col">
        <h4>Last Quarter <small>(AVG/week)</small></h4>
        <h4>${last_quarter}</h4>
      </div>
      <div class="col">
        <h4>This Quarter <small>(AVG/week)</h4>
        <h4>${this_quarter}</h4>
      </div>
    </div>
  `

    return Promise.resolve(ret)
  }
}
