import Api from '@/models/Api'
import FinancialPlan from '@/models/FinancialPlan'
import Opportunity from '@/models/Opportunity'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import { FILTERS, normalizeLabel } from '@/pages/ManagementDashboard/shared/helpers'
import moment from 'moment'
import numeral from 'numeral'

export default class WeeklyOverview extends SystemDashboardWidget {
  public title: string = 'Weekly Overview'

  public key: string = 'opportunity-week-card'

  public view: string = 'custom'

  public dimensions: string[] = ['region', 'advertiser', 'agency']

  public metrics: string[] = ['goal', 'goal_pipeline', 'goal_sold', 'goal_achieved']

  public size: any = {
    lg: 4,
    md: 12,
  }

  public loaderSize: string = '350px'

  constructor(args: any = null) {
    super()

    if (args && args.dimensions) {
      this.dimensions = args.dimensions
    }

    if (args && args.metrics) {
      this.metrics = args.metrics
    }

    if (args && args.filters) {
      this.filters = Object.assign(this.filters, args.filters)
    }
    if (args && args.internalFilters) {
      this.internalFilters = Object.assign(this.internalFilters, args.internalFilters)
    }

    if (args && args.rightCol) {
      this.rightCol = args.rightCol
    }

    if (args && args.leftCol) {
      this.leftCol = args.leftCol
    }

    if (args && args.size) {
      this.size = args.size
    }

    if (args && args.title) {
      this.title = args.title
    }

    if (args && args.key) {
      this.key = args.key
    }

    if (args && args.noPagination) {
      this.noPagination = args.noPagination
    }

    if (args && args.updateFields) {
      this.fields = args.updateFields(this.fields)
    }

    if (args && args.internalQuery) {
      this.internalQuery = args.internalQuery
    }

    if (args && args.internalQueryPush) {
      this.internalQuery.push(args.internalQueryPush)
    }
  }

  public get filterToQuery() {
    let query = []

    if (this.filters.product !== 'all') {
      query.push(`product:${this.filters.product}`)
    }

    if (this.filters.region !== 'all') {
      query.push(`region:${this.filters.region}`)
    }

    query.push(`year:${this.internalFilters.year}`)

    return query
  }

  public internalQuery: any = ['scope:agency']

  public get payload() {
    const query = [...new Set([...this.query, ...this.internalQuery, ...this.filterToQuery])]

    const filters = {
      ...this.internalFilters,
    }

    return {
      query,
      filters,
      snapshot: this.filters.snapshot || '',
      order: {},
    }
  }

  public flattenPayload: boolean = true

  public internalFilters: any = {
    year: moment().format('YYYY'),
  }

  public dataSource() {
    return Opportunity.weeklyCard(this.payload).then((res: any) => res.data.result)
  }

  public get customData() {
    const data = this.data
    // Closed
    const closed = numeral(data.closed).format('0,0')
    const last_week_closed = numeral(data.last_week_closed).format('0,0')
    const closed_percentage = numeral(data.last_week_closed_percentage).format('0%')
    const closed_color = data.last_week_closed_percentage > 0 ? 'text-success' : 'text-danger'
    const closed_icon = data.last_week_closed_percentage > 0 ? '▴' : '▾'

    // Closed Amount
    const closed_amount = numeral(data.closed_amount).format('$0,0.00a')
    const last_week_closed_amount = numeral(data.last_week_closed_amount).format('$0,0.00a')
    const closed_amount_percentage = numeral(data.last_week_closed_amount_percentage).format('0%')
    const closed_amount_color = data.last_week_closed_amount_percentage > 0 ? 'text-success' : 'text-danger'
    const closed_amount_icon = data.last_week_closed_amount_percentage > 0 ? '▴' : '▾'

    // New
    const new_count = numeral(data.new).format('0,0')
    const last_week_new = numeral(data.last_week_new).format('0,0')
    const new_percentage = numeral(data.last_week_new_percentage).format('0%')
    const new_color = data.last_week_new_percentage > 0 ? 'text-success' : 'text-danger'
    const new_icon = data.last_week_new_percentage > 0 ? '▴' : '▾'

    // New Ammount
    const new_amount = numeral(data.new_amount).format('$0,0.00a')
    const last_week_new_amount = numeral(data.last_week_new_amount).format('$0,0.00a')
    const new_amount_percentage = numeral(data.last_week_new_amount_percentage).format('0.00%')
    const new_amount_color = data.last_week_new_amount_percentage > 0 ? 'text-success' : 'text-danger'
    const new_amount_icon = data.last_week_new_amount_percentage > 0 ? '▴' : '▾'

    // Lost
    const lost = numeral(data.lost).format('0,0')
    const last_week_lost = numeral(data.last_week_lost).format('0,0')
    const lost_percentage = numeral(data.last_week_lost_percentage).format('0%')
    const lost_color = data.last_week_lost_percentage > 0 ? 'text-success' : 'text-danger'
    const lost_icon = data.last_week_lost_percentage > 0 ? '▴' : '▾'

    // Lost Amount
    const lost_amount = numeral(data.lost_amount).format('$0,0.00a')
    const last_week_lost_amount = numeral(data.last_week_lost_amount).format('$0,0.00a')
    const lost_amount_percentage = numeral(data.last_week_lost_amount_percentage).format('0.00%')
    const lost_amount_color = data.last_week_lost_amount_percentage > 0 ? 'text-success' : 'text-danger'
    const lost_amount_icon = data.last_week_lost_amount_percentage > 0 ? '▴' : '▾'

    return `
    <div class="row text-center mt-5 pt-2">
      <div class="col">
        <h6>Closed/Won</h6>
        <h4>${closed_amount}</h4>
        <p class="${closed_amount_color}">${closed_amount_icon} ${closed_amount_percentage} (Last week: ${last_week_closed_amount})</p>
      </div>
      <div class="col">
        <h6>Added</h6>
        <h4>${new_amount}</h4>
        <p class="${new_amount_color}">${new_amount_icon} ${new_amount_percentage} (Last week: ${last_week_new_amount})</p>
      </div>
      <div class="col">
        <h6>Lost</h6>
        <h4>-${lost_amount}</h4>
        <p class="${lost_amount_color}">${lost_amount_icon} ${lost_amount_percentage} (Last week: -${last_week_lost_amount})</p>
      </div>
    </div>
    <div class="row text-center mt-3">
      <div class="col">
        <h6>Closed Opps</h6>
        <h4>${closed}</h4>
        <p class="${closed_color}">${closed_icon} ${closed_percentage} (Last week: ${last_week_closed})</p>
      </div>
      <div class="col">
        <h6>New Opps</h6>
        <h4>${new_count}</h4>
        <p class="${new_color}">${new_icon} ${new_percentage} (Last week: ${last_week_new})</p>
      </div>
      <div class="col">
        <h6>Lost Opps</h6>
        <h4>-${lost}</h4>
        <p class="${lost_color}">${lost_icon} ${lost_percentage} (Last week: -${last_week_lost})</p>
      </div>`
  }
}
