import SelectOption from '@/models/interface/SelectOption'
import SystemDashboardAction from '@/models/SystemDashboardAction'

export default class ProductDashboardFilter extends SystemDashboardAction {
  // Target Property to be changed or event name
  public target: string = 'filters'

  // Unique key for the action
  public key: string = 'product'

  // Label for the action
  public label: string = 'Product'

  // Options for the action if applicable
  public options: SelectOption[] = [
    new SelectOption('All', 'all'),
    new SelectOption('SSL', 'ssl'),
    new SelectOption('CCL', 'ccl'),
  ]
}
