
import { Component, Prop, Vue } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import numeral from 'numeral'

@Component
export default class TableCellIndicator extends ViewModel {
  @Prop({ required: true }) value!: any

  @Prop({ required: true }) lastYearValue!: any

  @Prop({ required: true }) widgetKey!: string

  @Prop({ required: true }) index!: number

  @Prop({ required: true }) prefix!: string

  public get diff() {
    return this.calculateDiff(this.value, this.lastYearValue)
  }

  public calculateDiff(value: any, lastYear: any) {
    if (lastYear == 0) {
      return 0
    }
    return (value - lastYear) / lastYear
  }

  public fmtNumeral(value: any) {
    return numeral(value)
  }
}
