import SalesActivity from '@/models/SalesActivity'
import { buildColorPool } from '@/models/Util'
import { FILTERS, normalizeLabel } from '@/pages/ManagementDashboard/shared/helpers'

import numeral from 'numeral'

export default {
  title: 'Pipeline Movment',
  key: 'pipeline-movement',
  source: 'request',
  request: {
    module: new SalesActivity(),
    exec: 'pipelineMovement',
  },
  pre_load: {},
  type: 'line',
  size: {
    lg: 12,
    md: 12,
    sm: 12,
  },
  config: {
    dynamic_date: true,
    date_dimension_format: 'YYYY-MM-DD',
    date_format_labels: 'MMM Do',
    totalFormat: '0.00a',
    rawLables: true,
  },
  base_options_overwrite: false,
  metrics: ['amount'],
  dimensions: ['date', 'status'],
  filters: {
    dynamic_filter: {
      ...FILTERS.LAST_4_WEEKS,
    },
    group_mode: 'week',
  },
  query: [],
  order: {},
  labels: ['name'],
  dynamic_options: [
    { target: 'local_filters', sub_target: 'dynamic_filter' },
    {
      target: 'filters',
      sub_target: 'group_mode',
      options: [
        { text: 'Week', value: 'week' },
        { text: 'Month', value: 'month' },
        { text: 'Quarter', value: 'quarter' },
      ],
    },
  ],
  formatters: {
    yaxis: {
      labels: {
        formatter: (value: number, labels: string[], series: number[], __: number, opt: any) =>
          `${numeral(value).format('0.00a')}`,
      },
    },
    dataLabelFormatter: (value: number, labels: string[], series: number[], __: number, opt: any) =>
      `${numeral(value).format('0.00a')}`,
    tooltipFormatter: (value: number, labels: string[], series: any[], total: number, opt: any) => [
      numeral(value).format('0.00a'),
    ],
  },
}
