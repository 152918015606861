import HamiltonModule from '@/models/HamiltonModule'
import Opportunity from '@/models/Opportunity'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import { buildColorPool } from '@/models/Util'
import moment from 'moment'
import numeral from 'numeral'
import ColumnSelector from '../../filters/ColumnSelector'
import { FILTERS } from '../../helpers'

export default class TodayWidget extends SystemDashboardWidget {
  public title: string = 'Today'

  public key = 'publisher_today'

  public view: string = 'table'

  public size = {
    lg: 3,
    md: 3,
  }

  public fields: any = [
    {
      key: 'publisher',
      label: 'Publisher',
      class: 'text-center align-middle',
      show: true,
      thClass: 'align-middle text-center',
      sortable: true,
    },
    {
      key: 'impressions',
      label: 'Impressions',
      class: 'text-center align-middle',
      show: true,
      thClass: 'align-middle text-center',
      sortable: true,
      filter: true,
      type: 'abbreviate',
      total: 'abbreviate',
    },
    {
      key: 'vcr',
      label: 'VCR',
      class: 'text-center align-middle',
      show: true,
      thClass: 'align-middle text-center',
      sortable: false,
      filter: false,
      type: 'percentage',
      total: 'percentage',
      customTotal: (data: any) => {
        let starts = data.reduce((acc: any, cur: any) => acc + Number(cur.starts), 0)
        let completes = data.reduce((acc: any, cur: any) => acc + Number(cur.completes), 0)
        return completes / starts
      },
    },
  ]

  public dimensions = []

  public metrics = []

  public internalQuery = []

  public format_totals: any = '0,0'

  public dataSourceRequest: any = null

  public orderBy: any = 'impressions'

  public custom_data: any = null

  public page: any = 1

  public internalTableConfig: any = {
    fields: [],
  }

  public conditionalFields: any = null

  constructor(args: any = null) {
    super()

    if (args && args.dimensions) {
      this.dimensions = args.dimensions
    }

    if (args && args.metrics) {
      this.metrics = args.metrics
    }

    if (args && args.filters) {
      this.filters = Object.assign(this.filters, args.filters)
    }
    if (args && args.internalFilters) {
      this.internalFilters = Object.assign(this.internalFilters, args.internalFilters)
    }
    if (args && args.internalQuery) {
      this.internalQuery = Object.assign(this.internalQuery, args.internalQuery)
    }

    if (args && args.rightCol) {
      this.rightCol = args.rightCol
    }

    if (args && args.leftCol) {
      this.leftCol = args.leftCol
    }

    if (args && args.pushLeftCol) {
      this.leftCol.push(args.pushLeftCol)
    }
    if (args && args.prependLeftCol) {
      this.leftCol.unshift(args.prependLeftCol)
    }

    if (args && args.pushRightCol) {
      this.rightCol.push(args.pushRightCol)
    }
    if (args && args.prependRightCol) {
      this.rightCol = [...args.prependRightCol, ...this.rightCol]
    }

    if (args && args.size) {
      this.size = args.size
    }

    if (args && args.title) {
      this.title = args.title
    }

    if (args && args.key) {
      this.key = args.key
    }

    if (args && args.dataSourceRequest) {
      this.dataSourceRequest = args.dataSourceRequest
    }

    // group
    if (args && args.groups) {
      this.groups = Object.assign(this.groups, args.groups)
    }

    // internalGroups
    if (args && args.internalGroups) {
      this.internalGroups = Object.assign(this.internalGroups, args.internalGroups)
    }
    // format_totals
    if (args && args.format_totals) {
      this.format_totals = args.format_totals
    }

    if (args && args.updateFields) {
      this.fields = args.updateFields(this.fields)
    }

    if (args && args.custom_data) {
      this.custom_data = args.custom_data
    }

    if (args && args.fields) {
      this.fields = args.fields
      let x = new ColumnSelector({ fields: this.updateFieldsIfNeeded(), id: this.key })
      this.rightCol = this.rightCol.length ? [...this.rightCol, x] : [x]
    }
    if (args && args.orderBy) {
      this.orderBy = args.orderBy
    }

    // conditionalFields
    if (args && args.conditionalFields) {
      this.conditionalFields = args.conditionalFields
    }
  }

  public rightCol: any = []

  public filters = {
    product: 'all',
    region: 'all',
  }

  public internalFilters = {
    ...FILTERS.TODAY,
    select_by: 'publisher_vcr',
  }

  public get filterToQuery() {
    let query = []

    // query.push({ select_by: 'publisher_vcr' })
    // if select_by is null remove
    if (!this.internalFilters.select_by) {
      // @ts-ignore
      delete this.internalFilters.select_by
    }
    query.push(this.internalFilters)

    return query
  }

  public get payload() {
    const query = [...new Set([...this.query, ...this.internalQuery, ...this.filterToQuery])]

    return {
      query,

      ...this.pagination,
    }
  }

  public updateFieldsIfNeeded(reset_right_col: boolean = false) {
    this.fields = this.fields.map((field: any) => {
      if (this.conditionalFields && this.conditionalFields[this.groups.view_by]) {
        if (this.conditionalFields[this.groups.view_by].includes(field.key)) {
          field.show = true
        } else {
          field.show = false
        }
      }
      return field
    })

    if (reset_right_col) {
      let x = new ColumnSelector({ fields: this.fields, id: this.key })
      const updated = this.rightCol.filter((col: any) => col.id !== this.key)
      this.rightCol = []
      this.rightCol = updated.length ? [...updated, x] : [x]
    }

    return this.fields
  }

  public async dataSource() {
    this.updateFieldsIfNeeded(true)
    if (this.dataSourceRequest) {
      const res = await this.dataSourceRequest(this.payload)
      this.records = res.records
      return res.data
    }

    let request = new HamiltonModule()

    return await request
      .today({ context: this.payload, ignore_logic: true, module: null })
      .then((res: any) => {
        this.records = res.records
        return res.data
      })
  }

  public get customData() {
    return this.custom_data
  }
}
