import moment from 'moment'
import Api from './Api'
import Model from './interface/Model'

type Context = {
  perPage: any
  currentPage: number
  sortBy: any
  sortDesc: boolean
  filter: any
  query: any
}

export default class HamiltonModule extends Model {
  public api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'reports-consolidated' as string | null,
      plural: 'reports-consolidated' as string | null,
    },
  }

  public filters: any = {
    dates: {
      start: moment().startOf('year').format('YYYY-MM-DD hh:mm:ss'),
      shortcut: null,
      end: moment().format('YYYY-MM-DD hh:mm:ss'),
    },
  }

  public pagination_settings(context: Context) {
    return {
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...context.query],
      mode: 'exclusive',
    }
  }

  public today({
    context,
    module,
    ignore_logic,
  }: {
    context: any
    module: any
    ignore_logic: boolean
  }) {
    let options
    if (!ignore_logic) {
      const automated_filters = [...module.local_query]

      if (module.region) {
        automated_filters.push(`region:${module.region}`)
      }

      if (module.sales_rep) {
        automated_filters.push(`sr:${module.sales_rep}`)
      }

      if (module.local_filters.dynamic_filter) {
        automated_filters.push(module.local_filters.dynamic_filter)
      }
      if (module.local_filters.widget_filter) {
        automated_filters.push(module.local_filters.widget_filter)
      }

      let query = [...context.filter, ...module.query, ...automated_filters]
      query = [...new Set(query)]
      if (!context.query) context.query = []

      context.query = context.query.concat([...query])
      // context.query = make unique
      context.query = [...new Set(context.query)]

      options = this.pagination_settings(context)

      if (module.local_filters.order_by) {
        options.order_by = module.local_filters.order_by
      }
    } else {
      options = context
    }

    return HamiltonModule.paginate(options)
  }

  public mtdThroughYesterday({ context, module }: { context: Context; module: any }) {
    const automated_filters = [...module.local_query]

    if (module.region) {
      automated_filters.push(`region:${module.region}`)
    }

    if (module.sales_rep) {
      automated_filters.push(`sr:${module.sales_rep}`)
    }

    if (module.local_filters.dynamic_filter) {
      automated_filters.push(module.local_filters.dynamic_filter)
    }

    let query = [...context.filter, ...module.query, ...automated_filters]

    if (!context.query) context.query = []

    context.query = context.query.concat([...query])

    let options = this.pagination_settings(context)

    return HamiltonModule.paginate(options)
  }

  public ytdThroughYesterday({ context, module }: { context: Context; module: any }) {
    const automated_filters = [...module.local_query]

    if (module.region) {
      automated_filters.push(`region:${module.region}`)
    }

    if (module.sales_rep) {
      automated_filters.push(`sr:${module.sales_rep}`)
    }

    if (module.local_filters.dynamic_filter) {
      automated_filters.push(module.local_filters.dynamic_filter)
    }

    let query = [...context.filter, ...module.query, ...automated_filters]

    if (!context.query) context.query = []

    context.query = context.query.concat([...query])

    let options = this.pagination_settings(context)

    return HamiltonModule.paginate(options)
  }

  // add endpoint
  public todayTotal({ module, ignore_logic }: { module: any; ignore_logic: boolean }) {
    let query
    if (!ignore_logic) {
      const automated_filters = [...module.local_query]

      if (module.region) {
        automated_filters.push(`region:${module.region}`)
      }

      if (module.sales_rep) {
        automated_filters.push(`sr:${module.sales_rep}`)
      }

      if (module.local_filters.dynamic_filter) {
        automated_filters.push(module.local_filters.dynamic_filter)
      }

      query = [...module.query, ...automated_filters]
    }

    let api = new Api()

    return api
      .get(
        'reports-consolidated/today-total',
        ignore_logic
          ? { ...module, page_size: 'all', page: 1 }
          : { query, page_size: 'all', page: 1 },
      )
      .then(response => ({ data: response.data.result['reports-consolidated'] }))
  }

  public publisherShare({ module, ignore_logic }: { module: any; ignore_logic: boolean }) {
    let query: any = []
    if (!ignore_logic) {
      let automated_filters: any = []

      if (module.local_query) {
        automated_filters.concat(module.local_query)
      }
      if (module.local_filters) {
        automated_filters.concat(module.local_filters)
      }

      if (module.region) {
        automated_filters.push(`region:${module.region}`)
      }

      if (module.sales_rep) {
        automated_filters.push(`sr:${module.sales_rep}`)
      }

      if (module.local_filters.dynamic_filter) {
        automated_filters.push(module.local_filters.dynamic_filter)
      }

      query = [...module.query, ...automated_filters]
    }

    let api = new Api()

    return api
      .get(
        'reports-consolidated/publisher-share',
        ignore_logic
          ? { ...module, page_size: 'all', page: 1 }
          : { query, page_size: 'all', page: 1 },
      )
      .then((response: any) => response.data)
      .catch(() => ({ data: [] }))
  }

  public async advertiserSales({ context, module }: { context: Context; module: any }) {
    let automated_filters: any = []

    if (module.local_query) {
      automated_filters.concat(module.local_query)
    }
    if (module.local_filters) {
      automated_filters.concat(module.local_filters)
    }
    if (module.local_filters) {
      automated_filters.concat(this.filters)
    }

    if (module.region) {
      automated_filters.push(`region:${module.region}`)
    }

    if (module.sales_rep) {
      automated_filters.push(`sr:${module.sales_rep}`)
    }

    if (module.local_filters.dynamic_filter) {
      automated_filters.push(module.local_filters.dynamic_filter)
    }
    let query = [...context.filter, ...module.query, ...automated_filters]

    if (!context.query) context.query = []

    context.query = context.query.concat([...query])

    let options = this.pagination_settings(context)

    let api = new Api()

    return await api
      .get('reports-consolidated/advertiser-sales', options)
      .then(response => ({ data: response.data.result['reports-consolidated'] }))
      .catch(() => ({ data: [] }))
  }

  public async salesChannel({ context, module }: { context: Context; module: any }) {
    let automated_filters: any = []

    if (module.local_query) {
      automated_filters.concat(module.local_query)
    }
    if (module.local_filters) {
      automated_filters.concat(module.local_filters)
    }
    if (module.local_filters) {
      automated_filters.concat(this.filters)
    }

    if (module.region) {
      automated_filters.push(`region:${module.region}`)
    }

    if (module.sales_rep) {
      automated_filters.push(`sr:${module.sales_rep}`)
    }

    if (module.local_filters.dynamic_filter) {
      automated_filters.push(module.local_filters.dynamic_filter)
    }
    let query = [...context.filter, ...module.query, ...automated_filters]

    if (!context.query) context.query = []

    context.query = context.query.concat([...query])

    let options = this.pagination_settings(context)
    let api = new Api()

    return await api
      .get('reports-consolidated/sales-channel', options)
      .then(response => ({ data: response.data.result['reports-consolidated'] }))
      .catch(() => ({ data: [] }))
  }

  public async ordersImpressions({ context, module }: { context: Context; module: any }) {
    let automated_filters: any = []

    if (module.local_query) {
      automated_filters.concat(module.local_query)
    }
    if (module.local_filters) {
      automated_filters.concat(module.local_filters)
    }
    if (module.local_filters) {
      automated_filters.concat(this.filters)
    }

    if (module.region) {
      automated_filters.push(`region:${module.region}`)
    }

    if (module.sales_rep) {
      automated_filters.push(`sr:${module.sales_rep}`)
    }

    if (module.local_filters.dynamic_filter) {
      automated_filters.push(module.local_filters.dynamic_filter)
    }

    let query = [...context.filter, ...module.query, ...automated_filters]

    if (!context.query) context.query = []

    context.query = context.query.concat([...query])

    let options = this.pagination_settings(context)

    let api = new Api()

    return await api
      .get('reports-consolidated/orders', options)
      .then(response => ({ data: response.data.result['reports-consolidated'] }))
      .catch(err => ({ data: [] }))
  }

  public async ordersTopic({ context, module }: { context: Context; module: any }) {
    let automated_filters: any = []

    if (module.local_query) {
      automated_filters.concat(module.local_query)
    }
    if (module.local_filters) {
      automated_filters.concat(module.local_filters)
    }
    if (module.local_filters) {
      automated_filters.concat(this.filters)
    }

    if (module.region) {
      automated_filters.push(`region:${module.region}`)
    }

    if (module.sales_rep) {
      automated_filters.push(`sr:${module.sales_rep}`)
    }

    if (module.local_filters.dynamic_filter) {
      automated_filters.push(module.local_filters.dynamic_filter)
    }

    let query = [...context.filter, ...module.query, ...automated_filters]

    if (!context.query) context.query = []

    context.query = context.query.concat([...query])

    let options = this.pagination_settings(context)

    let api = new Api()

    return await api
      .get('reports-consolidated/orders-topic', options)
      .then(response => ({ data: response.data.result['reports-consolidated'] }))
      .catch(err => ({ data: [] }))
  }

  public async impressionsSource({ context, module }: { context: Context; module: any }) {
    let automated_filters: any = []

    if (module.local_query) {
      automated_filters.concat(module.local_query)
    }
    if (module.local_filters) {
      automated_filters.concat(module.local_filters)
    }
    if (module.local_filters) {
      automated_filters.concat(this.filters)
    }

    if (module.region) {
      automated_filters.push(`region:${module.region}`)
    }

    if (module.sales_rep) {
      automated_filters.push(`sr:${module.sales_rep}`)
    }

    if (module.local_filters.dynamic_filter) {
      automated_filters.push(module.local_filters.dynamic_filter)
    }

    let query = [...context.filter, ...module.query, ...automated_filters]

    if (!context.query) context.query = []

    context.query = context.query.concat([...query])

    let options = this.pagination_settings(context)

    let api = new Api()

    return await api
      .get('reports-consolidated/impressions-source', options)
      .then(response => ({ data: response.data.result['reports-consolidated'] }))
      .catch(err => ({ data: [] }))
  }

  public async impressionsConsolidation({
    module,
    ignore_logic,
  }: {
    module: any
    ignore_logic: boolean
  }) {
    let query: any = []
    if (!ignore_logic) {
      let automated_filters: any = []

      if (module.local_query) {
        automated_filters.concat(module.local_query)
      }

      if (module.local_filters) {
        automated_filters.concat(module.local_filters)
      }

      if (module.local_filters) {
        automated_filters.concat(this.filters)
      }

      if (module.region) {
        automated_filters.push(`region:${module.region}`)
      }

      if (module.sales_rep) {
        automated_filters.push(`sr:${module.sales_rep}`)
      }

      if (module.local_filters.dynamic_filter) {
        automated_filters.push(module.local_filters.dynamic_filter)
      }

      query = [...module.query, ...automated_filters]
    }

    let api = new Api()

    return api
      .get('reports-consolidated/impressions-consolidation', ignore_logic ? module : { query })
      .then((response: any) => response.data)
      .catch(err => ({ data: [] }))
  }
}
