import { randomUUID } from '@/models/interface/Common'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import RegionSalesRepGroup from '../../filters/RegionSalesRepGroup'
import PeriodGroup from '../../filters/PeriodGroup'
import DateRangeFilter from '../../filters/DateRangeFilter'
import HeaderFilter from '../../filters/HeaderFilter'

export default class HeaderDateFilterWidget extends SystemDashboardWidget {
  public key: string = randomUUID()

  public type: string = 'filter'

  public rightCol: any = [new HeaderFilter()]

  public size: any = {
    sm: 12,
    md: 12,
    lg: 12,
  }
}
