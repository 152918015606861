import OpportunityActivity from '@/models/OpportunityActivity'
import SystemDashboard from '@/models/SystemDashboard'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import User from '@/models/User'
import router from '@/Routes'
import SystemtModule from '@/store/SystemModule'
import { getModule } from 'vuex-module-decorators'
import ClosedBusinessThisWeekWidget from '../widgets/finance/ClosedBusinessThisWeek'
import PitchedAmountWidget from '../widgets/finance/PitchedAmountWidget'
import WeeklySummaryWidget from '../widgets/finance/WeeklySummaryWidget'
import RegionProductFilterWidget from '../widgets/others/RegionProductFilterWidget'

export default class WeeklySalesDashboardModule extends SystemDashboard {
  public title = 'Weekly Sales'

  public key: string = 'weekly_sales'

  public pipeline_export_mode: string = 'consolidated'

  public querySettings = {
    company_fields: [
      {
        name: 'agency',
        key: 'agency',
        type: 'agency',
      },
      {
        name: 'advertiser',
        key: 'advertiser',
        type: 'advertiser',
      },
    ],
    user_fields: [
      {
        name: 'sm',
        key: 'sales_management_id',
      },
      {
        name: 'sr',
        key: 'sales_rep_id',
      },
    ],
    custom_fields: [
      {
        name: 'product:ssl',
        value: 'product:ssl',
      },
      {
        name: 'product:ccl',
        value: 'product:ccl',
      },
      {
        name: 'deal_type:direct',
        value: 'deal_type:direct',
      },
      {
        name: 'deal_type:3rd Party',
        value: 'deal_type:3rd_party',
      },
    ],
  }

  public filters = {
    product: 'all',
    region: 'all',
  }

  public widgets: SystemDashboardWidget[] = [
    new RegionProductFilterWidget(),

    new WeeklySummaryWidget(),
    new ClosedBusinessThisWeekWidget(),
    new ClosedBusinessThisWeekWidget({
      title: 'Pitched This Week',
      key: 'pitched-this-week',
      internalQuery: ['scope:agency', 'is:pitched_this_week'],
      size: {
        lg: 12,
        md: 12,
      },
    }),
    new WeeklySummaryWidget({
      title: 'Meetings This Week-',
      key: 'meetings-this-week',
      driverRequest: 'salesRepActivity',
      dimensions: [],
      metrics: [],
      internalQuery: ['is:this_week', 'type:meeting'],
      fields: [
        {
          key: 'sales_rep',
          label: 'Sales Rep',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          formatter: (_: any, __: any, activity: OpportunityActivity) =>
            activity.owner_model.sales_rep.name,
        },
        {
          key: 'sub_type',
          label: 'Meeting Sub-Type',
          class: 'text-center align-middle',
          show: true,
          type: 'capitalize_replace',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },
        {
          key: 'agency',
          label: 'Agency',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          formatter: (_: any, __: any, activity: OpportunityActivity) =>
            activity.owner_model.agency.name,
        },
        {
          key: 'advertiser',
          label: 'Advertiser',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          formatter: (_: any, __: any, activity: OpportunityActivity) =>
            activity.owner_model.advertiser.name,
        },
        {
          key: 'name',
          label: 'Subject',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'contacts',
          label: 'Contacts',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          formatter: (_: any, __: any, activity: OpportunityActivity) => {
            if (activity.contacts && activity.contacts.length > 0) {
              if (activity.contacts.length > 3) {
                let remaining = activity.contacts.length - 3
                return `${activity.contacts
                  .slice(0, 3)
                  .map(contact => contact.first_name)
                  .join(', ')} +${remaining} more`
              }
              return activity.contacts.map(contact => contact.first_name).join(', ')
            }

            return 'N/A'
          },
        },
        {
          key: 'scheduled_at',
          label: 'Date',
          class: 'text-center align-middle',
          show: true,
          type: 'date',
          thClass: 'align-middle text-center',
          sortable: true,
        },
      ],
      perPage: 100,
    }),
    new WeeklySummaryWidget({
      title: 'Meetings Scheduled',
      key: 'meetings-scheduled',
      driverRequest: 'salesRepActivity',
      dimensions: [],
      metrics: [],
      internalQuery: ['is:scheduled', 'type:meeting'],
      fields: [
        {
          key: 'sales_rep',
          label: 'Sales Rep',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          formatter: (_: any, __: any, activity: OpportunityActivity) =>
            activity.owner_model.sales_rep.name,
        },
        {
          key: 'sub_type',
          label: 'Meeting Sub-Type',
          class: 'text-center align-middle',
          show: true,
          type: 'capitalize_replace',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },
        {
          key: 'agency',
          label: 'Agency',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          formatter: (_: any, __: any, activity: OpportunityActivity) =>
            activity.owner_model.agency.name,
        },
        {
          key: 'advertiser',
          label: 'Advertiser',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          formatter: (_: any, __: any, activity: OpportunityActivity) =>
            activity.owner_model.advertiser.name,
        },
        {
          key: 'name',
          label: 'Subject',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'contacts',
          label: 'Contacts',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          formatter: (_: any, __: any, activity: OpportunityActivity) => {
            if (activity.contacts && activity.contacts.length > 0) {
              if (activity.contacts.length > 3) {
                let remaining = activity.contacts.length - 3
                return `${activity.contacts
                  .slice(0, 3)
                  .map(contact => contact.first_name)
                  .join(', ')} +${remaining} more`
              }
              return activity.contacts.map(contact => contact.first_name).join(', ')
            }

            return 'N/A'
          },
        },
        {
          key: 'scheduled_at',
          label: 'Date',
          class: 'text-center align-middle',
          show: true,
          type: 'date',
          thClass: 'align-middle text-center',
          sortable: true,
        },
      ],

      perPage: 100,
      noTotal: true,
    }),
    new PitchedAmountWidget(),
    new PitchedAmountWidget({
      title: 'Closed Amount',
      key: 'closed-amount',
      driverRequest: 'closedKPI',
    }),
    new PitchedAmountWidget({
      title: 'Activity KPIs',
      key: 'activity-kpis',
      driverRequest: 'activityKPI',
    }),
  ]
}
