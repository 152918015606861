import SelectOption from '@/models/interface/SelectOption'
import SystemDashboardAction from '@/models/SystemDashboardAction'

export default class YearMonthQuarterColumnToggle extends SystemDashboardAction {
  // Target Property to be changed or event name
  public target: string = 'internalProps'

  // Unique key for the action
  public key: string = 'columns'

  public type: string = 'checkbox'

  // Options for the action if applicable
  public options: SelectOption[] = [
    new SelectOption('Show Quarters', 'show_quarters'),
    new SelectOption('Show Months', 'show_months'),
  ]
}
