
import { Component, Prop, Vue } from 'vue-property-decorator'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import moment from 'moment'
import Company from '@/models/Company'

@Component({
  components: {
    DatePicker,
  },
})
export default class FilterHeader extends Vue {
  @Prop({ required: true })
  public value!: any

  public local_date: any = {}

  public get date() {
    return this.value
  }

  public set date(value) {
    this.$emit('input', value)
  }

  public agency_id: string | null = '94311adc-bd36-468e-865b-5d6acff1c960'

  public company: any = {}

  public confirmDate() {
    this.date = {
      start: this.getYear(this.local_date.start),
      end: this.getYear(this.local_date.end),
    }
    this.$root.$emit('bv::hide::popover', 'filters-popover')
    setTimeout(() => {
      this.$emit('change')
    }, 300)
  }

  public getYear(date: any) {
    return moment(date).format('YYYY')
  }

  public loadCompany() {
    if (this.agency_id && this.agency_id.length) {
      Company.find(this.agency_id).then((company: any) => {
        this.company = company
      })
    }
  }

  public created() {
    // if process env is production
    if (process.env.VUE_APP_ENV === 'production') {
      this.agency_id = '94311adc-bd36-468e-865b-5d6acff1c960'
    }

    this.loadCompany()
  }
}
