import year_overview from './widgets/year_overview'
import existingBusinessPipeline from '../SalesActivities/widgets/existing-business-pipeline'
import newBusinessPipeline from '../SalesActivities/widgets/new-business-pipeline'
import meetings from '../SalesActivities/widgets/meetings'
import meetings_summary from '../SalesActivities/widgets/meetings-summary'

import pitched_revenue from '../SalesActivities/widgets/pitched-revenue'
import pitched_revenue_summary from '../SalesActivities/widgets/pitched-revenue-summary'

import sold from '../SalesActivities/widgets/sold-per'
import sold_summary from '../SalesActivities/widgets/sold-per-summary'
import financial_plan_overview from './widgets/financial-plan-overview'
import { FILTERS } from '../../shared/helpers'

// Overwrites
meetings.filters.dynamic_filter = {
  ...meetings.filters.dynamic_filter,
  ...FILTERS.YTD_THROUGH_MONTH,
}
pitched_revenue.filters.dynamic_filter = {
  ...pitched_revenue.filters.dynamic_filter,
  ...FILTERS.YTD_THROUGH_MONTH,
}
sold.filters.dynamic_filter = {
  ...sold.filters.dynamic_filter,
  ...FILTERS.YTD_THROUGH_MONTH,
}

// @reference widget global events listener
class EVENTS {
  private widget_obj: any

  public event_list: any = [
    {
      'leadership:product': (value: any, module: any) => {
        let query = module.local_query.filter((i: string) => !i.startsWith('product:'))

        if (value !== 'all') {
          query.push(`product:${value}`)
        }

        if (this.widget_obj.type === 'table') {
          module.local_filters.product = value
        } else if (this.widget_obj.type === 'funnel') {
          module.local_query = [...query, ...module.local_query]
          module.updateData()
        } else if (this.widget_obj.type === 'bar_marker') {
          module.local_filters.product = value
          module.updateData()
        } else {
          module.local_query = query
          module.updateData()
        }
      },
    },
    {
      'leadership:group_mode': (value: any, module: any) => {
        if (module.local_filters.hasOwnProperty('group_mode')) {
          module.local_filters.group_mode = value
        }
      },
    },
    {
      'leadership:group_role': (value: any, module: any) => {
        if (module.local_filters.hasOwnProperty('group_role')) {
          module.local_filters.group_role = value
        }
      },
    },
    {
      'leadership:region': (value: any, module: any) => {
        let query = module.local_query.filter((i: string) => !i.startsWith('region:'))

        if (value !== 'all') {
          query.push(`region:${value}`)
        }

        if (this.widget_obj.type === 'funnel') {
          // module.query = query
          module.local_query = [...query, ...module.local_query]
          module.updateData()
        } else if (this.widget_obj.type === 'bar_marker') {
          module.local_filters.region = value
          module.updateData()
        } else {
          module.local_query = query
          module.updateData()
        }
      },
    },
  ]

  public listen(widget_obj: any, events: string[]) {
    this.widget_obj = widget_obj
    let selected_events = this.event_list.filter((event: any) =>
      events.includes(Object.keys(event)[0]))
    if (!widget_obj.config.hasOwnProperty('listen')) {
      widget_obj.config.listen = []
    }
    widget_obj.config.listen = [...new Set([...widget_obj.config.listen, ...selected_events])]

    return widget_obj
  }

  static listen(widget_obj: any, events: string[]) {
    let event = new EVENTS()
    return event.listen(widget_obj, events)
  }
}

export default [
  financial_plan_overview, // contains events listener on config, left there for reference
  EVENTS.listen(year_overview, ['leadership:product', 'leadership:region']),
  EVENTS.listen(existingBusinessPipeline, ['leadership:product', 'leadership:region']),
  EVENTS.listen(newBusinessPipeline, ['leadership:product', 'leadership:region']),
  {
    title: 'Region Revenue vs Plan',
    key: 'region-revenue-vs-goal',
    source: 'financial_plan',
    type: 'bar_marker',
    size: {
      lg: 4,
      md: 12,
    },
    dimensions: ['region'],
    capitalize_labels: true,
    config: {},
    metrics: ['goal_sold', 'goal'],
    filters: {
      type: 'finance_goal',
    },
  },
  {
    title: 'Closed Business',
    key: 'closed-business',
    source: 'opportunity-advanced',
    type: 'table',
    size: {
      lg: 12,
      md: 12,
    },
    filters: {
      dynamic_filter: 'is:closed_this_week',
    },
    capitalize_labels: true,
    config: {
      noPagination: true,
      fields: [
        {
          key: 'sales_rep',
          label: 'Sales Rep',
          class: 'text-center align-middle',
          show: true,
          type: 'date',
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'agency',
          label: 'Agency',
          class: 'text-center align-middle',
          show: true,
          type: 'capitalize_replace',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },
        {
          key: 'advertiser',
          label: 'Advertiser',
          class: 'text-center align-middle',
          show: true,
          type: 'capitalize_replace',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },

        {
          key: 'amount',
          label: 'Amount',
          class: 'text-center align-middle',
          show: true,
          type: 'currency',
          thClass: 'align-middle text-center',
          sortable: true,
          total: 'currency_abbreviate',
        },
        {
          key: 'revenue_period',
          label: 'Revenue Period',
          class: 'text-center align-middle',
          show: true,
          type: 'text',
          thClass: 'align-middle text-center',
          sortable: true,
        },
      ],
    },
    dimensions: ['sales_rep', 'agency', 'advertiser'],
    metrics: ['raw_amount', 'revenue_period'],
    query: [],
    dynamic_options: [
      {
        target: 'filters',
        sub_target: 'dynamic_filter',
        options: [
          { text: 'This Week', value: 'is:closed_this_week' },
          { text: 'This Month', value: 'is:closed_this_month' },
        ],
      },
    ],
  },
  {
    title: '',
    key: 'filters',
    source: '',
    type: '',
    size: {
      lg: 12,
      md: 12,
    },
  },
  EVENTS.listen(meetings, [
    'leadership:group_mode',
    'leadership:group_role',
    'leadership:region',
    'leadership:product',
  ]),
  EVENTS.listen(meetings_summary, [
    'leadership:group_mode',
    'leadership:group_role',
    'leadership:region',
    'leadership:product',
  ]),
  EVENTS.listen(pitched_revenue, [
    'leadership:group_mode',
    'leadership:group_role',
    'leadership:region',
    'leadership:product',
  ]),
  EVENTS.listen(pitched_revenue_summary, [
    'leadership:group_mode',
    'leadership:group_role',
    'leadership:region',
    'leadership:product',
  ]),
  EVENTS.listen(sold, [
    'leadership:group_mode',
    'leadership:group_role',
    'leadership:region',
    'leadership:product',
  ]),
  EVENTS.listen(sold_summary, [
    'leadership:group_mode',
    'leadership:group_role',
    'leadership:region',
    'leadership:product',
  ]),
]
