
import {
  Component, Prop, Watch, Vue,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import moment from 'moment'
import { EventBus } from '@/plugins/eventBus'
import CustomIcon from '@/components/CustomIcon/CustomIcon.vue'
import SelectOption from '@/models/interface/SelectOption'
import User from '@/models/User'
import Widgets from './Leadership/widgets'
import DashboardWidget from '../components/DashboardWidget.vue'
import simpleCard from '../scripts/simple-card'
import dashboardSharedOptions from '../dashboard-shared-options'

@Component({
  components: {
    IconAction,
    SearchInput,
    DashboardWidget,
    CustomIcon,
  },
})
export default class LeadershipDashboardView extends ViewModel {
  @Prop({ default: null })
  public agency!: string | null

  @Prop({ default: () => '' })
  public tabKey!: string

  public ready: boolean = false

  public query: string[] = []

  public report_ready: boolean = false

  public local_user: any = null

  public dont_watch: boolean = false

  public query_settings = {
    company_fields: [
      {
        name: 'agency',
        key: 'agency',
        type: 'agency',
      },
      {
        name: 'advertiser',
        key: 'advertiser',
        type: 'advertiser',
      },
    ],
    custom_fields: [
      {
        name: 'product:ssl',
        value: 'product:ssl',
      },
      {
        name: 'product:ccl',
        value: 'product:ccl',
      },
      {
        name: 'deal_type:direct',
        value: 'deal_type:direct',
      },
      {
        name: 'deal_type:3rd Party',
        value: 'deal_type:3rd_party',
      },
    ],
    user_fields: [
      {
        name: 'sr',
        key: 'sales_rep_id',
      },
    ],
  }

  private debug_key: string | null | string[] = []

  public year: number = moment().year()

  public region: string | null = ''

  public product: string | null = 'all'

  public sales_rep: string | null = null

  public group_role: any = 'team'

  public group_mode: string | null = 'week'

  public company_creation_type: string | null = 'all'

  @Watch('product')
  public productFilterChange(val: string) {
    EventBus.$emit('leadership:product', val)
  }

  @Watch('group_mode')
  public groupModeChange(val: string) {
    EventBus.$emit('leadership:group_mode', val)
  }

  @Watch('group_role')
  public groupRoleChange(val: string) {
    EventBus.$emit('leadership:group_role', val)
  }

  @Watch('region')
  public onRegionChange(val: any) {
    EventBus.$emit('leadership:region', val)
  }

  @Watch('company_creation_type')
  public onCompanyCreationChange(val: any) {
    EventBus.$emit('leadership:company_creation_type', val)
  }

  public get product_filter() {
    if (
      (this.query.includes('product:ssl') && this.query.includes('product:ccl'))
      || (!this.query.includes('product:ssl') && !this.query.includes('product:ccl'))
    ) {
      return 'all'
    }

    if (this.query.includes('product:ccl')) {
      return 'ccl'
    }

    return 'ssl'
  }

  public set product_filter(value: string) {
    let query = this.query.filter((i: string) => !i.startsWith('product:'))

    if (value === 'ccl') {
      query = [...query, 'product:ccl']
    } else if (value === 'ssl') {
      query = [...query, 'product:ssl']
    }
    // Vue.set(this, 'query', [...query])
    this.query = [...query]
  }

  public get widgets() {
    if (!this.ready) return []
    if (!this.debug_key || (Array.isArray(this.debug_key) && !this.debug_key.length)) return Widgets.map((w: any) => this.injectBeforeLoad(w))
    return Widgets.filter((w: any) =>
      (Array.isArray(this.debug_key) ? this.debug_key.includes(w.key) : w.key === this.debug_key)).map((w: any) => this.injectBeforeLoad(w))
  }

  public injectBeforeLoad(widget: any) {
    if (widget.key === 'overview') {
      widget.filters = {
        ...widget.filters,
        region: this.region,
        group_role: this.group_role,
        group_mode: this.group_mode,
        product: this.product,
      }
    } else if (['meetings', 'pitched-revenue', 'sold-per'].includes(widget.key)) {
      widget.filters = {
        ...widget.filters,
        group_role: this.group_role,
        group_mode: this.group_mode,
      }
    }

    return widget
  }

  public get snapshot_id() {
    return this.$route.query.snapshot
  }

  public get options() {
    return dashboardSharedOptions
  }

  public mounted() {
    this.checkUser()
  }

  public updateData() {
    this.ready = false
    setTimeout(() => {
      this.init()
    }, 1000)
  }

  private buildParams() {
    let params: any = {
      ...this.query,
      year: this.year,
      region: this.region,
      product: this.product,
      group_role: this.group_role,
      group_mode: this.group_mode,
    }
    // params = JSON.parse(JSON.stringify(params))
    // console.log('params', params)
    // console.log('param region length', params.region.length)

    if (!params.region.length || params.region === 'all') {
      delete params.region
      // console.log('deleted region params', params)
    }

    return Object.keys(params).map(key => `${key}:${params[key]}`)
  }

  public resetFilters() {
    this.query = []
    this.region = 'all'

    this.group_mode = 'month'
    this.product = 'all'
    this.region = ''
    this.group_role = 'team'
  }

  public download() {
    this.$emit('download', { key: this.tabKey, query: this.buildParams() })
  }

  public exportPipeline() {}

  public addFilter(data: any) {
    if (!data || !data.filter || !data.name) return

    if (this.query.includes(data.filter)) return

    if (
      data.filter.startsWith('sm:')
      || data.filter.startsWith('sr:')
      || data.filter.startsWith('agency:')
      || data.filter.startsWith('advertiser:')
    ) {
      this.searchInput.addCustomTag(new SelectOption(data.name, data.filter))
    }

    Vue.set(this, 'query', [...this.query, data.filter])
  }

  public checkUser() {
    const { query } = this.$route

    if (query.target && typeof query.target === 'string') {
      User.find(query.target).then((user: User) => {
        this.local_user = user
        this.init()
      })
    } else {
      this.local_user = this.user
      this.init()
    }
  }

  public init() {
    const { query } = this.$route

    // if (this.local_user.region) {
    //   this.region = this.local_user.region
    // } else if (!this.has_cross_region_access) {
    //   this.region = ''
    // }

    if (query.query) {
      // @ts-ignore
      this.query = typeof query.query === 'string' ? [query.query] : [...query.query]
    }

    for (const q of this.query) {
      const [key, value] = q.split(':')
      if (this.hasOwnProperty(key)) {
        Vue.set(this, key, value)
      }
    }
    this.ready = true
  }
}
