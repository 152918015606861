
import {
  Component, Prop, Watch, Vue, Ref,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import SystemDashboard from '@/models/SystemDashboard'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import { clone } from 'lodash'
import CustomIcon from '@/components/CustomIcon/CustomIcon.vue'
import SelectOption from '@/models/interface/SelectOption'
import DashboardWidgetView from './DashboardWidgetView.vue'
import DashboardAction from './DashboardAction.vue'

@Component({
  components: {
    DashboardWidgetView,
    IconAction,
    SearchInput,
    DashboardAction,
    CustomIcon,
  },
})
export default class DashboardView extends ViewModel {
  @Prop({ required: true })
  public dashboard!: SystemDashboard

  @Ref() searchInput!: SearchInput

  public query: string[] = []

  public filters: any = {}

  public groups: any = {}

  /**
   * Query prop is updated directly, so we need to watch it
   * and update widget accordingly
   */
  @Watch('query')
  public onQueryChange(value: string[], old: string[]) {
    // Ignore update if dashboard is not ready
    if (!this.dashboard.ready) {
      return
    }

    if (JSON.stringify(value) != JSON.stringify(old)) {
      this.updateProp('query', value)
    }
  }

  /**
   * Check if all widgets are ready
   * This method is used for the PDF export
   */
  public get pageReady() {
    return this.dashboard.widgets.every(
      widget =>
        // @ts-ignore
        this.$refs[`widget-${widget.key}`]
        // @ts-ignore
        && this.$refs[`widget-${widget.key}`][0].localWidget.ready
        // @ts-ignore
        && !this.$refs[`widget-${widget.key}`][0].localWidget.loading,
    )
  }

  /**
   * Check if current filters are different from default values
   */
  public get hasActiveFilters() {
    const initial = JSON.stringify({
      query: this.dashboard.query,
      filters: this.dashboard.filters,
      groups: this.dashboard.groups,
    })

    const current = JSON.stringify({
      query: this.query,
      filters: this.filters,
      groups: this.groups,
    })

    return initial !== current
  }

  /**
   * Initialize dashboard with query, groups and filters
   * from defaults and URL
   */
  public mounted() {
    const query = this.$route.query

    // Initialize with default dashboard values
    Vue.set(this, 'query', clone(this.dashboard.query))
    Vue.set(this, 'filters', clone(this.dashboard.filters))
    Vue.set(this, 'groups', clone(this.dashboard.groups))

    // Overwrite filters from URL
    if (query.filters && query.tab === this.dashboard.key) {
      if (Array.isArray(query.filters)) {
        query.filters.forEach((filter: any) => {
          const tmp = filter.split(':')
          if (tmp.length === 2) {
            this.filters[tmp[0]] = tmp[1]
          }
        })
      } else {
        let tmp = query.filters.split(':')
        if (tmp.length === 2) {
          this.filters[tmp[0]] = tmp[1]
        }
      }
    }

    // Overwrite groups from URL
    if (query.groups && query.tab === this.dashboard.key) {
      if (Array.isArray(query.groups)) {
        query.groups.forEach((group: any) => {
          const tmp = group.split(':')
          if (tmp.length === 2) {
            this.groups[tmp[0]] = tmp[1]
          }
        })
      } else {
        let tmp = query.groups.split(':')
        if (tmp.length === 2) {
          this.groups[tmp[0]] = tmp[1]
        }
      }
    }

    // Overwrite query from URL
    if (query.query && query.tab === this.dashboard.key) {
      if (Array.isArray(query.query)) {
        // @ts-ignore
        this.query = query.query
      } else {
        // @ts-ignore
        this.query = [query.query]
      }
    }

    // Initialize dashboard
    this.dashboard.init(this.query, this.filters, this.groups)

    this.updateUrl()
  }

  /**
   * Get Filter value
   */
  public getActionValue(field: any) {
    if (field.target === 'filters') {
      return this.filters[field.key]
    }
    if (field.target === 'groups') {
      return this.groups[field.key]
    }

    return ''
  }

  /**
   * Get Secondary Filter value
   */
  public getSecondaryActionValue(field: any) {
    if (!field.secondaryKey) {
      return null
    }

    if (field.target === 'filters') {
      return this.filters[field.secondaryKey]
    }
    if (field.target === 'groups') {
      return this.groups[field.secondaryKey]
    }
    if (field.target === 'query') {
      return this.query
    }

    return ''
  }

  public onSetFilter(payload: any) {
    const {
      name, value, target, sub_target,
    } = payload

    if (target === 'query') {
      this.searchInput.addCustomTag(new SelectOption(name, value))

      let new_query = [...new Set([...this.query, value])]

      Vue.set(this, 'query', new_query)
      return
    }
    this.updateProp(target, sub_target, value)
  }

  /**
   * Triggered on dashboard action event
   */
  public onAction(payload: any) {
    const { action, value, refresh } = payload

    if (action.type === 'date_range') {
      // added this cuz of the value was an array, so if you looped the keyValues, it would be the same value, and also the url filter params would contain an
      // array, for start and end egx: start=2021-01-01,2021-01-02&end...
      // so when you refreshed the page with the start filter as an array, the wudgets consuming the filter start or end would not work
      this.filters.start = value[0]

      this.filters.end = value[1]

      this.updateUrl()

      this.dashboard.widgets.forEach(widget => {
        if (widget.type !== 'filter') {
          // @ts-ignore
          this.$refs[`widget-${widget.key}`][0].updateProp(
            action.target,
            'start',
            value[0],
            refresh,
          )
          // @ts-ignore
          this.$refs[`widget-${widget.key}`][0].updateProp(action.target, 'end', value[1], refresh)
        }
      })
    } else {
      this.updateProp(action.target, action.keyValues, value, refresh)
    }
  }

  /**
   * Sync query, groups and filters to widgets on change
   */
  public updateProp(target: string, key: any, value: any = null, refresh: boolean = true) {
    if (target === 'filters') {
      if (Array.isArray(key)) {
        key.forEach((k: any) => {
          this.filters[k] = value
        })
      } else {
        this.filters[key] = value
      }
    } else if (target === 'groups') {
      if (Array.isArray(key)) {
        key.forEach((k: any) => {
          this.groups[k] = value
        })
      } else {
        this.groups[key] = value
      }
    }

    this.dashboard.widgets.forEach(widget => {
      // If not widget skip prop update
      if (widget.type === 'filter') {
        return
      }
      // @ts-ignore
      this.$refs[`widget-${widget.key}`][0].updateProp(target, key, value, refresh)
    })

    this.updateUrl()
  }

  /**
   * Trigger widgets refresh
   */
  public updateData() {
    this.dashboard.widgets.forEach(widget => {
      // If not widget skip prop update
      if (widget.type === 'filter') {
        return
      }
      // @ts-ignore
      this.$refs[`widget-${widget.key}`][0].updateData()
    })
  }

  /**
   * Rests filter to default values
   */
  public resetFilters() {
    // Initialize with default dashboard values
    Vue.set(this, 'filters', clone(this.dashboard.filters))
    for (let key in this.filters) {
      this.updateProp('filters', key, this.filters[key], false)
    }

    Vue.set(this, 'groups', clone(this.dashboard.groups))
    for (let key in this.groups) {
      this.updateProp('groups', key, this.groups[key], false)
    }

    const originalQuery = clone(this.dashboard.query)
    Vue.set(this, 'query', clone(this.dashboard.query))

    // Update URL
    this.updateUrl()

    // If query changes, update is triggered automatically, if not, force update
    if (JSON.stringify(originalQuery) === JSON.stringify(this.query)) {
      // Update widgets
      this.updateData()
    }
  }

  /**
   * Download dashboard as PDF
   */
  public download() {
    this.$emit('download', this.urlQuery)
  }

  public get urlQuery() {
    const tab = this.dashboard.key
    return {
      tab,
      query: this.query,
      filters: Object.keys(this.filters).map(key => `${key}:${this.filters[key]}`),
      groups: Object.keys(this.groups).map(key => `${key}:${this.groups[key]}`),
    }
  }

  /**
   * Update URL to match active query, filters & groups
   */
  public updateUrl() {
    if (JSON.stringify(this.$route.query) !== JSON.stringify(this.urlQuery)) {
      this.$router.replace({ query: this.urlQuery })
    }
  }
}
