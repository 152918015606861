import { buildColorPool } from '@/models/Util'
import Opportunity from '@/models/Opportunity'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import { startCase } from 'lodash'
import moment from 'moment'
import numeral from 'numeral'

export default class PipelineStatusFunnelWidget extends SystemDashboardWidget {
  public title: string = `Opportunity Status Distribution ${moment().format('YYYY')}`

  public key: string = 'opportunity_status_distribution'

  public view: string = 'chart'

  public subView: string = 'funnel'

  public loaderSize: string = '350px'

  public dimensions: string[] = ['status']

  public metrics: string[] = ['amount']

  public size: any = {
    sm: 12,
    md: 4,
    lg: 4,
  }

  // API Filters
  public filters: any = {
    region: 'all',
    product: 'all',
  }

  public internalFilters: any = {
    year: moment().format('YYYY'),
    snapshot: null,
  }

  public dataSourceRequest: any = null

  public resultsMapper: any = null

  constructor(args: any = null) {
    super()

    if (args && args.dimensions) {
      this.dimensions = args.dimensions
    }

    if (args && args.metrics) {
      this.metrics = args.metrics
    }

    if (args && args.filters) {
      this.filters = Object.assign(this.filters, args.filters)
    }
    if (args && args.internalFilters) {
      this.internalFilters = Object.assign(this.internalFilters, args.internalFilters)
    }
    if (args && args.internalQuery) {
      this.internalQuery = Object.assign(this.internalQuery, args.internalQuery)
    }

    if (args && args.rightCol) {
      this.rightCol = args.rightCol
    }

    if (args && args.leftCol) {
      this.leftCol = args.leftCol
    }

    if (args && args.size) {
      this.size = args.size
    }

    if (args && args.title) {
      this.title = args.title
    }

    if (args && args.key) {
      this.key = args.key
    }

    if (args && args.dataSourceRequest) {
      this.dataSourceRequest = args.dataSourceRequest
    }

    // resultsMapper
    if (args && args.resultsMapper) {
      this.resultsMapper = args.resultsMapper
    }
  }

  public formatters: any = {
    label: (widget: SystemDashboardWidget, value: any, opt: any) => {
      const raw_value = widget.rawData[opt.dataPointIndex].amount
      const label = widget.chartSettings.labels[opt.dataPointIndex]

      return [`${label}: ${numeral(raw_value).format('$0.00a')}`]
    },
    tooltip: (widget: SystemDashboardWidget, value: any, opt: any) => {
      const raw_value = widget.rawData[opt.dataPointIndex].amount
      const total = widget.rawData.reduce(
        (acc: number, item: any) => acc + item[widget.metrics[0]],
        0,
      )

      return [
        `${numeral(raw_value).format('$0.00a')}`,
        ` ${numeral(raw_value / total).format('0.00%')}`,
      ]
    },
  }

  public get filterToQuery() {
    let query = []

    if (this.filters.product !== 'all') {
      query.push(`product:${this.filters.product}`)
    }

    if (this.filters.region !== 'all') {
      query.push(`region:${this.filters.region}`)
    }

    query.push(`year:${this.internalFilters.year}`)

    return query
  }

  public get payload() {
    const query = [...new Set([...this.query, ...this.internalQuery, ...this.filterToQuery])]

    return {
      dimensions: this.dimensions,
      metrics: this.metrics,
      filters: {
        ...this.internalFilters,
      },
      snapshot: this.internalFilters.snapshot,
      query,
      order: {
        percentage: 'asc',
      },
    }
  }

  public get customData() {
    let performance = numeral(
      this.rawData.reduce((acc: number, item: any) => {
        if (
          item.status === 'won'
          || item.status === 'processing'
          || item.status === 'repeat_business'
          || item.status === 'consideration'
        ) {
          return acc + item.amount
        }
        if (item.status === 'pitched') {
          return acc + item.amount * 0.1
        }

        return acc
      }, 0),
    ).format('$0.00a')

    return `<div class="row text-center mt-3">
      <div class="col">
        <h6>Pipeline</h6>
        <h4>${performance}</h4>
      </div>
    </div>
    `
  }

  public async dataSource() {
    if (this.dataSourceRequest) {
      const res = this.dataSourceRequest(this.payload)

      return res
    }

    return Opportunity.buildAdvancedQuery(this.payload).then((res: any) =>
      res.data.result.map((item: any) => {
        if (this.resultsMapper) {
          item = this.resultsMapper(item)
        }

        return item
      }))
  }

  protected dataMapper(data: any): Promise<any> {
    const data_series: any[] = []
    const labels: string[] = []
    const colors: string[] = []
    let total = 0

    const color_pool = buildColorPool(data.length, true)

    // Init Series
    let count = 0
    const total_count = data.length
    data.forEach((item: any, idx: number) => {
      let label = startCase(item[this.dimensions[0]])
      let val = Number(item[this.metrics[0]])

      if (label.toLocaleLowerCase() === 'pitched') {
        val *= 0.1
      }
      total += val
      labels.push(label)
      colors.push(color_pool(count++))
      data_series.push(total_count - count + 1)
    })

    // Udpate Settings
    this.chartSettings.colors = colors

    this.chartSettings.xaxis = {
      categories: labels,
    }
    this.chartSettings.labels = labels
    this.chartSettings.total = total
    return Promise.resolve([
      {
        name: startCase(this.metrics[0]),
        data: data_series,
      },
    ])
  }
}
