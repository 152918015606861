import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import Api from '@/models/Api'
import { FILTERS } from '../../helpers'
import RegionDashboardFilter from '../../filters/RegionDashboardFilter'
import ProductDashboardFilter from '../../filters/ProductDashboardFilter'
import YearMonthQuarterColumnToggle from '../../filters/YearMonthQuarterColumnToggle'

export default class FYOverviewWidget extends SystemDashboardWidget {
  public title: string = 'Year Overview'

  public key: string = 'year_overview'

  public view: string = 'table'

  public metrics: string[] = ['pipeline']

  public size: any = {
    sm: 12,
    md: 12,
    lg: 12,
  }

  // API Filters
  public filters: any = {
    region: 'all',
    product: 'all',
  }

  public internalFilters: any = {
    ...FILTERS.YEAR,
  }

  public internalProps: any = {
    show_months: false,
    show_quarters: true,
  }

  public leftCol: any = [new RegionDashboardFilter(), new ProductDashboardFilter()]

  public rightCol: any = [new YearMonthQuarterColumnToggle()]

  public noPagination: boolean = true

  public allowAll: boolean = true

  public noTotal: boolean = true

  public bordered: boolean = true

  public loaderSize: string = '350px'

  public flattenPayload: boolean = true

  public fields: any = [
    {
      key: 'label',
      label: '',
      class: 'text-center align-middle text-capitalize font-weight-bold',
      show: true,
      thClass: 'align-middle text-center',
      sortable: false,
      total: 'currency_abbreviate',
      thStyle: 'width: 15em',
    },
    {
      key: 'jan',
      label: 'Jan',
      class: 'text-center align-middle',
      show: false,
      thClass: 'align-middle text-center',
      sortable: false,
      total: 'currency_abbreviate',
      type: 'currency',
    },
    {
      key: 'feb',
      label: 'Feb',
      class: 'text-center align-middle',
      show: false,
      thClass: 'align-middle text-center',
      sortable: false,
      total: 'currency_abbreviate',
      type: 'currency',
    },
    {
      key: 'mar',
      label: 'Mar',
      class: 'text-center align-middle',
      show: false,
      thClass: 'align-middle text-center',
      sortable: false,
      total: 'currency_abbreviate',
      type: 'currency',
    },
    {
      key: 'q1',
      label: 'Q1',
      class: 'text-center align-middle',
      show: true,
      thClass: 'align-middle text-center',
      sortable: false,
      total: 'currency_abbreviate',
      type: 'currency',
      isRowHeader: true,
      variant: 'secondary',
    },
    {
      key: 'apr',
      label: 'Apr',
      class: 'text-center align-middle',
      show: false,
      thClass: 'align-middle text-center',
      sortable: false,
      total: 'currency_abbreviate',
      type: 'currency',
    },
    {
      key: 'may',
      label: 'May',
      class: 'text-center align-middle',
      show: false,
      thClass: 'align-middle text-center',
      sortable: false,
      total: 'currency_abbreviate',
      type: 'currency',
    },
    {
      key: 'jun',
      label: 'Jun',
      class: 'text-center align-middle',
      show: false,
      thClass: 'align-middle text-center',
      sortable: false,
      total: 'currency_abbreviate',
      type: 'currency',
    },
    {
      key: 'q2',
      label: 'Q2',
      class: 'text-center align-middle',
      show: true,
      thClass: 'align-middle text-center',
      sortable: false,
      total: 'currency_abbreviate',
      type: 'currency',
      isRowHeader: true,
      variant: 'secondary',
    },
    {
      key: 'jul',
      label: 'Jul',
      class: 'text-center align-middle',
      show: false,
      thClass: 'align-middle text-center',
      sortable: false,
      total: 'currency_abbreviate',
      type: 'currency',
    },
    {
      key: 'aug',
      label: 'Aug',
      class: 'text-center align-middle',
      show: false,
      thClass: 'align-middle text-center',
      sortable: false,
      total: 'currency_abbreviate',
      type: 'currency',
    },
    {
      key: 'sep',
      label: 'Sep',
      class: 'text-center align-middle',
      show: false,
      thClass: 'align-middle text-center',
      sortable: false,
      total: 'currency_abbreviate',
      type: 'currency',
    },
    {
      key: 'q3',
      label: 'Q3',
      class: 'text-center align-middle',
      show: true,
      thClass: 'align-middle text-center',
      sortable: false,
      total: 'currency_abbreviate',
      type: 'currency',
      isRowHeader: true,
      variant: 'secondary',
    },
    {
      key: 'oct',
      label: 'Oct',
      class: 'text-center align-middle',
      show: false,
      thClass: 'align-middle text-center',
      sortable: false,
      total: 'currency_abbreviate',
      type: 'currency',
    },
    {
      key: 'nov',
      label: 'Nov',
      class: 'text-center align-middle',
      show: false,
      thClass: 'align-middle text-center',
      sortable: false,
      total: 'currency_abbreviate',
      type: 'currency',
    },
    {
      key: 'dec',
      label: 'Dec',
      class: 'text-center align-middle',
      show: false,
      thClass: 'align-middle text-center',
      sortable: false,
      total: 'currency_abbreviate',
      type: 'currency',
    },
    {
      key: 'q4',
      label: 'Q4',
      class: 'text-center align-middle',
      show: true,
      thClass: 'align-middle text-center',
      sortable: false,
      total: 'currency_abbreviate',
      type: 'currency',
      isRowHeader: true,
      variant: 'secondary',
    },
    {
      key: 'total',
      label: 'total',
      class: 'text-center align-middle',
      show: true,
      thClass: 'align-middle text-center',
      sortable: false,
      total: 'currency_abbreviate',
      type: 'currency',
    },
  ]

  public showFields(widget: SystemDashboardWidget, fields: any) {
    return fields
      .map((field: any) => {
        if (['label', 'total'].includes(field.key)) {
          field.show = true
        } else if (['q1', 'q2', 'q3', 'q4'].includes(field.key)) {
          field.show = widget.internalProps.show_quarters
        } else {
          field.show = widget.internalProps.show_months
        }

        return field
      })
      .filter((field: any) => field.show !== false)
  }

  public dataSource() {
    let api = new Api()

    return api
      .get('leadership-dashboard/year-overview', this.payload)
      .then((response: any) => response.data.result)
      .catch(err => ({ data: [] }))
  }
}
