
import { Component, Prop } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import moment from 'moment'
import Widgets from './hamilton-widgets'
import DashboardWidget from '../components/DashboardWidget.vue'

@Component({
  components: {
    IconAction,
    SearchInput,
    DashboardWidget,
  },
})
export default class HamiltonDashboardView extends ViewModel {
  @Prop({ default: null })
  public agency!: string | null

  @Prop({ default: () => '' })
  public tabKey!: string

  public ready: boolean = false

  public query: string[] = []

  public report_ready: boolean = false

  public query_settings = {
    company_fields: [
      {
        name: 'agency',
        key: 'agency',
        type: 'agency',
      },
      {
        name: 'advertiser',
        key: 'advertiser',
        type: 'advertiser',
      },
    ],
    custom_fields: [
      {
        name: 'product:ssl',
        value: 'product:ssl',
      },
      {
        name: 'product:ccl',
        value: 'product:ccl',
      },
      {
        name: 'deal_type:direct',
        value: 'deal_type:direct',
      },
      {
        name: 'deal_type:3rd Party',
        value: 'deal_type:3rd_party',
      },
    ],
  }

  private debug_key: string | null | string[] = [
  ]

  public year: number = moment().year()

  public region: string | null = ''

  public sales_rep: string | null = null

  public get widgets() {
    if (!this.debug_key || (Array.isArray(this.debug_key) && !this.debug_key.length)) return Widgets
    return Widgets.filter((w: any) =>
      (Array.isArray(this.debug_key) ? this.debug_key.includes(w.key) : w.key === this.debug_key))
  }

  public get snapshot_id() {
    return this.$route.query.snapshot
  }

  public mounted() {
    this.ready = true
  }

  public updateData() {
    this.ready = false
    setTimeout(() => {
      this.ready = true
    }, 1000)
  }

  public resetFilters() {
    this.query = []
  }

  public download() {
    this.$emit('download', { key: this.tabKey })
  }

  public addFilter(data: any) {}

  public exportPipeline() {}
}
