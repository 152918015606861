// htmlGenerator.ts
import numeral from 'numeral'

export default function generateSummaryCustomHtml(data: any, settings: any): string {
  const last_30 = data.last_30 ? numeral(data.last_30).format(settings.format) : 0
  const last_7 = data.last_7 ? numeral(data.last_7).format(settings.format) : 0
  const this_quarter = data.this_quarter ? numeral(data.this_quarter).format(settings.format) : 0
  const last_quarter = data.last_quarter ? numeral(data.last_quarter).format(settings.format) : 0

  if (settings.cllbk) {
    // @ts-ignore
    return settings.cllbk({
      last_30, last_7, this_quarter, last_quarter,
    })
  }

  return ` 
    <div class="row text-center h-50 align-items-center mt-4">
      <div class="col">
        <h4>Last 7 days</h4>
        <h4>${last_7}</h4>
      </div>
      <div class="col">
        <h4>Last 30 Days <small>(AVG/week)</small></h4>
        <h4>${last_30}</h4>
      </div>
    </div>
    <div class="row text-center align-items-center mt-4">
      <div class="col">
        <h4>Last Quarter <small>(AVG/week)</small></h4>
        <h4>${last_quarter}</h4>
      </div>
      <div class="col">
        <h4>This Quarter <small>(AVG/week)</h4>
        <h4>${this_quarter}</h4>
      </div>
    </div>
  `
}
