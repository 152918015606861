import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import moment from 'moment'
import Opportunity from '@/models/Opportunity'
import numeral from 'numeral'
import { buildColorPool } from '@/models/Util'
import FinancialPlan from '@/models/FinancialPlan'
import { startCase } from 'lodash'
import { normalizeLabel } from '@/pages/ManagementDashboard/shared/helpers'
import BaseFilter from '../../filters/BaseFilter'
import PipelineSold from '../../filters/PipelineSold'

export default class OpportunityStageBySalesRepWidget extends SystemDashboardWidget {
  public title: string = 'Opportunity Stage by Sales Rep'

  public key = 'opp-by-sales-rep'

  public view: string = 'chart'

  public subView: string = 'bar_horizontal'

  public dimensions: string[] = ['sales_rep', 'status', 'sales_rep_id']

  public metrics: string[] = ['count']

  constructor(args: any = null) {
    super()

    if (args && args.dimensions) {
      this.dimensions = args.dimensions
    }

    if (args && args.metrics) {
      this.metrics = args.metrics
    }

    if (args && args.filters) {
      this.filters = Object.assign(this.filters, args.filters)
    }
    if (args && args.internalFilters) {
      this.internalFilters = Object.assign(this.internalFilters, args.internalFilters)
    }

    if (args && args.rightCol) {
      this.rightCol = args.rightCol
    }

    if (args && args.leftCol) {
      this.leftCol = args.leftCol
    }

    if (args && args.size) {
      this.size = args.size
    }

    if (args && args.title) {
      this.title = args.title
    }

    if (args && args.key) {
      this.key = args.key
    }

    if (args && args.internalQuery) {
      this.internalQuery = args.internalQuery
    }

    if (args && args.internalQueryPush) {
      this.internalQuery.push(args.internalQueryPush)
    }
  }

  public size: any = {
    lg: 8,
    md: 12,
  }

  public rightCol = [
    new BaseFilter({
      type: 'radio',
      options: [
        { text: 'Open', value: 'is:open' },
        { text: 'Sold', value: 'is:sold' },
      ],
    }),
  ]

  public internalFilters: any = {
    year: moment().format('YYYY'),
    dynamic_filter: 'is:open',
  }

  public formatters: any = {
    xaxis: (_: SystemDashboardWidget, value: any) => value,
    label: (_: SystemDashboardWidget, value: any) => {
      let format = '$0.00a'

      if (this.metrics[0] === 'count') {
        format = '0,0'
      }
      return [`${numeral(value).format(format)}`]
    },
    tooltip: (widget: SystemDashboardWidget, value: any, opt: any) => {
      // const total = widget.rawData.reduce((acc: number, item: any) => {
      //   return acc + item[widget.metrics[0]]
      // }, 0)
      let total = opt.series.reduce(
        (acc: number, item: any) => (acc += item[opt.dataPointIndex]),
        0,
      )
      let format = '$0.00a'

      if (this.metrics[0] === 'count') {
        format = '0,0'
      }
      return [`${numeral(value).format(format)}`, ` ${numeral(value / total).format('0.00%')}`]
    },
  }

  public filters: any = {
    product: 'all',
    region: 'all',
  }

  public get filterToQuery() {
    let query = []

    if (this.filters.product !== 'all') {
      query.push(`product:${this.filters.product}`)
    }

    if (this.filters.region !== 'all') {
      query.push(`region:${this.filters.region}`)
    }

    query.push(`year:${this.internalFilters.year}`)

    return query
  }

  public internalQuery: any = ['scope:agency']

  public get payload() {
    const query = [...new Set([...this.query, ...this.internalQuery, ...this.filterToQuery])]

    return {
      query,
      filters: {
        ...this.internalFilters,
      },
      metrics: this.metrics,
      dimensions: this.dimensions,
    }
  }

  public async dataSource() {
    return Opportunity.buildAdvancedQuery(this.payload).then((res: any) => res.data.result)
  }

  protected dataMapper(data: any): Promise<any> {
    const series: number[] = []
    const data_series: any[] = []
    const labels: any[] = []
    const colors: string[] = []
    const series_ids: string[] = []
    let total = 0

    const groups: string[] = []

    let x_series: string[] = []

    // Init Series
    data.forEach((item: any, idx: number) => {
      if (item[this.dimensions[1]]) {
        const label = item[this.dimensions[1]]
        const group_index = groups.indexOf(label)
        if (group_index === -1) {
          groups.push(label)
          data_series.push({ name: startCase(label), data: [] })
        }
      }

      if (item[this.dimensions[0]]) {
        const x_label = item[this.dimensions[0]]
        if (x_series.indexOf(x_label) === -1) {
          x_series.push(x_label)
        }
      }
    })

    const color_pool = buildColorPool(groups.length, true)

    data_series.forEach((item: any, idx: number) => {
      x_series.forEach(() => {
        item.data.push(0)
      })
      colors.push(color_pool(idx))
    })

    // if (config.date_dimension_format) {
    //   x_series = x_series.sort(
    //     (a, b) =>
    //       moment(a, config.date_dimension_format).unix() -
    //       moment(b, config.date_dimension_format).unix(),
    //   )
    // }

    data.forEach((item: any, idx: number) => {
      const val = Number(item[this.metrics[0]])
      total += val
      series.push(val)
      const label = item[this.dimensions[0]]
      const group_label = item[this.dimensions[1]]
      const group_index = groups.indexOf(group_label)
      const x_index = x_series.indexOf(label)

      data_series[group_index].data[x_index] += val

      if (['product', 'region', 'deal_type'].includes(this.dimensions[1])) {
        series_ids[group_index] = item[this.dimensions[1]].toLowerCase().replaceAll(' ', '_')
      } else if (this.dimensions[2]) {
        series_ids[group_index] = item[this.dimensions[this.dimensions.length - 1]]
      }
    })

    this.chartSettings.colors = colors

    this.chartSettings.labels = labels

    this.chartSettings.xaxis = {
      categories: x_series,
    }

    return Promise.resolve(data_series)
  }
}
