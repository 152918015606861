import FinancialPlan from '@/models/FinancialPlan'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'

import moment from 'moment'

export default class QuarterOverview extends SystemDashboardWidget {
  public title: string = 'Quarter Overview'

  public key: string = 'quarter-goal'

  public view: string = 'table'

  public noPagination: boolean = true

  public getFieldTotal(key: string) {
    return this.data.reduce((acc: number, row: any) => acc + Number(row[key]), 0)
  }

  constructor(args: any = null) {
    super()

    if (args && args.dimensions) {
      this.dimensions = args.dimensions
    }

    if (args && args.metrics) {
      this.metrics = args.metrics
    }

    if (args && args.filters) {
      this.filters = Object.assign(this.filters, args.filters)
    }
    if (args && args.internalFilters) {
      this.internalFilters = Object.assign(this.internalFilters, args.internalFilters)
    }

    if (args && args.rightCol) {
      this.rightCol = args.rightCol
    }

    if (args && args.leftCol) {
      this.leftCol = args.leftCol
    }

    if (args && args.size) {
      this.size = args.size
    }

    if (args && args.title) {
      this.title = args.title
    }

    if (args && args.key) {
      this.key = args.key
    }

    if (args && args.cellSlots) {
      this.cellSlots = args.cellSlots
    }

    if (args && args.footerCellSlots) {
      this.footerCellSlots = args.footerCellSlots
    }

    if (args && args.last_year_props) {
      this.last_year_props = args.last_year_props
    }

    if (args && args.noPagination) {
      this.noPagination = args.noPagination
    }

    if (args && args.updateFields) {
      this.fields = args.updateFields(this.fields)
    }
  }

  public last_year_props: any = {
    // used for getting last year values
    goal: 'last_year_goal',
    goal_forecast: 'last_year_goal_pipeline',
    goal_pipeline: 'last_year_goal_pipeline',
    goal_sold: 'last_year_goal_sold',
    goal_achieved: 'last_year_goal_achieved',
  }

  /**
   * Renders cells slots in the table body
   */
  public cellSlots: any[] = Object.keys(this.last_year_props).map((key: any) => ({
    key,
    component: 'TableCellIndicator', // also import this component in parent location
    slotProps: (data: any) => ({
      value: data.value,
      lastYearValue: parseFloat(data.item[this.last_year_props[key]]),
      widgetKey: this.key,
      index: data.index,
      prefix: this.last_year_props[key],
    }),
  }))

  /**
   * Renders cells slots in the table footer
   */
  public footerCellSlots: any[] = Object.keys(this.last_year_props).map((key: any) => ({
    key,
    component: 'TableCellIndicator',
    slotProps: (data: any, getFieldFoot: any) => ({
      value: getFieldFoot(key),
      lastYearValue: getFieldFoot(this.last_year_props[key]),
      widgetKey: this.key,
      index: data.index,
      prefix: this.last_year_props[key],
    }),
  }))

  public fields = [
    {
      key: 'quarter',
      label: 'Quarter',
      class: 'text-center align-middle text-capitalize',
      show: true,
      thClass: 'align-middle text-center',
      sortable: true,
    },
    {
      key: 'goal',
      label: 'Plan',
      class: 'text-center align-middle',
      show: true,
      thClass: 'align-middle text-center',
      type: 'currency_abbreviate',
      sortable: true,
      total: 'currency_abbreviate',
    },
    {
      key: 'reforecast',
      label: 'Forecast',
      class: 'text-center align-middle',
      show: true,
      thClass: 'align-middle text-center',
      type: 'currency_abbreviate',
      sortable: true,
      total: 'currency_abbreviate',
    },
    {
      key: 'goal_forecast',
      label: 'Pipeline',
      class: 'text-center align-middle',
      show: true,
      thClass: 'align-middle text-center',
      type: 'currency_abbreviate',
      sortable: true,
      total: 'currency_abbreviate',
    },
    {
      key: 'goal_sold',
      label: 'Sold',
      class: 'text-center align-middle',
      show: true,
      thClass: 'align-middle text-center',
      type: 'currency_abbreviate',
      sortable: true,
      total: 'currency_abbreviate',
    },
    {
      key: 'goal_achieved',
      label: 'Invoiced',
      class: 'text-center align-middle',
      show: true,
      thClass: 'align-middle text-center',
      type: 'currency_abbreviate',
      sortable: true,
      total: 'currency_abbreviate',
    },
  ]

  public dimensions: string[] = ['quarter']

  public metrics: string[] = [
    'goal',
    'goal_forecast',
    'goal_pipeline',
    'goal_sold',
    'goal_achieved',
    'last_year_goal',
    'last_year_goal_pipeline',
    'last_year_goal_sold',
    'last_year_goal_achieved',
  ]

  public size: any = {
    lg: 6,
    md: 6,
  }

  public loaderSize: string = '350px'

  // API Filters
  public filters: any = {
    region: 'all',
    product: 'all',
  }

  public internalQuery: any = ['scope:finance_goal']

  public get filterToQuery() {
    let query = []

    if (this.filters.product !== 'all') {
      query.push(`product:${this.filters.product}`)
    }

    if (this.filters.region !== 'all') {
      query.push(`region:${this.filters.region}`)
    }

    query.push(`year:${this.internalFilters.year}`)

    return query
  }

  public get payload() {
    const query = [...new Set([...this.query, ...this.internalQuery, ...this.filterToQuery])]

    return {
      metrics: this.metrics,
      dimensions: this.dimensions,
      query,
      filters: {
        ...this.internalFilters,
      },
    }
  }

  public internalFilters: any = {
    type: 'finance_goal',
    reforecast: 'latest:secondary',
    year: moment().format('YYYY'),
  }

  public flattenPayload: boolean = true

  public dataSource() {
    return FinancialPlan.getPlanDashboard(this.payload).then((res: any) => res.data.result)
  }

  public dataMapper(data: any): Promise<any> {
    data = data.sort((a: any, b: any) => {
      if (a[this.dimensions[0]] < b[this.dimensions[0]]) {
        return -1
      }
      if (a[this.dimensions[0]] > b[this.dimensions[0]]) {
        return 1
      }
      return 0
    })
    // console.log('dataMapper', data)

    return Promise.resolve(data)
  }
}
