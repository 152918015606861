import Opportunity from '@/models/Opportunity'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import { buildColorPool } from '@/models/Util'
import moment from 'moment'
import numeral from 'numeral'
import PipelineSold from '../../filters/PipelineSold'

export default class NewThisWeekWidget extends SystemDashboardWidget {
  public title: string = 'New This Week'

  public key = 'new-this-week'

  public view: string = 'table'

  public size = {
    lg: 8,
    md: 12,
  }

  public fields: any = [
    {
      key: 'status',
      label: 'Stage',
      class: 'text-center align-middle',
      show: true,
      type: 'capitalize_replace',
      thClass: 'align-middle text-center',
      sortable: true,
      filter: true,
    },
    {
      key: 'advertiser_id',
      label: 'Advertiser',
      class: 'text-center align-middle',
      show: true,
      type: 'object',
      object: {
        key: 'advertiser',
        property: 'name',
      },
      thClass: 'align-middle text-center',
      sortable: true,
    },
    {
      key: 'agency_id',
      label: 'Agency',
      class: 'text-center align-middle',
      show: true,
      type: 'object',
      object: {
        key: 'agency',
        property: 'name',
      },
      thClass: 'align-middle text-center',
      sortable: true,
    },
    {
      key: 'name',
      label: 'Name',
      class: 'text-center align-middle',
      show: true,
      type: 'text',
      thClass: 'align-middle text-center',
      sortable: true,
      filter: true,
      templateLink: true,
      template: (item: any) => `<a href="/opportunities/${item.id}">${item.name}</a>`,
    },
    {
      key: 'sales_rep_id',
      label: 'Sales Rep',
      class: 'text-center align-middle',
      show: true,
      type: 'object',
      object: {
        key: 'sales_rep',
        property: 'name',
      },
      thClass: 'align-middle text-center',
      sortable: true,
    },
    {
      key: 'amount',
      label: 'Amount',
      class: 'text-center align-middle',
      show: true,
      type: 'currency_abbreviate',
      thClass: 'align-middle text-center',
      sortable: true,
      total: 'currency_abbreviate',
    },
  ]

  public dimensions = ['quarter', 'product']

  public metrics = []

  public internalQuery = ['is:new_this_week']

  public rightCol = [
    new PipelineSold({
      options: [
        { text: 'Pipeline', value: 'is:pipeline' },
        { text: 'Sold', value: 'is:sold' },
      ],
    }),
  ]

  constructor(args: any = null) {
    super()

    if (args && args.dimensions) {
      this.dimensions = args.dimensions
    }

    if (args && args.metrics) {
      this.metrics = args.metrics
    }

    if (args && args.filters) {
      this.filters = Object.assign(this.filters, args.filters)
    }
    if (args && args.internalFilters) {
      this.internalFilters = Object.assign(this.internalFilters, args.internalFilters)
    }

    if (args && args.rightCol) {
      this.rightCol = args.rightCol
    }

    if (args && args.leftCol) {
      this.leftCol = args.leftCol
    }

    if (args && args.size) {
      this.size = args.size
    }

    if (args && args.title) {
      this.title = args.title
    }

    if (args && args.key) {
      this.key = args.key
    }

    if (args && args.noPagination) {
      this.noPagination = args.noPagination
    }

    if (args && args.updateFields) {
      this.fields = args.updateFields(this.fields)
    }

    if (args && args.internalQuery) {
      this.internalQuery = args.internalQuery
    }
    if (args && args.internalQueryPush) {
      this.internalQuery.push(args.internalQueryPush)
    }

    if (args && args.cellSlots) {
      this.cellSlots = args.cellSlots
    }
  }

  public cellSlots: any[] = []

  public filters = {
    product: 'all',
    region: 'all',
  }

  public internalFilters = {
    dynamic_filter: 'is:pipeline',
    year: moment().format('YYYY'),
  }

  public get filterToQuery() {
    let query = []

    if (this.filters.product !== 'all') {
      query.push(`product:${this.filters.product}`)
    }

    if (this.filters.region !== 'all') {
      query.push(`region:${this.filters.region}`)
    }

    // query.push(`year:${this.internalFilters.year}`) // it doesnt have year

    query.push(this.internalFilters.dynamic_filter)

    return query
  }

  /**
   * the original payload from old widget, every filter and params goes into param query, metrics and dimensions and pagination are out of the query
   */
  public get payload() {
    const query = [...new Set([...this.query, ...this.internalQuery, ...this.filterToQuery])]

    return {
      query,
      // metrics: this.metrics, // it doenst have metrics nor dimensions
      // dimensions: this.dimensions,
      ...this.pagination,
    }
  }

  public async dataSource() {
    // @ts-ignore
    return Opportunity.paginate(this.payload).then((res: any) => {
      this.records = res.records
      return res.data
    })
  }
}
