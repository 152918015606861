import SystemtModule from '@/store/SystemModule'
import moment from 'moment'
import numeral from 'numeral'
import { getModule } from 'vuex-module-decorators'

const gauge_custom_data = (
  data: any,
  target: string,
  period: string,
  secondary_label: string = 'Plan',
) => {
  let label = 'Sold'
  let note = ''

  if (target == 'forecast') {
    label = 'Pipeline'
    note = `<div style="font-size: x-small; bottom: 1rem; position: absolute" class="text-muted mt-5 px-2">
    * Pipeline: Repeated Business + 10% Pitched + Consideration + Processing + Sold
  </div>`
  } else if (target != 'sold') {
    label = 'Pipeline'
  }

  let last_year_goal_key = `last_year_goal_${target}`

  if (target == 'forecast') {
    last_year_goal_key = 'goal'
  }

  if (data[0].plan_name) {
    secondary_label = data[0].plan_name
  }

  // Sold Logic
  const sold = numeral(data[0][`goal_${target}`]).format('$0.00a')
  const last_year_sold = numeral(data[0][last_year_goal_key]).format('$0.00a')
  let last_year_sold_percentage: any = data[0][last_year_goal_key] == 0
    ? 1
    : (data[0][`goal_${target}`] - data[0][last_year_goal_key]) / data[0][last_year_goal_key]
  const sold_icon = last_year_sold_percentage > 0 ? '▴' : '▾'
  const sold_color = last_year_sold_percentage > 0 ? 'text-success' : 'text-danger'
  last_year_sold_percentage = numeral(last_year_sold_percentage).format('0%')

  // Goal Logic
  const goal = numeral(data[0].goal).format('$0.00a')
  const last_year_goal = numeral(data[0].last_year_goal).format('$0.00a')
  let last_year_goal_percentage: any = data[0].last_year_goal == 0
    ? 1
    : (data[0].goal - data[0].last_year_goal) / data[0].last_year_goal
  const goal_icon = last_year_goal_percentage > 0 ? '▴' : '▾'
  const goal_color = last_year_goal_percentage > 0 ? 'text-success' : 'text-danger'
  last_year_goal_percentage = numeral(last_year_goal_percentage).format('0%')

  return `<div class="row text-center mb-4">
    <div class="col">
      <h6>${label}</h6>
      <h4>${sold}</h4>
      <div class="${sold_color}" style="font-size:small;">${sold_icon} ${last_year_sold_percentage}</div>
      <div class="text-muted">*Last ${period}: ${last_year_sold}</div>
    </div>
    <div class="col">
      <h6>${secondary_label}</h6>
      <h4>${goal}</h4>
      <div class="${goal_color}" style="font-size:small;">${goal_icon} ${last_year_goal_percentage}</div>
      <div class="text-muted">*Last ${period}: ${last_year_goal}</div>
    </div>
  </div>${note}
  `
}

const system = getModule(SystemtModule)
const user = system.user

const allowedUsers = [
  '8e3e41ea-927d-4a19-be60-39c856c5b212', // Vin
  '8e3e41ea-e22a-4f6f-8e34-a557b2fcae57', // Ham
  '912cdd2c-00be-4262-8e09-c2eec82c07a2', // Jesse
  '92fb454e-7f5e-4fb5-a3d5-6b76756104ab', // Al
]

export default [
  {
    title: 'Sold to Plan %YEAR%',
    key: 'sold-to-goal-year',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 4,
      md: 6,
    },
    dimensions: ['year'],
    capitalize_labels: true,
    metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      type: 'finance_goal',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'sold', 'year'),
    },
  },
  {
    title: 'Q1 Sold to Plan',
    key: 'sold-to-goal-q1',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 2,
      md: 6,
    },
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      quarter: 1,
      type: 'finance_goal',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'sold', 'Q1'),
    },
  },
  {
    title: 'Q2 Sold to Plan',
    key: 'sold-to-goal-q2',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 2,
      md: 6,
    },
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      quarter: 2,
      type: 'finance_goal',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'sold', 'Q2'),
    },
  },
  {
    title: 'Q3 Sold to Plan',
    key: 'sold-to-goal-q3',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 2,
      md: 6,
    },
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      quarter: 3,
      type: 'finance_goal',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'sold', 'Q3'),
    },
  },
  {
    title: 'Q4 Sold to Plan',
    key: 'sold-to-goal-q4',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 2,
      md: 6,
    },
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      quarter: 4,
      type: 'finance_goal',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'sold', 'Q4'),
    },
  },
  // Forecast
  {
    title: 'Sold to Forecast %YEAR%',
    key: 'sold-to-reforecast-year',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 4,
      md: 6,
    },
    dimensions: ['year'],
    capitalize_labels: true,
    metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      type: 'finance_goal',
      reforecast: 'latest',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'sold', 'year', 'Forecast'),
    },
  },
  {
    title: 'Q1 Sold to Forecast',
    key: 'sold-to-reforecast-q1',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 2,
      md: 6,
    },
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      quarter: 1,
      type: 'finance_goal',
      reforecast: 'latest',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'sold', 'Q1', 'Forecast'),
    },
  },
  {
    title: 'Q2 Sold to Forecast',
    key: 'sold-to-reforecast-q2',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 2,
      md: 6,
    },
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      quarter: 2,
      type: 'finance_goal',
      reforecast: 'latest',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'sold', 'Q2', 'Forecast'),
    },
  },
  {
    title: 'Q3 Sold to Forecast',
    key: 'sold-to-reforecast-q3',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 2,
      md: 6,
    },
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      quarter: 3,
      type: 'finance_goal',
      reforecast: 'latest',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'sold', 'Q3', 'Forecast'),
    },
  },
  {
    title: 'Q4 Sold to Forecast',
    key: 'sold-to-reforecast-q4',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 2,
      md: 6,
    },
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      quarter: 4,
      type: 'finance_goal',
      reforecast: 'latest',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'sold', 'Q4', 'Forecast'),
    },
  },
  // Forecast
  // Forecast
  {
    title: 'Pipeline to Forecast %YEAR%',
    key: 'forecast-to-reforecast-year',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 4,
      md: 6,
    },
    dimensions: ['year'],
    capitalize_labels: true,
    metrics: ['goal_forecast', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      type: 'finance_goal',
      reforecast: 'latest',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'forecast', 'year', 'Forecast'),
    },
  },
  {
    title: 'Q1 Pipeline to Forecast',
    key: 'forecast-to-reforecast-q1',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 2,
      md: 6,
    },
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_forecast', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      quarter: 1,
      type: 'finance_goal',
      reforecast: 'latest',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'forecast', 'Q1', 'Forecast'),
    },
  },
  {
    title: 'Q2 Pipeline to Forecast',
    key: 'forecast-to-reforecast-q2',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 2,
      md: 6,
    },
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_forecast', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      quarter: 2,
      type: 'finance_goal',
      reforecast: 'latest',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'forecast', 'Q2', 'Forecast'),
    },
  },
  {
    title: 'Q3 Pipeline to Forecast',
    key: 'forecast-to-reforecast-q3',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 2,
      md: 6,
    },
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_forecast', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      quarter: 3,
      type: 'finance_goal',
      reforecast: 'latest',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'forecast', 'Q3', 'Forecast'),
    },
  },
  {
    title: 'Q4 Pipeline to Forecast',
    key: 'forecast-to-reforecast-q4',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 2,
      md: 6,
    },
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_forecast', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      quarter: 4,
      type: 'finance_goal',
      reforecast: 'latest',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'forecast', 'Q4', 'Forecast'),
    },
  },
  // Forecast
  {
    show: () => false,
    title: 'Pipeline to Plan %YEAR%',
    key: 'revenue-to-goal-year',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 4,
      md: 6,
    },
    dimensions: ['year'],
    capitalize_labels: true,
    metrics: ['goal_pipeline', 'goal', 'last_year_goal', 'last_year_goal_pipeline'],
    filters: {
      type: 'finance_goal',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'pipeline', 'year'),
    },
  },
  {
    show: () => false,
    title: 'Q1 Pipeline to Plan',
    key: 'plan-to-goal-q1',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 2,
      md: 6,
    },
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_pipeline', 'goal', 'last_year_goal', 'last_year_goal_pipeline'],
    filters: {
      quarter: 1,
      type: 'finance_goal',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'pipeline', 'Q1'),
    },
  },
  {
    show: () => false,
    title: 'Q2 Pipeline to Plan',
    key: 'plan-to-goal-q2',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 2,
      md: 6,
    },
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_pipeline', 'goal', 'last_year_goal', 'last_year_goal_pipeline'],
    filters: {
      quarter: 2,
      type: 'finance_goal',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'pipeline', 'Q2'),
    },
  },
  {
    show: () => false,
    title: 'Q3 Pipeline to Plan',
    key: 'plan-to-goal-q3',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 2,
      md: 6,
    },
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_pipeline', 'goal', 'last_year_goal', 'last_year_goal_pipeline'],
    filters: {
      quarter: 3,
      type: 'finance_goal',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'pipeline', 'Q3'),
    },
  },
  {
    show: () => false,
    title: 'Q4 Pipeline to Plan',
    key: 'plan-to-goal-q4',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 2,
      md: 6,
    },
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_pipeline', 'goal', 'last_year_goal', 'last_year_goal_pipeline'],
    filters: {
      quarter: 4,
      type: 'finance_goal',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'pipeline', 'Q4'),
    },
  },
  {
    title: 'Quarter Overview',
    key: 'quarter-goal',
    source: 'financial_plan',
    type: 'table',
    size: {
      lg: 6,
      md: 6,
    },
    dimensions: ['quarter'],
    capitalize_labels: true,
    config: {
      noPagination: true,
      fields: [
        {
          key: 'quarter',
          label: 'Quarter',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'goal',
          label: 'Plan',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: true,
          total: 'currency_abbreviate',
        },
        {
          key: 'reforecast',
          label: 'Forecast',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: true,
          total: 'currency_abbreviate',
        },
        {
          key: 'goal_forecast',
          label: 'Pipeline',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: true,
          total: 'currency_abbreviate',
        },
        {
          key: 'goal_sold',
          label: 'Sold',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: true,
          total: 'currency_abbreviate',
        },
        {
          key: 'goal_achieved',
          label: 'Invoiced',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: true,
          total: 'currency_abbreviate',
        },
      ],
    },
    metrics: [
      'goal',
      'goal_forecast',
      'goal_pipeline',
      'goal_sold',
      'goal_achieved',
      'last_year_goal',
      'last_year_goal_pipeline',
      'last_year_goal_sold',
      'last_year_goal_achieved',
    ],
    filters: {
      type: 'finance_goal',
      reforecast: 'latest:secondary',
    },
  },
  {
    title: 'Region Overview',
    key: 'region-goal',
    source: 'financial_plan',
    type: 'table',
    size: {
      lg: 6,
      md: 6,
    },
    dimensions: ['region'],
    capitalize_labels: true,
    config: {
      noPagination: true,
      fields: [
        {
          key: 'region',
          label: 'Region',
          class: 'text-center align-middle',
          show: true,
          type: 'capitalize',
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'goal',
          label: 'Plan',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: true,
          total: 'currency_abbreviate',
        },
        {
          key: 'reforecast',
          label: 'Forecast',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: true,
          total: 'currency_abbreviate',
        },
        {
          key: 'goal_forecast',
          label: 'Pipeline',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: true,
          total: 'currency_abbreviate',
        },
        {
          key: 'goal_sold',
          label: 'Sold',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: true,
          total: 'currency_abbreviate',
        },
        {
          key: 'goal_achieved',
          label: 'Invoiced',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: true,
          total: 'currency_abbreviate',
        },
      ],
    },
    metrics: [
      'goal',
      'goal_forecast',
      'goal_pipeline',
      'goal_sold',
      'goal_achieved',
      'last_year_goal',
      'last_year_goal_pipeline',
      'last_year_goal_sold',
      'last_year_goal_achieved',
    ],
    filters: {
      type: 'finance_goal',
      reforecast: 'latest:secondary',
    },
  },
  {
    title: 'Closed This Week',
    key: 'week-pipeline',
    source: 'week-pipeline',
    type: 'table',
    size: {
      lg: 8,
      md: 12,
    },
    capitalize_labels: true,
    config: {
      noPagination: true,
      actions: [
        { title: 'Export Pipeline', event: 'exportPipeline' },
        { title: 'Export Recently Closed Opportunities', event: 'exportOpportunities' },
      ],
      fields: [
        {
          key: 'advertiser',
          label: 'Advertiser',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'agency',
          label: 'Agency',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'east',
          label: 'East',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: true,
          total: 'currency_abbreviate',
        },
        {
          key: 'midwest',
          label: 'Midwest',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: true,
          total: 'currency_abbreviate',
        },
        {
          key: 'west',
          label: 'West',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: true,
          total: 'currency_abbreviate',
        },
        {
          key: 'national',
          label: 'National',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: true,
          total: 'currency_abbreviate',
        },
        {
          key: 'total',
          label: 'Total',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: true,
          total: 'currency_abbreviate',
        },
      ],
    },
    metrics: ['goal', 'goal_pipeline', 'goal_sold', 'goal_achieved'],
    filters: {
      type: 'finance_goal',
    },
  },
  {
    title: 'Weekly Overview',
    type: 'custom',
    source: 'opportunity-week-card',
    size: {
      lg: 4,
      md: 12,
    },
    config: {
      custom_data: (data: any) => {
        // Closed
        const closed = numeral(data.closed).format('0,0')
        const last_week_closed = numeral(data.last_week_closed).format('0,0')
        const closed_percentage = numeral(data.last_week_closed_percentage).format('0%')
        const closed_color = data.last_week_closed_percentage > 0 ? 'text-success' : 'text-danger'
        const closed_icon = data.last_week_closed_percentage > 0 ? '▴' : '▾'

        // Closed Amount
        const closed_amount = numeral(data.closed_amount).format('$0,0.00a')
        const last_week_closed_amount = numeral(data.last_week_closed_amount).format('$0,0.00a')
        const closed_amount_percentage = numeral(data.last_week_closed_amount_percentage).format(
          '0%',
        )
        const closed_amount_color = data.last_week_closed_amount_percentage > 0 ? 'text-success' : 'text-danger'
        const closed_amount_icon = data.last_week_closed_amount_percentage > 0 ? '▴' : '▾'

        // New
        const new_count = numeral(data.new).format('0,0')
        const last_week_new = numeral(data.last_week_new).format('0,0')
        const new_percentage = numeral(data.last_week_new_percentage).format('0%')
        const new_color = data.last_week_new_percentage > 0 ? 'text-success' : 'text-danger'
        const new_icon = data.last_week_new_percentage > 0 ? '▴' : '▾'

        // New Ammount
        const new_amount = numeral(data.new_amount).format('$0,0.00a')
        const last_week_new_amount = numeral(data.last_week_new_amount).format('$0,0.00a')
        const new_amount_percentage = numeral(data.last_week_new_amount_percentage).format('0.00%')
        const new_amount_color = data.last_week_new_amount_percentage > 0 ? 'text-success' : 'text-danger'
        const new_amount_icon = data.last_week_new_amount_percentage > 0 ? '▴' : '▾'

        // Lost
        const lost = numeral(data.lost).format('0,0')
        const last_week_lost = numeral(data.last_week_lost).format('0,0')
        const lost_percentage = numeral(data.last_week_lost_percentage).format('0%')
        const lost_color = data.last_week_lost_percentage > 0 ? 'text-success' : 'text-danger'
        const lost_icon = data.last_week_lost_percentage > 0 ? '▴' : '▾'

        // Lost Amount
        const lost_amount = numeral(data.lost_amount).format('$0,0.00a')
        const last_week_lost_amount = numeral(data.last_week_lost_amount).format('$0,0.00a')
        const lost_amount_percentage = numeral(data.last_week_lost_amount_percentage).format(
          '0.00%',
        )
        const lost_amount_color = data.last_week_lost_amount_percentage > 0 ? 'text-success' : 'text-danger'
        const lost_amount_icon = data.last_week_lost_amount_percentage > 0 ? '▴' : '▾'

        return `
        <div class="row text-center mt-5 pt-2">
          <div class="col">
            <h6>Closed/Won</h6>
            <h4>${closed_amount}</h4>
            <p class="${closed_amount_color}">${closed_amount_icon} ${closed_amount_percentage} (Last week: ${last_week_closed_amount})</p>
          </div>
          <div class="col">
            <h6>Added</h6>
            <h4>${new_amount}</h4>
            <p class="${new_amount_color}">${new_amount_icon} ${new_amount_percentage} (Last week: ${last_week_new_amount})</p>
          </div>
          <div class="col">
            <h6>Lost</h6>
            <h4>-${lost_amount}</h4>
            <p class="${lost_amount_color}">${lost_amount_icon} ${lost_amount_percentage} (Last week: -${last_week_lost_amount})</p>
          </div>
        </div>
        <div class="row text-center mt-3">
          <div class="col">
            <h6>Closed Opps</h6>
            <h4>${closed}</h4>
            <p class="${closed_color}">${closed_icon} ${closed_percentage} (Last week: ${last_week_closed})</p>
          </div>
          <div class="col">
            <h6>New Opps</h6>
            <h4>${new_count}</h4>
            <p class="${new_color}">${new_icon} ${new_percentage} (Last week: ${last_week_new})</p>
          </div>
          <div class="col">
            <h6>Lost Opps</h6>
            <h4>-${lost}</h4>
            <p class="${lost_color}">${lost_icon} ${lost_percentage} (Last week: -${last_week_lost})</p>
          </div>`
      },
    },
  },
  {
    title: 'Opportunity Status Distribution %YEAR%',
    key: 'opportunity-status-distribution',
    source: 'opportunity-advanced',
    type: 'funnel',
    size: {
      lg: 4,
      md: 12,
    },
    capitalize_labels: true,
    label: 'Revenue',
    dimensions: ['status'],
    metrics: ['amount'],
    order: {
      percentage: 'asc',
    },
    filters: {},
    status: ['targeting', 'pitched', 'consideration', 'io_sent', 'won'],
  },
  {
    title: 'Invoiced to Plan %YEAR%',
    key: 'revenue-to-goal',
    source: 'financial_plan',
    type: 'radialBar',
    size: {
      lg: 4,
      md: 12,
    },
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_achieved', 'goal'],
    filters: {
      type: 'finance_goal',
    },
  },
  {
    title: 'Region Revenue vs Plan',
    key: 'region-revenue-vs-goal',
    source: 'financial_plan',
    type: 'bar_marker',
    size: {
      lg: 4,
      md: 12,
    },
    dimensions: ['region'],
    capitalize_labels: true,
    config: {},
    metrics: ['goal_sold', 'goal'],
    filters: {
      type: 'finance_goal',
    },
  },
  {
    title: 'Product Revenue Cumulative %YEAR%',
    key: 'monthly-product-revenue-cumulative',
    source: 'opportunity-advanced',
    type: 'line',
    size: {
      lg: 4,
      md: 12,
    },
    capitalize_labels: false,
    config: {
      date_dimension_format: 'MMMM YYYY',
      click_key: 'product',
      click_filter_name: 'Product',
      cumulative: true,
    },
    dimensions: ['month', 'product'],
    metrics: ['amount'],
    filters: {
      dynamic_filter: 'is:sold',
    },
    dynamic_options: [
      {
        target: 'filters',
        sub_target: 'dynamic_filter',
        options: [
          { text: 'Pipeline', value: 'is:forecast' },
          { text: 'Sold', value: 'is:sold' },
        ],
      },
    ],
  },
  {
    title: 'Region Revenue Cumulative %YEAR%',
    key: 'monthly-region-revenue-cumulative',
    source: 'opportunity-advanced',
    type: 'line',
    size: {
      lg: 4,
      md: 12,
    },
    capitalize_labels: false,
    config: {
      date_dimension_format: 'MMMM YYYY',
      click_key: 'region',
      click_filter_name: 'Region',
      cumulative: true,
    },
    dimensions: ['month', 'region'],
    metrics: ['amount'],
    filters: {
      dynamic_filter: 'is:sold',
    },
    dynamic_options: [
      {
        target: 'filters',
        sub_target: 'dynamic_filter',
        options: [
          { text: 'Pipeline', value: 'is:forecast' },
          { text: 'Sold', value: 'is:sold' },
        ],
      },
    ],
  },
  {
    title: 'MLB Delivered vs Plan',
    key: 'mlb-gauge',
    source: 'company_goal_status',
    type: 'gauge_number',
    size: {
      lg: 4,
      md: 12,
    },
    dimensions: [],
    capitalize_labels: true,
    metrics: ['delivered', 'goal'],
    filters: {
      company_id: 'f37c9dd6-4776-4122-8a37-37d67f4ff47a',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => {
        const delivered = numeral(data[0].delivered).format('0.00a')
        const goal = numeral(data[0].goal).format('0.00a')
        const start_at = data[0].start_at ? moment(data[0].start_at).format('MMM DD, YYYY') : ''
        const end_at = data[0].end_at ? moment(data[0].end_at).format('MMM DD, YYYY') : ''

        const date = start_at && end_at ? `${start_at} - ${end_at}` : ''

        return `<div class="row text-center">
          <div class="col">
            <h6>Delivered</h6>
            <h4>${delivered}</h4>
          </div>
          <div class="col">
            <h6>Plan</h6>
            <h4>${goal}</h4>
          </div>
        </div>
        <div class="text-center text-muted">
          ${date}
        </div>
        `
      },
    },
  },
  {
    title: 'Agency Distribution %YEAR%',
    key: 'agency-revenue-distribution',
    source: 'opportunity-advanced',
    type: 'treemap',
    size: {
      lg: 12,
      md: 12,
    },
    dimensions: ['agency', 'agency_id'],
    metrics: ['amount'],
    filters: {
      dynamic_filter: 'is:sold',
    },
    config: {
      click_key: 'agency',
      click_filter_name: 'Agency',
    },
    dynamic_options: [
      {
        target: 'filters',
        sub_target: 'dynamic_filter',
        options: [
          { text: 'Pipeline', value: 'is:forecast' },
          { text: 'Sold', value: 'is:sold' },
        ],
      },
    ],
  },
  {
    title: 'Advertiser Distribution %YEAR%',
    key: 'advertiser-revenue-distribution',
    source: 'opportunity-advanced',
    type: 'treemap',
    size: {
      lg: 12,
      md: 12,
    },
    dimensions: ['advertiser', 'advertiser_id'],
    metrics: ['amount'],
    filters: {
      dynamic_filter: 'is:sold',
    },
    config: {
      click_key: 'advertiser',
      click_filter_name: 'Advertiser',
    },
    dynamic_options: [
      {
        target: 'filters',
        sub_target: 'dynamic_filter',
        options: [
          { text: 'Pipeline', value: 'is:forecast' },
          { text: 'Sold', value: 'is:sold' },
        ],
      },
    ],
  },
  {
    title: 'Monthly Product Distribution %YEAR%',
    key: 'monthly-product-revenue',
    source: 'opportunity-advanced',
    type: 'bar_stacked',
    size: {
      lg: 12,
      md: 12,
    },
    capitalize_labels: false,
    config: {
      date_dimension_format: 'MMMM YYYY',
      click_key: 'product',
      click_filter_name: 'Product',
    },
    dimensions: ['month', 'product'],
    metrics: ['amount'],
    filters: {
      dynamic_filter: 'is:sold',
    },
    dynamic_options: [
      {
        target: 'filters',
        sub_target: 'dynamic_filter',
        options: [
          { text: 'Pipeline', value: 'is:forecast' },
          { text: 'Sold', value: 'is:sold' },
        ],
      },
    ],
  },
  {
    title: 'Monthly Region Distribution %YEAR%',
    key: 'monthly-region-revenue',
    source: 'opportunity-advanced',
    type: 'bar_stacked',
    size: {
      lg: 12,
      md: 12,
    },
    capitalize_labels: true,
    config: {
      date_dimension_format: 'MMMM YYYY',
      click_key: 'region',
      click_filter_name: 'Region',
    },
    dimensions: ['month', 'region'],
    metrics: ['amount'],
    filters: {
      dynamic_filter: 'is:sold',
    },
    dynamic_options: [
      {
        target: 'filters',
        sub_target: 'dynamic_filter',
        options: [
          { text: 'Pipeline', value: 'is:forecast' },
          { text: 'Sold', value: 'is:sold' },
        ],
      },
    ],
  },
  {
    title: 'Direct vs 3rd Party Distribution %YEAR%',
    key: 'monthly-source-revenue',
    source: 'opportunity-advanced',
    type: 'bar_stacked',
    size: {
      lg: 12,
      md: 12,
    },
    capitalize_labels: false,
    config: {
      date_dimension_format: 'MMMM YYYY',
      click_key: 'deal_type',
      click_filter_name: 'Deal Type',
    },
    dimensions: ['month', 'deal_type'],
    metrics: ['amount'],
    filters: {
      dynamic_filter: 'is:sold',
    },
    dynamic_options: [
      {
        target: 'filters',
        sub_target: 'dynamic_filter',
        options: [
          { text: 'Pipeline', value: 'is:forecast' },
          { text: 'Sold', value: 'is:sold' },
        ],
      },
    ],
  },
] as any[]
