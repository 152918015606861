import { randomUUID } from '@/models/interface/Common'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import RegionSalesRepGroup from '../../filters/RegionSalesRepGroup'
import PeriodGroup from '../../filters/PeriodGroup'
import DateRangeFilter from '../../filters/DateRangeFilter'
import { FILTERS } from '../../helpers'

export default class LeadershipMidFilterWidget extends SystemDashboardWidget {
  public key: string = randomUUID()

  public type: string = 'filter'

  public filters: any = {
    ...FILTERS.YTD,
  }

  public leftCol: any = [new RegionSalesRepGroup(), new PeriodGroup()]

  public rightCol: any = [new DateRangeFilter()]

  public size: any = {
    sm: 12,
    md: 12,
    lg: 12,
  }
}
