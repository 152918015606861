import SelectOption from './interface/SelectOption'

export default class SystemDashboardAction {
  // Target Property to be changed or event name
  public target: string = ''

  // Unique key for the action
  public key: string = ''

  // Unique key for the action
  public secondaryKey: string = ''

  // Type of action: dropdown, radio, date, checkbox
  public type: string = 'dropdown'

  // Label for the action
  public label: string = ''

  // Options for the action if applicable
  public options: SelectOption[] = []

  /**
   * Get the key values for the action
   */
  public get keyValues() {
    if (this.secondaryKey) {
      return [this.key, this.secondaryKey]
    } if (this.type === 'checkbox') {
      return this.options.map((option: SelectOption) => option.value)
    }
    return this.key
  }
}
