import LeadershipDashboardModule from '@/models/LeadershipDashboardModule'
import simpleCard from '@/pages/ManagementDashboard/scripts/simple-card'
import { FILTERS } from '@/pages/ManagementDashboard/shared/helpers'
import BigNumber from 'bignumber.js'
import dashboardSharedOptions from '../../../dashboard-shared-options'

export default {
  title: 'Year Overview',
  key: 'overview',
  source: 'request',
  request: {
    module: new LeadershipDashboardModule(),
    exec: 'yearOverview',
  },
  pre_load: {},
  type: 'table',
  size: {
    lg: 12,
    md: 12,
  },
  capitalize_labels: true,
  config: {
    columnGroup: true,
    bordered: true,
    allowAll: true,
    noPagination: true,
    noTotal: true,
    fields: [
      {
        key: 'label',
        label: '',
        class: 'text-center align-middle text-capitalize font-weight-bold',
        show: true,
        thClass: 'align-middle text-center',
        sortable: false,
        total: 'currency_abbreviate',
        thStyle: 'width: 15em',
      },
      {
        key: 'jan',
        label: 'Jan',
        class: 'text-center align-middle',
        show: false,
        thClass: 'align-middle text-center',
        sortable: true,
        total: 'currency_abbreviate',
        type: 'currency',
      },
      {
        key: 'feb',
        label: 'Feb',
        class: 'text-center align-middle',
        show: false,
        thClass: 'align-middle text-center',
        sortable: false,
        total: 'currency_abbreviate',
        type: 'currency',
      },
      {
        key: 'mar',
        label: 'Mar',
        class: 'text-center align-middle',
        show: true,
        thClass: 'align-middle text-center',
        sortable: true,
        total: 'currency_abbreviate',
        type: 'currency',
      },
      {
        key: 'q1',
        label: 'Q1',
        class: 'text-center align-middle',
        show: true,
        thClass: 'align-middle text-center',
        sortable: true,
        total: 'currency_abbreviate',
        type: 'currency',
        isRowHeader: true,
        variant: 'secondary',
      },
      {
        key: 'apr',
        label: 'Apr',
        class: 'text-center align-middle',
        show: false,
        thClass: 'align-middle text-center',
        sortable: true,
        total: 'currency_abbreviate',
        type: 'currency',
      },
      {
        key: 'may',
        label: 'May',
        class: 'text-center align-middle',
        show: false,
        thClass: 'align-middle text-center',
        sortable: true,
        total: 'currency_abbreviate',
        type: 'currency',
      },
      {
        key: 'jun',
        label: 'Jun',
        class: 'text-center align-middle',
        show: false,
        thClass: 'align-middle text-center',
        sortable: true,
        total: 'currency_abbreviate',
        type: 'currency',
      },
      {
        key: 'q2',
        label: 'Q2',
        class: 'text-center align-middle',
        show: true,
        thClass: 'align-middle text-center',
        sortable: true,
        total: 'currency_abbreviate',
        type: 'currency',
        isRowHeader: true,
        variant: 'secondary',
      },
      {
        key: 'jul',
        label: 'Jul',
        class: 'text-center align-middle',
        show: false,
        thClass: 'align-middle text-center',
        sortable: true,
        total: 'currency_abbreviate',
        type: 'currency',
      },
      {
        key: 'aug',
        label: 'Aug',
        class: 'text-center align-middle',
        show: false,
        thClass: 'align-middle text-center',
        sortable: true,
        total: 'currency_abbreviate',
        type: 'currency',
      },
      {
        key: 'sep',
        label: 'Sep',
        class: 'text-center align-middle',
        show: false,
        thClass: 'align-middle text-center',
        sortable: true,
        total: 'currency_abbreviate',
        type: 'currency',
      },
      {
        key: 'q3',
        label: 'Q3',
        class: 'text-center align-middle',
        show: true,
        thClass: 'align-middle text-center',
        sortable: true,
        total: 'currency_abbreviate',
        type: 'currency',
        isRowHeader: true,
        variant: 'secondary',
      },
      {
        key: 'oct',
        label: 'Oct',
        class: 'text-center align-middle',
        show: false,
        thClass: 'align-middle text-center',
        sortable: true,
        total: 'currency_abbreviate',
        type: 'currency',
      },
      {
        key: 'nov',
        label: 'Nov',
        class: 'text-center align-middle',
        show: false,
        thClass: 'align-middle text-center',
        sortable: true,
        total: 'currency_abbreviate',
        type: 'currency',
      },
      {
        key: 'dec',
        label: 'Dec',
        class: 'text-center align-middle',
        show: false,
        thClass: 'align-middle text-center',
        sortable: true,
        total: 'currency_abbreviate',
        type: 'currency',
      },
      {
        key: 'q4',
        label: 'Q4',
        class: 'text-center align-middle',
        show: true,
        thClass: 'align-middle text-center',
        sortable: true,
        total: 'currency_abbreviate',
        type: 'currency',
        isRowHeader: true,
        variant: 'secondary',
      },
      {
        key: 'total',
        label: 'total',
        class: 'text-center align-middle',
        show: true,
        thClass: 'align-middle text-center',
        sortable: true,
        total: 'currency_abbreviate',
        type: 'currency',
      },
    ],
  },
  metrics: ['pipeline'],
  dimensions: [],
  query: [],
  filters: {
    dynamic_filter: {
      ...FILTERS.YEAR,
    },
    region: '',
    product: 'all',
    group_mode: 'quarter',
  },
  order: {},
  dynamic_options: [
    {
      target: 'filters',
      sub_target: 'region',
      options: dashboardSharedOptions.region_filters,
    },
    {
      target: 'filters',
      sub_target: 'product',
      options: dashboardSharedOptions.product_filter_options,
    },
  ],
}
