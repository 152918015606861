import FinancialPlan from '@/models/FinancialPlan'
import Opportunity from '@/models/Opportunity'
import OpportunityActivity from '@/models/OpportunityActivity'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import User from '@/models/User'
import { buildColorPool } from '@/models/Util'
import SalesActivityDriver from '@/pages/ManagementDashboard/views/drivers/SalesActivityDriver'
import router from '@/Routes'
import SystemtModule from '@/store/SystemModule'
import moment from 'moment'
import { getModule } from 'vuex-module-decorators'

export default class WeeklySummaryWidget extends SystemDashboardWidget {
  public title: string = 'Weekly Summary'

  public key: string = 'weekly-summary'

  public view: string = 'table'

  public size: any = {
    lg: 12,
    md: 12,
  }

  public noPagination: boolean = true

  public fields: any = [
    {
      key: 'sales_rep',
      label: 'Sales Rep',
      class: 'text-center align-middle',
      show: true,
      thClass: 'align-middle text-center',
      sortable: true,
    },
    {
      key: 'sold',
      label: 'Total Sold',
      class: 'text-center align-middle',
      show: true,
      type: 'currency_abbreviate',
      thClass: 'align-middle text-center',
      sortable: true,
      filter: true,
      total: 'currency_abbreviate',
    },
    {
      key: 'pitched',
      label: 'Total Pitched',
      class: 'text-center align-middle',
      show: true,
      type: 'currency_abbreviate',
      thClass: 'align-middle text-center',
      sortable: true,
      filter: true,
      total: 'currency_abbreviate',
    },
    {
      key: 'meetings_this_week',
      label: 'Total Meetings This Week',
      class: 'text-center align-middle',
      show: true,
      type: 'separator',
      thClass: 'align-middle text-center',
      sortable: true,
      total: 'sum',
    },
    {
      key: 'meetings_scheduled',
      label: 'Scheduled',
      class: 'text-center align-middle',
      show: true,
      type: 'separator',
      thClass: 'align-middle text-center',
      sortable: true,
      total: 'sum',
    },
  ]

  public driverRequest: any = 'salesRepWeeklyActivity'

  constructor(args: any = null) {
    super()

    if (args && args.dimensions) {
      this.dimensions = args.dimensions
    }

    if (args && args.metrics) {
      this.metrics = args.metrics
    }

    if (args && args.filters) {
      this.filters = Object.assign(this.filters, args.filters)
    }
    if (args && args.internalFilters) {
      this.internalFilters = Object.assign(this.internalFilters, args.internalFilters)
    }

    if (args && args.rightCol) {
      this.rightCol = args.rightCol
    }

    if (args && args.leftCol) {
      this.leftCol = args.leftCol
    }

    if (args && args.size) {
      this.size = args.size
    }

    if (args && args.title) {
      this.title = args.title
    }

    if (args && args.key) {
      this.key = args.key
    }

    if (args && args.driverRequest) {
      this.driverRequest = args.driverRequest
    }
    if (args && args.internalQuery) {
      this.internalQuery = args.internalQuery
    }
    if (args && args.perPage) {
      this.perPage = args.perPage
    }
    if (args && args.resultsMapper) {
      this.resultsMapper = args.resultsMapper
    }

    if (args && args.fields) {
      this.fields = args.fields
    }
    if (args && args.noTotal) {
      this.noTotal = args.noTotal
    }
  }

  public resultsMapper: any = null

  public dimensions = ['sales_rep']

  public metrics = ['amount']

  public get notes() {
    return '<small>* All YOY analisys represent final 2023 revenue amoun</small>'
  }

  public filters: any = {
    region: 'all',
    product: 'all',
  }

  public internalFilters: any = {}

  public internalQuery: any = []

  public local_user: User = new User()

  public auth_user: any = getModule(SystemtModule).user

  public get has_cross_region_access() {
    return this.local_user!.canOptions('management_dashboard', 'cross_region')
  }

  public get filterToQuery() {
    let query = []

    if (this.filters.product !== 'all') {
      query.push(`product:${this.filters.product}`)
    }

    if (this.filters.region !== 'all') {
      query.push(`region:${this.filters.region}`)
    }

    return query
  }

  public get payload() {
    const query = [...new Set([...this.query, ...this.internalQuery, ...this.filterToQuery])]

    return {
      query,
      ...this.pagination,
    }
  }

  public async beforeDataRequest() {
    let _router = router.currentRoute

    const { query } = _router

    if (query.target && typeof query.target === 'string') {
      this.local_user = await User.find(query.target)
    } else {
      // @ts-ignore
      this.local_user = getModule(SystemtModule).user
    }

    if (this.auth_user.region) {
      this.filters.region = this.auth_user.region
    } else if (!this.has_cross_region_access) {
      this.filters.region = 'all'
    }

    if (this.local_user.manager_id) {
      this.internalQuery.push(`sr:${this.local_user.id}`)
    }

    return new Promise(resolve => {
      setTimeout(() => {
        resolve(true)
      }, 500)
    })
  }

  public async dataSource() {
    await this.beforeDataRequest()
    let requests: any = new SalesActivityDriver()
    return requests[this.driverRequest]({
      context: this.payload,
      ignore_logic: true,
      module: null,
    }).then((res: any) =>
      res.data.map((item: any) => {
        if (this.resultsMapper) {
          item = this.resultsMapper(item)
        }

        return item
      }))
  }
}
