import existingBusinessPipeline from './widgets/existing-business-pipeline'
import meetings from './widgets/meetings'
import meetingsSummary from './widgets/meetings-summary'
import newBusinessPipeline from './widgets/new-business-pipeline'
import numberOfPitched from './widgets/number-of-pitched'
import numberOfPitchedSummary from './widgets/number-of-pitched-summary'
import pipelineMovment from './widgets/pipeline-movement'
import pipelineMovementThisWeek from './widgets/pipeline-movement-this-week'
import pitchedRevenue from './widgets/pitched-revenue'
import pitchedRevenueSummary from './widgets/pitched-revenue-summary'
import soldPer from './widgets/sold-per'
import soldPerSummary from './widgets/sold-per-summary'
import targetOps from './widgets/target-ops'
import targetOpsSummary from './widgets/target-ops-summary'
import touchpoints from './widgets/touchpoints'
import touchpointsSummary from './widgets/touchpoints-summary'

export default [
  existingBusinessPipeline,
  newBusinessPipeline,
  pipelineMovementThisWeek,
  targetOps,
  targetOpsSummary,
  meetings,
  meetingsSummary,
  pitchedRevenue,
  pitchedRevenueSummary,
  numberOfPitched,
  numberOfPitchedSummary,
  soldPer,
  soldPerSummary,
  touchpoints,
  touchpointsSummary,

  pipelineMovment,
]
