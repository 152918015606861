import SelectOption from '@/models/interface/SelectOption'
import SystemDashboardAction from '@/models/SystemDashboardAction'

export default class PipelineSold extends SystemDashboardAction {
  // Target Property to be changed or event name
  public target: string = 'internalFilters'

  // Unique key for the action
  public key: string = 'dynamic_filter'

  // Label for the action
  public label: string = 'View By'

  // Options for the action if applicable
  public options: SelectOption[] = [
    new SelectOption('Pipeline', 'is:forecast'),
    new SelectOption('Sold', 'is:sold'),
  ]

  constructor(args: any = null) {
    super()
    if (args && args.options) {
      this.options = args.options.map((option: any) => new SelectOption(option.text, option.value))
    }
  }
}
