import baseChartSettings from '@/pages/ManagementDashboardV2/settings/baseChartSettings'
import { clone, over, startCase } from 'lodash'

export default class SystemDashboardWidget {
  /**
   * Widget Title
   */
  public title: string = ''

  /**
   * Widget Description, reserved for future use
   */
  public description: string = ''

  /**
   *  HTML String for additional widget information
   */
  public help: string = ''

  /**
   * Widget Key
   */
  public key: string = ''

  /**
   *  Widget type: widget, header, filter, separator
   */
  public type: string = 'widget'

  /**
   * Widget view: card, chart, table, custom
   */
  public view: string = ''

  /**
   *  Chart type or custom component name
   */
  public subView: string = ''

  /**
   * Table fields
   */
  public fields: any = []

  public get computedFields() {
    return this.showFields(this, this.fields)
  }

  public set computedFields(fields: any) {
    this.fields = fields
  }

  /**
   * Hide Table pagination and show all records
   */
  public noPagination: boolean = false

  /**
   * Hide Table total row
   */
  public noTotal: boolean = false

  /**
   * Table pagination size
   */
  public perPage: number = 10

  /**
   * Allow page size of all records
   */
  public allowAll: boolean = false

  /**
   * Indicates if table should be bordered
   */
  public bordered: boolean = false

  /**
   *  Total Record in table
   */
  public records: number = 0

  /**
   * API query filters
   */
  public query: string[] = []

  /**
   * API Filters
   */
  public filters: any = {}

  /**
   * Data group methods
   */
  public groups: any = {}

  /**
   * API dimensions
   */
  public dimensions: string[] = []

  /**
   * API metrics
   */
  public metrics: string[] = []

  /**
   * Right column actions
   */
  public rightCol: any = []

  /**
   * Left column actions
   */
  public leftCol: any = []

  /**
   * Internal query that can not be changed via filters
   */
  public internalQuery: string[] = []

  /**
   * Internal filters that can be changed via actions, this triggers a refresh on change
   */
  public internalFilters: any = {}

  /**
   * Internal groups that can be changed via actions, this triggers a refresh on change
   */
  public internalGroups: any = {}

  /**
   * Internal properties that can be changed via actions, this does not trigger a refresh on change
   */
  public internalProps: any = {}

  /**
   * Flatten the payload for the API, useful for get requests
   */
  public flattenPayload: boolean = false

  /**
   * Highlights incompatible filters
   */
  public incompatibleOptions: any = {}

  /**
   * Pagination settings
   * They were missing //TODO:@vini remove comment after checking the changes
   *
   * The table component in DashboardWidgetViewshould update these properties
   */
  public page: number = 1

  public orderBy: String = 'created_at'

  public sortDesc: boolean = true

  public mode: string = 'exclusive'

  /**
   * Widget size
   */
  public size: any = {
    lg: 12,
    md: 12,
    sm: 12,
  }

  public loaderSize = '13px'

  /**
   * Chart formatters
   */
  public formatters: any = {}

  public widgetClass: any = null

  /**
   * Chart events
   */
  public events: any = {}

  public viewComponent: any = null

  // Ready indicator
  protected _ready: boolean = false

  // Ready indicator getter
  public get ready() {
    return this._ready
  }

  // Loading indicator
  protected _loading: boolean = false

  // Loading indicator getter
  public get loading() {
    return this._loading
  }

  public reload() {
    this._ready = false

    this.updateData()
  }

  /**
   * Raw data from the API
   */
  public rawData: any = []

  /**
   * Widget data post mapping
   */
  public data: any = []

  /**
   * Widget foot notes
   *
   * Supports HTML
   *
   * @returns string
   */
  public get notes(): string {
    return ''
  }

  public show(): boolean {
    return true
  }

  /**
   * Hook to make dashboard changes prior to initialization
   */
  protected setup(query: string[] | null = null, filters: any = null) {}

  /**
   * Initialize the widget, avoid overwriding it.
   */
  public init(query: string[] | null = null, filters: any = null, groups: any = null) {
    if (query !== null) {
      this.query = [...new Set([...this.query, ...query])]
    }

    if (filters !== null) {
      this.filters = { ...this.filters, ...filters }
    }

    if (groups !== null) {
      this.groups = { ...this.groups, ...groups }
    }

    this.setup()
  }

  /**
   * Build the payload for the API request
   */
  protected get payload() {
    const query = [...new Set([...this.query, ...this.internalQuery])]

    const filters = {
      ...this.filters,
      ...this.internalFilters,
    }

    if (this.flattenPayload) {
      return {
        query,
        metrics: this.metrics,
        dimensions: this.dimensions,
        ...filters,
      }
    }

    return {
      metrics: this.metrics,
      dimensions: this.dimensions,
      query,
      filters,
    }
  }

  public chartSettings: any = {
    colors: [],
    total: 0,
    labels: [],
  }

  public get chartOptions() {
    // Get base chart type settings
    // @ts-ignore
    let options = { ...baseChartSettings[this.subView], ...this.chartSettings, events: {} }

    if (this.formatters) {
      if (this.formatters.hasOwnProperty('label') && this.formatters.label && options.dataLabels) {
        options.dataLabels.formatter = (value: number, opt: any) =>
          this.formatters.label(this, value, opt)
      }

      if (this.formatters.hasOwnProperty('tooltip') && this.formatters.tooltip && options.tooltip) {
        options.tooltip.y.formatter = (value: number, opt: any) =>
          this.formatters.tooltip(this, value, opt)
      }

      // tooltipYTitle
      // once case of usage is the radialbar chart that contains in tooltip `Label: Tooltip values` so this function removes the title from
      // the tooltip and only shows the values ex:  `Label: Tooltip values` -> `Tooltip values` the "Label:" is removed
      if (
        this.formatters.hasOwnProperty('tooltipYTitle')
        && options.tooltip
        && options.tooltip.y
        && options.tooltip.y.title
      ) {
        options.tooltip.y.title.formatter = (seriesName: any, opt: any) =>
          this.formatters.tooltipYTitle(this, seriesName, opt)
      }

      // if (this.formatters.hasOwnProperty('label') && this.formatters.label && options.dataLabels) {
      //   options.dataLabels.formatter = (value: number, opt: any) =>
      //     this.formatters.label(this, value, opt)
      // }

      if (
        this.formatters.hasOwnProperty('xaxis')
        && this.formatters.xaxis
        && options.xaxis
        && options.xaxis.labels
      ) {
        options.xaxis.labels.formatter = (value: number, opt: any) =>
          this.formatters.xaxis(this, value, opt)
      }

      if (
        this.formatters.hasOwnProperty('yaxis')
        && this.formatters.yaxis
        && options.yaxis
        && options.yaxis.labels
      ) {
        options.yaxis.labels.formatter = (value: number, opt: any) =>
          this.formatters.yaxis(this, value, opt)
      }

      if (
        this.formatters.hasOwnProperty('totalBarFormatter')
        && this.formatters.totalBarFormatter
        && options.plotOptions.bar.dataLabels.total
      ) {
        options.plotOptions.bar.dataLabels.total.formatter = (value: number, opt: any) =>
          this.formatters.totalBarFormatter(this, value, opt)
      }
    }

    if (this.events) {
      if (this.events.hasOwnProperty('click')) {
        if (!options.chart.events) {
          options.chart.events = {}
        }
        options.chart.events.click = (event: any, chartContext: any, opts: any) => {
          this.events.click(this, event, chartContext, opts)
        }
      }
    }

    // Return custom settings
    return options
  }

  /**
   * Renders content at the bottom of the widget
   */
  public get customData() {
    return ''
  }

  public get incompatibleNotes() {
    let incompatible: string[] = []
    if (this.incompatibleOptions.query) {
      this.query.forEach(q => {
        this.incompatibleOptions.query.forEach((v: any) => {
          if (q.includes(v)) {
            incompatible.push(startCase(v))
          }
        })
      })
    }
    return incompatible
  }

  /**
   * Handles prop updates and trigger refresh if applicable
   */
  public updateProp(target: string, key: any, value: any = null, refresh: boolean = true) {
    if (target === 'query') {
      this.query = clone(key)
    } else if (target === 'filters') {
      if (Array.isArray(key)) {
        for (let i in key) {
          const k = key[i]
          if (this.filters.hasOwnProperty(k)) {
            this.filters[k] = clone(value[i])
          }
        }
      } else if (this.filters.hasOwnProperty(key)) {
        this.filters[key] = clone(value)
      } else {
        return
      }
    } else if (target === 'groups') {
      if (Array.isArray(key)) {
        for (let i in key) {
          const k = key[i]
          if (this.groups.hasOwnProperty(k)) {
            this.groups[k] = clone(value[i])
          }
        }
      } else if (this.groups.hasOwnProperty(key)) {
        this.groups[key] = clone(value)
      } else {
        return
      }
    } else if (target === 'internalFilters') {
      if (Array.isArray(key)) {
        for (let i in key) {
          const k = key[i]
          if (this.internalFilters.hasOwnProperty(k)) {
            this.internalFilters[k] = clone(value[i])
          }
        }
      } else if (this.internalFilters.hasOwnProperty(key)) {
        this.internalFilters[key] = clone(value)
      } else {
        return
      }
    } else if (target === 'internalGroups') {
      if (Array.isArray(key)) {
        for (let i in key) {
          const k = key[i]
          if (this.internalGroups.hasOwnProperty(k)) {
            this.internalGroups[k] = clone(value[i])
          }
        }
      } else if (this.internalGroups.hasOwnProperty(key)) {
        this.internalGroups[key] = clone(value)
      } else {
        return
      }
    } else if (target === 'internalProps') {
      if (Array.isArray(key)) {
        for (let i in key) {
          const k = key[i]
          if (this.internalProps.hasOwnProperty(k)) {
            this.internalProps[k] = clone(value[i])
          }
        }
      } else if (this.internalProps.hasOwnProperty(key)) {
        this.internalProps[key] = clone(value)
      } else {
        return
      }
    } else if (target === 'dimensions') {
      // key could be a string or number type ex: '0', so update the target on key
      this.dimensions[parseInt(key)] = value
    } else {
      return
    }

    if (this.ready && refresh && target !== 'internalProps') {
      setTimeout(() => this.updateData(), 100)
    }
  }

  /**
   * Handles table field visibility filters
   */
  public showFields(_: SystemDashboardWidget, fields: any) {
    return fields.filter((field: any) => field.show !== false)
  }

  /**
   * Handles API request
   */
  protected dataSource(): Promise<any> {
    return Promise.resolve([])
  }

  /**
   * Handles API data mapping
   */
  protected dataMapper(data: any): Promise<any> {
    return Promise.resolve(data)
  }

  /**
   * Handles widget data update
   */
  public updateData() {
    this._loading = true

    this.dataSource()
      .then((data: any) => {
        this.rawData = data

        return this.dataMapper(data).then((data: any) => {
          this.data = data
          setTimeout(() => {
            this._ready = true
            this._loading = false
          }, 200)
        })
      })
      .catch((err: any) => {
        this.rawData = []
        this.data = []
        this._loading = false
      })
  }

  public get pagination() {
    return {
      page: this.page,
      page_size: this.perPage,
      order_by: this.orderBy,
      mode: this.mode,
      order: this.sortDesc ? 'desc' : 'asc',
    }
  }
}
