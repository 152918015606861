import Api from './Api'

export default class CimWorkloadModule {
  public queryAutomatedFilters(module: any) {
    let automated_filters: any = {}
    let query: any = []

    if (module.query && module.query.length) {
      query = [...module.query]
    }

    if (module.local_filters) {
      Object.entries(module.local_filters).forEach(([key, value]) => {
        if (key === 'dynamic_filter') {
          automated_filters.start = module.local_filters.dynamic_filter.start
          automated_filters.end = module.local_filters.dynamic_filter.end
          automated_filters.shortcut = module.local_filters.dynamic_filter.shortcut
        } else {
          automated_filters[key] = value
        }
      })
    }
    let metrics: any = []
    let dimensions: any = []

    if (module.local_query && module.local_query.length) {
      query = module.local_query
    }
    if (module.metrics) {
      metrics = module.metrics
    }
    if (module.dimensions) {
      dimensions = module.dimensions
    }

    if (module.region) {
      query.push(`region:${module.region}`)
    }

    if (module.sales_rep) {
      query.push(`sr:${module.sales_rep}`)
    }

    query = [...new Set(query)]

    return {
      query,
      metrics,
      dimensions,
      automated_filters,
    }
  }

  public pagination_settings(context: any) {
    return {
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...context.query],
      mode: 'exclusive',
    }
  }

  public async numberOfTaskRequest({ module }: any) {
    const { query, automated_filters } = this.queryAutomatedFilters(module)

    let api = new Api()

    return api
      .get('cim-workload/number-of-task-request', { query, ...automated_filters })
      .then((response: any) => response.data)
      .catch(err => ({ data: [] }))
  }

  public async pendingTask({ module, context }: any) {
    const { query, automated_filters } = this.queryAutomatedFilters(module)

    let q: any = []

    q.concat(automated_filters)

    if (!context.query) context.query = []

    context.query = context.query.concat([...q])

    let options = this.pagination_settings(context)

    let api = new Api()

    return api
      .get('cim-workload/pending-tasks', options)
      .then((response: any) => response.data)
      .catch(err => ({ data: [] }))
  }

  public async numberOfMediaplans({ module }: any) {
    const { query, automated_filters } = this.queryAutomatedFilters(module)

    let api = new Api()
    return api
      .get('cim-workload/number-of-mediaplans', { query, ...automated_filters })
      .then((response: any) => response.data)
      .catch(err => ({ data: [] }))
  }

  public async numberOfRevenueAssigned({ module }: any) {
    const { query, automated_filters } = this.queryAutomatedFilters(module)

    let api = new Api()
    return api
      .get('cim-workload/number-of-revenue-assigned', { query, ...automated_filters })
      .then((response: any) => response.data)
      .catch(err => ({ data: [] }))
  }

  public async numberOfMediaplansWon({ module }: any) {
    const { query, automated_filters } = this.queryAutomatedFilters(module)

    let api = new Api()
    return api
      .get('cim-workload/number-of-mediaplans-won', { query, ...automated_filters })
      .then((response: any) => response.data)
      .catch(err => ({ data: [] }))
  }

  public async numberOfAdvertiser({ module }: any) {
    const { query, automated_filters } = this.queryAutomatedFilters(module)

    let api = new Api()

    return api
      .get('cim-workload/number-of-advertisers', { query, ...automated_filters })
      .then((response: any) => response.data)
      .catch(err => ({ data: [] }))
  }

  public async numberOfAgencies({ module }: any) {
    const { query, automated_filters } = this.queryAutomatedFilters(module)

    let api = new Api()

    return api
      .get('cim-workload/number-of-agencies', { query, ...automated_filters })
      .then((response: any) => response.data)
      .catch(err => ({ data: [] }))
  }
}
