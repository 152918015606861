
import { Component, Prop, Watch } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import PageHeader from '@/components/PageHeader/PageHeader.vue'
import moment from 'moment'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import Company from '@/models/Company'
import DatePicker from '@/components/DatePicker/DatePicker.vue'

// @ts-ignore
import CKEditor from '@ckeditor/ckeditor5-vue2'
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { EventBus } from '@/plugins/eventBus'
import { validate } from 'vee-validate'
import numeral from 'numeral'
import dashboardSharedOptions from '../../dashboard-shared-options'
import Widgets from './widgets'
import DashboardWidget from '../../components/DashboardWidget.vue'

@Component({
  components: {
    IconAction,
    SearchInput,
    PageHeader,
    DashboardWidget,
    CompanyPicker,
    DatePicker,
    ckeditor: CKEditor.component,
  },
})
export default class ActiveInitialTermDashboard extends ViewModel {
  @Prop({ default: null })
  public agency!: string | null

  @Prop({ default: () => '' })
  public tabKey!: string

  public editor = ClassicEditor

  public ready: boolean = false

  public query: string[] = []

  public report_ready: boolean = false

  private debug_key: string | null | string[] = []

  public date_period: any = moment().startOf('year').format('YYYY-MM-DD')

  public year: any = {
    start: moment().startOf('year').year(),
    end: moment().add(1, 'year').startOf('year').year(),
  }

  public agency_id: string | null = '94311adc-bd36-468e-865b-5d6acff1c960'

  public sales_rep: string | null = null

  public region: string | null = ''

  public product: string | null = 'all'

  public group_role: any = 'team'

  public group_mode: string | null = 'month'

  public company: any = {}

  public editing: boolean = false

  public net_total: any = 0

  @Watch('agency_id')
  public onAgencyIdChange(val: string) {
    if (val && val.length) {
      EventBus.$emit('global:agency_id', val)
    }
  }

  public get widgets() {
    if (!this.debug_key || (Array.isArray(this.debug_key) && !this.debug_key.length)) return Widgets.map(w => this.injectBeforeLoad(w))
    return Widgets.filter((w: any) =>
      (Array.isArray(this.debug_key) ? this.debug_key.includes(w.key) : w.key === this.debug_key)).map(w => this.injectBeforeLoad(w))
  }

  public injectBeforeLoad(widget: any) {
    if (['initial-overview', 'mediaplans'].includes(widget.key)) {
      widget.filters.agency_id = this.agency_id
    }
    return widget
  }

  public get options() {
    return dashboardSharedOptions
  }

  public get snapshot_id() {
    return this.$route.query.snapshot
  }

  public created() {
    // if process env is production
    if (process.env.VUE_APP_ENV === 'production') {
      this.agency_id = '94311adc-bd36-468e-865b-5d6acff1c960'
    }
  }

  public mounted() {
    this.init()
    if (process.env.VUE_APP_ENV === 'production') {
      EventBus.$emit('global:agency_id', this.agency_id)
    }
    this.loadCompany()

    EventBus.$on('int-overview-data', (data: any) => {
      this.net_total = numeral(data.value).format('$0,00a')
    })
  }

  public loadCompany() {
    if (this.agency_id && this.agency_id.length) {
      Company.find(this.agency_id).then((company: any) => {
        this.company = company
      })
    }
  }

  public init() {
    const { query } = this.$route

    if (query.query) {
      // @ts-ignore
      this.query = typeof query.query === 'string' ? [query.query] : [...query.query]
    }
    // Load filters
    this.ready = true
  }

  public updateData() {
    this.ready = false
    setTimeout(() => {
      this.ready = true
    }, 1000)
  }

  public resetFilters() {
    this.query = []
  }

  public download() {
    this.$emit('download', { key: this.tabKey })
  }

  public addFilter(data: any) {}

  public exportPipeline() {}

  public confirmDate() {
    EventBus.$emit('global:year', {
      start: moment(this.year.start).year(),
      end: moment(this.year.end).year(),
    })

    this.$root.$emit('bv::hide::popover', 'filters-popover')
  }
}
