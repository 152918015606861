import SystemDashboard from '@/models/SystemDashboard'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import DateRangeFilter from '../filters/DateRangeFilter'
import { FILTERS } from '../helpers'
import ImpressionConcolidationWidget from '../widgets/finance/ImpressionConcolidationWidget'
import PublisherShareWidget from '../widgets/finance/PublisherShareWidget'
import TodayTotalWidget from '../widgets/finance/TodayTotalWidget'
import TodayWidget from '../widgets/finance/TodayWidget'

export default class HamiltonOverviewModule extends SystemDashboard {
  public title = 'Hamilton Overview'

  public key: string = 'hamilton_dashboard'

  public widgets: SystemDashboardWidget[] = [
    new TodayWidget(),
    new TodayWidget({
      title: 'Yesterday',
      key: 'publisher_yesterday',
      internalFilters: {
        ...FILTERS.YESTERDAY,
      },
    }),
    new TodayWidget({
      title: 'MTD Through Yesterday',
      key: 'publisher_mtd_through_yesterday',
      internalFilters: {
        ...FILTERS.MTD_THROUGH_YESTERDAY,
      },
    }),
    new TodayWidget({
      title: 'YTD Through Yesterday',
      key: 'publisher_ytd_through_yesterday',
      internalFilters: {
        ...FILTERS.YTD_THROUGH_YESTERDAY,
      },
    }),

    new TodayWidget({
      title: 'Today',
      key: 'today-property',
      internalFilters: {
        ...FILTERS.TODAY,
        select_by: 'property',
      },
    }),
    new TodayWidget({
      title: 'Yesterday',
      key: 'yesterday-property',
      internalFilters: {
        ...FILTERS.YESTERDAY,
        select_by: 'property',
      },
    }),
    new TodayWidget({
      title: 'MTD Through Yesterday',
      key: 'mtd_through_yesterday',
      internalFilters: {
        ...FILTERS.MTD_THROUGH_YESTERDAY,
        select_by: 'property',
      },
    }),
    new TodayWidget({
      title: 'YTD Through Yesterday',
      key: 'ytd_through_yesterday',
      internalFilters: {
        ...FILTERS.YTD_THROUGH_YESTERDAY,
        select_by: 'property',
      },
    }),
    // pie
    new TodayTotalWidget(),
    new TodayTotalWidget({
      title: 'Yesterday',
      key: 'pie-yesterday-total',
      internalFilters: {
        ...FILTERS.YESTERDAY,
      },
    }),
    new TodayTotalWidget({
      title: 'MTD Through Yesterday',
      key: 'pie-mtd-yesterday-total',
      internalFilters: {
        ...FILTERS.MTD_THROUGH_YESTERDAY,
      },
    }),
    new TodayTotalWidget({
      title: 'YTD Through Yesterday',
      key: 'pie-ytd-yesterday-total',
      internalFilters: {
        ...FILTERS.YTD_THROUGH_YESTERDAY,
      },
    }),

    new TodayWidget({
      title: 'Today',
      key: 'advertiser-today',
      internalFilters: {
        select_by: 'advertiser',
      },
      updateFields: (fields: any) => {
        fields[0].label = 'Advertiser'
        fields[0].key = 'advertiser'
        return fields
      },
    }),
    new TodayWidget({
      title: 'Yesterday',
      key: 'advertiser-yesterday',
      internalFilters: {
        ...FILTERS.YESTERDAY,
        select_by: 'advertiser',
      },
      updateFields: (fields: any) => {
        fields[0].label = 'Advertiser'
        fields[0].key = 'advertiser'
        return fields
      },
    }),
    new TodayWidget({
      title: 'MTD Through Yesterday',
      key: 'advertiser-mtd-through-yesterday',
      internalFilters: {
        ...FILTERS.MTD_THROUGH_YESTERDAY,
        select_by: 'advertiser',
      },
      updateFields: (fields: any) => {
        fields[0].label = 'Advertiser'
        fields[0].key = 'advertiser'
        return fields
      },
    }),
    new TodayWidget({
      title: 'YTD Through Yesterday',
      key: 'advertiser-ytd-through-yesterday',
      internalFilters: {
        ...FILTERS.YTD_THROUGH_YESTERDAY,
        select_by: 'advertiser',
      },
      updateFields: (fields: any) => {
        fields[0].label = 'Advertiser'
        fields[0].key = 'advertiser'
        return fields
      },
    }),

    new PublisherShareWidget({
      date_format_labels: 'MMM Do',
      totalFormat: '0.00a',
    }),
    new TodayWidget({
      title: 'Advertiser Sales',
      key: 'advertiser-sales',
      size: {
        lg: 12,
        md: 12,
      },
      internalFilters: {
        ...FILTERS.MONTH,
        select_by: 'advertiser-sales',
      },
      fields: [
        {
          key: 'advertiser',
          label: 'Advertiser',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'impressions',
          label: 'Impressions',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'abbreviate',
          total: 'abbreviate',
        },
        {
          key: 'cost_cpm',
          label: 'C. CPM',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
          total: 'currency',
          customTotal: (data: any) => {
            let impressions = data.reduce((acc: any, cur: any) => acc + Number(cur.impressions), 0)
            let amount = data.reduce((acc: any, cur: any) => acc + Number(cur.cost), 0)
            return amount / (impressions / 1000)
          },
        },
        {
          key: 'cost',
          label: 'Cost',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
          total: 'currency_abbreviate',
        },
        {
          key: 'cpm',
          label: 'R. CPM',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
          total: 'currency',
          customTotal: (data: any) => {
            let impressions = data.reduce((acc: any, cur: any) => acc + Number(cur.impressions), 0)
            let amount = data.reduce((acc: any, cur: any) => acc + Number(cur.revenue), 0)
            return amount / (impressions / 1000)
          },
        },
        {
          key: 'revenue',
          label: 'Revenue',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
          total: 'currency_abbreviate',
        },
        {
          key: 'net_cpm',
          label: 'N. CPM',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
          total: 'currency',
          customTotal: (data: any) => {
            let impressions = data.reduce((acc: any, cur: any) => acc + Number(cur.impressions), 0)
            let amount = data.reduce((acc: any, cur: any) => acc + Number(cur.net), 0)
            return amount / (impressions / 1000)
          },
        },
        {
          key: 'net',
          label: 'Net',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
          total: 'currency_abbreviate',
        },
        {
          key: 'net_percentage',
          label: 'Net %',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'percentage',
          total: 'percentage',
          customTotal: (data: any) => {
            let net = data.reduce((acc: any, cur: any) => acc + Number(cur.net), 0)
            let revenue = data.reduce((acc: any, cur: any) => acc + Number(cur.revenue), 0)
            return net / revenue
          },
        },
      ],
      rightCol: [new DateRangeFilter({ target: 'internalFilters' })],
      custom_data:
        '<span class="text-muted">- All Revenue & Cost are estimated and subject to change after reconciliation.<br/> - Totals are based on the visible data, to get full total, please change the pagination to All.</span>',
    }),
    new TodayWidget({
      title: 'Sales Channel',
      key: 'sales-channel',
      size: {
        lg: 12,
        md: 12,
      },
      internalFilters: {
        ...FILTERS.MONTH,
        select_by: 'sales_channel',
      },
      fields: [
        {
          key: 'channel',
          label: 'Sales Channel',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'impressions',
          label: 'Impressions',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'abbreviate',
          total: 'abbreviate',
        },
        {
          key: 'cost_cpm',
          label: 'C. CPM',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
          total: 'currency',
          customTotal: (data: any) => {
            let impressions = data.reduce((acc: any, cur: any) => acc + Number(cur.impressions), 0)
            let amount = data.reduce((acc: any, cur: any) => acc + Number(cur.cost), 0)
            return amount / (impressions / 1000)
          },
        },
        {
          key: 'cost',
          label: 'Cost',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
          total: 'currency_abbreviate',
        },
        {
          key: 'cpm',
          label: 'R. CPM',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
          total: 'currency',
          customTotal: (data: any) => {
            let impressions = data.reduce((acc: any, cur: any) => acc + Number(cur.impressions), 0)
            let amount = data.reduce((acc: any, cur: any) => acc + Number(cur.revenue), 0)
            return amount / (impressions / 1000)
          },
        },
        {
          key: 'revenue',
          label: 'Revenue',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
          total: 'currency_abbreviate',
        },
        {
          key: 'net_cpm',
          label: 'N. CPM',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
          total: 'currency',
          customTotal: (data: any) => {
            let impressions = data.reduce((acc: any, cur: any) => acc + Number(cur.impressions), 0)
            let amount = data.reduce((acc: any, cur: any) => acc + Number(cur.net), 0)
            return amount / (impressions / 1000)
          },
        },
        {
          key: 'net',
          label: 'Net',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
          total: 'currency_abbreviate',
        },
        {
          key: 'net_percentage',
          label: 'Net %',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'percentage',
          total: 'percentage',
          customTotal: (data: any) => {
            let net = data.reduce((acc: any, cur: any) => acc + Number(cur.net), 0)
            let revenue = data.reduce((acc: any, cur: any) => acc + Number(cur.revenue), 0)
            return net / revenue
          },
        },
      ],
      rightCol: [new DateRangeFilter({ target: 'internalFilters' })],
      custom_data:
        '<span class="text-muted">- All Revenue & Cost are estimated and subject to change after reconciliation.<br/> - Totals are based on the visible data, to get full total, please change the pagination to All.</span>',
    }),

    new TodayWidget({
      title: 'Orders - Third Party',
      key: 'orders-third-party',
      size: {
        lg: 12,
        md: 12,
      },
      internalFilters: {
        ...FILTERS.MONTH,
        select_by: 'media_plan',
      },
      internalQuery: ['is:third_party'],
      fields: [
        {
          key: 'media_plan',
          label: 'Order',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'impressions',
          label: 'Impressions',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'abbreviate',
          total: 'abbreviate',
        },
        {
          key: 'cost_cpm',
          label: 'C. CPM',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
          total: 'currency',
          customTotal: (data: any) => {
            let impressions = data.reduce((acc: any, cur: any) => acc + Number(cur.impressions), 0)
            let amount = data.reduce((acc: any, cur: any) => acc + Number(cur.cost), 0)
            return amount / (impressions / 1000)
          },
        },
        {
          key: 'cost',
          label: 'Cost',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
          total: 'currency_abbreviate',
        },
        {
          key: 'cpm',
          label: 'R. CPM',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
          total: 'currency',
          customTotal: (data: any) => {
            let impressions = data.reduce((acc: any, cur: any) => acc + Number(cur.impressions), 0)
            let amount = data.reduce((acc: any, cur: any) => acc + Number(cur.revenue), 0)
            return amount / (impressions / 1000)
          },
        },
        {
          key: 'revenue',
          label: 'Revenue',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
          total: 'currency_abbreviate',
        },
        {
          key: 'net_cpm',
          label: 'N. CPM',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
          total: 'currency',
          customTotal: (data: any) => {
            let impressions = data.reduce((acc: any, cur: any) => acc + Number(cur.impressions), 0)
            let amount = data.reduce((acc: any, cur: any) => acc + Number(cur.net), 0)
            return amount / (impressions / 1000)
          },
        },
        {
          key: 'net',
          label: 'Net',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
          total: 'currency_abbreviate',
        },
        {
          key: 'net_percentage',
          label: 'Net %',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'percentage',
          total: 'percentage',
          customTotal: (data: any) => {
            let net = data.reduce((acc: any, cur: any) => acc + Number(cur.net), 0)
            let revenue = data.reduce((acc: any, cur: any) => acc + Number(cur.revenue), 0)
            return net / revenue
          },
        },
      ],
      rightCol: [new DateRangeFilter({ target: 'internalFilters' })],
      custom_data:
        '<span class="text-muted">- All Revenue & Cost are estimated and subject to change after reconciliation.<br/> - Totals are based on the visible data, to get full total, please change the pagination to All.</span>',
    }),
    //
    new TodayWidget({
      title: 'Orders - Direct',
      key: 'orders-direct',
      size: {
        lg: 12,
        md: 12,
      },
      internalFilters: {
        ...FILTERS.MONTH,
        select_by: 'media_plan',
      },
      internalQuery: ['is:direct'],
      fields: [
        {
          key: 'media_plan',
          label: 'Order',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'impressions',
          label: 'Impressions',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'abbreviate',
          total: 'abbreviate',
        },
        {
          key: 'cost_cpm',
          label: 'C. CPM',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
          total: 'currency',
          customTotal: (data: any) => {
            let impressions = data.reduce((acc: any, cur: any) => acc + Number(cur.impressions), 0)
            let amount = data.reduce((acc: any, cur: any) => acc + Number(cur.cost), 0)
            return amount / (impressions / 1000)
          },
        },
        {
          key: 'cost',
          label: 'Cost',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
          total: 'currency_abbreviate',
        },
        {
          key: 'cpm',
          label: 'R. CPM',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
          total: 'currency',
          customTotal: (data: any) => {
            let impressions = data.reduce((acc: any, cur: any) => acc + Number(cur.impressions), 0)
            let amount = data.reduce((acc: any, cur: any) => acc + Number(cur.revenue), 0)
            return amount / (impressions / 1000)
          },
        },
        {
          key: 'revenue',
          label: 'Revenue',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
          total: 'currency_abbreviate',
        },
        {
          key: 'net_cpm',
          label: 'N. CPM',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
          total: 'currency',
          customTotal: (data: any) => {
            let impressions = data.reduce((acc: any, cur: any) => acc + Number(cur.impressions), 0)
            let amount = data.reduce((acc: any, cur: any) => acc + Number(cur.net), 0)
            return amount / (impressions / 1000)
          },
        },
        {
          key: 'net',
          label: 'Net',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
          total: 'currency_abbreviate',
        },
        {
          key: 'net_percentage',
          label: 'Net %',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'percentage',
          total: 'percentage',
          customTotal: (data: any) => {
            let net = data.reduce((acc: any, cur: any) => acc + Number(cur.net), 0)
            let revenue = data.reduce((acc: any, cur: any) => acc + Number(cur.revenue), 0)
            return net / revenue
          },
        },
      ],
      rightCol: [new DateRangeFilter({ target: 'internalFilters' })],
      custom_data:
        '<span class="text-muted">- All Revenue & Cost are estimated and subject to change after reconciliation.<br/> - Totals are based on the visible data, to get full total, please change the pagination to All.</span>',
    }),

    new TodayWidget({
      title: 'Publishers',
      key: 'publisher-impressions-share',
      size: {
        lg: 12,
        md: 12,
      },
      internalFilters: {
        ...FILTERS.MONTH,
        select_by: 'publisher',
      },
      fields: [
        {
          key: 'publisher',
          label: 'Publisher',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'impressions',
          label: 'Impressions',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'abbreviate',
          total: 'abbreviate',
        },
        {
          key: 'cost_cpm',
          label: 'C. CPM',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
          total: 'currency',
          customTotal: (data: any) => {
            let impressions = data.reduce((acc: any, cur: any) => acc + Number(cur.impressions), 0)
            let amount = data.reduce((acc: any, cur: any) => acc + Number(cur.cost), 0)
            return amount / (impressions / 1000)
          },
        },
        {
          key: 'cost',
          label: 'Cost',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
          total: 'currency_abbreviate',
        },
        {
          key: 'cpm',
          label: 'R. CPM',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
          total: 'currency',
          customTotal: (data: any) => {
            let impressions = data.reduce((acc: any, cur: any) => acc + Number(cur.impressions), 0)
            let amount = data.reduce((acc: any, cur: any) => acc + Number(cur.revenue), 0)
            return amount / (impressions / 1000)
          },
        },
        {
          key: 'revenue',
          label: 'Revenue',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
          total: 'currency_abbreviate',
        },
        {
          key: 'net_cpm',
          label: 'N. CPM',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
          total: 'currency',
          customTotal: (data: any) => {
            let impressions = data.reduce((acc: any, cur: any) => acc + Number(cur.impressions), 0)
            let amount = data.reduce((acc: any, cur: any) => acc + Number(cur.net), 0)
            return amount / (impressions / 1000)
          },
        },
        {
          key: 'net',
          label: 'Net',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'currency',
          total: 'currency_abbreviate',
        },
        {
          key: 'net_percentage',
          label: 'Net %',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'percentage',
          total: 'percentage',
          customTotal: (data: any) => {
            let net = data.reduce((acc: any, cur: any) => acc + Number(cur.net), 0)
            let revenue = data.reduce((acc: any, cur: any) => acc + Number(cur.revenue), 0)
            return net / revenue
          },
        },
      ],
      rightCol: [new DateRangeFilter({ target: 'internalFilters' })],
      custom_data:
        '<span class="text-muted">- All Revenue & Cost are estimated and subject to change after reconciliation.<br/> - Totals are based on the visible data, to get full total, please change the pagination to All.</span>',
    }),

    new ImpressionConcolidationWidget({
      cumulative: false,
    }),
    new ImpressionConcolidationWidget({
      title: 'Impressions Accumulated',
      key: 'impression-monthly-consolidation',
    }),
  ]
}
