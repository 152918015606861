import { randomUUID } from '@/models/interface/Common'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import RegionDashboardFilter from '../../filters/RegionDashboardFilter'
import ProductDashboardFilter from '../../filters/ProductDashboardFilter'

export default class RegionProductFilterWidget extends SystemDashboardWidget {
  public key: string = randomUUID()

  public type: string = 'filter'

  public leftCol: any = [new RegionDashboardFilter()]

  public rightCol: any = [new ProductDashboardFilter()]

  public size: any = {
    sm: 12,
    md: 12,
    lg: 12,
  }

  constructor(args: any = null) {
    super()
    if (args && args.pushLeftCol) {
      this.leftCol.push(args.pushLeftCol)
    }
    if (args && args.pushRightCol) {
      this.rightCol.push(args.pushRightCol)
    }

    if (args && args.leftCol) {
      this.leftCol = args.leftCol
    }

    if (args && args.rightCol) {
      this.rightCol = args.rightCol
    }
  }
}
