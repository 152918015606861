import SelectOption from '@/models/interface/SelectOption'
import SystemDashboardAction from '@/models/SystemDashboardAction'

export default class RegionDashboardFilter extends SystemDashboardAction {
  // Target Property to be changed or event name
  public target: string = 'filters'

  // Unique key for the action
  public key: string = 'region'

  // Label for the action
  public label: string = 'Region'

  // Options for the action if applicable
  public options: SelectOption[] = [
    new SelectOption('All', 'all'),
    new SelectOption('National', 'national'),
    new SelectOption('East', 'east'),
    new SelectOption('West', 'west'),
    new SelectOption('Midwest', 'midwest'),
  ]
}
