import HamiltonModule from '@/models/HamiltonModule'
import { FILTERS } from '@/pages/ManagementDashboard/shared/helpers'
import BigNumber from 'bignumber.js'

export default {
  title: 'Advertiser Sales',
  key: 'advertiser-sales',
  source: 'request',
  request: {
    module: new HamiltonModule(),
    exec: 'today',
  },
  pre_load: {},
  type: 'table',
  size: {
    lg: 12,
    md: 12,
  },
  capitalize_labels: true,
  config: {
    allowAll: true,
    dynamic_date: true,
    custom_data: () =>
      '<span class="text-muted">- All Revenue & Cost are estimated and subject to change after reconciliation.<br/> - Totals are based on the visible data, to get full total, please change the pagination to All.</span>',
    fields: [
      {
        key: 'advertiser',
        label: 'Advertiser',
        class: 'text-center align-middle',
        show: true,
        thClass: 'align-middle text-center',
        sortable: true,
      },
      {
        key: 'impressions',
        label: 'Impressions',
        class: 'text-center align-middle',
        show: true,
        thClass: 'align-middle text-center',
        sortable: true,
        type: 'abbreviate',
        total: 'abbreviate',
      },
      {
        key: 'cost_cpm',
        label: 'C. CPM',
        class: 'text-center align-middle',
        show: true,
        thClass: 'align-middle text-center',
        sortable: true,
        type: 'currency',
        total: 'currency',
        customTotal: (data: any) => {
          let impressions = data.reduce((acc: any, cur: any) => acc + Number(cur.impressions), 0)
          let amount = data.reduce((acc: any, cur: any) => acc + Number(cur.cost), 0)
          return amount / (impressions / 1000)
        },
      },
      {
        key: 'cost',
        label: 'Cost',
        class: 'text-center align-middle',
        show: true,
        thClass: 'align-middle text-center',
        sortable: true,
        type: 'currency',
        total: 'currency_abbreviate',
      },
      {
        key: 'cpm',
        label: 'R. CPM',
        class: 'text-center align-middle',
        show: true,
        thClass: 'align-middle text-center',
        sortable: true,
        type: 'currency',
        total: 'currency',
        customTotal: (data: any) => {
          let impressions = data.reduce((acc: any, cur: any) => acc + Number(cur.impressions), 0)
          let amount = data.reduce((acc: any, cur: any) => acc + Number(cur.revenue), 0)
          return amount / (impressions / 1000)
        },
      },
      {
        key: 'revenue',
        label: 'Revenue',
        class: 'text-center align-middle',
        show: true,
        thClass: 'align-middle text-center',
        sortable: true,
        type: 'currency',
        total: 'currency_abbreviate',
      },
      {
        key: 'net_cpm',
        label: 'N. CPM',
        class: 'text-center align-middle',
        show: true,
        thClass: 'align-middle text-center',
        sortable: true,
        type: 'currency',
        total: 'currency',
        customTotal: (data: any) => {
          let impressions = data.reduce((acc: any, cur: any) => acc + Number(cur.impressions), 0)
          let amount = data.reduce((acc: any, cur: any) => acc + Number(cur.net), 0)
          return amount / (impressions / 1000)
        },
      },
      {
        key: 'net',
        label: 'Net',
        class: 'text-center align-middle',
        show: true,
        thClass: 'align-middle text-center',
        sortable: true,
        type: 'currency',
        total: 'currency_abbreviate',
      },
      {
        key: 'net_percentage',
        label: 'Net %',
        class: 'text-center align-middle',
        show: true,
        thClass: 'align-middle text-center',
        sortable: true,
        type: 'percentage',
        total: 'percentage',
        customTotal: (data: any) => {
          let net = data.reduce((acc: any, cur: any) => acc + Number(cur.net), 0)
          let revenue = data.reduce((acc: any, cur: any) => acc + Number(cur.revenue), 0)
          return net / revenue
        },
      },
    ],
  },
  filters: {
    dynamic_filter: {
      ...FILTERS.MONTH,
    },
    widget_filter: {
      select_by: 'advertiser-sales',
    },
    order_by: 'impressions',
  },
  metrics: [],
  dimensions: [],
  query: [],
  order: {},
  dynamic_options: [{ target: 'local_filters', sub_target: 'dynamic_filter' }],
}
