
import {
  Component, Prop, Ref, Watch,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import Widget from '@/components/Widget/Widget.vue'
import { cloneDeep } from 'lodash'
import VueApexCharts from 'vue-apexcharts'
import DataTable from '@/components/DataTable/index.vue'
import numeral from 'numeral'
import DashboardAction from './DashboardAction.vue'
import TableCellIndicator from './TableCellIndicator.vue'
import TableColLink from './TableColLink.vue'

@Component({
  components: {
    Widget,
    VueApexCharts,
    DataTable,
    DashboardAction,
    TableCellIndicator,
    TableColLink,
  },
})
export default class DashboardWidgetView extends ViewModel {
  @Ref() public dataTable!: any

  @Prop({ required: true })
  public widget!: SystemDashboardWidget

  public localWidget: SystemDashboardWidget = new SystemDashboardWidget()

  public order_by: any = null

  @Watch('widget')
  public onLoadingChange() {
    this.localWidget = cloneDeep(this.widget)
    this.localWidget.viewComponent = this
  }

  public get isLoading() {
    return this.localWidget.loading
  }

  public mounted() {
    this.localWidget = cloneDeep(this.widget)
    this.localWidget.viewComponent = this
    this.localWidget.updateData()
  }

  public updateData() {
    this.localWidget.updateData()
  }

  public updateProp(target: string, key: any, value: any = null, refresh: boolean = true) {
    this.localWidget.updateProp(target, key, value, refresh)
  }

  public loading: boolean = false

  /*
   * Reload the data, use this for the table component
   * the name of the header or footer celll clicked
   */
  public reload(event: string) {
    if (event === this.localWidget.orderBy) {
      this.localWidget.sortDesc = !this.localWidget.sortDesc
    }

    if (this.loading) return
    this.updateData()
    this.localWidget.orderBy = event
  }

  public rows(ctx: any) {
    return new Promise(resolve => {
      setTimeout(() => {
        this.loading = false
        resolve(this.localWidget.data)
      }, 100)
    })
  }

  /**
   * Get Filter value
   */
  public getActionValue(field: any) {
    if (field.type === 'checkbox') {
      let ret: any[] = []

      field.options.forEach((option: any) => {
        // @ts-ignore
        if (this.localWidget[field.target][option.value]) {
          ret.push(option.value)
        }
      })

      return ret
    }
    if (field.type === 'popover') {
      return this.localWidget.fields
    }
    // @ts-ignore
    return this.localWidget[field.target][field.key]
  }

  /**
   * Get Secondary Filter value
   */
  public getSecondaryActionValue(field: any) {
    if (!field.secondaryKey) {
      return null
    }

    if (field.target === 'filters') {
      return this.localWidget.filters[field.secondaryKey]
    }
    if (field.target === 'groups') {
      return this.localWidget.groups[field.secondaryKey]
    }
    if (field.target === 'query') {
      return this.localWidget.query
    }
    if (field.target === 'internalFilters') {
      return this.localWidget.internalFilters[field.secondaryKey]
    }
    if (field.target === 'internalProps') {
      return this.localWidget.internalProps[field.secondaryKey]
    }

    return ''
  }

  /**
   * Triggered on dashboard action event
   */
  public onAction(payload: any) {
    const { action, value, refresh } = payload

    this.updateProp(action.target, action.keyValues, value, refresh)
  }

  public getFieldFoot(key: string) {
    const field_index = this.localWidget.fields.findIndex((f: any) => f.key === key)
    const field = this.localWidget.fields[field_index]

    if (field_index === 0) {
      return 'Total'
    }
    if (!field || !field.total || field.total === 'none' || !this.localWidget.data) {
      return ''
    }
    let ret = 0

    if (field.customTotal) {
      ret = field.customTotal(this.localWidget.data)
    } else {
      ret = this.localWidget.data.reduce((acc: number, row: any) => acc + Number(row[key]), 0)
    }

    let format = '0,0.00a'

    if (field.total.includes('currency')) {
      format = '$0,0.00a'
    }

    if (field.total.includes('sum')) {
      format = '0,0'
    }

    if (field.total.includes('percentage')) {
      format = '0,0.00%'
    }

    if (field.total.includes('avg')) {
      return numeral(ret / this.localWidget.data.length).format(format)
    }
    return numeral(ret).format(format)
  }

  public pageChanged(page: number) {
    this.localWidget.page = page
    this.updateData()
  }

  public applyFilter(
    name: string,
    value: string,
    target: string,
    sub_target: string = '',
    global: boolean = true,
  ) {
    if (global) {
      this.$emit('setFilter', {
        name,
        value,
        target,
        sub_target,
      })
    } else {
      this.localWidget.updateProp(target, sub_target, value)
    }
  }
}
