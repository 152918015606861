import { buildColorPool } from '@/models/Util'
import Opportunity from '@/models/Opportunity'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import { startCase } from 'lodash'
import moment from 'moment'
import numeral from 'numeral'

export default class OpportunityStatusDistribution extends SystemDashboardWidget {
  public title: string = `Opportunity Status Distribution ${moment().format('YYYY')}`

  public key: string = 'opportunity_status_distribution'

  public view: string = 'chart'

  public subView: string = 'funnel'

  public loaderSize: string = '350px'

  public dimensions: string[] = ['status']

  public metrics: string[] = ['amount']

  public size: any = {
    sm: 12,
    md: 4,
    lg: 4,
  }

  // API Filters
  public filters: any = {
    region: 'all',
    product: 'all',
  }

  public internalFilters: any = {
    year: moment().format('YYYY'),
  }

  public internalQuery: any = ['scope:agency']

  public formatters: any = {
    label: (widget: SystemDashboardWidget, value: any, opt: any) => {
      const raw_value = widget.rawData[opt.dataPointIndex].amount
      const label = widget.chartSettings.labels[opt.dataPointIndex]

      return [`${label}: ${numeral(raw_value).format('$0.00a')}`]
    },
    tooltip: (widget: SystemDashboardWidget, value: any, opt: any) => {
      const raw_value = widget.rawData[opt.dataPointIndex].amount
      const total = widget.rawData.reduce(
        (acc: number, item: any) => acc + item[widget.metrics[0]],
        0,
      )

      return [
        `${numeral(raw_value).format('$0.00a')}`,
        ` ${numeral(raw_value / total).format('0.00%')}`,
      ]
    },
  }

  public get filterToQuery() {
    let query = []

    if (this.filters.product !== 'all') {
      query.push(`product:${this.filters.product}`)
    }

    if (this.filters.region !== 'all') {
      query.push(`region:${this.filters.region}`)
    }

    query.push(`year:${this.internalFilters.year}`)

    return query
  }

  public get payload() {
    const query = [...new Set([...this.query, ...this.internalQuery, ...this.filterToQuery])]
    const filters = {
      ...this.internalFilters,
    }
    return {
      dimensions: this.dimensions,
      metrics: this.metrics,
      filters,

      order: {
        percentage: 'asc',
      },
      query,
      snapshot: this.filters.snapshot || '',
    }
  }

  public dataSource() {
    return Opportunity.buildAdvancedQuery(this.payload).then((res: any) => res.data.result)
  }

  protected dataMapper(data: any): Promise<any> {
    const data_series: any[] = []
    const labels: string[] = []
    const colors: string[] = []
    let total = 0

    const color_pool = buildColorPool(data.length, true)

    // Init Series
    let count = 0
    const total_count = data.length
    data.forEach((item: any, idx: number) => {
      const val = Number(item[this.metrics[0]])
      total += val

      let label = startCase(item[this.dimensions[0]])
      labels.push(label)
      colors.push(color_pool(count++))
      data_series.push(total_count - count + 1)
    })

    // Udpate Settings
    this.chartSettings.colors = colors
    this.chartSettings.xaxis = {
      categories: labels,
    }
    this.chartSettings.labels = labels

    return Promise.resolve([
      {
        name: startCase(this.metrics[0]),
        data: data_series,
      },
    ])
  }
}
