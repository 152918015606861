import SystemDashboard from '@/models/SystemDashboard'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import MarketingRequest from '@/models/MarketingRequest'
import { FILTERS } from '@/pages/ManagementDashboard/shared/helpers'
import CimWorkloadModule from '@/models/CimWorkloadModule'
import { startCase } from 'lodash'
import CustomWidgetWrapper from '../widgets/finance/CustomWidgetWrapper'
import NumberOfTasksWidget from '../widgets/finance/NumberOfTasksWidget'
import RegionProductFilterWidget from '../widgets/others/RegionProductFilterWidget'
import ActivityThisWeekWidget from '../widgets/finance/ActivityThisWeekWidget'

const moduleRequest = new MarketingRequest()
export default class MarquetingRequestModule extends SystemDashboard {
  public title = 'Marketing Request'

  public key: string = 'marketing_request'

  public querySettings = {
    company_fields: [
      {
        name: 'agency',
        key: 'agency',
        type: 'agency',
      },
      {
        name: 'advertiser',
        key: 'advertiser',
        type: 'advertiser',
      },
    ],
    custom_fields: [
      {
        name: 'product:ssl',
        value: 'product:ssl',
      },
      {
        name: 'product:ccl',
        value: 'product:ccl',
      },
      {
        name: 'deal_type:direct',
        value: 'deal_type:direct',
      },
      {
        name: 'deal_type:3rd Party',
        value: 'deal_type:3rd_party',
      },
    ],
    user_fields: [
      {
        name: 'sr',
        key: 'sales_rep_id',
      },
    ],
  }

  public filters = {
    product: 'all',
    region: 'all',
  }

  public shared_fields: any = [
    {
      key: 'title',
      label: 'Title',
      class: 'text-center align-middle text-capitalize',
      show: true,
      thClass: 'align-middle text-center',
      sortable: true,
    },

    {
      key: 'owner',
      label: 'Owner',
      class: 'text-center align-middle text-capitalize ',
      show: true,
      thClass: 'align-middle text-center  ',
      sortable: true,
    },
    {
      key: 'assigned_to',
      label: 'Assigned To',
      class: 'text-center align-middle text-capitalize ',
      show: true,
      thClass: 'align-middle text-center',
      sortable: true,
    },

    {
      key: 'status',
      label: 'Status',
      class: 'text-center align-middle text-capitalize ',
      show: true,
      thClass: 'align-middle text-center',
      sortable: true,
    },

    {
      key: 'created_at',
      label: 'Created At',
      class: 'text-center align-middle text-capitalize',
      show: true,
      thClass: 'align-middle text-center',
      sortable: true,
      type: 'date',
    },
    {
      key: 'due_at',
      label: 'Due At',
      class: 'text-center align-middle text-capitalize',
      show: true,
      thClass: 'align-middle text-center',
      sortable: true,
      type: 'date',
    },
  ]

  public widgets: SystemDashboardWidget[] = [
    new RegionProductFilterWidget({
      rightCol: [],
    }),
    new CustomWidgetWrapper({
      size: {
        lg: 12,
        md: 12,
        sm: 12,
      },
      dataSourceRequest: (payload: any) => moduleRequest.dueRequests(payload),
    }),

    new ActivityThisWeekWidget({
      title: 'List of whats due today',
      key: 'list-of-whats-due-today',
      size: {
        lg: 6,
        md: 6,
        sm: 12,
      },
      bordered: true,
      noTotal: true,
      dimensions: [],
      internalQuery: ['due_at:today'],
      fields: this.shared_fields,
      perPage: 10,
      dataSourceRequest: (payload: any) =>
        moduleRequest.notCompleted(payload).then((res: any) => {
          res.data = res.data.map((item: any) => {
            item.status = startCase(item.status)
            return item
          })
          return res
        }),

      cellSlots: [
        {
          key: 'title',
          component: 'TableColLink', // also import this component in parent location
          slotProps: (data: any) => ({
            link: `tasks/${data.item.id}`,
            innerText: data.item.title,
          }),
        },
      ],
    }),
    new ActivityThisWeekWidget({
      title: 'List of whats due this week',
      key: 'list-of-whats-due-this-week',
      size: {
        lg: 6,
        md: 6,
        sm: 12,
      },
      bordered: true,
      noTotal: true,
      dimensions: [],
      internalQuery: ['due_at:this_week'],
      fields: this.shared_fields,
      perPage: 10,
      dataSourceRequest: (payload: any) =>
        moduleRequest.notCompleted(payload).then((res: any) => {
          res.data = res.data.map((item: any) => {
            item.status = startCase(item.status)
            return item
          })
          return res
        }),
      cellSlots: [
        {
          key: 'title',
          component: 'TableColLink', // also import this component in parent location
          slotProps: (data: any) => ({
            link: `tasks/${data.item.id}`,
            innerText: data.item.title,
          }),
        },
      ],
    }),
    new ActivityThisWeekWidget({
      title: 'Requests Not completed',
      key: 'requests-not-completed',
      size: {
        lg: 6,
        md: 6,
        sm: 12,
      },
      bordered: true,
      noTotal: true,
      dimensions: [],
      internalQuery: [],
      fields: this.shared_fields,
      perPage: 10,
      dataSourceRequest: (payload: any) =>
        moduleRequest.notCompleted(payload).then((res: any) => {
          res.data = res.data.map((item: any) => {
            item.status = startCase(item.status)
            return item
          })
          return res
        }),

      cellSlots: [
        {
          key: 'title',
          component: 'TableColLink',
          slotProps: (data: any) => ({
            link: `tasks/${data.item.id}`,
            innerText: data.item.title,
          }),
        },
      ],
    }),
    new ActivityThisWeekWidget({
      title: 'Future Volume Requests',
      key: 'future-volume-requests',
      size: {
        lg: 6,
        md: 6,
        sm: 12,
      },
      bordered: true,
      noTotal: true,
      dimensions: [],
      internalQuery: ['due_at:next_six_weeks'],
      fields: this.shared_fields,
      perPage: 10,
      dataSourceRequest: (payload: any) =>
        moduleRequest.notCompleted(payload).then((res: any) => {
          res.data = res.data.map((item: any) => {
            item.status = startCase(item.status)
            return item
          })
          return res
        }),
      cellSlots: [
        {
          key: 'title',
          component: 'TableColLink', // also import this component in parent location
          slotProps: (data: any) => ({
            link: `tasks/${data.item.id}`,
            innerText: data.item.title,
          }),
        },
      ],
    }),
  ]
}
