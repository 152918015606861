import SystemDashboard from '@/models/SystemDashboard'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'

import moment from 'moment'
import ActiveInitialTermModule from '@/models/ActiveInitialTermModule'
import HeaderDateFilterWidget from '../widgets/others/HeaderDateFilterWidget'
import { FILTERS } from '../helpers'
import InitialOverviewWidget from '../widgets/finance/InitialOverviewWidget'
import ActivityThisWeekWidget from '../widgets/finance/ActivityThisWeekWidget'

export default class ActiveInternationalModule extends SystemDashboard {
  public title: string = 'Active International'

  public key = 'international_term'

  public customRight: string = '<span/>'

  public get customBottom() {
    return `
    <div class="mt-5 row">
      <div class="col col-sm-12 col-md-6">
        <div class="widget h-100">
          <div>
            <h2>Terminology</h2>
            <br />
            <p><strong>Order</strong>: One IO / Media Plan</p>
            <p><strong>Revenue</strong>: Line Item(s) with net/cash value</p>
            <p><strong>Gross Amount</strong>: Amount agency recognizes (net / .85)</p>
            <p><strong>Net Amount</strong>: Amount to be paid to CIM (gross x .85)</p>
            <p><strong>Added Value (AV)</strong>: Line Item(s) with no cash value (i.e bonus)</p>
            <p>
              <strong>Gross Value(AV)</strong>: Line Item(s) represented as gross with no net on IO.
              ($58,823 gross AV is $50,000 net AV… $0 cash)
            </p>
            <p>
              <strong>Net Value(AV)</strong>: The line items net equivalent from gross AV. ($58,823
              gross AV is $50,000 net AV… $0 cash)
            </p>
            <p><strong>Total Gross Value</strong>: Gross amount + Gross value</p>
            <p><strong>Cost</strong>: Amount spent on inventory. Cost is is projected ultil campagin is delivered and invoiced in full.</p>
          </div>
        </div>
      </div>
      <div class="col col-sm-12 col-md-6">
        <div class="widget h-100">
          <div>
            <h2>Requirements:</h2>
            <br />
            <p><strong>Agency</strong>: Active International</p>
            <p>
              <strong>Dates</strong>: 6/10/24+ (will end once achieve total gross value)
            </p>
            <p>
              <strong>Paid Media Targets</strong>: $3,529,411 gross amount (100%) $3,000,000 net
              amount (85%)
            </p>
            <p>
              <strong>Added Value Targets</strong>: $2,352,941 gross value (100%) $2,000,000 net
              value (85%)
            </p>
            <p>
              <strong>Total Value Targets</strong>: $5,882,352 total gross value (100%) $3,529,411
              net amount (51%)
            </p>
          </div>
        </div>
      </div>
    </div>
    `
  }

  public filters: any = {
    start: moment(FILTERS.YEAR.start).format('YYYY'),
    end: moment(FILTERS.YEAR.end).add(1, 'year').format('YYYY'),
    agency_id: '94311adc-bd36-468e-865b-5d6acff1c960',
    product: 'all',
    region: 'all',
  }

  public widgets: SystemDashboardWidget[] = [
    new HeaderDateFilterWidget(),
    new InitialOverviewWidget(),
    new ActivityThisWeekWidget({
      metrics: [],
      dimensions: [],
      perPage: 100,
      title: 'Media Plans',
      key: 'mediaplans',
      size: {
        lg: 12,
        md: 12,
      },
      fields: [
        {
          key: 'order_date',
          label: 'Order Date',
          class: 'text-center align-middle text-capitalize font-weight-bold',
          show: true,
          thClass: 'align-middle text-center',
          sortable: false,
          thStyle: 'width: 15em',
          type: 'date',
        },
        {
          key: 'order',
          label: 'Order',
          class: 'text-center align-middle text-capitalize font-weight-bold',
          show: true,
          thClass: 'align-middle text-center',
          sortable: false,
          thStyle: 'width: 15em',
          templateLink: true,
        },
        {
          key: 'flight_start',
          label: 'Flight (Start-End)',
          class: 'text-center align-middle text-capitalize font-weight-bold',
          show: true,
          thClass: 'align-middle text-center',
          sortable: false,
          type: 'currency',
          thStyle: 'width: 15em',
          template: (item: any) => {
            let start = moment(item.flight_start).format('MMM, Do YYYY')
            let end = moment(item.flight_end).format('MMM, Do YYYY')
            return `${start} - ${end}`
          },
        },
        {
          key: 'value',
          label: 'Net Amount',
          class: 'text-center align-middle text-capitalize font-weight-bold',
          show: true,
          thClass: 'align-middle text-center',
          sortable: false,
          type: 'currency',
          thStyle: 'width: 15em',
        },
        {
          key: 'delivered',
          label: 'Delivered',
          class: 'text-center align-middle text-capitalize font-weight-bold',
          show: true,
          thClass: 'align-middle text-center',
          sortable: false,
          type: 'percentage',
          thStyle: 'width: 15em',
        },
        {
          key: 'revenue',
          label: 'Revenue',
          class: 'text-center align-middle text-capitalize font-weight-bold',
          show: false,
          thClass: 'align-middle text-center',
          sortable: false,
          type: 'currency',
          thStyle: 'width: 15em',
        },
        {
          key: 'cost',
          label: 'Cost *',
          class: 'text-center align-middle text-capitalize font-weight-bold',
          show: true,
          thClass: 'align-middle text-center',
          sortable: false,
          type: 'currency',
          thStyle: 'width: 15em',
        },
        {
          key: 'profit',
          label: 'Profit',
          class: 'text-center align-middle text-capitalize font-weight-bold',
          show: true,
          thClass: 'align-middle text-center',
          sortable: false,
          type: 'currency',
          thStyle: 'width: 15em',
        },
      ],
      cellSlots: [
        {
          key: 'order',
          component: 'TableColLink',
          slotProps: (data: any) => ({
            link: `/app/sales/media_plan/${data.item.id}/view`,
            innerText: data.item.order,
          }),
        },
      ],
      internalQuery: [],
      dataSourceRequest: (payload: any) => {
        let request = new ActiveInitialTermModule()
        return request.mediaPlans({
          module: { ...payload, ...this.filters },
          ignore_logic: true,
          context: null,
        })
      },
    }),
  ]
}
