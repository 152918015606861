import { startCase } from 'lodash'
import { DateTime } from 'luxon'
import moment from 'moment'

const normalizeLabel = (name: string, settings: any) => {
  if (!name) return 'N/A'
  if (settings.capitalizeLabels) {
    name = startCase(name)
  } else if (settings.dateFormatLabels) {
    name = moment(name, settings.config.date_dimension_format).format(settings.dateFormatLabels)
  }
  return name
}

const FILTERS = {
  TODAY: {
    // 24hr time
    start: DateTime.now().startOf('day').toFormat('yyyy-LL-dd HH:mm:ss'),
    end: DateTime.now().endOf('day').toFormat('yyyy-LL-dd HH:mm:ss'),
    shortcut: 'today',
  },
  YESTERDAY: {
    start: DateTime.now().minus({ days: 1 }).startOf('day').toFormat('yyyy-LL-dd HH:mm:ss'),
    end: DateTime.now().minus({ days: 1 }).endOf('day').toFormat('yyyy-LL-dd HH:mm:ss'),
    shortcut: 'yesterday',
  },
  // mtdThroughYesterday
  MTD_THROUGH_YESTERDAY: {
    start: DateTime.now().startOf('month').toFormat('yyyy-LL-dd HH:mm:ss'),
    end: DateTime.now().minus({ days: 1 }).endOf('day').toFormat('yyyy-LL-dd HH:mm:ss'),
  },
  // ytdThroughYesterday
  YTD_THROUGH_YESTERDAY: {
    start: DateTime.now().startOf('year').toFormat('yyyy-LL-dd HH:mm:ss'),
    end: DateTime.now().minus({ days: 1 }).endOf('day').toFormat('yyyy-LL-dd HH:mm:ss'),
  },
  // ytdThroughYesterday
  YTD_THROUGH_TODAY: {
    start: DateTime.now().startOf('year').toFormat('yyyy-LL-dd HH:mm:ss'),
    end: DateTime.now().endOf('day').toFormat('yyyy-LL-dd HH:mm:ss'),
  },
  // ytdThroughYesterday
  YTD_THROUGH_MONTH: {
    start: DateTime.now().startOf('year').toFormat('yyyy-LL-dd HH:mm:ss'),
    end: DateTime.now().endOf('month').toFormat('yyyy-LL-dd HH:mm:ss'),
  },
  // YEAR
  YEAR: {
    start: DateTime.now().startOf('year').toFormat('yyyy-LL-dd HH:mm:ss'),
    end: DateTime.now().endOf('year').toFormat('yyyy-LL-dd HH:mm:ss'),
    shortcut: 'year',
  },
  MONTH: {
    start: DateTime.now().startOf('month').toFormat('yyyy-LL-dd HH:mm:ss'),
    end: DateTime.now().endOf('month').toFormat('yyyy-LL-dd HH:mm:ss'),
    shortcut: 'month',
  },
  LAST_30_DAYS: {
    start: DateTime.now().minus({ days: 30 }).toFormat('yyyy-LL-dd HH:mm:ss'),
    end: DateTime.now().toFormat('yyyy-LL-dd HH:mm:ss'),
    shortcut: '-30',
  },
  LAST_4_WEEKS: {
    start: moment()
      .subtract(4, 'weeks')
      .startOf('week')
      .add(1, 'day')
      .format('YYYY-MM-DD HH:mm:ss'),
    end: moment().endOf('week').add(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
  },
  YTD: {
    start: moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'),
    end: moment().format('YYYY-MM-DD HH:mm:ss'),
    shortcut: 'ytd',
  },
  YTM: {
    start: moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'),
    end: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
    shortcut: 'ytd',
  },
}

export { normalizeLabel, FILTERS }
