import numeral from 'numeral'
import moment from 'moment'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import { groupBy } from 'lodash'
import Company from './Company'
import User from './User'
import Model from './interface/Model'
import DatePickerDate from './DatePickerDate'
import Api from './Api'
import WebMessage from './WebMessage'
import ModelWithFiles from './interface/ModelWithFiles'
import { RegionOptions, buildFormData } from './interface/Common'
import FinancialPlanProductModule from './FinancialPlanProductModule'

export default class FinancialPlan extends ModelWithFiles {
  protected api_settings = {
    save_mode: 'form',
    paths: {
      singular: 'financial_plans' as string | null,
      plural: 'financial_plans' as string | null,
    },
  }

  public id: any = null

  public parent_id: any = null

  public year: number = moment().add(1, 'year').year()

  public region: null | string = null

  public agency_id: null | string = null

  public agency: null | Company = null

  public sales_management_id: null | string = null

  public sales_management: null | User = null

  public sales_rep_id: null | string = null

  public sales_rep: null | User = null

  public period_start_at: null | string = null

  public period_end_at: null | string = null

  public product: null | string = null

  public snapshot_id: null | string = null

  public goal: number = 0

  public goal_planned: number = 0

  public goal_pipeline: number = 0

  public goal_achieved: number = 0

  public goal_sold: number = 0

  public last_year_goal: number = 0

  public last_year_goal_planned: number = 0

  public last_year_goal_pipeline: number = 0

  public last_year_goal_sold: number = 0

  public last_year_goal_achieved: number = 0

  public sub_plans: FinancialPlan[] = []

  public metrics: any = null

  public type: string = 'annual_goal'

  public _showDetails = false

  public _loading = false

  public filter_by: any = {
    period: 'quarterly',
    scope: ['region'],
  }

  public options: any = {
    inspection: [
      { text: 'Sales Rep', value: 'sales_rep' },
      { text: 'Product', value: 'product' },
      { text: 'Client', value: 'agency' },
      { text: 'Region', value: 'region' },
    ],
    inspection_period: [
      { text: 'Quarterly', value: 'quarterly' },
      { text: 'Monthly', value: 'monthly' },
    ],
  }

  public get period_dates() {
    return new DatePickerDate(this.period_start_at, this.period_end_at, null, 'YYYY-MM-DD HH:mm:ss')
  }

  public set period_dates(value: DatePickerDate) {
    this.period_start_at = value.start
    this.period_end_at = value.end
  }

  protected onSave(response: any, meta: any = null) {
    WebMessage.success('Financial Plan saved')

    return response
  }

  public get view_goal(): string {
    return numeral(this.goal).format('$0.00a')
  }

  public get planned_percent(): number {
    if (this.goal > 0 && this.goal_planned > 0) {
      return this.goal_planned / this.goal
    }
    return 0
  }

  public get view_goal_planned(): string {
    let percent = 0
    if (this.goal > 0 && this.goal_planned > 0) {
      percent = (this.goal_planned / this.goal) * 100
    }

    return `${numeral(this.goal_planned).format('$0.00a')} (${percent.toFixed(2)}%)`
  }

  public get view_goal_pipeline(): string {
    let ref = this.goal_planned

    if (this.type === 'sales_rep_goal') {
      ref = this.goal
    }

    let percent = 0
    if (this.goal > 0 && this.goal_pipeline > 0) {
      percent = (this.goal_pipeline / ref) * 100
    }

    return `${numeral(this.goal_pipeline).format('$0.00a')} (${percent.toFixed(2)}%)`
  }

  public get view_goal_sold(): string {
    let ref = this.goal_pipeline

    if (this.type === 'sales_rep_goal') {
      ref = this.goal
    }

    let percent = 0
    if (this.goal > 0 && this.goal_sold > 0) {
      percent = (this.goal_sold / ref) * 100
    }

    return `${numeral(this.goal_sold).format('$0.00a')} (${percent.toFixed(2)}%)`
  }

  public get view_goal_achieved(): string {
    let percent = 0
    if (this.goal > 0 && this.goal_achieved > 0) {
      percent = (this.goal_achieved / this.goal) * 100
    }
    return `${numeral(this.goal_achieved).format('$0.00a')} (${percent.toFixed(2)}%)`
  }

  public get view_not_achieved(): string {
    return numeral(this.goal_planned - this.goal_achieved).format('$0.00a')
  }

  public get period(): string {
    const start = moment(this.period_start_at)
    const end = moment(this.period_end_at)

    if (start.isSame(end, 'month')) {
      return `${start.format('MMM, YYYY')}`
    }

    return `${start.format('MMM, YYYY')} - ${end.format('MMM, YYYY')}`
  }

  public sub_table_sub_head_fields: any = [
    {
      key: 'goal',
      label: 'Plan',
      show: true,
    },
    // Not supported
    // {
    //   key: 'goal_planned',
    //   label: 'Pending Plan',
    //   show: false,
    // },
    {
      key: 'goal_pipeline',
      label: 'Pipeline',
      show: true,
    },
    {
      key: 'goal_sold',
      label: 'Sold',
      show: false,
    },
    {
      key: 'goal_achieved',
      label: 'Achieved',
      show: true,
    },
  ]

  public get sub_head_fields() {
    return this.sub_table_sub_head_fields.filter((b: any) => b.show)
  }

  public isSubHeaderColVisible(key: string) {
    return this.sub_head_fields.some((f: any) => f.key === key)
  }

  public sub_table_data: any = []

  public sub_table_settings: any = {
    loading: false,
    records: 0,
    visible_columns: false,
    pagination: {
      page_size: 25,
      page: 1,
      order_by: 'created_at',
      order: 'asc',
    },
  }

  public toggleColunms() {
    this.sub_table_settings.visible_columns = !this.sub_table_settings.visible_columns
  }

  public is_requesting: boolean = false

  public sub_loading: boolean = false

  public base_sub_table_fields: any = []

  public bulk_year_fields: any = [
    {
      key: 'month',
      label: '',
      sortable: false,
      filter: false,
      show: true,
      thClass: 'p-0  align-middle text-center border-right sub-cell-head',
      tdClass: 'sub-cell  align-middle text-center p-0 text-capitalize',
      thStyle: 'min-width:200px!important; width:200px!important',
    },
    {
      key: 'ssl',
      label: 'SSl',
      sortable: false,
      filter: false,
      show: true,
      thClass: 'p-0  align-middle text-center border-right sub-cell-head',
      tdClass: 'sub-cell  align-middle text-center p-0 text-capitalize',
      thStyle: 'min-width:200px!important; width:200px!important',
    },
    {
      key: 'ccl',
      label: 'CCl',
      sortable: false,
      filter: false,
      show: true,
      thClass: 'p-0  align-middle text-center border-right sub-cell-head',
      tdClass: 'sub-cell  align-middle text-center p-0 text-capitalize',
      thStyle: 'min-width:200px!important; width:200px!important',
    },
    {
      key: 'total',
      label: 'Total',
      sortable: false,
      filter: false,
      show: true,
      thClass: 'p-0  align-middle text-center   sub-cell-head',
      tdClass: 'sub-cell  align-middle text-center p-0 text-capitalize',
      thStyle: 'min-width:200px!important; width:200px!important',
    },
  ]

  public month_goals: any = []

  public bulk_year_sub_head_fields: any = [
    {
      key: 'month',
      label: '',
      show: true,
    },
    {
      key: 'east_ssl',
      label: 'East',
      show: true,
    },
    {
      key: 'west_ssl',
      label: 'west',
      show: true,
    },
    {
      key: 'midwest_ssl',
      label: 'midwest',
      show: true,
    },
    {
      key: 'national_ssl',
      label: 'national',
      show: true,
    },
    {
      key: 'total_ssl',
      label: 'total',
      show: true,
    },
    {
      key: 'east_ccl',
      label: 'East',
      show: true,
    },
    {
      key: 'west_ccl',
      label: 'west',
      show: true,
    },
    {
      key: 'midwest_ccl',
      label: 'midwest',
      show: true,
    },
    {
      key: 'national_ccl',
      label: 'national',
      show: true,
    },
    {
      key: 'total_ccl',
      label: 'total',
      show: true,
    },
    {
      key: 'total',
      label: 'total',
      show: true,
    },
  ]

  public hide_columns(group: string, hide: boolean = false) {
    this.bulk_year_sub_head_fields = this.bulk_year_sub_head_fields.map((item: any) => {
      if (item.key.includes(`_${group}`) && !item.key.includes(`total_${group}`)) {
        item.show = hide
      }

      return item
    })
  }

  public loadFields() {
    let ref = {
      key: 'label',
      label: '',
      sortable: false,
      filter: true,
      show: true,
      thClass: 'p-0  align-middle text-center border-right sub-cell-head',
      tdClass: 'sub-cell border-left align-middle text-center p-0 text-capitalize',
      thStyle: 'min-width:200px!important; width:200px!important',
    }

    let base: any = []
    // { text: 'Sales Rep', value: 'sales_rep' },
    // { text: 'Product', value: 'product' },
    // { text: 'Client', value: 'agency' },
    // { text: 'Region', value: 'region' },

    if (this.filter_by.scope.includes('sales_rep')) {
      base.push({
        ...ref,
        key: 'sales_rep',
        label: 'Sales Rep',
      })
    }

    if (this.filter_by.scope.includes('product')) {
      base.push({
        ...ref,
        key: 'product',
        label: 'Product',
      })
    }

    if (this.filter_by.scope.includes('agency')) {
      base.push({
        ...ref,
        key: 'agency',
        label: 'Client',
      })
    }

    if (this.filter_by.scope.includes('region')) {
      base.push({
        ...ref,
        key: 'region',
        label: 'Region',
      })
    }
    let reorder: any = []

    this.filter_by.scope.forEach((filter: any) => {
      let b = base.find((f: any) => f.key === filter)
      reorder.push(b)
    })
    base = reorder

    let concat: any = []

    if (this.sub_table_data[0]) {
      // get first obj to create fields and loop keys
      for (let res in this.sub_table_data[0]) {
        if (!['product', 'agency', 'sales_rep', 'region', 'label'].includes(res)) {
          concat.push({
            key: res,
            label: res,
            sortable: false,
            filter: false,
            show: true,
            thClass: 'p-0  align-middle text-center border-right sub-cell-head',
            tdClass: 'sub-cell border-left align-middle text-center p-0',
          })
        }
      }

      this.base_sub_table_fields = base.concat(concat)
    }
  }

  public get sub_table_fields() {
    let filtered = this.base_sub_table_fields.filter((b: any) => b.show)

    return filtered
  }

  public get isQuarterly() {
    return this.filter_by.period === 'quarterly'
  }

  public get isLoading() {
    return this._loading
  }

  public approve(name: string, description: string) {
    const api = new Api()

    return api.post(`financial_plans/approve/${this.year}`, {
      name,
      description,
    })
  }

  public toObject(source: any) {
    let instance = this.clone()

    Object.assign(instance, source)

    if (source.agency) {
      instance.agency = Company.toObject(source.agency)
    }

    if (source.sales_management) {
      instance.sales_management = User.toObject(source.sales_management)
    }

    if (source.sales_rep) {
      instance.sales_rep = User.toObject(source.sales_rep)
    }

    if (source.sub_plans) {
      instance.sub_plans = FinancialPlan.toObjectList(source.sub_plans)
    }

    return instance
  }

  public get apiData() {
    return {
      id: this.id,
      parent_id: this.parent_id,
      year: this.year,
      region: this.region,
      agency_id: this.agency_id,
      sales_management_id: this.sales_management_id,
      sales_rep_id: this.sales_rep_id,
      period_start_at: this.period_start_at
        ? moment(this.period_start_at).format('YYYY-MM-DD')
        : null,
      period_end_at: this.period_end_at ? moment(this.period_end_at).format('YYYY-MM-DD') : null,
      product: this.product,
      goal: this.goal,
      goal_planned: this.goal_planned,
      goal_achieved: this.goal_achieved,
      type: this.type,
      metrics: [], // TODO after the metric logic is added  chanch the way things is loaded by levels (scope), also remove the orWhere from the backend
      files: this.files,
      month_goals: this.month_goals,
    }
  }

  public static getPlan({
    year = null, region = null, agency = null, scope = null,
  }: any) {
    let api = new Api()
    let url: any = year
    if (region) url += `/${region}`
    if (agency) url += `/${agency}`

    return api
      .get(`financial_plans/${url}`, { scope })
      .then(response => FinancialPlan.toObjectList(response.data.result))
  }

  public clonePlan(source_year: number, increment: number) {
    let api = new Api()
    return api.post('financial_plans/clone', {
      source_year,
      target_year: this.year,
      increment,
    })
  }

  public static getPlanDashboard(payload: any) {
    let api = new Api()
    return api.post('financial_plans/dashboard', payload)
  }

  public static downloadDashboard(year: number, query: string) {
    let api = new Api()

    const instance_id = getModule(SystemtModule)._uuid

    return api.get(
      `financial_plans/export/${year}/${encodeURIComponent(query)}?instance_id=${instance_id}`,
    )
  }

  public static overview(
    year: number,
    regions: string[],
    products: string[] = ['ssl', 'ccl'],
    snapshot: any = null,
  ) {
    let api = new Api()
    return api.post(`financial_plans/overview/${year}`, { products, snapshot, regions })
  }

  public exportAnnualPlan() {
    let api = new Api()

    WebMessage.success('Generating Files, do not close this window!')

    const instance_id = getModule(SystemtModule)._uuid

    return api.get(`financial_plan/export/${this.year}?instance_id=${instance_id}`)
  }

  public subTableRow() {
    if (this.is_requesting) return
    let api = new Api()
    this._loading = true
    this.is_requesting = true
    return api
      .get('financial_inspection', {
        ...this.sub_table_settings.pagination,
        query: [`year:${this.year}`, `period:${this.filter_by.period}`],
        group: this.filter_by.scope,
      })
      .then(response => {
        let temp: any = []

        this.sub_table_settings.records = response.data.result.records

        response.data.result.financial_plans.forEach((f: any) => {
          let label = f.region
          if (this.filter_by.scope === 'agency') {
            label = f.agency.name ?? '-'
          } else if (this.filter_by.scope === 'product') {
            label = f.product ?? '-'
          }
          // remove unused to prevent extra columns in table

          if (this.filter_by.scope.includes('sales_rep')) {
            f.sales_rep = f.sales_rep.name
          }

          if (this.filter_by.scope.includes('agency')) {
            f.agency = f.agency.name
          }

          if (!this.filter_by.scope.includes('region')) {
            delete f.region
          }

          if (!this.filter_by.scope.includes('product')) {
            delete f.product
          }

          delete f.year
          delete f.agency_id
          delete f.sales_rep_id

          temp.push({
            label,
            ...f,
          })
        })

        this.sub_table_data = temp

        this.loadFields()

        setTimeout(() => {
          // to prevent double request
          this._loading = false
          this.is_requesting = false
        }, 300)

        return response.data.result
      })
      .catch(() => {
        this.is_requesting = false
        this._loading = false
      })
  }

  public syncGoal(params: any) {
    let api = new Api()

    WebMessage.success('Synchronizing Plan')

    const instance_id = getModule(SystemtModule)._uuid

    return api.post(`financial_plan_sync/${this.id}`, {
      instance_id,
      ...params,
    })
  }

  public static batchSave(items: FinancialPlan[]) {
    let api = new Api()
    return api.post('financial_plans/batch', { financial_plans: items.map(item => item.apiData) })
  }

  public static batchDelete(ids: string[]) {
    let api = new Api()
    return api.delete('financial_plans/batch', { ids })
  }

  public static calculateTotals(query: any) {
    let api = new Api()
    return api.get('financial_plans/calculate-goals', query)
  }

  public async importPlan() {
    let api = new Api()
    const formData = new FormData()

    buildFormData(formData, {
      ...this.apiData,
      model_files_meta: this.model_files_meta,
      model_files_binary: this.model_files_binary,
      instance_id: this.instance_id,
    })

    return api
      .form(`financial_plans/import-plan/${this.id}`, formData)
      .then(response => {
        // @ts-ignore
        WebMessage.success('File imported')
      })
      .catch(this.onError)
  }

  public AVAILABLE_PRODUCTS = ['ssl', 'ccl'] // TODO add more products to this list if so, update the code where usings products

  public AVAILABLE_REGIONS: Array<any> = RegionOptions.map(op => op.value)

  public PRODUCT_SETIONS = this.AVAILABLE_REGIONS.concat('total') // total, must be last index in array

  public buildGoalPerMonthArray() {
    return moment.months().map((month: string) => {
      let obj: any = {
        month,
        total: 0,
      }

      for (let product of this.AVAILABLE_PRODUCTS) {
        obj[product] = []
      }

      return obj
    })
  }

  public async financeGoal() {
    let api = new Api()
    return api
      .get(`financial_plan/finance_goal/${this.id}`)
      .then(response => FinancialPlanProductModule.init().getGoalPerMonth(response.data.result))
  }

  public async salesRepConsolidatedGoals() {
    let api = new Api()
    return api
      .get(`financial_plan/sales-consolidated/${this.id}`, {
        ...this.sub_table_settings.pagination,
      })
      .then(response => {
        this.sub_table_data = response.data.result.financial_plans

        this.loadFields()

        setTimeout(() => {
          // to prevent double request
          this._loading = false
          this.is_requesting = false
        }, 300)
        return true
      })
  }

  public async salesRepConsolidatedInspection(id: any) {
    if (this.is_requesting) return
    let api = new Api()
    this._loading = true
    this.is_requesting = true
    return api.get(`financial_plan/sales-consolidated/${id}/${this.year}`).then(response => {
      setTimeout(() => {
        this._loading = false
        this.is_requesting = false
      }, 300)
      return response.data.result
    })
  }

  public async saveSalesRepConsolidation(sales_rep_id: any, payload: any) {
    if (this.is_requesting) return
    let api = new Api()
    this._loading = true
    this.is_requesting = true
    return api.post(`financial_plan/save-consolidated/${sales_rep_id}/${this.year}`, { payload })
  }
}
