import SalesActivity from '@/models/SalesActivity'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import moment from 'moment'
import numeral from 'numeral'
import { FILTERS } from '../../helpers'

export default class SalesActivitySummaryWidget extends SystemDashboardWidget {
  public title: string = 'Targeting Ops'

  public key: string = 'target-ops-summary'

  public view: string = 'card'

  public size = {
    lg: 4,
    md: 4,
    sm: 12,
  }

  public format_totals: any = '0,0'

  public dimensions: string[] = []

  public metrics: string[] = []

  public dataSourceRequest: any = null

  constructor(args: any = null) {
    super()

    if (args && args.dimensions) {
      this.dimensions = args.dimensions
    }

    if (args && args.metrics) {
      this.metrics = args.metrics
    }

    if (args && args.filters) {
      this.filters = Object.assign(this.filters, args.filters)
    }
    if (args && args.internalFilters) {
      this.internalFilters = Object.assign(this.internalFilters, args.internalFilters)
    }
    if (args && args.internalQuery) {
      this.internalQuery = Object.assign(this.internalQuery, args.internalQuery)
    }

    if (args && args.rightCol) {
      this.rightCol = args.rightCol
    }

    if (args && args.leftCol) {
      this.leftCol = args.leftCol
    }

    if (args && args.size) {
      this.size = args.size
    }

    if (args && args.title) {
      this.title = args.title
    }

    if (args && args.key) {
      this.key = args.key
    }

    if (args && args.dataSourceRequest) {
      this.dataSourceRequest = args.dataSourceRequest
    }

    // group
    if (args && args.groups) {
      this.groups = Object.assign(this.groups, args.groups)
    }

    // internalGroups
    if (args && args.internalGroups) {
      this.internalGroups = Object.assign(this.internalGroups, args.internalGroups)
    }
    // format_totals
    if (args && args.format_totals) {
      this.format_totals = args.format_totals
    }
  }

  public internalFilters: any = {
    ...FILTERS.YEAR,
  }

  public filters: any = {
    product: 'all',
    region: 'all',
  }

  public internalQuery: any = ['summary:30,7']

  public get filterToQuery() {
    let query = []

    if (this.filters.product !== 'all') {
      query.push(`product:${this.filters.product}`)
    }

    if (this.filters.region !== 'all') {
      query.push(`region:${this.filters.region}`)
    }

    // query.push(`year:${this.internalFilters.year}`)

    return query
  }

  public get groupList() {
    let group_mode = this.internalGroups.group_mode
    let group_role = this.internalGroups.group_role === 'region' ? 'team' : 'individual'
    return {
      group_mode,
      group_role,
    }
  }

  public get payload() {
    const query = [...new Set([...this.query, ...this.internalQuery, ...this.filterToQuery])]
    const filters = {
      ...this.internalFilters,
    }

    return {
      query,
      //   filters,
      metrics: this.metrics,
      dimensions: this.dimensions,
      ...this.groupList,
      start: this.internalFilters.start,
      end: this.internalFilters.end,
    }
  }

  public dataSource() {
    if (this.dataSourceRequest) {
      const res = this.dataSourceRequest(this.payload)

      return res
    }
    let request = new SalesActivity()

    return request
      .targetOps({ module: this.payload, ignore_logic: true })
      .then((res: any) => res.result)
  }

  protected dataMapper(data: any): Promise<any> {
    const last_30 = data.last_30 ? numeral(data.last_30).format(this.format_totals) : 0
    const last_7 = data.last_7 ? numeral(data.last_7).format(this.format_totals) : 0
    const this_quarter = data.this_quarter
      ? numeral(data.this_quarter).format(this.format_totals)
      : 0
    const last_quarter = data.last_quarter
      ? numeral(data.last_quarter).format(this.format_totals)
      : 0

    let ret = ` 
    <div class="row text-center h-50 align-items-center" style="margin-top:150px;">
        <div class="col">
          <h4>Last 7 days</h4>
          <h4>${last_7}</h4>
        </div>
        <div class="col">
          <h4>Last 30 Days <small>(AVG/week)</small></h4>
          <h4>${last_30}</h4>
        </div>
      </div>
       <div class="row text-center align-items-center mt-5">
        <div class="col">
          <h4>Last Quarter <small>(AVG/week)</small></h4>
          <h4>${last_quarter}</h4>
        </div>
        <div class="col">
          <h4>This Quarter <small>(AVG/week)</h4>
          <h4>${this_quarter}</h4>
        </div>
      </div>
    `

    return Promise.resolve(ret)
  }
}
