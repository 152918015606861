import SelectOption from '@/models/interface/SelectOption'
import SystemDashboardAction from '@/models/SystemDashboardAction'

export default class PlannedActualSwitch extends SystemDashboardAction {
  // Target Property to be changed or event name
  public target: string = 'groups'

  // Unique key for the action
  public key: string = 'view_by'

  // Label for the action
  public label: string = 'View By'

  public type: string = 'radio'

  // Options for the action if applicable
  public options: SelectOption[] = [
    new SelectOption('Planned', 'planned'),
    new SelectOption('Actual', 'actual'),
  ]
}
