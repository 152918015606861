
import PageHeader from '@/components/PageHeader/PageHeader.vue'
import ViewModel from '@/models/ViewModel'
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import VueApexCharts from 'vue-apexcharts'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import WebMessage from '@/models/WebMessage'
import SelectOption from '@/models/interface/SelectOption'
import User from '@/models/User'
import CustomIcon from '@/components/CustomIcon/CustomIcon.vue'
import ReportBuilder from '@/models/ReportBuilder'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import Opportunity from '@/models/Opportunity'
import moment from 'moment'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import widgets from './sales-activity-widgets'
import DashboardWidget from '../components/DashboardWidget.vue'

@Component({
  components: {
    PageHeader,
    Widget,
    VueApexCharts,
    IconAction,
    DashboardWidget,
    SearchInput,
    CustomIcon,
    SelectPicker,
  },
})
export default class SalesWeekly extends ViewModel {
  @Ref() searchInput!: SearchInput

  public year: number = moment().year()

  public region: string | null = ''

  public sales_rep: string | null = null

  @Prop({ default: null })
  public agency!: string | null

  @Prop({ default: () => '' })
  public tabKey!: string

  public ready: boolean = false

  // Search Input
  public query: string[] = []

  public report_ready: boolean = false

  public local_user: User = new User()

  public get snapshot_id() {
    return this.$route.query.snapshot
  }

  public get snapshot_revision() {
    return this.$route.query.revision
  }

  public get is_snapshot() {
    return !!this.snapshot_id
  }

  public get snapshot_query() {
    return this.is_snapshot ? { snapshot: this.snapshot_id } : {}
  }

  public get has_cross_region_access() {
    return this.local_user!.canOptions('management_dashboard', 'cross_region')
  }

  public report_channel: any = null

  private debug_key: string | null = null

  // private debug_key: string | null = 'closed-business-this-week'

  public query_settings = {
    company_fields: [
      {
        name: 'agency',
        key: 'agency',
        type: 'agency',
      },
      {
        name: 'advertiser',
        key: 'advertiser',
        type: 'advertiser',
      },
    ],
    user_fields: [
      {
        name: 'sm',
        key: 'sales_management_id',
      },
      {
        name: 'sr',
        key: 'sales_rep_id',
      },
    ],
    custom_fields: [
      {
        name: 'product:ssl',
        value: 'product:ssl',
      },
      {
        name: 'product:ccl',
        value: 'product:ccl',
      },
      {
        name: 'deal_type:direct',
        value: 'deal_type:direct',
      },
      {
        name: 'deal_type:3rd Party',
        value: 'deal_type:3rd_party',
      },
    ],
  }

  public modal = {
    pipeline: false,
  }

  public pipeline_export_mode: string = 'consolidated'

  public pipeline_export_options: SelectOption[] = [
    new SelectOption('Advertiser & Agency', 'consolidated'),
    new SelectOption('Owner, Opportunity, Advertiser & Agency', 'sales_rep'),
    new SelectOption('Region, Opportunity, Advertiser & Agency', 'region'),
  ]

  public product_filter_options: any = [
    { text: 'SSL', value: 'ssl' },
    { text: 'CCL', value: 'ccl' },
    { text: 'All', value: 'all' },
  ]

  public region_filters: any = [
    { text: 'National', value: 'national' },
    { text: 'East', value: 'east' },
    { text: 'West', value: 'west' },
    { text: 'Midwest', value: 'midwest' },
    { text: 'All', value: '' },
  ]

  public get product_filter() {
    if (
      (this.query.includes('product:ssl') && this.query.includes('product:ccl'))
      || (!this.query.includes('product:ssl') && !this.query.includes('product:ccl'))
    ) {
      return 'all'
    }

    if (this.query.includes('product:ccl')) {
      return 'ccl'
    }

    return 'ssl'
  }

  public set product_filter(value: string) {
    let query = this.query.filter((i: string) => !i.startsWith('product:'))

    if (value === 'ccl') {
      query = [...query, 'product:ccl']
    } else if (value === 'ssl') {
      query = [...query, 'product:ssl']
    }
    this.query = [...query]
  }

  public get widgets() {
    if (!this.debug_key) return widgets.map((w: any) => this.injectBeforeLoad(w))
    return widgets
      .filter((w: any) => w.key === this.debug_key)
      .map((w: any) => this.injectBeforeLoad(w))
  }

  public injectBeforeLoad(widget: any) {
    let list = ['pitched-amount', 'closed-amount', 'activity-kpis']

    if (list.includes(widget.key)) {
      let modes = ['group_mode:week', 'group_mode:month', 'group_mode:quarter']

      this.query.forEach((q: string) => {
        if (modes.includes(q)) {
          widget.filters.group_mode = q
        }
      })
    }

    return widget
  }

  public addFilter(data: any) {
    if (!data || !data.filter || !data.name) return

    if (this.query.includes(data.filter)) return

    if (
      data.filter.startsWith('sm:')
      || data.filter.startsWith('sr:')
      || data.filter.startsWith('agency:')
      || data.filter.startsWith('advertiser:')
    ) {
      this.searchInput.addCustomTag(new SelectOption(data.name, data.filter))
    }

    Vue.set(this, 'query', [...this.query, data.filter])
  }

  public updateReportReady() {
    const result = this.ready
      && !this.loading
      && this.searchInput
      && this.searchInput.ready
      && !this.searchInput.busy
      && this.widgets.every(
        // @ts-ignore
        (w: any) =>
          this.$refs[w.key]
          // @ts-ignore
          && this.$refs[w.key][0]
          // @ts-ignore
          && this.$refs[w.key][0].ready
          // @ts-ignore
          && !this.$refs[w.key][0].loading,
      )

    if (result) {
      // Delay is required to allow the page/charts to render
      setTimeout(() => {
        this.report_ready = true
      }, 5000)
    } else {
      setTimeout(() => {
        this.updateReportReady()
      }, 500)
    }
  }

  public resetReportReady() {
    this.report_ready = false

    this.updateReportReady()
  }

  public mounted() {
    const { query } = this.$route

    if (query.target && typeof query.target === 'string') {
      User.find(query.target).then((user: User) => {
        this.local_user = user
        this.init()
      })
    } else {
      this.local_user = this.user
      this.init()
    }
  }

  public init() {
    const { query } = this.$route
    this.registerEvent()

    if (this.user.region) {
      this.region = this.user.region
    } else if (!this.has_cross_region_access) {
      this.region = 'none'
    }

    if (this.local_user.manager_id) {
      this.sales_rep = this.local_user.id
    }

    if (query.query) {
      // @ts-ignore
      this.query = typeof query.query === 'string' ? [query.query] : [...query.query]
    }

    for (const q of this.query) {
      const [key, value] = q.split(':')
      if (this.hasOwnProperty(key)) {
        Vue.set(this, key, value)
      }
    }
    // Load filters
    this.ready = true

    this.resetReportReady()
  }

  private buildParams() {
    const params: any = {
      year: this.year,
      query: this.query,
    }

    return params
  }

  public get query_params() {
    const query: any = []
    const params = this.buildParams()
    Object.keys(params).forEach((key: string) => {
      // @ts-ignore
      if (Array.isArray(params[key])) {
        // @ts-ignore
        params[key].forEach((i: string) => {
          query.push({
            name: key,
            value: i,
          })
        })
      } else {
        query.push({
          name: key,
          // @ts-ignore
          value: params[key],
        })
      }
    })

    if (this.is_snapshot) {
      query.push({
        name: 'snapshot',
        value: this.snapshot_id,
      })
    }

    return query.reduce((q: string, i: any) => {
      if (q) {
        q += '&'
      }

      return `${q + i.name}=${i.value}`
    }, '')
  }

  public updateData() {
    this.ready = false

    setTimeout(() => {
      this.resetReportReady()
      this.ready = true
    }, 500)
  }

  @Watch('region')
  public onRegionChange() {
    this.updateData()
  }

  public resetFilters() {
    this.query = []
  }

  public download() {
    this.$emit('download', { ...this.buildParams(), key: this.tabKey })
  }

  public exportPipeline() {
    this.modal.pipeline = true
  }

  public confirmPipelineExport() {
    let report = new ReportBuilder()

    report.type = 'pipeline_report'
    report.dimensions = ['advertiser', 'agency']

    if (this.pipeline_export_mode === 'sales_rep') {
      report.dimensions = ['sales_rep', 'opportunity', 'advertiser', 'agency']
    } else if (this.pipeline_export_mode === 'region') {
      report.dimensions = ['region', 'opportunity', 'advertiser', 'agency']
    }

    report.metrics = []
    report.filters = []
    report.period_picker.start = `${this.year}-01-01`
    report.period_picker.end = `${this.year}-12-31`
    report.period_picker.shortcut = null
    report.period_filter = 'none'

    if (this.query.length > 0) {
      WebMessage.confirm(
        'You currently have filters applied. Do you want keep them or download the full pipeline?',
        'Pipeline Filters',
        { okTitle: 'Keep Filter', cancelTitle: 'Clear Filters' },
      ).then((value: boolean) => {
        WebMessage.success('Generating Pipeline report. Please wait for the download to start.')
        if (value) {
          this.query.forEach((i: string) => {
            let key = i.split(':')[0]
            let value = i.split(':')[1]

            if (key == 'sm') {
              key = 'sales_management'
            } else if (key == 'sr') {
              key = 'sales_rep'
            }

            if (!report.filters[key]) {
              report.filters[key] = []
            }

            report.filters[key].push(value)
          })
        }

        report.download().catch(() => {
          WebMessage.error('There was an error generating your report. Please try again later.')
        })
      })

      return
    }
    WebMessage.success('Generating Pipeline report. Please wait for the download to start.')

    report.download().catch(() => {
      WebMessage.error('There was an error generating your report. Please try again later.')
    })
  }

  public exportWeekClosedOpportunities() {
    let query: any = []
    if (this.query.length > 0) {
      WebMessage.confirm(
        'You currently have filters applied. Do you want keep them or download all opportunities closed this week?',
        'Pipeline Filters',
        { okTitle: 'Keep Filter', cancelTitle: 'Clear Filters' },
      ).then((value: boolean) => {
        WebMessage.success('Generating report. Please wait for the download to start.')

        if (value) {
          query = this.query
        }

        Opportunity.exportWeekClosedOpportunities({ query }).catch(() => {
          WebMessage.error('There was an error generating your report. Please try again later.')
        })
      })

      return
    }

    Opportunity.exportWeekClosedOpportunities({ query }).catch(() => {
      WebMessage.error('There was an error generating your report. Please try again later.')
    })
    WebMessage.success('Generating report. Please wait for the download to start.')
  }

  /**
   * Unregister event listeners
   */
  public beforeDestroy() {
    this.unregisterEvent()
  }

  /**
   * Capture the report data returned in the channel
   */
  private registerEvent() {
    this.report_channel = Vue.prototype.$echo
      .private(`user.${this.user!.id}`)
      .listen('AsyncReportReady', (e: any) => {
        const instance_id = getModule(SystemtModule)._uuid

        if (instance_id !== e.instance_id) return

        if (!e.report_hash) {
          this.loading = false

          return
        }

        ReportBuilder.getReport(e.report_hash).catch(error => {
          this.loading = false
        })
      })
  }

  /**
   * Clear report channel
   */
  private unregisterEvent() {
    Vue.prototype.$echo.private(`user.${this.user!.id}`).stopListening('AsyncReportReady')
  }
}
