import SystemtModule from '@/store/SystemModule'
import moment from 'moment'
import numeral from 'numeral'
import { getModule } from 'vuex-module-decorators'

const company_id = 'f37c9dd6-4776-4122-8a37-37d67f4ff47a'

export default [
  {
    title: 'Real-time Today',
    key: 'realtime',
    source: 'company_realtime',
    type: 'custom',
    size: {
      lg: 12,
      md: 12,
    },
    show: () => {
      const system = getModule(SystemtModule)

      return !system.user?.isSystem
    },
    dimensions: [],
    capitalize_labels: true,
    metrics: ['delivered', 'goal'],
    filters: {
      company_id,
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => {
        const impressions = numeral(data.impressions).format('0.00a')

        const hits = numeral(data.hits).format('0.00a')

        const requests = numeral(data.requests).format('0.00a')

        const miss = numeral(data.miss).format('0.00a')

        const rate_fill = numeral(
          data.miss > 0 && data.requests > 0 ? data.hits / data.requests : 1,
        ).format('0.00%')

        const rate_303 = numeral(
          data.impressions > 0 && data.miss > 0 ? data.miss / data.impressions : 0,
        ).format('0.00%')

        const rate_delivery = numeral(
          data.impressions > 0 && data.requests > 0 ? data.impressions / data.requests : 0,
        ).format('0.00%')

        const updated_at = moment(data.updated_at).format('MMM DD, YYYY hh:mm A')

        return `<div class="row text-center mt-5">
          <div class="col">
            <h6>Requests</h6>
            <h4>${requests}</h4>
          </div>
          <div class="col">
            <h6>303s</h6>
            <h4>${miss}</h4>
          </div>
          <div class="col">
            <h6>Impressions</h6>
            <h4>${impressions}</h4>
          </div>
          <div class="col">
            <h6>Fill Rate</h6>
            <h4>${rate_fill}</h4>
          </div>
          <div class="col">
            <h6>Delivery Rate</h6>
            <h4>${rate_delivery}</h4>
          </div>
          <div class="col">
          <h6>303 Rate</h6>
          <h4>${rate_303}</h4>
        </div>
        </div>
        <div class="text-center text-muted mt-3">
          Updated at: ${updated_at}
        </div>
        `
      },
    },
  },
  {
    title: 'Delivery Status Report',
    source: 'company_goal_metrics',
    key: 'mlb-delivery-status',
    type: 'table',
    size: {
      lg: 8,
      md: 12,
    },
    dimensions: ['quarter'],
    capitalize_labels: true,
    config: {
      noPagination: true,
      noTotal: true,
      fields: [
        {
          key: 'period',
          label: 'Period',
          class: 'text-center align-middle',
          type: 'uppercase',
          show: true,
          thClass: 'align-middle text-center',
          sortable: false,
        },
        {
          key: 'impressions',
          label: 'Impressions',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'abbreviate',
          sortable: false,
          total: 'abbreviate',
        },
        {
          key: 'cpm',
          label: 'MLB CPM',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency',
          sortable: false,
          total: 'currency',
        },
        {
          key: 'earned_revenue',
          label: 'MLB Earned Revenue',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: false,
          total: 'currency_abbreviate',
        },
        // {
        //   key: 'sold',
        //   label: 'Total CIM Sold Impressions',
        //   class: 'text-center align-middle',
        //   show: true,
        //   thClass: 'align-middle text-center',
        //   type: 'abbreviate',
        //   sortable: false,
        //   total: 'abbreviate',
        // },
      ],
    },
    metrics: [
      'goal',
      'goal_pipeline',
      'goal_sold',
      'goal_achieved',
      'last_year_goal',
      'last_year_goal_pipeline',
      'last_year_goal_sold',
      'last_year_goal_achieved',
    ],
    filters: {
      company_id,
    },
  },
  {
    title: 'Delivered vs Goal',
    key: 'mlb-gauge',
    source: 'company_goal_status',
    type: 'gauge_number',
    size: {
      lg: 4,
      md: 12,
    },
    dimensions: [],
    capitalize_labels: true,
    metrics: ['delivered', 'goal'],
    filters: {
      company_id,
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => {
        const delivered = numeral(data[0].delivered).format('0.00a')
        const goal = numeral(data[0].goal).format('0.00a')
        const start_at = data[0].start_at ? moment(data[0].start_at).format('MMM DD, YYYY') : ''
        const end_at = data[0].end_at ? moment(data[0].end_at).format('MMM DD, YYYY') : ''

        const date = start_at && end_at ? `${start_at} - ${end_at}` : ''

        return `<div class="row text-center">
          <div class="col">
            <h6>Delivered</h6>
            <h4>${delivered}</h4>
          </div>
          <div class="col">
            <h6>Goal</h6>
            <h4>${goal}</h4>
          </div>
        </div>
        <div class="text-center text-muted">
          ${date}
        </div>
        `
      },
    },
  },
  {
    title: 'Last 30 days',
    key: 'last-30-days',
    source: 'company_goal_30_days',
    type: 'bar',
    size: {
      lg: 12,
      md: 12,
    },
    capitalize_labels: false,
    config: {
      date_dimension_format: 'YYYY-MM-DD',
      date_format_labels: 'MMM DD, YYYY',
      custom_label: 'Impressions',
    },
    dimensions: ['date'],
    metrics: ['impressions'],
    filters: {
      company_id,
    },
  },
] as any[]
