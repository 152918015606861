import numberOfAdvertisers from './widgets/number-of-advertisers'
import numberOfAgencies from './widgets/number-of-agencies'
import numberOfMediaplanWon from './widgets/number-of-mediaplan-won'
import numberOfMediaplans from './widgets/number-of-mediaplans'
import numberOfRevenueAssigned from './widgets/number-of-revenue-assigned'
import numberOfTasks from './widgets/number-of-tasks'
import pendingTask from './widgets/pending-task'

export default [
  numberOfTasks,
  pendingTask,
  numberOfMediaplans,
  numberOfRevenueAssigned,
  numberOfMediaplanWon,
  numberOfAdvertisers,
  numberOfAgencies,
]
