import SalesActivity from '@/models/SalesActivity'
import { buildColorPool } from '@/models/Util'
import generateSummaryCustomHtml from '@/pages/ManagementDashboard/scripts/summary-html'
import { FILTERS, normalizeLabel } from '@/pages/ManagementDashboard/shared/helpers'

import numeral from 'numeral'

export default {
  title: 'Meetings',
  key: 'meetings-summary',
  source: 'request',
  request: {
    module: new SalesActivity(),
    exec: 'meetings',
  },
  pre_load: {},
  type: 'custom',
  size: {
    lg: 4,
    md: 4,
    sm: 12,
  },
  config: {
    dynamic_date: true,
    totalFormat: '0.00a',
    custom_data: (data: any) => generateSummaryCustomHtml(data, { format: '0,0' }),
  },
  dimensions: ['date', 'activity'],
  capitalize_labels: true,
  metrics: ['amount'],
  filters: {
    dynamic_filter: {
      ...FILTERS.YEAR,
    },
    group_mode: 'month',
    group_role: 'individual',
  },
  query: ['summary:30,7'],
  order: {},
  dynamic_options: [],
  formatters: {},
}
