// htmlGenerator.ts
import { clone } from 'lodash'
import numeral from 'numeral'

const getColor = (val: any) => {
  if (val) {
    return `text-${val}`
  }
}

export default function simpleCard(data: any, settings: any): string {
  let COLUMN_CLASS: any = 'col-sm-12 col-md-3'
  let ROW_CLASS: any = ''

  let no_result: boolean = false

  if (!data.length) {
    no_result = true
  }

  if (settings.columnClass) {
    COLUMN_CLASS = settings.columnClass
  }
  if (settings.rowClass) {
    ROW_CLASS = settings.rowClass
  }

  if (settings.formatWhereKey) {
    for (const key in settings.formatWhereKey) {
      if (data.hasOwnProperty(key)) {
        data[key] = numeral(data[key]).format(settings.formatWhereKey[key])
      }
    }
  }

  if (settings.overview) {
    let cards: any = ''

    let payload = Object.entries(data)
    for (let index = 0; index < payload.length; index++) {
      const element = payload[index]
      const key = element[0]
      let title = element[0]
      const value = element[1]

      if (title !== 'reforecast_name') {
        if (title.includes('%%') && data.reforecast_name) {
          // extract tag wrapped by %%
          const tag = title.match(/%%(.*?)%%/g)

          if (tag) {
            const replace_key = tag[0].replaceAll('%%', '')
            if (data[replace_key]) {
              title = title.replace(tag[0], data[replace_key])
            }
          }
        }

        // format title
        title = title.replaceAll('_', ' ')

        if (settings.loading) {
          cards += `
       <div class="${COLUMN_CLASS}">
            <div class="card p-4 border-0">
                 <div style="width:150px;height:25px;" class="b-skeleton b-skeleton-text b-skeleton-animate-wave ${
  settings.loading ? 'd-block' : 'd-none'
}"></div>
                <div class="text-center p-4">
                    <div class="d-flex justify-content-center">
                     <div style="min-width:100px;min-height:18px;" class="b-skeleton b-skeleton-text b-skeleton-animate-wave ${
  settings.loading ? 'd-block' : 'd-none'
}"></div>
                    </div>
                </div>
            </div>
        </div>
        `
        } else {
          let color = settings.valueColors[element[0]]

          if (color && typeof color !== 'string') {
            color = color(value)
          }

          color = getColor(color)

          cards += `
        <div class="${COLUMN_CLASS} ">
            <div class="${settings.noCard || 'card'} p-4 border-0  h-100">
                <h4 class="text-capitalize">${title}</h4>
                <div class="text-center p-4 ${color}">
                    <h4>${value}</h4>
                </div>
            </div>
        </div>
        `
        }
      }
    }

    return `
        <div class="row ${ROW_CLASS}">
            ${cards}
        </div>
        `
  }

  return ` 
    <div class="row text-center h-50 align-items-center mt-4">
        <div class="col">
              <h4>0</h4>
        </div>
    </div>
  `
}
