
import { Component, Prop, Vue } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'

@Component
export default class TableColLink extends ViewModel {
  @Prop({ required: true }) link!: any

  @Prop({ required: true }) innerText!: any
}
