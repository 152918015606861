import SelectOption from '@/models/interface/SelectOption'
import SystemDashboardAction from '@/models/SystemDashboardAction'

export default class PeriodGroup extends SystemDashboardAction {
  // Target Property to be changed or event name
  public target: string = 'groups'

  // Unique key for the action
  public key: string = 'period'

  // Label for the action
  public label: string = 'Group By'

  // Options for the action if applicable
  public options: SelectOption[] = [
    new SelectOption('Week', 'week'),
    new SelectOption('Month', 'month'),
    new SelectOption('Quarter', 'quarter'),
  ]

  constructor(args: any = null) {
    super()
    if (args && args.pushOption) {
      this.options.push(args.pushOption)
    }
    if (args && args.concatOptions) {
      let mapped = args.concatOptions.map((opt: any) => new SelectOption(opt.text, opt.value))
      this.options = this.options.concat(mapped)
    }
    if (args && args.target) {
      this.target = args.target
    }

    if (args && args.key) {
      this.key = args.key
    }
  }
}
