
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import moment from 'moment'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import SelectOption from '@/models/interface/SelectOption'
import WebMessage from '@/models/WebMessage'
import ReportBuilder from '@/models/ReportBuilder'
import CustomIcon from '@/components/CustomIcon/CustomIcon.vue'
import { EventBus } from '@/plugins/eventBus'
import Widgets from './SalesActivities/widget-list'
import DashboardWidget from '../components/DashboardWidget.vue'

@Component({
  components: {
    IconAction,
    SearchInput,
    DashboardWidget,
    SelectPicker,
    CustomIcon,
  },
})
export default class SalesActivityDashboard extends ViewModel {
  @Prop({ default: null })
  public agency!: string | null

  @Prop({ default: () => '' })
  public tabKey!: string

  public ready: boolean = false

  public query: string[] = []

  public report_ready: boolean = false

  public query_settings = {
    company_fields: [
      {
        name: 'agency',
        key: 'agency',
        type: 'agency',
      },
      {
        name: 'advertiser',
        key: 'advertiser',
        type: 'advertiser',
      },
    ],
    custom_fields: [
      {
        name: 'product:ssl',
        value: 'product:ssl',
      },
      {
        name: 'product:ccl',
        value: 'product:ccl',
      },
      {
        name: 'deal_type:direct',
        value: 'deal_type:direct',
      },
      {
        name: 'deal_type:3rd Party',
        value: 'deal_type:3rd_party',
      },
    ],
    user_fields: [
      {
        name: 'sr',
        key: 'sales_rep_id',
      },
    ],
  }

  public modal = {
    pipeline: false,
  }

  public pipeline_export_mode: string = 'consolidated'

  public pipeline_export_options: SelectOption[] = [
    new SelectOption('Advertiser & Agency', 'consolidated'),
    new SelectOption('Owner, Opportunity, Advertiser & Agency', 'sales_rep'),
    new SelectOption('Region, Opportunity, Advertiser & Agency', 'region'),
  ]

  public product_filter_options: any = [
    { text: 'SSL', value: 'ssl' },
    { text: 'CCL', value: 'ccl' },
    { text: 'All', value: 'all' },
  ]

  public region_filters: any = [
    { text: 'National', value: 'national' },
    { text: 'East', value: 'east' },
    { text: 'West', value: 'west' },
    { text: 'Midwest', value: 'midwest' },
    { text: 'All', value: '' },
  ]

  public group_roles = [
    { text: 'Region', value: 'team' },
    { text: 'Sales Rep', value: 'individual' },
  ]

  public get product_filter() {
    if (
      (this.query.includes('product:ssl') && this.query.includes('product:ccl'))
      || (!this.query.includes('product:ssl') && !this.query.includes('product:ccl'))
    ) {
      return 'all'
    }

    if (this.query.includes('product:ccl')) {
      return 'ccl'
    }

    return 'ssl'
  }

  public set product_filter(value: string) {
    let query = this.query.filter((i: string) => !i.startsWith('product:'))

    if (value === 'ccl') {
      query = [...query, 'product:ccl']
    } else if (value === 'ssl') {
      query = [...query, 'product:ssl']
    }
    // Vue.set(this, 'query', [...query])
    this.query = [...query]
  }

  private debug_key: string | null | string[] = [
    // 'target-ops',
  ]

  public year: number = moment().year()

  public region: string | null = ''

  public sales_rep: string | null = null

  public group_role: any = 'team'

  @Watch('group_role')
  public groupRoleChange(val: string) {
    EventBus.$emit('sales-activity:group_role', val)
  }

  public get widgets() {
    if (!this.debug_key || (Array.isArray(this.debug_key) && !this.debug_key.length)) return Widgets.map((w: any) => this.injectBeforeLoad(w))
    return Widgets.filter((w: any) =>
      (Array.isArray(this.debug_key) ? this.debug_key.includes(w.key) : w.key === this.debug_key)).map((w: any) => this.injectBeforeLoad(w))
  }

  // TODO james continue here
  public injectBeforeLoad(widget: any) {
    const group_modes = ['group_mode:week', 'group_mode:month', 'group_mode:quarter']
    const keysToCheck = [
      'meetings',
      'pitched-revenue',
      'sold-per',
      'touch-points',
      'target-ops',
      'total-pitched',
      'pipeline-movement',
    ]
    if (keysToCheck.includes(widget.key)) {
      widget.filters = Object.assign(widget.filters, {
        group_role: this.group_role,
        region: this.region,
      })

      const found_group_mode = this.query.find((i: string) => group_modes.includes(i))
      if (found_group_mode) {
        widget.filters.group_mode = found_group_mode.split(':')[1]
      }
    }

    return widget
  }

  public get snapshot_id() {
    return this.$route.query.snapshot
  }

  public mounted() {
    this.init()
  }

  public beforeDestroy() {
    EventBus.$destroy()
  }

  public updateData() {
    this.ready = false
    setTimeout(() => {
      this.ready = true
    }, 1000)
  }

  @Watch('region')
  public onRegionChange() {
    this.updateData()
  }

  public resetFilters() {
    this.query = []
  }

  public download() {
    this.$emit('download', { key: this.tabKey })
  }

  public addFilter(data: any) {}

  public exportPipeline() {
    this.modal.pipeline = true
  }

  public confirmPipelineExport() {
    let report = new ReportBuilder()

    report.type = 'pipeline_report'
    report.dimensions = ['advertiser', 'agency']

    if (this.pipeline_export_mode === 'sales_rep') {
      report.dimensions = ['sales_rep', 'opportunity', 'advertiser', 'agency']
    } else if (this.pipeline_export_mode === 'region') {
      report.dimensions = ['region', 'opportunity', 'advertiser', 'agency']
    }

    report.metrics = []
    report.filters = []
    report.period_picker.start = `${this.year}-01-01`
    report.period_picker.end = `${this.year}-12-31`
    report.period_picker.shortcut = null
    report.period_filter = 'none'

    if (this.query.length > 0) {
      WebMessage.confirm(
        'You currently have filters applied. Do you want keep them or download the full pipeline?',
        'Pipeline Filters',
        { okTitle: 'Keep Filter', cancelTitle: 'Clear Filters' },
      ).then((value: boolean) => {
        WebMessage.success('Generating Pipeline report. Please wait for the download to start.')
        if (value) {
          this.query.forEach((i: string) => {
            let key = i.split(':')[0]
            let value = i.split(':')[1]

            if (key == 'sm') {
              key = 'sales_management'
            } else if (key == 'sr') {
              key = 'sales_rep'
            }

            if (!report.filters[key]) {
              report.filters[key] = []
            }

            report.filters[key].push(value)
          })
        }

        report.download().catch(() => {
          WebMessage.error('There was an error generating your report. Please try again later.')
        })
      })

      return
    }
    WebMessage.success('Generating Pipeline report. Please wait for the download to start.')

    report.download().catch(() => {
      WebMessage.error('There was an error generating your report. Please try again later.')
    })
  }

  public init() {
    const { query } = this.$route

    if (query.query) {
      // @ts-ignore
      this.query = typeof query.query === 'string' ? [query.query] : [...query.query]
    }

    for (const q of this.query) {
      const [key, value] = q.split(':')
      if ({ ...this }.hasOwnProperty(key)) {
        Vue.set(this, key, value)
      }
    }
    this.ready = true
  }
}
