import HamiltonModule from '@/models/HamiltonModule'
import { buildColorPool } from '@/models/Util'
import { FILTERS, normalizeLabel } from '@/pages/ManagementDashboard/shared/helpers'
import numeral from 'numeral'

export default {
  title: 'MTD Through Yesterday',
  key: 'pie-mtd-yesterday-total',
  source: 'request',
  request: {
    module: new HamiltonModule(),
    exec: 'todayTotal',
  },
  pre_load: {},
  type: 'pie',
  size: {
    lg: 3,
    md: 3,
  },
  capitalize_labels: true,

  metrics: ['impressions'],
  dimensions: ['device_type'],
  query: [],
  filters: {
    dynamic_filter: {
      ...FILTERS.MTD_THROUGH_YESTERDAY,
    },
  },
  order: {},
  labels: ['device_type'],
  dynamic_options: {},
  formatters: {
    dataLabelFormatter: (value: number, labels: string[], series: number[], a: number, opt: any) => {
      let total = series.reduce((acc: number, item: any) => (acc += item), 0)
      return `${numeral(series[opt.seriesIndex] / total).format('0.00%')}`
    },
    tooltipFormatter: (value: number, labels: string[], series: number[], __: number, opt: any) => {
      let total = series.reduce((acc: number, item: any) => (acc += item), 0)
      return [`${numeral(value).format('0.00a')}`,
        `${numeral(series[opt.seriesIndex] / total).format('0.00%')}`]
    },
  },
  mapper: (data: any[], settings: any) => {
    let series: any[] = []
    const series_raw: any[] = []
    let labels: string[] = []
    const colors: string[] = []
    let total = 0

    const { dimensions, metrics, config } = settings

    total = data.reduce((acc: number, item: any) => (acc += Number(item[metrics[0]])), 0)
    const color_pool = buildColorPool(data.length)
    data.forEach((item: any, idx: number) => {
      const val = Number(item[metrics[0]])
      total += val
      series.push(val)
      labels.push(normalizeLabel(item[dimensions[0]], settings))
      colors.push(color_pool(idx))
    })

    if (config.set_label_to_percentage) {
      if (!data || !data[0] || !data[0][metrics[1]] || !data[0][metrics[0]]) {
        labels = [numeral(0).format('0.00%')]
      } else {
        labels = [
          numeral(Number(data[0][metrics[0]]) / Number(data[0][metrics[1]])).format('0.00%'),
        ]
      }
    }

    return {
      series_raw: series,
      series,
      labels,
      colors,
      total,
    }
  },
}
