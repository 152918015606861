import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import moment from 'moment'
import Opportunity from '@/models/Opportunity'
import numeral from 'numeral'
import { buildColorPool } from '@/models/Util'
import FinancialPlan from '@/models/FinancialPlan'
import { startCase } from 'lodash'
import BaseFilter from '../../filters/BaseFilter'
import PipelineSold from '../../filters/PipelineSold'

export default class SalesRepRevenueVSPlanWidget extends SystemDashboardWidget {
  public title: string = 'Sales Rep Revenue vs Plan'

  public key = 'sales-rep-revenue-vs-goal'

  public view: string = 'chart'

  public subView: string = 'bar_marker'

  public dimensions: string[] = ['sales_rep', 'sales_rep_id']

  public metrics: string[] = ['goal_sold', 'goal']

  public size: any = {
    lg: 4,
    md: 12,
  }

  constructor(args: any = null) {
    super()

    if (args && args.dimensions) {
      this.dimensions = args.dimensions
    }

    if (args && args.metrics) {
      this.metrics = args.metrics
    }

    if (args && args.filters) {
      this.filters = Object.assign(this.filters, args.filters)
    }
    if (args && args.internalFilters) {
      this.internalFilters = Object.assign(this.internalFilters, args.internalFilters)
    }

    if (args && args.rightCol) {
      this.rightCol = args.rightCol
    }

    if (args && args.leftCol) {
      this.leftCol = args.leftCol
    }

    if (args && args.size) {
      this.size = args.size
    }

    if (args && args.title) {
      this.title = args.title
    }

    if (args && args.key) {
      this.key = args.key
    }

    if (args && args.internalQuery) {
      this.internalQuery = args.internalQuery
    }

    if (args && args.internalQueryPush) {
      this.internalQuery.push(args.internalQueryPush)
    }
  }

  public internalFilters: any = {
    year: moment().format('YYYY'),
    type: 'sales_rep_consolidated_goal',
  }

  public formatters: any = {
    xaxis: (widget: SystemDashboardWidget, value: any, opt: any) => numeral(value).format('$0.0a'),
    yaxis: (widget: SystemDashboardWidget, value: any, opt: any) => value,
    label: (_: SystemDashboardWidget, value: any) => `${numeral(value).format('$0.0a')}`,
    tooltip: (_: SystemDashboardWidget, value: any) => [numeral(value).format('$0.0a')],
  }

  public filters: any = {
    product: 'all',
    region: 'all',
  }

  public get filterToQuery() {
    let query = []

    if (this.filters.product !== 'all') {
      query.push(`product:${this.filters.product}`)
    }

    if (this.filters.region !== 'all') {
      query.push(`region:${this.filters.region}`)
    }

    query.push(`year:${this.internalFilters.year}`)

    return query
  }

  public internalQuery = ['scope:sales_rep_consolidated_goal']

  public get payload() {
    const query = [...new Set([...this.query, ...this.internalQuery, ...this.filterToQuery])]

    return {
      query,
      filters: {
        ...this.internalFilters,
      },
      metrics: this.metrics,
      dimensions: this.dimensions,
    }
  }

  public async dataSource() {
    // return Promise.resolve(
    //   [
    //     {
    //       sales_rep: 'John Doe',
    //       goal: 1000,
    //       goal_forecast: 800,
    //       goal_sold: 700,
    //       goal_achieved: 600,
    //     },
    //     {
    //       sales_rep: 'Wario doe Doe',
    //       goal: 2000,
    //       goal_forecast: 1800,
    //       goal_sold: 1700,
    //       goal_achieved: 1600,
    //     },
    //   ].sort((a: any, b: any) => b[this.metrics[0]] - a[this.metrics[0]]),
    // )
    return FinancialPlan.getPlanDashboard(this.payload).then((res: any) =>
      res.data.result.sort((a: any, b: any) => b[this.metrics[0]] - a[this.metrics[0]]))
  }

  protected dataMapper(data: any): Promise<any> {
    const series: number[] = []
    const data_series: any[] = []
    const labels: any[] = []
    // const colors: string[] = []
    const series_ids: string[] = []
    let total = 0

    const groups: string[] = []

    let x_series: string[] = []

    // Init Series
    data.forEach((item: any, idx: number) => {
      if (item[this.dimensions[0]]) {
        const label = item[this.dimensions[0]]
        const group_index = groups.indexOf(label)
        if (group_index === -1) {
          groups.push(label)
          data_series.push({
            x: label,
            y: 0,
            goals: [],
          })
        }
      }
    })

    const colors = ['#00C1DE', '#b0ca36']

    data.forEach((item: any, idx: number) => {
      const val = Number(item[this.metrics[0]])
      const goal = Number(item[this.metrics[1]])
      total += val
      series.push(val)

      const group_label = item[this.dimensions[0]]
      const group_index = groups.indexOf(group_label)

      data_series[group_index].y = val
      data_series[group_index].goals.push({
        name: 'Goal',
        value: goal,
        strokeWidth: 3,
        strokeHeight: 10,
        strokeColor: '#b0ca36',
      })

      if (['product', 'region', 'deal_type'].includes(this.dimensions[1])) {
        series_ids[group_index] = item[this.dimensions[1]].toLowerCase().replaceAll(' ', '_')
      } else if (this.dimensions[2]) {
        series_ids[group_index] = item[this.dimensions[this.dimensions.length - 1]]
      }
    })

    this.chartSettings.colors = colors

    this.chartSettings.xaxis = {
      categories: x_series,
      labels: {
        // formatter: function (val: any) {
        //   return numeral(val).format('$0.0a')
        // },
      },
    }
    // yaxis
    this.chartSettings.yaxis = {
      labels: {},
    }

    this.chartSettings.labels = labels

    this.chartSettings.total = total

    return Promise.resolve([{ name: startCase(this.metrics[0]), data: data_series }])
  }

  // protected dataMapper(data: any): Promise<any> {
  //   const series: number[] = []
  //   const data_series: any[] = []
  //   const labels: any[] = []
  //   const colors: string[] = []
  //   const series_ids: string[] = []
  //   let total = 0
  //   let total_cumulative: any[] = []

  //   const groups: string[] = []

  //   let x_series: string[] = []

  //   // Init Series
  //   data.forEach((item: any, idx: number) => {
  //     const label = item[this.dimensions[1]]
  //     const group_index = groups.indexOf(label)
  //     if (group_index === -1) {
  //       groups.push(label)
  //       data_series.push({ name: label, data: [] })
  //     }

  //     const x_label = item[this.dimensions[0]]

  //     if (x_series.indexOf(x_label) === -1) {
  //       x_series.push(x_label)
  //     }
  //   })

  //   const color_pool = buildColorPool(groups.length)

  //   data_series.forEach((item: any, idx: number) => {
  //     x_series.forEach(() => {
  //       item.data.push(0)
  //     })
  //     if (item.name === 'CCL') {
  //       colors.push('#555c6e')
  //     } else if (item.name === 'SSL') {
  //       colors.push('#00C1DE')
  //     } else {
  //       colors.push(color_pool(idx))
  //     }
  //   })

  //   data.forEach((item: any, idx: number) => {
  //     const label = item[this.dimensions[0]]
  //     const group_label = item[this.dimensions[1]]
  //     const group_index = groups.indexOf(group_label)
  //     const x_index = x_series.indexOf(label)
  //     labels.push(label)
  //     let val = Number(item[this.metrics[0]])
  //     if (isNaN(val)) {
  //       val = 0
  //     }
  //     if (!total_cumulative[group_index]) {
  //       total_cumulative[group_index] = 0
  //     }

  //     total += val
  //     total_cumulative[group_index] += val

  //     series.push(val)

  //     data_series[group_index].data[x_index] += val

  //     if (['product', 'region', 'deal_type'].includes(this.dimensions[1])) {
  //       series_ids[group_index] = item[this.dimensions[1]].toLowerCase().replaceAll(' ', '_')
  //     } else if (this.dimensions[2]) {
  //       series_ids[group_index] = item[this.dimensions[2]]
  //     }
  //   })

  //   // Udpate Settings
  //   this.chartSettings.colors = colors

  //   this.chartSettings.xaxis = {
  //     categories: x_series,
  //   }
  //   this.chartSettings.labels = labels

  //   return Promise.resolve(data_series)
  // }
}
