import SystemtModule from '@/store/SystemModule'
import numeral from 'numeral'
import { getModule } from 'vuex-module-decorators'

const gauge_custom_data = (
  data: any,
  target: string,
  period: string,
  secondary_label: string = 'Plan',
) => {
  let label = 'Sold'
  let note = ''

  if (target == 'forecast') {
    label = 'Performance'
    note = `<div style="font-size: x-small; bottom: 1rem; position: absolute" class="text-muted mt-5 px-2">
    * Performance: Repeated Business + 10% Pitched + Consideration + Processing + Sold
  </div>`
  } else if (target != 'sold') {
    label = 'Pipeline'
  }

  if (!data || !data[0]) {
    return ''
  }

  // Sold Logic
  const sold = numeral(data[0][`goal_${target}`]).format('$0.00a')
  const last_year_sold = numeral(data[0][`last_year_goal_${target}`]).format('$0.00a')
  let last_year_sold_percentage: any = data[0][`last_year_goal_${target}`] == 0
    ? 1
    : (data[0][`goal_${target}`] - data[0][`last_year_goal_${target}`])
        / data[0][`last_year_goal_${target}`]
  const sold_icon = last_year_sold_percentage > 0 ? '▴' : '▾'
  const sold_color = last_year_sold_percentage > 0 ? 'text-success' : 'text-danger'
  last_year_sold_percentage = numeral(last_year_sold_percentage).format('0%')

  // Goal Logic
  const goal = numeral(data[0].goal).format('$0.00a')
  const last_year_goal = numeral(data[0].last_year_goal).format('$0.00a')
  let last_year_goal_percentage: any = data[0].last_year_goal == 0
    ? 1
    : (data[0].goal - data[0].last_year_goal) / data[0].last_year_goal
  const goal_icon = last_year_goal_percentage > 0 ? '▴' : '▾'
  const goal_color = last_year_goal_percentage > 0 ? 'text-success' : 'text-danger'
  last_year_goal_percentage = numeral(last_year_goal_percentage).format('0%')

  if (data[0].plan_name) {
    secondary_label = data[0].plan_name
  }

  return `<div class="row text-center">
    <div class="col">
      <h6>${label}</h6>
      <h4>${sold}</h4>
      <div class="${sold_color}" style="font-size:small;">${sold_icon} ${last_year_sold_percentage}</div>
      <div class="text-muted">*Last ${period}: ${last_year_sold}</div>
    </div>
    <div class="col">
      <h6>Plan</h6>
      <h4>${goal}</h4>
      <div class="${goal_color}" style="font-size:small;">${goal_icon} ${last_year_goal_percentage}</div>
      <div class="text-muted">*Last ${period}: ${last_year_goal}</div>
    </div>
  </div> ${note}
  `
}

const system = getModule(SystemtModule)
const user = system.user

const opts = [
  {
    title: 'Pipeline Clean-up',
    key: 'pipeline-cleanup',
    source: 'opportunity-table',
    type: 'table',
    size: {
      lg: 12,
      md: 12,
    },
    capitalize_labels: true,
    config: {
      fields: [
        {
          key: 'start_at',
          label: 'Start At',
          class: 'text-center align-middle',
          show: true,
          type: 'date',
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Stage',
          class: 'text-center align-middle',
          show: true,
          type: 'capitalize_replace',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },
        {
          key: 'advertiser_id',
          label: 'Advertiser',
          class: 'text-center align-middle',
          show: true,
          type: 'object',
          object: {
            key: 'advertiser',
            property: 'name',
          },
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'agency_id',
          label: 'Agency',
          class: 'text-center align-middle',
          show: true,
          type: 'object',
          object: {
            key: 'agency',
            property: 'name',
          },
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'opp_name',
          label: 'Name',
          class: 'text-center align-middle',
          show: true,
          type: 'text',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },
        {
          key: 'amount',
          label: 'Amount',
          class: 'text-center align-middle',
          show: true,
          type: 'currency_abbreviate',
          thClass: 'align-middle text-center',
          sortable: true,
          total: 'currency_abbreviate',
        },
      ],
    },
    metrics: [],
    query: ['is:clean_up'],
  },
  {
    title: 'Dormant Activity',
    key: 'pipeline-stale',
    source: 'opportunity-table',
    type: 'table',
    size: {
      lg: 12,
      md: 12,
    },
    capitalize_labels: true,
    config: {
      fields: [
        {
          key: 'start_at',
          label: 'Start At',
          class: 'text-center align-middle',
          show: true,
          type: 'date',
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Stage',
          class: 'text-center align-middle',
          show: true,
          type: 'capitalize_replace',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },
        {
          key: 'advertiser_id',
          label: 'Advertiser',
          class: 'text-center align-middle',
          show: true,
          type: 'object',
          object: {
            key: 'advertiser',
            property: 'name',
          },
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'agency_id',
          label: 'Agency',
          class: 'text-center align-middle',
          show: true,
          type: 'object',
          object: {
            key: 'agency',
            property: 'name',
          },
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'opp_name',
          label: 'Name',
          class: 'text-center align-middle',
          show: true,
          type: 'text',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },
        {
          key: 'amount',
          label: 'Amount',
          class: 'text-center align-middle',
          show: true,
          type: 'currency_abbreviate',
          thClass: 'align-middle text-center',
          sortable: true,
          total: 'currency_abbreviate',
        },
      ],
    },
    metrics: [],
    query: ['is:stale'],
  },
  {
    title: 'New This Week Overview',
    key: 'new-this-week-overview',
    source: 'opportunity-advanced',
    type: 'bar_stacked',
    size: {
      lg: 4,
      md: 12,
    },
    capitalize_labels: true,
    config: {
      click_key: 'product',
      click_filter_name: 'Product',
    },
    dimensions: ['quarter', 'product'],
    metrics: ['amount'],
    filters: {
      dynamic_filter: 'is:pipeline',
    },
    query: ['is:new_this_week'],
    dynamic_options: [
      {
        target: 'filters',
        sub_target: 'dynamic_filter',
        options: [
          { text: 'Pipeline', value: 'is:pipeline' },
          { text: 'Sold', value: 'is:sold' },
        ],
      },
    ],
  },
  {
    title: 'New This Week',
    key: 'new-this-week',
    source: 'opportunity-table',
    type: 'table',
    size: {
      lg: 8,
      md: 12,
    },
    capitalize_labels: true,
    filters: {
      dynamic_filter: 'is:pipeline',
    },
    dynamic_options: [
      {
        target: 'filters',
        sub_target: 'dynamic_filter',
        options: [
          { text: 'Pipeline', value: 'is:pipeline' },
          { text: 'Sold', value: 'is:sold' },
        ],
      },
    ],
    config: {
      fields: [
        {
          key: 'status',
          label: 'Stage',
          class: 'text-center align-middle',
          show: true,
          type: 'capitalize_replace',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },
        {
          key: 'advertiser_id',
          label: 'Advertiser',
          class: 'text-center align-middle',
          show: true,
          type: 'object',
          object: {
            key: 'advertiser',
            property: 'name',
          },
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'agency_id',
          label: 'Agency',
          class: 'text-center align-middle',
          show: true,
          type: 'object',
          object: {
            key: 'agency',
            property: 'name',
          },
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'opp_name',
          label: 'Name',
          class: 'text-center align-middle',
          show: true,
          type: 'text',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },
        {
          key: 'sales_rep_id',
          label: 'Sales Rep',
          class: 'text-center align-middle',
          show: true,
          type: 'object',
          object: {
            key: 'sales_rep',
            property: 'name',
          },
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'amount',
          label: 'Amount',
          class: 'text-center align-middle',
          show: true,
          type: 'currency_abbreviate',
          thClass: 'align-middle text-center',
          sortable: true,
          total: 'currency_abbreviate',
        },
      ],
    },
    metrics: [],
    query: ['is:new_this_week'],
  },
  {
    title: 'Open Deals',
    key: 'large-deals',
    source: 'opportunity-table',
    type: 'table',
    size: {
      lg: 12,
      md: 12,
    },
    capitalize_labels: true,
    config: {
      fields: [
        {
          key: 'status',
          label: 'Stage',
          class: 'text-center align-middle',
          show: true,
          type: 'capitalize_replace',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },
        {
          key: 'advertiser_id',
          label: 'Advertiser',
          class: 'text-center align-middle',
          show: true,
          type: 'object',
          object: {
            key: 'advertiser',
            property: 'name',
          },
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'agency_id',
          label: 'Agency',
          class: 'text-center align-middle',
          show: true,
          type: 'object',
          object: {
            key: 'agency',
            property: 'name',
          },
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'opp_name',
          label: 'Name',
          class: 'text-center align-middle',
          show: true,
          type: 'text',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },
        {
          key: 'sales_rep_id',
          label: 'Sales Rep',
          class: 'text-center align-middle',
          show: true,
          type: 'object',
          object: {
            key: 'sales_rep',
            property: 'name',
          },
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'amount',
          label: 'Amount',
          class: 'text-center align-middle',
          show: true,
          type: 'currency_abbreviate',
          thClass: 'align-middle text-center',
          sortable: true,
          total: 'currency_abbreviate',
        },
      ],
    },
    metrics: [],
    query: ['is:open'],
    filters: {
      dynamic_filter: 'is:large_deal',
    },
    dynamic_options: [
      {
        target: 'filters',
        sub_target: 'dynamic_filter',
        options: [
          { text: 'Large Deals', value: 'is:large_deal' },
          { text: 'All', value: '' },
        ],
      },
    ],
  },
  {
    title: 'Touch Point Summary',
    key: 'touch-point-summary',
    source: 'activity-amount-summary',
    type: 'custom',
    size: {
      lg: 6,
      md: 12,
    },
    dimensions: ['activity', 'task'],
    capitalize_labels: true,
    config: {
      custom_data: (data: any) => {
        const last_week = numeral(data.last_week).format('0,0')
        const last_week_color = data.last_week > data.previous_week ? 'text-success' : 'text-danger'
        const last_week_icon = data.last_week > data.previous_week ? '▴' : '▾'
        const last_week_percentage = numeral(
          data.previous_week <= 0 ? 0 : (data.previous_week - data.last_week) / data.previous_week,
        ).format('0%')

        const this_week = numeral(data.this_week).format('0,0')
        const this_week_color = data.this_week > data.last_week ? 'text-success' : 'text-danger'
        const this_week_icon = data.this_week > data.last_week ? '▴' : '▾'
        const this_week_percentage = numeral(
          data.last_week <= 0 ? 0 : (data.last_week - data.this_week) / data.last_week,
        ).format('0%')

        const next_week = numeral(data.next_week).format('0,0')
        const next_week_color = data.next_week > data.this_week ? 'text-success' : 'text-danger'
        const next_week_icon = data.next_week > data.this_week ? '▴' : '▾'
        const next_week_percentage = numeral(
          data.this_week <= 0 ? 0 : (data.this_week - data.next_week) / data.this_week,
        ).format('0%')

        const last_quarter = numeral(data.last_quarter).format('0,0')
        const last_quarter_color = data.last_quarter > data.previous_quarter ? 'text-success' : 'text-danger'
        const last_quarter_icon = data.last_quarter > data.previous_quarter ? '▴' : '▾'
        const last_quarter_percentage = numeral(
          data.previous_quarter <= 0
            ? 0
            : (data.previous_quarter - data.last_quarter) / data.previous_quarter,
        ).format('0%')

        const this_quarter = numeral(data.this_quarter).format('0,0')
        const this_quarter_color = data.this_quarter > data.last_quarter ? 'text-success' : 'text-danger'
        const this_quarter_icon = data.this_quarter > data.last_quarter ? '▴' : '▾'
        const this_quarter_percentage = numeral(
          data.last_quarter <= 0 ? 0 : (data.last_quarter - data.this_quarter) / data.last_quarter,
        ).format('0%')

        const next_quarter = numeral(data.next_quarter).format('0,0')
        const next_quarter_color = data.next_quarter > data.this_quarter ? 'text-success' : 'text-danger'
        const next_quarter_icon = data.next_quarter > data.this_quarter ? '▴' : '▾'
        const next_quarter_percentage = numeral(
          data.this_quarter <= 0 ? 0 : (data.this_quarter - data.next_quarter) / data.this_quarter,
        ).format('0%')

        return `  
        <div class="row text-center mt-5 pt-3">
          <div class="col">
            <h6>Last Week</h6>
            <h4>${last_week}</h4>
            <p class="${last_week_color}">${last_week_icon} ${last_week_percentage} <br />Previous Week</p>
          </div>
          <div class="col">
            <h6>This Week</h6>
            <h4>${this_week}</h4>
            <p class="${this_week_color}">${this_week_icon} ${this_week_percentage} <br />Last Week</p>
          </div>
          <div class="col">
            <h6>Next Week</h6>
            <h4>${next_week}</h4>
            <p class="${next_week_color}">${next_week_icon} ${next_week_percentage} <br />This Week</p>
          </div>
        </div>   
        <div class="row text-center mt-3">
          <div class="col">
            <h6>Last Quarter</h6>
            <h4>${last_quarter}</h4>
            <p class="${last_quarter_color}">${last_quarter_icon} ${last_quarter_percentage} <br />Previous Quarter</p>
          </div>
          <div class="col">
            <h6>This Quarter</h6>
            <h4>${this_quarter}</h4>
            <p class="${this_quarter_color}">${this_quarter_icon} ${this_quarter_percentage} <br />Last Quarter</p>
          </div>
          <div class="col">
            <h6>Next Quarter</h6>
            <h4>${next_quarter}</h4>
            <p class="${next_quarter_color}">${next_quarter_icon} ${next_quarter_percentage} <br />This Quarter</p>
          </div>
        </div>
        `
      },
    },
    metrics: ['count'],
    filters: {},
  },
  {
    title: 'Activity Summary',
    key: 'this-week-summary',
    source: 'activity-amount-summary',
    type: 'custom',
    size: {
      lg: 6,
      md: 12,
    },
    dimensions: ['activity'],
    capitalize_labels: true,
    config: {
      custom_data: (data: any) => {
        const last_week = numeral(data.last_week).format('0,0')
        const last_week_color = data.last_week > data.previous_week ? 'text-success' : 'text-danger'
        const last_week_icon = data.last_week > data.previous_week ? '▴' : '▾'
        const last_week_percentage = numeral(
          data.previous_week <= 0 ? 0 : (data.previous_week - data.last_week) / data.previous_week,
        ).format('0%')

        const this_week = numeral(data.this_week).format('0,0')
        const this_week_color = data.this_week > data.last_week ? 'text-success' : 'text-danger'
        const this_week_icon = data.this_week > data.last_week ? '▴' : '▾'
        const this_week_percentage = numeral(
          data.last_week <= 0 ? 0 : (data.last_week - data.this_week) / data.last_week,
        ).format('0%')

        const next_week = numeral(data.next_week).format('0,0')
        const next_week_color = data.next_week > data.this_week ? 'text-success' : 'text-danger'
        const next_week_icon = data.next_week > data.this_week ? '▴' : '▾'
        const next_week_percentage = numeral(
          data.this_week <= 0 ? 0 : (data.this_week - data.next_week) / data.this_week,
        ).format('0%')

        const last_month = numeral(data.last_month).format('0,0')
        const last_month_color = data.last_month > data.previous_month ? 'text-success' : 'text-danger'
        const last_month_icon = data.last_month > data.previous_month ? '▴' : '▾'
        const last_month_percentage = numeral(
          data.previous_month <= 0
            ? 0
            : (data.previous_month - data.last_month) / data.previous_month,
        ).format('0%')

        const this_month = numeral(data.this_month).format('0,0')
        const this_month_color = data.this_month > data.last_month ? 'text-success' : 'text-danger'
        const this_month_icon = data.this_month > data.last_month ? '▴' : '▾'
        const this_month_percentage = numeral(
          data.last_month <= 0 ? 0 : (data.last_month - data.this_month) / data.last_month,
        ).format('0%')

        const next_month = numeral(data.next_month).format('0,0')
        const next_month_color = data.next_month > data.this_month ? 'text-success' : 'text-danger'
        const next_month_icon = data.next_month > data.this_month ? '▴' : '▾'
        const next_month_percentage = numeral(
          data.this_month <= 0 ? 0 : (data.this_month - data.next_month) / data.this_month,
        ).format('0%')

        return `  
        <div class="row text-center mt-5 pt-3">
          <div class="col">
            <h6>Last Week</h6>
            <h4>${last_week}</h4>
            <p class="${last_week_color}">${last_week_icon} ${last_week_percentage} <br />Previous Week</p>
          </div>
          <div class="col">
            <h6>This Week</h6>
            <h4>${this_week}</h4>
            <p class="${this_week_color}">${this_week_icon} ${this_week_percentage} <br />Last Week</p>
          </div>
          <div class="col">
            <h6>Next Week</h6>
            <h4>${next_week}</h4>
            <p class="${next_week_color}">${next_week_icon} ${next_week_percentage} <br />This Week</p>
          </div>
        </div>   
        <div class="row text-center mt-3">
          <div class="col">
            <h6>Last Month</h6>
            <h4>${last_month}</h4>
            <p class="${last_month_color}">${last_month_icon} ${last_month_percentage} <br />Previous Month</p>
          </div>
          <div class="col">
            <h6>This Month</h6>
            <h4>${this_month}</h4>
            <p class="${this_month_color}">${this_month_icon} ${this_month_percentage} <br />Last Month</p>
          </div>
          <div class="col">
            <h6>Next Month</h6>
            <h4>${next_month}</h4>
            <p class="${next_month_color}">${next_month_icon} ${next_month_percentage} <br />This Month</p>
          </div>
        </div>
        `
      },
    },
    metrics: ['count'],
    filters: {},
  },
  // {
  //   title: 'Activity Amount',
  //   key: 'next-week-summary',
  //   source: 'activity-amount-summary',
  //   type: 'custom',
  //   size: {
  //     lg: 4,
  //     md: 12,
  //   },
  //   dimensions: ['activity'],
  //   capitalize_labels: true,
  //   config: {
  //     custom_data: (data: any) => {
  //       const last_week = numeral(data.last_week).format('$0,0.00a')
  //       const last_week_color = data.last_week > data.previous_week ? 'text-success' : 'text-danger'
  //       const last_week_icon = data.last_week > data.previous_week ? '▴' : '▾'
  //       const last_week_percentage = numeral(
  //         data.previous_week <= 0 ? 0 : (data.previous_week - data.last_week) / data.previous_week,
  //       ).format('0%')

  //       const this_week = numeral(data.this_week).format('$0,0.00a')
  //       const this_week_color = data.this_week > data.last_week ? 'text-success' : 'text-danger'
  //       const this_week_icon = data.this_week > data.last_week ? '▴' : '▾'
  //       const this_week_percentage = numeral(
  //         data.last_week <= 0 ? 0 : (data.last_week - data.this_week) / data.last_week,
  //       ).format('0%')

  //       const next_week = numeral(data.next_week).format('$0,0.00a')
  //       const next_week_color = data.next_week > data.this_week ? 'text-success' : 'text-danger'
  //       const next_week_icon = data.next_week > data.this_week ? '▴' : '▾'
  //       const next_week_percentage = numeral(
  //         data.this_week <= 0 ? 0 : (data.this_week - data.next_week) / data.this_week,
  //       ).format('0%')

  //       const last_month = numeral(data.last_month).format('$0,0.00a')
  //       const last_month_color = data.last_month > data.previous_month ? 'text-success' : 'text-danger'
  //       const last_month_icon = data.last_month > data.previous_month ? '▴' : '▾'
  //       const last_month_percentage = numeral(
  //         data.previous_month <= 0
  //           ? 0
  //           : (data.previous_month - data.last_month) / data.previous_month,
  //       ).format('0%')

  //       const this_month = numeral(data.this_month).format('$0,0.00a')
  //       const this_month_color = data.this_month > data.last_month ? 'text-success' : 'text-danger'
  //       const this_month_icon = data.this_month > data.last_month ? '▴' : '▾'
  //       const this_month_percentage = numeral(
  //         data.last_month <= 0 ? 0 : (data.last_month - data.this_month) / data.last_month,
  //       ).format('0%')

  //       const next_month = numeral(data.next_month).format('$0,0.00a')
  //       const next_month_color = data.next_month > data.this_month ? 'text-success' : 'text-danger'
  //       const next_month_icon = data.next_month > data.this_month ? '▴' : '▾'
  //       const next_month_percentage = numeral(
  //         data.this_month <= 0 ? 0 : (data.this_month - data.next_month) / data.this_month,
  //       ).format('0%')

  //       return `
  //       <div class="row text-center mt-5 pt-3">
  //         <div class="col">
  //           <h6>Last Week</h6>
  //           <h4>${last_week}</h4>
  //           <p class="${last_week_color}">${last_week_icon} ${last_week_percentage} <br />Previous Week</p>
  //         </div>
  //         <div class="col">
  //           <h6>This Week</h6>
  //           <h4>${this_week}</h4>
  //           <p class="${this_week_color}">${this_week_icon} ${this_week_percentage} <br />Last Week</p>
  //         </div>
  //         <div class="col">
  //           <h6>Next Week</h6>
  //           <h4>${next_week}</h4>
  //           <p class="${next_week_color}">${next_week_icon} ${next_week_percentage} <br />This Week</p>
  //         </div>
  //       </div>
  //       <div class="row text-center mt-3">
  //         <div class="col">
  //           <h6>Last Month</h6>
  //           <h4>${last_month}</h4>
  //           <p class="${last_month_color}">${last_month_icon} ${last_month_percentage} <br />Previous Month</p>
  //         </div>
  //         <div class="col">
  //           <h6>This Month</h6>
  //           <h4>${this_month}</h4>
  //           <p class="${this_month_color}">${this_month_icon} ${this_month_percentage} <br />Last Month</p>
  //         </div>
  //         <div class="col">
  //           <h6>Next Month</h6>
  //           <h4>${next_month}</h4>
  //           <p class="${next_month_color}">${next_month_icon} ${next_month_percentage} <br />This Month</p>
  //         </div>
  //       </div>
  //       `
  //     },
  //   },
  //   metrics: ['amount'],
  //   filters: {},
  // },
  {
    title: 'Activity This Week',
    key: 'activity-this-week',
    source: 'activity-table',
    type: 'table',
    size: {
      lg: 6,
      md: 12,
    },
    capitalize_labels: true,
    query: ['is:this_week'],
    config: {
      fields: [
        {
          key: 'scheduled_at',
          label: 'Date',
          class: 'text-center align-middle',
          show: true,
          type: 'date',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },
        {
          key: 'formOpportunitySalesRep',
          label: 'Sales Rep',
          class: 'text-center align-middle',
          show: true,
          type: 'text',
          thClass: 'align-middle text-center',
          sortable: false,
        },
        {
          key: 'name',
          label: 'Description',
          class: 'text-center align-middle',
          show: true,
          type: 'text',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },
        {
          key: 'type',
          label: 'Activity',
          class: 'text-center align-middle',
          show: true,
          type: 'capitalize',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },
        {
          key: 'sub_type',
          label: 'Type',
          class: 'text-center align-middle',
          show: true,
          type: 'capitalize_replace',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },
        {
          key: 'formOpportunityStatus',
          label: 'Stage',
          class: 'text-center align-middle',
          show: true,
          type: 'capitalize_replace',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },
        {
          key: 'formOpportunityAdvertiser',
          label: 'Advertiser',
          class: 'text-center align-middle',
          show: true,
          type: 'text',
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'formOpportunityAgency',
          label: 'Agency',
          class: 'text-center align-middle',
          show: true,
          type: 'text',
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'formOpportunityName',
          label: 'Opportunity',
          class: 'text-center align-middle',
          show: true,
          type: 'text',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },
        {
          key: 'formOpportunityAmount',
          label: 'Amount',
          class: 'text-center align-middle',
          show: true,
          type: 'currency_abbreviate',
          thClass: 'align-middle text-center',
          sortable: true,
          total: 'currency_abbreviate',
        },
      ],
    },
  },
  {
    title: 'Activity Next Week',
    key: 'activity-next-week',
    source: 'activity-table',
    type: 'table',
    size: {
      lg: 6,
      md: 12,
    },
    capitalize_labels: true,
    query: ['is:next_week'],
    config: {
      fields: [
        {
          key: 'scheduled_at',
          label: 'Date',
          class: 'text-center align-middle',
          show: true,
          type: 'date',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },
        {
          key: 'formOpportunitySalesRep',
          label: 'Sales Rep',
          class: 'text-center align-middle',
          show: true,
          type: 'text',
          thClass: 'align-middle text-center',
          sortable: false,
        },
        {
          key: 'name',
          label: 'Description',
          class: 'text-center align-middle',
          show: true,
          type: 'text',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },
        {
          key: 'type',
          label: 'Activity',
          class: 'text-center align-middle',
          show: true,
          type: 'capitalize',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },
        {
          key: 'sub_type',
          label: 'Type',
          class: 'text-center align-middle',
          show: true,
          type: 'capitalize_replace',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },
        {
          key: 'formOpportunityStatus',
          label: 'Stage',
          class: 'text-center align-middle',
          show: true,
          type: 'capitalize_replace',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },
        {
          key: 'formOpportunityAdvertiser',
          label: 'Advertiser',
          class: 'text-center align-middle',
          show: true,
          type: 'text',
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'formOpportunityAgency',
          label: 'Agency',
          class: 'text-center align-middle',
          show: true,
          type: 'text',
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'formOpportunityName',
          label: 'Opportunity',
          class: 'text-center align-middle',
          show: true,
          type: 'text',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },
        {
          key: 'formOpportunityAmount',
          label: 'Amount',
          class: 'text-center align-middle',
          show: true,
          type: 'currency_abbreviate',
          thClass: 'align-middle text-center',
          sortable: true,
          total: 'currency_abbreviate',
        },
      ],
    },
  },
  {
    title: 'Sold to Plan %YEAR%',
    key: 'sold-to-goal-year',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 4,
      md: 6,
    },
    dimensions: ['year'],
    capitalize_labels: true,
    metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      type: 'finance_goal',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'sold', 'year'),
    },
  },
  {
    title: 'Q1 Sold to Plan',
    key: 'sold-to-goal-q1',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 2,
      md: 6,
    },
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      quarter: 1,
      type: 'finance_goal',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'sold', 'Q1'),
    },
  },
  {
    title: 'Q2 Sold to Plan',
    key: 'sold-to-goal-q2',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 2,
      md: 6,
    },
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      quarter: 2,
      type: 'finance_goal',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'sold', 'Q2'),
    },
  },
  {
    title: 'Q3 Sold to Plan',
    key: 'sold-to-goal-q3',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 2,
      md: 6,
    },
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      quarter: 3,
      type: 'finance_goal',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'sold', 'Q3'),
    },
  },
  {
    title: 'Q4 Sold to Plan',
    key: 'sold-to-goal-q4',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 2,
      md: 6,
    },
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      quarter: 4,
      type: 'finance_goal',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'sold', 'Q4'),
    },
  },
  // Reforecast
  {
    title: 'Sold to Reforecast %YEAR%',
    key: 'sold-to-reforecast-year',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 4,
      md: 6,
    },
    dimensions: ['year'],
    capitalize_labels: true,
    metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      type: 'finance_goal',
      reforecast: 'latest',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'sold', 'year', 'Reforecast'),
    },
  },
  {
    title: 'Q1 Sold to Reforecast',
    key: 'sold-to-reforecast-q1',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 2,
      md: 6,
    },
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      quarter: 1,
      type: 'finance_goal',
      reforecast: 'latest',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'sold', 'Q1', 'Reforecast'),
    },
  },
  {
    title: 'Q2 Sold to Reforecast',
    key: 'sold-to-reforecast-q2',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 2,
      md: 6,
    },
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      quarter: 2,
      type: 'finance_goal',
      reforecast: 'latest',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'sold', 'Q2', 'Reforecast'),
    },
  },
  {
    title: 'Q3 Sold to Reforecast',
    key: 'sold-to-reforecast-q3',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 2,
      md: 6,
    },
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      quarter: 3,
      type: 'finance_goal',
      reforecast: 'latest',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'sold', 'Q3', 'Reforecast'),
    },
  },
  {
    title: 'Q4 Sold to Reforecast',
    key: 'sold-to-reforecast-q4',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 2,
      md: 6,
    },
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      quarter: 4,
      type: 'finance_goal',
      reforecast: 'latest',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'sold', 'Q4', 'Reforecast'),
    },
  },
  // Reforecast
  // Forecast
  {
    title: 'Performance to Reforecast %YEAR%',
    key: 'forecast-to-reforecast-year',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 4,
      md: 6,
    },
    dimensions: ['year'],
    capitalize_labels: true,
    metrics: ['goal_forecast', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      type: 'finance_goal',
      reforecast: 'latest',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'forecast', 'year', 'Reforecast'),
    },
  },
  {
    title: 'Q1 Performance to Reforecast',
    key: 'forecast-to-reforecast-q1',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 2,
      md: 6,
    },
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_forecast', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      quarter: 1,
      type: 'finance_goal',
      reforecast: 'latest',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'forecast', 'Q1', 'Reforecast'),
    },
  },
  {
    title: 'Q2 Performance to Reforecast',
    key: 'forecast-to-reforecast-q2',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 2,
      md: 6,
    },
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_forecast', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      quarter: 2,
      type: 'finance_goal',
      reforecast: 'latest',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'forecast', 'Q2', 'Reforecast'),
    },
  },
  {
    title: 'Q3 Performance to Reforecast',
    key: 'forecast-to-reforecast-q3',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 2,
      md: 6,
    },
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_forecast', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      quarter: 3,
      type: 'finance_goal',
      reforecast: 'latest',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'forecast', 'Q3', 'Reforecast'),
    },
  },
  {
    title: 'Q4 Performance to Reforecast',
    key: 'forecast-to-reforecast-q4',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 2,
      md: 6,
    },
    dimensions: ['quarter'],
    capitalize_labels: true,
    metrics: ['goal_forecast', 'goal', 'last_year_goal', 'last_year_goal_sold'],
    filters: {
      quarter: 4,
      type: 'finance_goal',
      reforecast: 'latest',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'forecast', 'Q4', 'Reforecast'),
    },
  },
  // Forecast
  {
    title: 'Pipeline to Plan %YEAR%',
    key: 'revenue-to-goal-year',
    source: 'financial_plan',
    type: 'gauge',
    size: {
      lg: 4,
      md: 6,
    },
    dimensions: ['year'],
    capitalize_labels: true,
    metrics: ['goal_pipeline', 'goal', 'last_year_goal', 'last_year_goal_pipeline'],
    filters: {
      type: 'finance_goal',
    },
    config: {
      set_label_to_percentage: true,
      custom_data: (data: any) => gauge_custom_data(data, 'pipeline', 'year'),
    },
  },
  // {
  //   title: 'Q1 Pipeline to Plan',
  //   key: 'plan-to-goal-q1',
  //   source: 'financial_plan',
  //   type: 'gauge',
  //   size: {
  //     lg: 2,
  //     md: 6,
  //   },
  //   dimensions: ['quarter'],
  //   capitalize_labels: true,
  //   metrics: ['goal_pipeline', 'goal', 'last_year_goal', 'last_year_goal_pipeline'],
  //   filters: {
  //     quarter: 1,
  //     type: 'finance_goal',
  //   },
  //   config: {
  //     set_label_to_percentage: true,
  //     custom_data: (data: any) => gauge_custom_data(data, 'pipeline', 'Q1'),
  //   },
  // },
  // {
  //   title: 'Q2 Pipeline to Plan',
  //   key: 'plan-to-goal-q2',
  //   source: 'financial_plan',
  //   type: 'gauge',
  //   size: {
  //     lg: 2,
  //     md: 6,
  //   },
  //   dimensions: ['quarter'],
  //   capitalize_labels: true,
  //   metrics: ['goal_pipeline', 'goal', 'last_year_goal', 'last_year_goal_pipeline'],
  //   filters: {
  //     quarter: 2,
  //     type: 'finance_goal',
  //   },
  //   config: {
  //     set_label_to_percentage: true,
  //     custom_data: (data: any) => gauge_custom_data(data, 'pipeline', 'Q2'),
  //   },
  // },
  // {
  //   title: 'Q3 Pipeline to Plan',
  //   key: 'plan-to-goal-q3',
  //   source: 'financial_plan',
  //   type: 'gauge',
  //   size: {
  //     lg: 2,
  //     md: 6,
  //   },
  //   dimensions: ['quarter'],
  //   capitalize_labels: true,
  //   metrics: ['goal_pipeline', 'goal', 'last_year_goal', 'last_year_goal_pipeline'],
  //   filters: {
  //     quarter: 3,
  //     type: 'finance_goal',
  //   },
  //   config: {
  //     set_label_to_percentage: true,
  //     custom_data: (data: any) => gauge_custom_data(data, 'pipeline', 'Q3'),
  //   },
  // },
  // {
  //   title: 'Q4 Pipeline to Plan',
  //   key: 'plan-to-goal-q4',
  //   source: 'financial_plan',
  //   type: 'gauge',
  //   size: {
  //     lg: 2,
  //     md: 6,
  //   },
  //   dimensions: ['quarter'],
  //   capitalize_labels: true,
  //   metrics: ['goal_pipeline', 'goal', 'last_year_goal', 'last_year_goal_pipeline'],
  //   filters: {
  //     quarter: 4,
  //     type: 'finance_goal',
  //   },
  //   config: {
  //     set_label_to_percentage: true,
  //     custom_data: (data: any) => gauge_custom_data(data, 'pipeline', 'Q4'),
  //   },
  // },
  // {
  //   title: 'Quarter Overview',
  //   key: 'quarter-goal',
  //   source: 'financial_plan',
  //   type: 'table',
  //   size: {
  //     lg: 4,
  //     md: 12,
  //   },
  //   dimensions: ['quarter'],
  //   capitalize_labels: true,
  //   config: {
  //     noPagination: true,
  //     fields: [
  //       {
  //         key: 'quarter',
  //         label: 'Quarter',
  //         class: 'text-center align-middle',
  //         show: true,
  //         thClass: 'align-middle text-center',
  //         sortable: true,
  //       },
  //       {
  //         key: 'goal',
  //         label: 'Plan',
  //         class: 'text-center align-middle',
  //         show: true,
  //         thClass: 'align-middle text-center',
  //         type: 'currency_abbreviate',
  //         sortable: true,
  //         total: 'currency_abbreviate',
  //       },
  //       {
  //         key: 'goal_forecast',
  //         label: 'Forecast',
  //         class: 'text-center align-middle',
  //         show: true,
  //         thClass: 'align-middle text-center',
  //         type: 'currency_abbreviate',
  //         sortable: true,
  //         total: 'currency_abbreviate',
  //       },
  //       {
  //         key: 'goal_sold',
  //         label: 'Sold',
  //         class: 'text-center align-middle',
  //         show: true,
  //         thClass: 'align-middle text-center',
  //         type: 'currency_abbreviate',
  //         sortable: true,
  //         total: 'currency_abbreviate',
  //       },
  //       {
  //         key: 'goal_achieved',
  //         label: 'Invoiced',
  //         class: 'text-center align-middle',
  //         show: true,
  //         thClass: 'align-middle text-center',
  //         type: 'currency_abbreviate',
  //         sortable: true,
  //         total: 'currency_abbreviate',
  //       },
  //     ],
  //   },
  //   metrics: [
  //     'goal',
  //     'goal_forecast',
  //     'goal_pipeline',
  //     'goal_sold',
  //     'goal_achieved',
  //     'last_year_goal',
  //     'last_year_goal_pipeline',
  //     'last_year_goal_sold',
  //     'last_year_goal_achieved',
  //   ],
  //   filters: {
  //     type: 'finance_goal',
  //   },
  // },
  {
    title: 'Sales Rep Overview',
    key: 'sales-rep-goal',
    source: 'financial_plan',
    type: 'table',
    size: {
      lg: 4,
      md: 12,
    },
    dimensions: ['sales_rep'],
    capitalize_labels: true,
    config: {
      fields: [
        {
          key: 'sales_rep',
          label: 'Sales Rep',
          class: 'text-center align-middle',
          show: true,
          type: 'capitalize',
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'goal',
          label: 'Plan',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: true,
          total: 'currency_abbreviate',
        },
        {
          key: 'goal_forecast',
          label: 'Forecast',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: true,
          total: 'currency_abbreviate',
        },
        {
          key: 'goal_sold',
          label: 'Sold',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: true,
          total: 'currency_abbreviate',
        },
        {
          key: 'goal_achieved',
          label: 'Invoiced',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: true,
          total: 'currency_abbreviate',
        },
      ],
    },
    metrics: [
      'goal',
      'goal_forecast',
      'goal_pipeline',
      'goal_sold',
      'goal_achieved',
      'last_year_goal',
      'last_year_goal_pipeline',
      'last_year_goal_sold',
      'last_year_goal_achieved',
    ],
    filters: {
      type: 'sales_rep_consolidated_goal',
    },
  },
  {
    title: 'Weekly Overview',
    type: 'custom',
    source: 'opportunity-week-card',
    size: {
      lg: 4,
      md: 12,
    },
    config: {
      custom_data: (data: any) => {
        // Closed
        const closed = numeral(data.closed).format('0,0')
        const last_week_closed = numeral(data.last_week_closed).format('0,0')
        const closed_percentage = numeral(data.last_week_closed_percentage).format('0%')
        const closed_color = data.last_week_closed_percentage > 0 ? 'text-success' : 'text-danger'
        const closed_icon = data.last_week_closed_percentage > 0 ? '▴' : '▾'

        // Closed Amount
        const closed_amount = numeral(data.closed_amount).format('$0,0.00a')
        const last_week_closed_amount = numeral(data.last_week_closed_amount).format('$0,0.00a')
        const closed_amount_percentage = numeral(data.last_week_closed_amount_percentage).format(
          '0%',
        )
        const closed_amount_color = data.last_week_closed_amount_percentage > 0 ? 'text-success' : 'text-danger'
        const closed_amount_icon = data.last_week_closed_amount_percentage > 0 ? '▴' : '▾'

        // New
        const new_count = numeral(data.new).format('0,0')
        const last_week_new = numeral(data.last_week_new).format('0,0')
        const new_percentage = numeral(data.last_week_new_percentage).format('0%')
        const new_color = data.last_week_new_percentage > 0 ? 'text-success' : 'text-danger'
        const new_icon = data.last_week_new_percentage > 0 ? '▴' : '▾'

        // New Ammount
        const new_amount = numeral(data.new_amount).format('$0,0.00a')
        const last_week_new_amount = numeral(data.last_week_new_amount).format('$0,0.00a')
        const new_amount_percentage = numeral(data.last_week_new_amount_percentage).format('0.00%')
        const new_amount_color = data.last_week_new_amount_percentage > 0 ? 'text-success' : 'text-danger'
        const new_amount_icon = data.last_week_new_amount_percentage > 0 ? '▴' : '▾'

        // Lost
        const lost = numeral(data.lost).format('0,0')
        const last_week_lost = numeral(data.last_week_lost).format('0,0')
        const lost_percentage = numeral(data.last_week_lost_percentage).format('0%')
        const lost_color = data.last_week_lost_percentage > 0 ? 'text-success' : 'text-danger'
        const lost_icon = data.last_week_lost_percentage > 0 ? '▴' : '▾'

        // Lost Amount
        const lost_amount = numeral(data.lost_amount).format('$0,0.00a')
        const last_week_lost_amount = numeral(data.last_week_lost_amount).format('$0,0.00a')
        const lost_amount_percentage = numeral(data.last_week_lost_amount_percentage).format(
          '0.00%',
        )
        const lost_amount_color = data.last_week_lost_amount_percentage > 0 ? 'text-success' : 'text-danger'
        const lost_amount_icon = data.last_week_lost_amount_percentage > 0 ? '▴' : '▾'

        return `
        <div class="row text-center mt-5 pt-2">
          <div class="col">
            <h6>Closed/Won</h6>
            <h4>${closed_amount}</h4>
            <p class="${closed_amount_color}">${closed_amount_icon} ${closed_amount_percentage} (Last week: ${last_week_closed_amount})</p>
          </div>
          <div class="col">
            <h6>Added</h6>
            <h4>${new_amount}</h4>
            <p class="${new_amount_color}">${new_amount_icon} ${new_amount_percentage} (Last week: ${last_week_new_amount})</p>
          </div>
          <div class="col">
            <h6>Lost</h6>
            <h4>-${lost_amount}</h4>
            <p class="${lost_amount_color}">${lost_amount_icon} ${lost_amount_percentage} (Last week: -${last_week_lost_amount})</p>
          </div>
        </div>
        <div class="row text-center mt-3">
          <div class="col">
            <h6>Closed Opps</h6>
            <h4>${closed}</h4>
            <p class="${closed_color}">${closed_icon} ${closed_percentage} (Last week: ${last_week_closed})</p>
          </div>
          <div class="col">
            <h6>New Opps</h6>
            <h4>${new_count}</h4>
            <p class="${new_color}">${new_icon} ${new_percentage} (Last week: ${last_week_new})</p>
          </div>
          <div class="col">
            <h6>Lost Opps</h6>
            <h4>-${lost}</h4>
            <p class="${lost_color}">${lost_icon} ${lost_percentage} (Last week: -${last_week_lost})</p>
          </div>`
      },
    },
  },
  {
    title: 'Amount by Stage %YEAR%',
    key: 'opportunity-status-distribution',
    source: 'opportunity-advanced',
    type: 'funnel',
    size: {
      lg: 4,
      md: 12,
    },
    capitalize_labels: true,
    label: 'Revenue',
    dimensions: ['status'],
    metrics: ['amount'],
    order: {
      percentage: 'asc',
    },
    filters: {},
    status: ['targeting', 'pitched', 'consideration', 'io_sent', 'won'],
  },
  {
    title: 'Product Distribution %YEAR%',
    key: 'monthly-product-revenue',
    source: 'opportunity-advanced',
    type: 'bar_stacked',
    size: {
      lg: 8,
      md: 12,
    },
    capitalize_labels: true,
    config: {
      date_dimension_format: 'MMMM YYYY',
      click_key: 'product',
      click_filter_name: 'Product',
    },
    dimensions: ['quarter', 'product'],
    metrics: ['amount'],
    filters: {
      dynamic_filter: 'is:pipeline',
    },
    dynamic_options: [
      {
        target: 'dimensions',
        sub_target: '0',
        options: [
          { text: 'Quarter', value: 'quarter' },
          { text: 'Month', value: 'month' },
        ],
      },
      {
        target: 'filters',
        sub_target: 'dynamic_filter',
        options: [
          { text: 'Pipeline', value: 'is:pipeline' },
          { text: 'Sold', value: 'is:sold' },
        ],
      },
    ],
  },
  {
    title: 'Sales Rep Distribution %YEAR%',
    key: 'monthly-sales-rep-revenue',
    source: 'opportunity-advanced',
    type: 'bar_stacked',
    size: {
      lg: 8,
      md: 12,
    },
    capitalize_labels: true,
    config: {
      date_dimension_format: 'MMMM YYYY',
      click_key: 'sr',
      click_filter_name: 'Sales Rep',
    },
    dimensions: ['quarter', 'sales_rep', 'sales_rep_id'],
    metrics: ['amount'],
    filters: {
      dynamic_filter: 'is:pipeline',
    },
    dynamic_options: [
      {
        target: 'dimensions',
        sub_target: '0',
        options: [
          { text: 'Quarter', value: 'quarter' },
          { text: 'Month', value: 'month' },
        ],
      },
      {
        target: 'filters',
        sub_target: 'dynamic_filter',
        options: [
          { text: 'Pipeline', value: 'is:pipeline' },
          { text: 'Sold', value: 'is:sold' },
        ],
      },
    ],
  },
  {
    title: 'Sales Rep Revenue vs Plan',
    key: 'sales-rep-revenue-vs-goal',
    source: 'financial_plan',
    type: 'bar_marker',
    size: {
      lg: 4,
      md: 12,
    },
    dimensions: ['sales_rep', 'sales_rep_id'],
    capitalize_labels: true,
    config: {},
    metrics: ['goal_sold', 'goal'],
    filters: {
      type: 'sales_rep_consolidated_goal',
    },
  },
  {
    title: 'Opportunity Stage by Sales Rep',
    key: 'opp-by-sales-rep',
    source: 'opportunity-advanced',
    type: 'bar_horizontal',
    size: {
      lg: 8,
      md: 12,
    },
    capitalize_labels: true,
    config: {},
    dimensions: ['sales_rep', 'status', 'sales_rep_id'],
    metrics: ['count'],
    filters: {
      dynamic_filter: 'is:open',
    },
    dynamic_options: [
      {
        target: 'filters',
        sub_target: 'dynamic_filter',
        options: [
          { text: 'Open', value: 'is:open' },
          { text: 'Sold', value: 'is:sold' },
        ],
      },
    ],
  },
  {
    title: 'Count by Stage %YEAR%',
    key: 'opp-by-stage',
    source: 'opportunity-advanced',
    type: 'funnel',
    size: {
      lg: 4,
      md: 12,
    },
    capitalize_labels: true,
    label: 'Revenue',
    dimensions: ['status'],
    metrics: ['count'],
    order: {
      percentage: 'asc',
    },
    filters: {},
    status: ['targeting', 'pitched', 'consideration', 'io_sent', 'won'],
  },
  // {
  //   title: 'Agency by Sales Rep',
  //   key: 'agency-by-sales-rep',
  //   source: 'opportunity-advanced',
  //   type: 'bar_proportional',
  //   size: {
  //     lg: 12,
  //     md: 12,
  //   },
  //   capitalize_labels: true,
  //   config: {},
  //   dimensions: ['sales_rep', 'agency', 'sales_rep_id'],
  //   metrics: ['amount'],
  //   filters: {
  //     dynamic_filter: 'is:pipeline',
  //   },
  //   dynamic_options: {
  //     target: 'filters',
  //     sub_target: 'dynamic_filter',
  //     options: [
  //       { text: 'Pipeline', value: 'is:pipeline' },
  //       { text: 'Sold', value: 'is:sold' },
  //     ],
  //   },
  // },
  {
    title: 'Agency Distribution %YEAR%',
    key: 'agency-revenue-distribution',
    source: 'opportunity-advanced',
    type: 'treemap',
    size: {
      lg: 12,
      md: 12,
    },
    dimensions: ['agency', 'agency_id'],
    metrics: ['amount'],
    filters: {
      dynamic_filter: 'is:pipeline',
    },
    config: {
      click_key: 'agency',
      click_filter_name: 'Agency',
    },
    dynamic_options: [
      {
        target: 'filters',
        sub_target: 'dynamic_filter',
        options: [
          { text: 'Pipeline', value: 'is:pipeline' },
          { text: 'Sold', value: 'is:sold' },
        ],
      },
    ],
  },
  {
    title: 'Advertiser Distribution %YEAR%',
    key: 'advertiser-revenue-distribution',
    source: 'opportunity-advanced',
    type: 'treemap',
    size: {
      lg: 12,
      md: 12,
    },
    dimensions: ['advertiser', 'advertiser_id'],
    metrics: ['amount'],
    filters: {
      dynamic_filter: 'is:pipeline',
    },
    config: {
      click_key: 'advertiser',
      click_filter_name: 'Advertiser',
    },
    dynamic_options: [
      {
        target: 'filters',
        sub_target: 'dynamic_filter',
        options: [
          { text: 'Pipeline', value: 'is:pipeline' },
          { text: 'Sold', value: 'is:sold' },
        ],
      },
    ],
  },
] as any[]
export default opts
// export default opts.filter((opt) => opt.key === 'agency-revenue-distribution')
