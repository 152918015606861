import SelectOption from '@/models/interface/SelectOption'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import User from '@/models/User'
import { buildColorPool } from '@/models/Util'
import SalesActivityDriver from '@/pages/ManagementDashboard/views/drivers/SalesActivityDriver'
import router from '@/Routes'
import SystemtModule from '@/store/SystemModule'
import { startCase } from 'lodash'
import numeral from 'numeral'
import { getModule } from 'vuex-module-decorators'
import DateRangeFilter from '../../filters/DateRangeFilter'
import PeriodGroup from '../../filters/PeriodGroup'
import RegionSalesRepGroup from '../../filters/RegionSalesRepGroup'
import { FILTERS } from '../../helpers'

export default class PitchedAmountWidget extends SystemDashboardWidget {
  public title: string = 'Pitched Amount'

  public key: string = 'pitched-amount'

  public view: string = 'chart'

  public subView: any = 'line'

  public loaderSize: string = '350px'

  public size: any = {
    lg: 12,
    md: 12,
  }

  public local_user: User = new User()

  public auth_user: any = getModule(SystemtModule).user

  public dimensions = ['date', 'activity']

  public metrics = ['amount']

  public driverRequest: any = 'pitchedKPI'

  constructor(args: any = null) {
    super()

    if (args && args.dimensions) {
      this.dimensions = args.dimensions
    }

    if (args && args.metrics) {
      this.metrics = args.metrics
    }

    if (args && args.filters) {
      this.filters = Object.assign(this.filters, args.filters)
    }
    if (args && args.internalFilters) {
      this.internalFilters = Object.assign(this.internalFilters, args.internalFilters)
    }

    if (args && args.rightCol) {
      this.rightCol = args.rightCol
    }

    if (args && args.leftCol) {
      this.leftCol = args.leftCol
    }

    if (args && args.size) {
      this.size = args.size
    }

    if (args && args.title) {
      this.title = args.title
    }

    if (args && args.key) {
      this.key = args.key
    }

    if (args && args.driverRequest) {
      this.driverRequest = args.driverRequest
    }
    if (args && args.internalQuery) {
      this.internalQuery = args.internalQuery
    }
    if (args && args.perPage) {
      this.perPage = args.perPage
    }
  }

  public internalQuery: any = ['group_mode:week']

  public leftCol: any = [
    new PeriodGroup({
      pushOption: new SelectOption('Day', 'day'),
      target: 'internalFilters',
      key: 'group_mode',
    }),
  ]

  public rightCol: any = [new DateRangeFilter({ target: 'internalFilters' })]

  public filters: any = {
    region: 'all',
    product: 'all',
  }

  public internalFilters: any = {
    group_mode: 'week',
    ...FILTERS.YTM,
  }

  public get has_cross_region_access() {
    return this.local_user!.canOptions('management_dashboard', 'cross_region')
  }

  public get filterToQuery() {
    let query = []

    if (this.filters.product !== 'all') {
      query.push(`product:${this.filters.product}`)
    }

    if (this.filters.region !== 'all') {
      query.push(`region:${this.filters.region}`)
    }
    query.push({
      start: this.internalFilters.start,
      end: this.internalFilters.end,
      shortcut: this.internalFilters.shortcut,
    })

    return query
  }

  public get payload() {
    const query = [...new Set([...this.query, ...this.internalQuery, ...this.filterToQuery])]

    return {
      query,
      ...this.pagination,
    }
  }

  public async beforeDataRequest() {
    let _router = router.currentRoute

    const { query } = _router

    if (query.target && typeof query.target === 'string') {
      this.local_user = await User.find(query.target)
    } else {
      // @ts-ignore
      this.local_user = getModule(SystemtModule).user
    }

    if (this.auth_user.region) {
      this.filters.region = this.auth_user.region
    } else if (!this.has_cross_region_access) {
      this.filters.region = 'all'
    }

    if (this.local_user.manager_id) {
      this.internalQuery.push(`sr:${this.local_user.id}`)
    }

    return new Promise(resolve => {
      setTimeout(() => {
        resolve(true)
      }, 500)
    })
  }

  public async dataSource() {
    await this.beforeDataRequest()
    let requests: any = new SalesActivityDriver()
    return requests[this.driverRequest]({
      context: this.payload,
      ignore_logic: true,
      module: null,
    }).then((res: any) => res.data)
  }

  public formatters: any = {
    yaxis: (_: SystemDashboardWidget, value: any) => `${numeral(value).format('0,0')}`,
    label: (_: SystemDashboardWidget, value: any) => `${numeral(value).format('0,0')}`,
    tooltip: (_: SystemDashboardWidget, value: any) => [numeral(value).format('0,0')],
  }

  protected dataMapper(data: any): Promise<any> {
    const series: number[] = []
    const data_series: any[] = []
    const labels: any[] = []
    const colors: string[] = []
    const series_ids: string[] = []
    let total = 0
    let total_cumulative: any[] = []

    const groups: string[] = []

    let x_series: string[] = []

    // Init Series
    data.forEach((item: any, idx: number) => {
      const label = item[this.dimensions[1]]
      const group_index = groups.indexOf(label)
      if (group_index === -1) {
        groups.push(label)
        data_series.push({ name: startCase(label), data: [] })
      }

      const x_label = item[this.dimensions[0]]

      if (x_series.indexOf(x_label) === -1) {
        x_series.push(x_label)
      }
    })

    const color_pool = buildColorPool(groups.length)

    data_series.forEach((item: any, idx: number) => {
      x_series.forEach(() => {
        item.data.push(0)
      })
      if (item.name === 'CCL') {
        colors.push('#555c6e')
      } else if (item.name === 'SSL') {
        colors.push('#00C1DE')
      } else {
        colors.push(color_pool(idx))
      }
    })

    data.forEach((item: any, idx: number) => {
      const label = item[this.dimensions[0]]
      const group_label = item[this.dimensions[1]]
      const group_index = groups.indexOf(group_label)
      const x_index = x_series.indexOf(label)

      let val = Number(item[this.metrics[0]])
      if (isNaN(val)) {
        val = 0
      }
      if (!total_cumulative[group_index]) {
        total_cumulative[group_index] = 0
      }

      total += val
      total_cumulative[group_index] += val

      series.push(val)

      data_series[group_index].data[x_index] += val

      if (['product', 'region', 'deal_type'].includes(this.dimensions[1])) {
        series_ids[group_index] = item[this.dimensions[1]].toLowerCase().replaceAll(' ', '_')
      } else if (this.dimensions[2]) {
        series_ids[group_index] = item[this.dimensions[2]]
      }
    })

    // Udpate Settings
    this.chartSettings.colors = colors
    this.chartSettings.xaxis = {
      categories: x_series,
    }
    this.chartSettings.labels = labels

    return Promise.resolve(data_series)
  }
}
