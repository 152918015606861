import CimWorkloadModule from '@/models/CimWorkloadModule'
import SystemDashboard from '@/models/SystemDashboard'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import router from '@/Routes'
import PeriodGroup from '../filters/PeriodGroup'
import RegionSalesRepGroup from '../filters/RegionSalesRepGroup'
import { FILTERS } from '../helpers'
import ActivityThisWeekWidget from '../widgets/finance/ActivityThisWeekWidget'
import NumberOfTasksWidget from '../widgets/finance/NumberOfTasksWidget'
import SalesActivitySummaryWidget from '../widgets/finance/SalesActivitySummaryWidget'
import RegionProductFilterWidget from '../widgets/others/RegionProductFilterWidget'

export default class CIMWorkloadModule extends SystemDashboard {
  public title: string = 'Marketing Workload'

  public key: string = 'marketing_workload'

  public get snapshot_id() {
    return router.currentRoute.query.snapshot
  }

  public querySettings = {
    company_fields: [
      {
        name: 'agency',
        key: 'agency',
        type: 'agency',
      },
      {
        name: 'advertiser',
        key: 'advertiser',
        type: 'advertiser',
      },
    ],
    custom_fields: [
      {
        name: 'product:ssl',
        value: 'product:ssl',
      },
      {
        name: 'product:ccl',
        value: 'product:ccl',
      },
      {
        name: 'deal_type:direct',
        value: 'deal_type:direct',
      },
      {
        name: 'deal_type:3rd Party',
        value: 'deal_type:3rd_party',
      },
    ],
  }

  public filters = {
    product: 'all',
    region: 'all',
    ...FILTERS.LAST_4_WEEKS,
  }

  public groups = {
    view: 'region',
    period: 'week',
  }

  public get periodGroups() {
    return new PeriodGroup({
      concatOptions: [
        { text: 'Year', value: 'year' },
        { text: 'Day', value: 'day' },
      ],
    })
  }

  public widgets: SystemDashboardWidget[] = [
    new RegionProductFilterWidget({
      rightCol: [this.periodGroups],
    }),

    new NumberOfTasksWidget({
      leftCol: [this.periodGroups, new RegionSalesRepGroup()],
      format_totals: '0',
      size: {
        lg: 8,
        md: 8,
        sm: 12,
      },
      internalFilters: {
        ignore_dimension: true,
        ignore_metrics: true,
      },
      dimensions: ['date', 'name'],
    }),

    new SalesActivitySummaryWidget({
      title: 'Number of Tasks Summary',
      key: 'number-of-tasks-summary',
      internalGroups: {
        group_mode: 'month',
        group_role: 'individual',
      },
      internalFilters: {
        ...FILTERS.YEAR,
      },
      internalQuery: ['summary:30,7'],
      dataSourceRequest: (payload: any) => {
        let request = new CimWorkloadModule()
        return request.numberOfTaskRequest(payload).then((response: any) => response.result)
      },
    }),

    new ActivityThisWeekWidget({
      title: 'Pending Tasks',
      key: 'pending-tasks',
      size: {
        lg: 12,
        md: 12,
        sm: 12,
      },
      bordered: true,
      allowAll: true,
      noTotal: true,
      metrics: ['pipeline'],
      dimensions: [],
      // leftCol: [new RegionDashboardFilter()],
      // rightCol: [new ProductDashboardFilter()], //TODO check with vini if this should contain filters
      internalFilters: {
        ...FILTERS.YEAR,
      },
      internalQuery: [],
      internalGroups: {
        product: 'all',
        group_mode: 'quarter',
      },
      fields: [
        {
          key: 'title',
          label: 'Title',
          class: 'text-center align-middle text-capitalize',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
        },

        {
          key: 'owner',
          label: 'Owner',
          class: 'text-center align-middle text-capitalize ',
          show: true,
          thClass: 'align-middle text-center  ',
          sortable: true,
        },
        {
          key: 'assigned_to',
          label: 'Assigned To',
          class: 'text-center align-middle text-capitalize ',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
        },

        {
          key: 'status',
          label: 'Status',
          class: 'text-center align-middle text-capitalize ',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
        },

        {
          key: 'created_at',
          label: 'Created At',
          class: 'text-center align-middle text-capitalize',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'date',
        },
        {
          key: 'due_at',
          label: 'Due At',
          class: 'text-center align-middle text-capitalize',
          show: true,
          thClass: 'align-middle text-center',
          sortable: true,
          type: 'date',
        },
      ],
      perPage: 100,
      dataSourceRequest: (payload: any) => {
        let request = new CimWorkloadModule()
        return request.pendingTask(payload)
      },
      cellSlots: [
        {
          key: 'title',
          component: 'TableColLink', // also import this component in parent location
          slotProps: (data: any) => ({
            link: `tasks/${data.item.id}`,
            innerText: data.item.title,
          }),
        },
      ],
    }),

    new NumberOfTasksWidget({
      title: 'Number of Media Plans',
      key: 'number-of-media-plans',
      leftCol: [
        this.periodGroups,
        new RegionSalesRepGroup({
          pushOption: { text: 'Account Manager', value: 'account_manager' },
        }),
      ],
      format_totals: '0',
      dimensions: ['date', 'name'],
      metrics: ['count'],
      internalFilters: {
        ...FILTERS.YEAR,
        ignore_dimension: true,
        ignore_metrics: true,
      },
      size: {
        lg: 8,
        md: 8,
        sm: 12,
      },
      dataSourceRequest: (payload: any) => {
        let request = new CimWorkloadModule()
        return request.numberOfMediaplans(payload).then((response: any) => response.result)
      },
    }),

    new SalesActivitySummaryWidget({
      title: 'Number of Media Plans Summary',
      key: 'number-of-media-plans-summary',
      internalGroups: {
        group_mode: 'month',
        group_role: 'individual',
      },
      internalFilters: {
        ...FILTERS.YEAR,
      },
      internalQuery: ['summary:30,7'],
      dataSourceRequest: (payload: any) => {
        let request = new CimWorkloadModule()
        return request.numberOfMediaplans(payload).then((response: any) => response.result)
      },
    }),

    new NumberOfTasksWidget({
      title: 'Number of Revenue Assigned',
      key: 'number-of-revenue-assigned',
      leftCol: [
        this.periodGroups,
        new RegionSalesRepGroup({
          pushOption: { text: 'Account Manager', value: 'account_manager' },
        }),
      ],
      format_totals: '$0,00a',
      dimensions: ['date', 'name'],
      metrics: ['amount'],
      internalFilters: {
        ...FILTERS.YEAR,
        ignore_dimension: true,
        ignore_metrics: true,
      },
      size: {
        lg: 8,
        md: 8,
        sm: 12,
      },
      dataSourceRequest: (payload: any) => {
        let request = new CimWorkloadModule()
        return request.numberOfRevenueAssigned(payload).then((response: any) => response.result)
      },
    }),

    new SalesActivitySummaryWidget({
      title: 'Number of Revenue Assigned Summary',
      key: 'number-of-revenue-assigned-summary',
      internalGroups: {
        group_mode: 'month',
        group_role: 'individual',
      },
      internalFilters: {
        ...FILTERS.YEAR,
      },
      internalQuery: ['summary:30,7'],
      format_totals: '$0,00a',
      dataSourceRequest: (payload: any) => {
        let request = new CimWorkloadModule()
        return request.numberOfRevenueAssigned(payload).then((response: any) => response.result)
      },
    }),

    new NumberOfTasksWidget({
      title: 'Number of Media Plan Won',
      key: 'number-of-mediaplan-won',
      leftCol: [
        this.periodGroups,
        new RegionSalesRepGroup({
          pushOption: { text: 'Account Manager', value: 'account_manager' },
        }),
      ],
      dimensions: ['date', 'name'],
      metrics: ['count'],
      format_totals: '0',

      internalFilters: {
        ...FILTERS.YEAR,

        ignore_dimension: true,
        ignore_metrics: true,
      },
      size: {
        lg: 8,
        md: 8,
        sm: 12,
      },
      dataSourceRequest: (payload: any) => {
        let request = new CimWorkloadModule()
        return request.numberOfMediaplansWon(payload).then((response: any) => response.result)
      },
    }),

    new SalesActivitySummaryWidget({
      title: 'Number of Media Plan Won Summary',
      key: 'number-of-mediaplan-won-summary',
      internalGroups: {
        group_mode: 'month',
        group_role: 'individual',
      },
      internalFilters: {
        ...FILTERS.YEAR,
      },
      internalQuery: ['summary:30,7'],
      dataSourceRequest: (payload: any) => {
        let request = new CimWorkloadModule()
        return request.numberOfMediaplansWon(payload).then((response: any) => response.result)
      },
    }),

    new NumberOfTasksWidget({
      title: 'Number of Advertiser',
      key: 'number-of-advertiser',
      leftCol: [
        this.periodGroups,
        new RegionSalesRepGroup({
          pushOption: { text: 'Account Manager', value: 'account_manager' },
        }),
      ],
      dimensions: ['date', 'name'],
      metrics: ['count'],
      format_totals: '0',
      internalFilters: {
        ...FILTERS.YEAR,
        ignore_dimension: true,
        ignore_metrics: true,
      },
      size: {
        lg: 8,
        md: 8,
        sm: 12,
      },
      dataSourceRequest: (payload: any) => {
        let request = new CimWorkloadModule()
        return request.numberOfAdvertiser(payload).then((response: any) => response.result)
      },
    }),
    new SalesActivitySummaryWidget({
      title: 'Number of Advertiser Summary',
      key: 'number-of-advertiser-summary',
      internalGroups: {
        group_mode: 'month',
        group_role: 'individual',
      },
      internalFilters: {
        ...FILTERS.YEAR,
      },
      internalQuery: ['summary:30,7'],
      dataSourceRequest: (payload: any) => {
        let request = new CimWorkloadModule()
        return request.numberOfAdvertiser(payload).then((response: any) => response.result)
      },
    }),

    new NumberOfTasksWidget({
      title: 'Number of Agencies',
      key: 'number-of-agencies',
      format_totals: '0',
      leftCol: [
        this.periodGroups,
        new RegionSalesRepGroup({
          pushOption: { text: 'Account Manager', value: 'account_manager' },
        }),
      ],
      dimensions: ['date', 'name'],
      metrics: ['count'],
      internalFilters: {
        ...FILTERS.YEAR,
        ignore_dimension: true,
        ignore_metrics: true,
      },
      size: {
        lg: 8,
        md: 8,
        sm: 12,
      },
      dataSourceRequest: (payload: any) => {
        let request = new CimWorkloadModule()
        return request.numberOfAgencies(payload).then((response: any) => response.result)
      },
    }),

    new SalesActivitySummaryWidget({
      title: 'Number of Agencies Summary',
      key: 'number-of-agency-summary',
      internalGroups: {
        group_mode: 'month',
        group_role: 'individual',
      },
      internalFilters: {
        ...FILTERS.YEAR,
      },
      internalQuery: ['summary:30,7'],
      dataSourceRequest: (payload: any) => {
        let request = new CimWorkloadModule()
        return request.numberOfAgencies(payload).then((response: any) => response.result)
      },
    }),
  ]
}
