import HamiltonModule from '@/models/HamiltonModule'
import { FILTERS } from '@/pages/ManagementDashboard/shared/helpers'
import BigNumber from 'bignumber.js'
import { DateTime } from 'luxon'

export default {
  title: 'YTD Through Yesterday',
  key: 'publisher_ytd_through_yesterday',
  source: 'request',
  request: {
    module: new HamiltonModule(),
    exec: 'today',
  },
  pre_load: {},
  type: 'table',
  size: {
    lg: 3,
    md: 3,
  },
  capitalize_labels: true,
  config: {
    allowAll: true,
    fields: [
      {
        key: 'publisher',
        label: 'Publisher',
        class: 'text-center align-middle',
        show: true,
        thClass: 'align-middle text-center',
        sortable: true,
      },
      {
        key: 'impressions',
        label: 'Impressions',
        class: 'text-center align-middle',
        show: true,
        thClass: 'align-middle text-center',
        sortable: true,
        filter: true,
        type: 'abbreviate',
        total: 'abbreviate',
      },
      {
        key: 'vcr',
        label: 'VCR',
        class: 'text-center align-middle',
        show: true,
        thClass: 'align-middle text-center',
        sortable: false,
        filter: false,
        type: 'percentage',
        total: 'percentage',
        customTotal: (data:any) => {
          let starts = data.reduce((acc:any, cur:any) => acc + Number(cur.starts), 0)
          let completes = data.reduce((acc:any, cur:any) => acc + Number(cur.completes), 0)
          return completes / starts
        },
      },
    ],
  },
  metrics: [],
  dimensions: [],
  query: [],
  filters: {
    dynamic_filter: {
      ...FILTERS.YTD_THROUGH_YESTERDAY,
    },
    widget_filter: {
      select_by: 'publisher_vcr',
    },
    order_by: 'impressions',
  },
  order: {},
  dynamic_options: {},
}
