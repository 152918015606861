import SystemDashboard from '@/models/SystemDashboard'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import moment from 'moment'
import SoldOfPlanWidget from '../widgets/finance/SoldOfPlanWidget'
import SoldOfForecastWidget from '../widgets/finance/SoldOfForecastWidget'
import RegionProductFilterWidget from '../widgets/others/RegionProductFilterWidget'
import SoldToPlanWidget from '../widgets/finance/SoldToPlanWidget'
import QuarterOverview from '../widgets/finance/QuarterOverview'
import ClosedThisWeek from '../widgets/finance/ClosedThisWeek'
import WeeklyOverview from '../widgets/finance/WeeklyOverview'
import OpportunityStatusDistribution from '../widgets/finance/OpportunityStatusDistribution'
import InvoicedToPlanYear from '../widgets/finance/InvoicedToPlanYear'
import RegionRevenueBarWidget from '../widgets/finance/RegionRevenueBarWidget'
import ProductRevenueCumulativeYear from '../widgets/finance/ProductRevenueCumulativeYear'
import MLBDelivered from '../widgets/delivery/MLBDelivered'
import AgencyDistributionYear from '../widgets/finance/AgencyDistributionYear'
import MonthlyProductDistribution from '../widgets/finance/MonthlyProductDistribution'

import SoldToForecastYear from '../widgets/finance/SoldToForecastYear'

import PipelineToForecastYearWidget from '../widgets/finance/PipelineToForecastYearWidget'

// TODO @vini, i checked everything and removed errors also checked request by request in comparison with widgets from V1
export default class OverviewDashboardModule extends SystemDashboard {
  public title = 'Overview'

  public key: string = 'overview_dashboard'

  public filters = {
    product: 'all',
    region: 'all',
  }

  public groups = {
    view: 'region',
    period: 'week',
  }

  public querySettings = {
    company_fields: [
      {
        name: 'agency',
        key: 'agency',
        type: 'agency',
      },
      {
        name: 'advertiser',
        key: 'advertiser',
        type: 'advertiser',
      },
    ],
    custom_fields: [
      {
        name: 'deal_type:Direct',
        value: 'deal_type:direct',
      },
      {
        name: 'deal_type:3rd Party',
        value: 'deal_type:3rd_party',
      },
    ],
    user_fields: [
      {
        name: 'sr',
        key: 'sales_rep_id',
      },
      {
        name: 'sm',
        key: 'sales_manager_id',
      },
    ],
  }

  public get SoldToPlanWidgets() {
    return [
      new SoldToPlanWidget(),
      new SoldToPlanWidget({
        title: 'Q1 Sold to Plan',
        key: 'sold-to-goal-q1',
        dimensions: ['quarter'],
        size: {
          lg: 2,
          md: 6,
        },
        internalFilters: {
          quarter: 1,
        },
      }),
      new SoldToPlanWidget({
        title: 'Q2 Sold to Plan',
        key: 'sold-to-goal-q2',
        dimensions: ['quarter'],
        size: {
          lg: 2,
          md: 6,
        },
        internalFilters: {
          quarter: 2,
        },
      }),
      new SoldToPlanWidget({
        title: 'Q3 Sold to Plan',
        key: 'sold-to-goal-q3',
        dimensions: ['quarter'],
        size: {
          lg: 2,
          md: 6,
        },
        internalFilters: {
          quarter: 3,
        },
      }),
      new SoldToPlanWidget({
        title: 'Q4 Sold to Plan',
        key: 'sold-to-goal-q4',
        dimensions: ['quarter'],
        size: {
          lg: 2,
          md: 6,
        },
        internalFilters: {
          quarter: 4,
        },
      }),
    ]
  }

  public get SoldToForecastYearWidgets() {
    return [
      new SoldToForecastYear(),
      new SoldToForecastYear({
        title: 'Q1 Sold to Forecast',
        key: 'sold-to-reforecast-q1',
        dimensions: ['quarter'],
        size: {
          lg: 2,
          md: 6,
        },
        internalFilters: {
          quarter: 1,
        },
      }),
      new SoldToForecastYear({
        title: 'Q2 Sold to Forecast',
        key: 'sold-to-reforecast-q2',
        dimensions: ['quarter'],
        size: {
          lg: 2,
          md: 6,
        },
        internalFilters: {
          quarter: 2,
        },
      }),
      new SoldToForecastYear({
        title: 'Q3 Sold to Forecast',
        key: 'sold-to-reforecast-q3',
        dimensions: ['quarter'],
        size: {
          lg: 2,
          md: 6,
        },
        internalFilters: {
          quarter: 3,
        },
      }),
      new SoldToForecastYear({
        title: 'Q4 Sold to Forecast',
        key: 'sold-to-reforecast-q4',
        dimensions: ['quarter'],
        size: {
          lg: 2,
          md: 6,
        },
        internalFilters: {
          quarter: 4,
        },
      }),
    ]
  }

  public get PipelineToForecastYearWidgets() {
    return [
      new PipelineToForecastYearWidget(),
      new PipelineToForecastYearWidget({
        title: 'Q1 Pipeline to Forecast',
        key: 'pipeline-to-reforecast-q1',
        dimensions: ['quarter'],
        size: {
          lg: 2,
          md: 6,
        },
        internalFilters: {
          quarter: 1,
        },
      }),
      new PipelineToForecastYearWidget({
        title: 'Q2 Pipeline to Forecast',
        key: 'pipeline-to-reforecast-q2',
        dimensions: ['quarter'],
        size: {
          lg: 2,
          md: 6,
        },
        internalFilters: {
          quarter: 2,
        },
      }),
      new PipelineToForecastYearWidget({
        title: 'Q3 Pipeline to Forecast',
        key: 'pipeline-to-reforecast-q3',
        dimensions: ['quarter'],
        size: {
          lg: 2,
          md: 6,
        },
        internalFilters: {
          quarter: 3,
        },
      }),
      new PipelineToForecastYearWidget({
        title: 'Q4 Pipeline to Forecast',
        key: 'pipeline-to-reforecast-q4',
        dimensions: ['quarter'],
        size: {
          lg: 2,
          md: 6,
        },
        internalFilters: {
          quarter: 4,
        },
      }),
    ]
  }

  public get AgencyAdvertiserDistributionYearWidgets() {
    return [
      new AgencyDistributionYear(),
      new AgencyDistributionYear({
        title: `Advertiser Distribution ${moment().format('YYYY')}`,
        key: 'advertiser-revenue-distribution',
        dimensions: ['advertiser', 'advertiser_id'],
      }),
    ]
  }

  public widgets: SystemDashboardWidget[] = [
    new RegionProductFilterWidget(),
    ...this.SoldToPlanWidgets,

    ...this.SoldToForecastYearWidgets,

    ...this.PipelineToForecastYearWidgets,

    new QuarterOverview(),

    new QuarterOverview({
      title: 'Region Overview',
      dimensions: ['region'],
      key: 'region-goal',
      metrics: [
        'goal',
        'goal_forecast',
        'goal_pipeline',
        'goal_sold',
        'goal_achieved',
        'last_year_goal',
        'last_year_goal_pipeline',
        'last_year_goal_sold',
        'last_year_goal_achieved',
      ],
      updateFields: (fields: any) => {
        fields[0].label = 'Region'
        fields[0].key = 'region'
        return fields
      },
    }),
    new ClosedThisWeek(),
    new WeeklyOverview(),
    new OpportunityStatusDistribution(),
    new InvoicedToPlanYear(),
    new RegionRevenueBarWidget(),
    new ProductRevenueCumulativeYear(),
    new ProductRevenueCumulativeYear({
      dimensions: ['month', 'region'],
      key: 'monthly-region-revenue-cumulative',
      title: `Region Revenue Cumulative ${moment().format('YYYY')}`,
    }),

    new MLBDelivered({
      title: 'Delivered vs Goal',
      key: 'mlb-gauge',
      dimensions: [],
      metrics: ['delivered', 'goal'],
      size: {
        lg: 4,
        md: 12,
      },
      filters: {
        company_id: 'f37c9dd6-4776-4122-8a37-37d67f4ff47a',
      },
    }),
    ...this.AgencyAdvertiserDistributionYearWidgets,
    new MonthlyProductDistribution(),
    new MonthlyProductDistribution({
      dimensions: ['month', 'region'],
      title: `Monthly Region Distribution ${moment().format('YYYY')}`,
      key: 'monthly-region-revenue',
    }),

    new MonthlyProductDistribution({
      dimensions: ['month', 'deal_type'],
      title: `Direct vs 3rd Party Distribution ${moment().format('YYYY')}`,
      key: 'monthly-source-revenue',
    }),
  ]
}
