
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import VueApexCharts from 'vue-apexcharts'
import ViewModel from '@/models/ViewModel'
import Widget from '@/components/Widget/Widget.vue'
import FinancialPlan from '@/models/FinancialPlan'
import MediaPlan from '@/models/MediaPlan'
// @ts-ignore
import { VueFunnelGraph } from 'vue-funnel-graph-js'
import Invoice from '@/models/Invoice'
import DataTable from '@/components/DataTable/index.vue'
import Opportunity from '@/models/Opportunity'
import numeral from 'numeral'
import OpportunityActivity from '@/models/OpportunityActivity'
import ReportBuilder from '@/models/ReportBuilder'
import { response } from 'express'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import Company from '@/models/Company'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import moment from 'moment'
import DatePickerDate from '@/models/DatePickerDate'
import { clone, cloneDeep } from 'lodash'
import { EventBus } from '@/plugins/eventBus'
import settings from './plan-widget-settings'

@Component({
  components: {
    Widget,
    VueApexCharts,
    VueFunnelGraph,
    DataTable,
    DatePicker,
  },
})
export default class PlanWidget extends ViewModel {
  @Ref() readonly containerRef!: HTMLDivElement

  @Ref() readonly dataTable!: HTMLFormElement

  @Ref() readonly chart!: any

  @Prop()
  public widget!: any

  @Prop()
  public request!: any

  @Prop()
  public pre_load!: any

  @Prop({ default: 'Agency Revenue distribution' })
  public title!: string | null

  @Prop({ required: true })
  public widgetKey!: string

  @Prop()
  public year!: number

  @Prop({ default: null })
  public region!: string | null

  @Prop({ default: null })
  public sales_rep!: string | null

  @Prop({ default: false })
  public show_year_note!: boolean

  @Prop({ default: null })
  public agency!: string | null

  @Prop({ required: true })
  public type!: string

  @Prop({ required: true })
  public dimensions!: string[]

  @Prop()
  public formatters!: any

  @Prop()
  public mapper!: any

  @Prop()
  public baseOptionsOverwrite!: any

  @Prop()
  public dynamic_options!: any

  @Prop({ required: true })
  public metrics!: string[]

  @Prop({ default: () => {} })
  public filters!: any

  @Prop({ default: () => [] })
  public queryParams!: string[]

  @Prop({ default: () => ({}) })
  public order!: any

  @Prop({ default: () => ({}) })
  public query!: any

  @Prop({ default: () => ({}) })
  public config!: any

  @Prop({ required: true })
  public source!: string

  @Prop({ default: false })
  public capitalizeLabels!: boolean

  @Prop({ default: false })
  public disableAnimations!: boolean

  @Prop({ default: '' })
  public snapshot_id!: string

  public table_initialized: boolean = false

  public busy: boolean = false

  @Watch('query')
  public onQueryChange(val: any, old: any) {
    if (JSON.stringify(old) !== JSON.stringify(val)) {
      this.updateData()
    }
  }

  public fmtNumeral(value: number) {
    return numeral(value)
  }

  public calculateDiff(value: number, lastYear: number) {
    if (lastYear == 0) {
      return 0
    }
    return (value - lastYear) / lastYear
  }

  public get unsupported_filters() {
    const ret: string[] = []

    if (this.source === 'financial_plan') {
      this.query.forEach((q: string) => {
        if (q.startsWith('agency')) {
          if (!ret.includes('Agency')) ret.push('Agency')
        }
        if (q.startsWith('advertiser')) {
          if (!ret.includes('Advertiser')) ret.push('Advertiser')
        }
        if (q.startsWith('sr:')) {
          if (!ret.includes('Sales Rep')) ret.push('Sales Rep')
        }
        if (q.startsWith('sm:')) {
          if (!ret.includes('Sales Manager')) ret.push('Sales Manager')
        }
        if (q.startsWith('deal_type:')) {
          if (!ret.includes('Deal Type')) ret.push('Deal Type')
        }
      })
    }

    return ret
  }

  @Watch('local_metrics')
  public onMetricsChange(val: any, old: any) {
    if (!this.ready) return
    this.updateData()
  }

  @Watch('local_dimensions')
  public onDimensionsChange(val: any, old: any) {
    if (!this.ready) return
    this.updateData()
  }

  @Watch('local_filters', { deep: true })
  public onFiltersChange(val: any, old: any) {
    if (!this.ready) return
    if (this.request) {
      this.request.module.filters = val
    }
    this.updateData()
  }

  public get container() {
    if (!this.containerRef || !this.containerRef.clientWidth) {
      return {
        width: 800,
        height: 300,
      }
    }

    return {
      width: this.containerRef.clientWidth,
      height: 300,
    }
  }

  public process_id: string | null = null

  public report_data: any = []

  public loading: boolean = false

  public ready: boolean = false

  public total: number = 0

  public records: number = 0

  public series: any[] = []

  public series_raw: number[] = []

  public labels: string[] = []

  public sub_labels: string[] = []

  public colors: string[] = []

  public local_metrics: string[] = []

  public local_dimensions: string[] = []

  public load_filters: boolean = false

  public local_filters: any = {
    dynamic_filters: {},
  }

  public local_query: any = []

  public series_ids: string[] = []

  public report_channel: any = null

  public order_by: any = null

  public table_group_columns: any = ['quarter']

  public table_groups = [
    { text: 'Month', value: 'month' },
    { text: 'Quarter', value: 'quarter' },
  ]

  public show_fields(fields: any) {
    if (this.config.columnGroup) {
      let quarters = ['q1', 'q2', 'q3', 'q4']
      let months = [
        'jan',
        'feb',
        'mar',
        'apr',
        'may',
        'jun',
        'jul',
        'aug',
        'sep',
        'oct',
        'nov',
        'dec',
      ]
      let ignore = ['label', 'total']
      return fields.filter(f => {
        if (!ignore.includes(f.key)) {
          if (this.table_group_columns.includes('quarter') && quarters.includes(f.key)) {
            f.show = true
          } else if (this.table_group_columns.includes('month') && months.includes(f.key)) {
            f.show = true
          } else {
            f.show = false
          }
        }
        return f.show
      })
    }
    return fields.filter(f => f.show)
  }

  public get cardTitle() {
    return this.title?.replaceAll('%YEAR%', this.year.toString())
  }

  public get chartSettings() {
    // @ts-ignore
    return settings[this.type]
  }

  public get options() {
    let options = this.chartSettings.base_options

    if (this.baseOptionsOverwrite && this.baseOptionsOverwrite !== false) {
      options = Object.assign(options, this.baseOptionsOverwrite)
    }

    if (this.disableAnimations !== false) {
      options.chart.animations = {
        enabled: false,
      }
    }

    if (this.type === 'bar_stacked' || this.type === 'funnel') {
      options.xaxis.categories = this.labels
    } else {
      options.labels = this.labels
    }

    if (this.colors && this.colors.length > 0) {
      options.colors = this.colors
    }
    if (this.chartSettings.dataLabelFormatter) {
      options.dataLabels.formatter = (value: number, opt: any) =>
        this.chartSettings.dataLabelFormatter(
          value,
          this.labels,
          this.series_raw,
          this.total,
          opt,
          {
            dimensions: this.local_dimensions,
            metrics: this.local_metrics,
            capitalizeLabels: this.capitalizeLabels,
            config: this.config,
          },
        )
    }
    if (this.chartSettings.tooltipFormatter) {
      options.tooltip.y.formatter = (value: number, opt: any) =>
        this.chartSettings.tooltipFormatter(value, this.labels, this.series_raw, this.total, opt, {
          dimensions: this.local_dimensions,
          metrics: this.local_metrics,
          capitalizeLabels: this.capitalizeLabels,
          config: this.config,
        })
    }
    if (this.chartSettings.legendFormatter) {
      options.legend.formatter = (value: number, opt: any) =>
        this.chartSettings.legendFormatter(value, this.labels, this.series_raw, this.total, opt, {
          dimensions: this.local_dimensions,
          metrics: this.local_metrics,
          capitalizeLabels: this.capitalizeLabels,
          config: this.config,
        })
    }

    if (this.chartSettings.plotFormatter) {
      options.plotOptions.radialBar.dataLabels.value.formatter = (value: number, opt: any) =>
        this.chartSettings.plotFormatter(value, this.labels, this.series_raw, this.total, opt, {
          dimensions: this.local_dimensions,
          metrics: this.local_metrics,
          capitalizeLabels: this.capitalizeLabels,
          config: this.config,
        })
    }

    if (this.chartSettings.totalBarFormatter) {
      options.plotOptions.bar.dataLabels.total.formatter = (value: number, opt: any) =>
        this.chartSettings.totalBarFormatter(value, this.labels, this.series_raw, this.total, opt, {
          dimensions: this.local_dimensions,
          metrics: this.local_metrics,
          capitalizeLabels: this.capitalizeLabels,
          config: this.config,
        })
    }

    if (this.config && this.config.click_key) {
      options.chart.events = {
        click: (_: any, __: any, config: any) => {
          let filter = `${this.config.click_key}:`
          let name = `${this.config.click_key}:`

          if (this.type === 'treemap') {
            filter += this.series_ids[config.dataPointIndex]
            name += this.labels[config.dataPointIndex]
          } else {
            filter += this.series_ids[config.seriesIndex]
            name
              += this.series[config.seriesIndex]?.name
              || this.series[config.seriesIndex]?.activity
              || '-'
          }

          this.$emit('addFilter', { filter, name })
        },
      }
    }

    if (this.formatters && this.formatters.hasOwnProperty('tooltipFormatter')) {
      options.tooltip.y.formatter = (value: number, opt: any) =>
        this.formatters.tooltipFormatter(value, this.labels, this.series_raw, this.total, opt, {
          dimensions: this.local_dimensions,
          metrics: this.local_metrics,
          capitalizeLabels: this.capitalizeLabels,
          config: this.config,
        })
    }

    if (this.formatters && this.formatters.hasOwnProperty('dataLabelFormatter')) {
      options.dataLabels.formatter = (value: number, opt: any) =>
        this.formatters.dataLabelFormatter(value, this.labels, this.series_raw, this.total, opt, {
          dimensions: this.local_dimensions,
          metrics: this.local_metrics,
          capitalizeLabels: this.capitalizeLabels,
          config: this.config,
        })
    }

    if (this.formatters && this.formatters.hasOwnProperty('yaxis')) {
      options.yaxis.labels.formatter = (value: number, opt: any) =>
        this.formatters.yaxis.labels.formatter(
          value,
          this.labels,
          this.series_raw,
          this.total,
          opt,
          {
            dimensions: this.local_dimensions,
            metrics: this.local_metrics,
            capitalizeLabels: this.capitalizeLabels,
            config: this.config,
          },
        )
    }

    return options
  }

  public mapData() {
    if (this.type === 'table') {
      // sort by the first dimension
      this.report_data = this.report_data.sort((a: any, b: any) => {
        if (a[this.local_dimensions[0]] < b[this.local_dimensions[0]]) {
          return -1
        }
        if (a[this.local_dimensions[0]] > b[this.local_dimensions[0]]) {
          return 1
        }
        return 0
      })
      return
    }
    if (this.type === 'custom' || this.type === 'debug') {
      return
    }
    let ret: any = {}

    if (this.mapper) {
      ret = this.mapper(this.report_data, {
        dimensions: this.local_dimensions,
        metrics: this.local_metrics,
        capitalizeLabels: this.capitalizeLabels,
        dateFormatLabels: this.config.date_format_labels,
        config: this.config,
      })
    } else {
      ret = this.chartSettings.mapper(this.report_data, {
        dimensions: this.local_dimensions,
        metrics: this.local_metrics,
        capitalizeLabels: this.capitalizeLabels,
        dateFormatLabels: this.config.date_format_labels,
        config: this.config,
      })
    }

    if (ret.colors) {
      this.colors = ret.colors
    }
    this.total = ret.total
    this.series = ret.series
    this.series_raw = ret.series_raw

    if (ret.series_ids) {
      this.series_ids = ret.series_ids
    } else {
      this.series_ids = []
    }
    if (ret.labels) {
      this.labels = ret.labels
    }
    if (ret.sub_labels) {
      this.sub_labels = ret.sub_labels
    }

    if (this.chart) {
      this.busy = true

      setTimeout(() => {
        this.busy = false
      }, 200)
    }
  }

  public stopLoading() {
    setTimeout(() => {
      this.loading = false
    }, 200)
  }

  public updateData(context: any = null) {
    if (this.type === 'table' && context === null) {
      this.ready = true
      this.stopLoading()
      this.table_initialized = false
      if (this.dataTable) {
        this.dataTable.refresh()
      }
      return new Promise(resolve => resolve([]))
    }

    this.loading = true
    this.resetData()
    let query = [...this.query]

    query.push(`year:${this.year}`)
    if (this.region) {
      query.push(`region:${this.region}`)
    }
    if (this.agency) {
      query.push(`agency_id:${this.agency}`)
    }

    if (
      !this.user!.canOptions('management_dashboard', 'cross_region')
      && this.region === 'national'
    ) {
      query.push('deal_type:direct')
    }

    if (this.sales_rep) {
      query.push(`sr:${this.sales_rep}`)
    }

    const scope = this.filters?.type ?? 'agency'

    query.push(`scope:${scope}`)

    if (this.source === 'report') {
      const report = new ReportBuilder()
      report.dimensions = this.local_dimensions
      report.metrics = this.local_metrics
      report.filters = this.filters
      return report.runReport().then((res: any) => {
        if (typeof res.data.result != 'undefined') {
          this.report_data = res.data.result
          this.mapData()
          this.stopLoading()
          this.ready = true
          this.table_initialized = true

          return this.report_data
        }
        this.process_id = res.data.process_id

        return []
      })
    }
    if (this.source === 'financial_plan') {
      return FinancialPlan.getPlanDashboard({
        dimensions: this.local_dimensions,
        metrics: this.local_metrics,
        filters: {
          year: this.year,
          ...this.local_filters,
        },
        order: this.order,
        query: [...query, ...this.local_query],
        snapshot: this.snapshot_id,
      }).then((res: any) => {
        this.report_data = res.data.result.sort(
          (a: any, b: any) => b[this.local_metrics[0]] - a[this.local_metrics[0]],
        )

        this.mapData()
        this.stopLoading()
        this.ready = true
        this.table_initialized = true

        return this.report_data
      })
    }
    if (this.source === 'opportunity') {
      return Opportunity.buildQuery({
        dimensions: this.local_dimensions,
        metrics: this.local_metrics,
        filters: {
          year: this.year,
          ...this.local_filters,
        },
        order: this.order,
        query: [...query, ...this.local_query],
        snapshot: this.snapshot_id,
      }).then((res: any) => {
        if (this.type !== 'funnel') {
          this.report_data = res.data.result.sort(
            (a: any, b: any) => b[this.local_metrics[0]] - a[this.local_metrics[0]],
          )
        } else {
          this.report_data = res.data.result
        }
        this.mapData()
        this.stopLoading()
        this.ready = true
        this.table_initialized = true

        return this.report_data
      })
    }
    if (this.source === 'opportunity-table') {
      const automated_filters = [...this.local_query]
      if (this.region) {
        automated_filters.push(`region:${this.region}`)
      }

      if (this.sales_rep) {
        automated_filters.push(`sr:${this.sales_rep}`)
      }

      if (this.local_filters.dynamic_filter) {
        automated_filters.push(this.local_filters.dynamic_filter)
      }

      return Opportunity.paginate({
        page_size: context.perPage,
        page: context.currentPage,
        order_by: context.sortBy,
        order: context.sortDesc ? 'desc' : 'asc',
        query: [...context.filter, ...this.query, ...automated_filters],
        mode: 'exclusive',
      }).then((res: any) => {
        this.report_data = res.data
        this.records = res.records
        this.stopLoading()
        this.ready = true
        this.table_initialized = true

        return this.report_data
      })
    }

    if (this.source === 'activity-table') {
      const automated_filters = [...this.local_query]
      if (this.region) {
        automated_filters.push(`region:${this.region}`)
      }

      if (this.sales_rep) {
        automated_filters.push(`sr:${this.sales_rep}`)
      }

      return OpportunityActivity.paginate({
        page_size: context.perPage,
        page: context.currentPage,
        order_by: context.sortBy,
        order: context.sortDesc ? 'desc' : 'asc',
        query: [...context.filter, ...this.query, ...automated_filters],
        mode: 'exclusive',
      }).then((res: any) => {
        this.report_data = res.data
        this.records = res.records
        this.stopLoading()
        this.ready = true
        this.table_initialized = true
        return this.report_data
      })
    }

    if (this.source === 'opportunity-week-card') {
      return Opportunity.weeklyCard({
        dimensions: this.local_dimensions,
        metrics: this.local_metrics,
        filters: {
          year: this.year,
          ...this.local_filters,
        },
        order: this.order,
        query: [...query, ...this.local_query],
        snapshot: this.snapshot_id,
      }).then((res: any) => {
        this.report_data = res.data.result
        this.stopLoading()
        this.ready = true
        this.table_initialized = true

        return this.report_data
      })
    }

    if (this.source === 'activity-amount-summary') {
      return OpportunityActivity.amountSummary({
        dimensions: this.local_dimensions,
        metrics: this.local_metrics,
        filters: {
          year: this.year,
          ...this.local_filters,
        },
        order: this.order,
        query: [...query, ...this.local_query],
        snapshot: this.snapshot_id,
      }).then((res: any) => {
        this.report_data = res.data.result
        this.stopLoading()
        this.ready = true
        this.table_initialized = true

        return this.report_data
      })
    }

    if (this.source === 'week-pipeline') {
      return Opportunity.weekPipeline({
        dimensions: this.local_dimensions,
        metrics: this.local_metrics,
        filters: {
          year: this.year,
          ...this.local_filters,
        },
        order: this.order,
        query: [...query, ...this.local_query],
        snapshot: this.snapshot_id,
      }).then((res: any) => {
        this.report_data = res.data.result
        this.stopLoading()
        this.ready = true
        this.table_initialized = true

        return this.report_data
      })
    }
    if (this.source === 'opportunity-advanced') {
      return Opportunity.buildAdvancedQuery({
        dimensions: this.local_dimensions,
        metrics: this.local_metrics,
        filters: {
          year: this.year,
          ...this.local_filters,
        },
        order: this.order,
        query: [...query, ...this.local_query],
        snapshot: this.snapshot_id,
      }).then((res: any) => {
        if (
          this.type !== 'funnel'
          && this.type !== 'bar_stacked'
          && this.type !== 'bar_horizontal'
        ) {
          this.report_data = res.data.result.sort(
            (a: any, b: any) => b[this.local_metrics[0]] - a[this.local_metrics[0]],
          )
        } else {
          this.report_data = res.data.result
        }
        this.mapData()
        this.stopLoading()
        this.ready = true
        this.table_initialized = true

        return this.report_data
      })
    }
    if (this.source === 'media_plans') {
      return MediaPlan.buildQuery({
        dimensions: this.local_dimensions,
        metrics: this.local_metrics,
        filters: {
          year: this.year,
          ...this.local_filters,
        },
        query: [...query, ...this.local_query],
        snapshot: this.snapshot_id,
      }).then((res: any) => {
        this.report_data = res.data.result.sort(
          (a: any, b: any) => b[this.local_metrics[0]] - a[this.local_metrics[0]],
        )
        this.mapData()
        this.stopLoading()
        this.ready = true
        this.table_initialized = true

        return this.report_data
      })
    }

    if (this.source === 'invoices') {
      return Invoice.buildQuery({
        dimensions: this.local_dimensions,
        metrics: this.local_metrics,
        filters: {
          year: this.year,
          ...this.local_filters,
        },
        query: [...query, ...this.local_query],
        snapshot: this.snapshot_id,
      }).then((res: any) => {
        this.report_data = res.data.result.sort(
          (a: any, b: any) => b[this.local_metrics[0]] - a[this.local_metrics[0]],
        )
        this.mapData()
        this.stopLoading()
        this.ready = true
        this.table_initialized = true

        return this.report_data
      })
    }

    if (this.source === 'company_goal_metrics') {
      return Company.activeGoalMetrics({
        id: this.filters.company_id,
      }).then((res: any) => {
        this.report_data = res.result
        // this.mapData()
        this.stopLoading()
        this.ready = true
        this.table_initialized = true

        return this.report_data
      })
    }

    if (this.source === 'company_realtime') {
      return Company.realtimeMetrics({
        id: this.filters.company_id,
      }).then((res: any) => {
        this.report_data = res.result
        // this.mapData()
        this.stopLoading()
        this.ready = true
        this.table_initialized = true

        return this.report_data
      })
    }

    if (this.source === 'company_goal_status') {
      return Company.activeGoalStatus({
        id: this.filters.company_id,
      }).then((res: any) => {
        this.report_data = res.result
        this.mapData()
        this.stopLoading()
        this.ready = true
        this.table_initialized = true

        return this.report_data
      })
    }

    if (this.source === 'company_goal_30_days') {
      return Company.activeGoal30DaysLegacy({
        id: this.filters.company_id,
      }).then((res: any) => {
        this.report_data = res.result
        this.mapData()
        this.stopLoading()
        this.ready = true
        this.table_initialized = true

        return this.report_data
      })
    }

    if (this.source === 'request') {
      return this.request.module[this.request.exec]({
        context,
        module: this,
        widget: this.widget,
      }).then((res: any) => {
        let data = []

        if (res.result) {
          data = res.result
        } else {
          data = res
        }

        if (this.widget && this.widget.map_response) {
          res.data = this.widget.map_response(data.data ?? data)
        }

        Vue.set(this, 'report_data', data.data ?? data)

        this.mapData()
        this.ready = true
        this.table_initialized = true
        this.records = data.records
        this.stopLoading()

        return this.report_data
      })
    }
  }

  public mounted() {
    Vue.set(this, 'local_metrics', this.metrics)
    Vue.set(this, 'local_dimensions', this.dimensions)
    if (this.widget && Object.keys(this.widget.filters).length) {
      Vue.set(this, 'local_filters', this.filters)
    }
    if (!this.widget) {
      Vue.set(this, 'local_filters', cloneDeep(this.filters))
    }
    Vue.set(this, 'local_query', this.queryParams)

    this.registerEvent()

    // Init filters
    if (
      this.local_filters
      && this.local_filters.dynamic_filter
      && this.config.dynamic_date
      && (!this.local_filters.dynamic_filter.start
        || this.local_filters.dynamic_filter?.start === 'Invalid date')
    ) {
      let shortcut = this.local_filters?.dynamic_filter.shortcut || null
      let start = moment().startOf('month').format('YYYY-MM-DD')
      let end = moment().endOf('month').format('YYYY-MM-DD')

      if (shortcut && shortcut === 'today') {
        start = moment().startOf('day').format('YYYY-MM-DD')
        end = moment().endOf('day').format('YYYY-MM-DD')
      } else if (shortcut && shortcut === 'yesterday') {
        start = moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD')
        end = moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD')
      }

      let picker_date: DatePickerDate = new DatePickerDate(start, end, shortcut || 'month')
      this.local_filters.dynamic_filter = picker_date
    } else if (
      this.local_filters
      && this.local_filters.dynamic_filter
      && this.config.dynamic_date
    ) {
      let { start, end, shortcut } = this.local_filters.dynamic_filter
      let picker_date: DatePickerDate = new DatePickerDate(start, end, shortcut || 'month')
      this.local_filters.dynamic_filter = picker_date
    }

    if (this.widget && this.widget.filters && this.widget.filters.hasOwnProperty('order_by')) {
      this.order_by = this.widget.filters.order_by
    }

    setTimeout(() => {
      this.load_filters = true
      this.updateData()
    }, 100)

    EventBus.$on('sales-activity:group_role', (value: string) => {
      if (this.local_filters && this.local_filters.hasOwnProperty('group_role')) {
        this.local_filters.group_role = value
      }
    })

    // global events that SomeWidget.ts listens)
    if (this.config.listen) {
      this.config.listen.forEach((listener: any) => {
        for (const [key, value] of Object.entries(listener)) {
          EventBus.$on(key, (v: any) => {
            value(v, this)
          })
        }
      })
    }
  }

  public getFieldFoot(key: string) {
    const field_index = this.config.fields.findIndex((f: any) => f.key === key)
    const field = this.config.fields[field_index]

    if (field_index === 0) {
      return 'Total'
    }
    if (!field || !field.total || field.total === 'none') {
      return ''
    }
    let ret = 0

    if (field.customTotal) {
      ret = field.customTotal(this.report_data)
    } else {
      ret = this.report_data.reduce((acc: number, row: any) => acc + Number(row[key]), 0)
    }

    let format = '0,0.00a'

    if (field.total.includes('currency')) {
      format = '$0,0.00a'
    }

    if (field.total.includes('sum')) {
      format = '0,0'
    }

    if (field.total.includes('percentage')) {
      format = '0,0.00%'
    }

    if (field.total.includes('avg')) {
      return numeral(ret / this.report_data.length).format(format)
    }
    return numeral(ret).format(format)
  }

  public getFieldTotal(key: string) {
    return this.report_data.reduce((acc: number, row: any) => acc + Number(row[key]), 0)
  }

  private resetData() {
    this.colors = []
    this.total = 0
    this.series = []
    this.series_raw = []
    this.labels = []
    this.sub_labels = []
  }

  /**
   * Unregister event listeners
   */
  public beforeDestroy() {
    this.unregisterEvent()
    EventBus.$destroy()
  }

  /**
   * Capture the paste event and get the data
   */
  private registerEvent() {
    this.report_channel = Vue.prototype.$echo
      .private(`user.${this.user!.id}`)
      .listen('AsyncReportReady', (e: any) => {
        const instance_id = getModule(SystemtModule)._uuid

        if (instance_id && instance_id !== e.instance_id && e.process_id != this.process_id) return

        if (!e.report_hash) {
          this.loading = false

          return
        }

        ReportBuilder.getReport(e.report_hash)
          .then(response => {
            this.report_data = response.data.result
            this.mapData()
            this.loading = false
            this.ready = true
            this.table_initialized = true
          })
          .catch(error => {
            this.loading = false
          })
      })
  }

  /**
   * Clear paste event to prevent triggering the event outside the page
   */
  private unregisterEvent() {
    Vue.prototype.$echo.private(`user.${this.user!.id}`).stopListening('AsyncReportReady')
  }
}
