import Company from '@/models/Company'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import { buildColorPool } from '@/models/Util'
import { random, startCase } from 'lodash'
import moment from 'moment'
import numeral from 'numeral'

export default class MLBDelivered extends SystemDashboardWidget {
  public title: string = 'MLB Delivered vs Plan'

  public view: string = 'chart'

  public key: string = 'mlb-gauge'

  public subView: string = 'gauge_number'

  public loaderSize: string = '350px'

  public size: any = {
    lg: 4,
    md: 12,
  }

  public dataSourceRequest: any = null

  constructor(args: any = null) {
    super()

    if (args && args.dimensions) {
      this.dimensions = args.dimensions
    }

    if (args && args.metrics) {
      this.metrics = args.metrics
    }

    if (args && args.filters) {
      this.filters = { ...this.filters, ...args.filters }
    }
    if (args && args.internalFilters) {
      this.internalFilters = Object.assign(this.internalFilters, args.internalFilters)
    }

    if (args && args.rightCol) {
      this.rightCol = args.rightCol
    }

    if (args && args.leftCol) {
      this.leftCol = args.leftCol
    }

    if (args && args.size) {
      this.size = args.size
    }

    if (args && args.title) {
      this.title = args.title
    }

    if (args && args.key) {
      this.key = args.key
    }

    if (args && args.noPagination) {
      this.noPagination = args.noPagination
    }

    if (args && args.updateFields) {
      this.fields = args.updateFields(this.fields)
    }

    if (args && args.internalQuery) {
      this.internalQuery = args.internalQuery
    }

    if (args && args.dataSourceRequest) {
      this.dataSourceRequest = args.dataSourceRequest
    }

    if (args && args.noPagination) {
      this.noPagination = args.noPagination
    }

    if (args && args.fields) {
      this.fields = args.fields
    }
  }

  public dimensions = []

  public metrics = ['delivered', 'goal']

  public filters: any = {
    company_id: '96ed6c23-1824-4aa8-bfb6-10b1c98473c8',
  }

  public internalFilters = {
    start: moment().startOf('year').format('YYYY-MM-DD'),
    end: moment().endOf('year').format('YYYY-MM-DD'),
  }

  public get filterToQuery() {
    let query: any = []

    if (this.filters.product !== 'all') {
      query.push(`product:${this.filters.product}`)
    }

    if (this.filters.region !== 'all') {
      query.push(`region:${this.filters.region}`)
    }

    return query
  }

  public get payload() {
    const query = [...new Set([...this.query, ...this.internalQuery, ...this.filterToQuery])]
    const filters = {
      ...this.internalFilters,
    }

    return {
      id: this.filters.company_id, // the endpoint is expecting this id as param, so only this prop will be used
      query,
      filters,
      metrics: this.metrics,
      dimensions: this.dimensions,
    }
  }

  public set_label_to_percentage: boolean = true

  public formatters: any = {
    yaxis: (_: SystemDashboardWidget, value: any) => `${numeral(value).format('0,00%')}`,
    label: (_: SystemDashboardWidget, value: any) => `${numeral(value).format('0,00%')}`,
    tooltip: (widget: SystemDashboardWidget, value: any, opt: any) => {
      const raw_value = widget.rawData[opt.dataPointIndex].delivered

      return [`${numeral(raw_value).format('0.00a')}`, ` (${numeral(value / 100).format('0.00%')})`]
    },
  }

  /**
   * Use for testing payload
   */
  public async testDataSource() {
    return Promise.resolve([
      {
        goal: 100,
        delivered: 50,
        start_at: '2024-01-01',
        end_at: '2024-12-31',
      },
    ])
  }

  public async dataSource() {
    if (this.dataSourceRequest) {
      const res = this.dataSourceRequest(this.payload)

      return res.then((res: any) => res.result)
    }
    return Company.activeGoalStatus(this.payload)
      .then((response: any) => response.result)
      .catch((err: any) => ({ data: [] }))
  }

  public get customData() {
    const delivered = numeral(this.rawData[0]?.delivered).format('0.00a')
    const goal = numeral(this.rawData[0]?.goal).format('0.00a')
    const start_at = this.rawData[0]?.start_at
      ? moment(this.rawData[0]?.start_at).format('MMM DD, YYYY')
      : ''
    const end_at = this.rawData[0]?.end_at
      ? moment(this.rawData[0]?.end_at).format('MMM DD, YYYY')
      : ''

    const date = start_at && end_at ? `${start_at} - ${end_at}` : ''

    return `<div class="row text-center">
      <div class="col">
        <h6>Delivered</h6>
        <h4>${delivered}</h4>
      </div>
      <div class="col">
        <h6>Plan</h6>
        <h4>${goal}</h4>
      </div>
    </div>
    <div class="text-center text-muted">
      ${date}
    </div>
    `
  }

  protected dataMapper(data: any): Promise<any> {
    const series: number[] = []
    const series_raw: number[] = []
    let labels: string[] = []
    const colors: string[] = []
    let total = 0

    const color_pool = buildColorPool(data.length)

    total = data.reduce((acc: number, item: any) => (acc += Number(item[this.metrics[1]])), 0)

    data.forEach((item: any, idx: number) => {
      const val = Number(item[this.metrics[0]])
      const target = Number(item[this.metrics[1]])

      series.push((val / target) * 100)

      series_raw.push(val)
      labels.push(item[this.dimensions[0]])
      colors.push(color_pool(idx))
    })

    if (this.set_label_to_percentage) {
      if (!data || !data[0] || !data[0][this.metrics[1]] || !data[0][this.metrics[0]]) {
        labels = [numeral(0).format('0.00%')]
      } else {
        labels = [
          numeral(Number(data[0][this.metrics[0]]) / Number(data[0][this.metrics[1]])).format(
            '0.00%',
          ),
        ]
      }
    }

    this.chartSettings.colors = colors

    this.chartSettings.xaxis = {
      categories: labels,
    }

    this.chartSettings.labels = labels

    return Promise.resolve(series)
  }
}
