import Opportunity from '@/models/Opportunity'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import moment from 'moment'
import WeekOrMonthFilter from '../../filters/WeekOrMonthFilter'

export default class ClosedBusinessWidget extends SystemDashboardWidget {
  public title: string = 'Closed Business'

  public key: string = 'closed-business'

  public view: string = 'table'

  public size: any = {
    sm: 12,
    md: 12,
    lg: 12,
  }

  // API Filters
  public filters: any = {
    region: 'all',
    product: 'all',
  }

  public internalFilters: any = {
    year: moment().format('YYYY'),
    period: 'this_week',
  }

  public dimensions: string[] = ['sales_rep', 'agency', 'advertiser']

  public metrics: string[] = ['raw_amount', 'revenue_period']

  public noPagination: boolean = true

  public loaderSize: string = '350px'

  public fields: any = [
    {
      key: 'sales_rep',
      label: 'Sales Rep',
      class: 'text-center align-middle',
      show: true,
      type: 'date',
      thClass: 'align-middle text-center',
      sortable: true,
    },
    {
      key: 'agency',
      label: 'Agency',
      class: 'text-center align-middle',
      show: true,
      type: 'capitalize_replace',
      thClass: 'align-middle text-center',
      sortable: true,
      filter: true,
    },
    {
      key: 'advertiser',
      label: 'Advertiser',
      class: 'text-center align-middle',
      show: true,
      type: 'capitalize_replace',
      thClass: 'align-middle text-center',
      sortable: true,
      filter: true,
    },

    {
      key: 'amount',
      label: 'Amount',
      class: 'text-center align-middle',
      show: true,
      type: 'currency',
      thClass: 'align-middle text-center',
      sortable: true,
      total: 'currency_abbreviate',
    },
    {
      key: 'revenue_period',
      label: 'Revenue Period',
      class: 'text-center align-middle',
      show: true,
      type: 'text',
      thClass: 'align-middle text-center',
      sortable: true,
    },
  ]

  public rightCol: any = [
    new WeekOrMonthFilter(),
  ]

  public get payload() {
    const query = [...new Set([...this.query, ...this.internalQuery])]

    const filters = {
      ...this.filters,
      ...this.internalFilters,
    }

    if (this.internalFilters.period === 'this_week') {
      filters.dynamic_filter = 'is:closed_this_week'
    } else {
      filters.dynamic_filter = 'is:closed_this_month'
    }

    delete filters.period

    return {
      metrics: this.metrics,
      dimensions: this.dimensions,
      query,
      filters,
    }
  }

  public dataSource() {
    return Opportunity.buildAdvancedQuery(this.payload).then((res: any) => res.data.result)
  }
}
