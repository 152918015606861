import SystemDashboard from '@/models/SystemDashboard'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import SoldOfPlanWidget from '../widgets/finance/SoldOfPlanWidget'
import SoldOfForecastWidget from '../widgets/finance/SoldOfForecastWidget'
import PipelineWidget from '../widgets/finance/PipelineWidget'
import PipelineDeficitWidget from '../widgets/finance/PipelineDeficitWidget'
import PipelineStatusFunnelWidget from '../widgets/sales/PipelineStatusFunnelWidget'
import RegionProductFilterWidget from '../widgets/others/RegionProductFilterWidget'
import RegionRevenueBarWidget from '../widgets/finance/RegionRevenueBarWidget'
import LeadershipMidFilterWidget from '../widgets/others/LeadershipMidFilterWidget'
import ClosedBusinessWidget from '../widgets/sales/ClosedBusinessWidget'
import MeetingsChartWidget from '../widgets/sales/MeetingsChartWidget'
import PitchedRevenueWidget from '../widgets/sales/PitchedRevenueWidget'
import { FILTERS } from '../helpers'
import SoldRevenueWidget from '../widgets/sales/SoldRevenueWidget'
import SoldRevenuSummaryeWidget from '../widgets/sales/SoldRevenueSummaryWidget'
import MeetingsSummaryWidget from '../widgets/sales/MeetingsSummaryWidget'
import PitchedRevenueSummaryWidget from '../widgets/sales/PitchedRevenueSummaryWidget'
import FYOverviewWidget from '../widgets/finance/FYOverviewWidget'

export default class LeadershipDashboardModule extends SystemDashboard {
  public title = 'Leadership'

  public key: string = 'leadership'

  public querySettings = {
    company_fields: [
      {
        name: 'agency',
        key: 'agency',
        type: 'agency',
      },
      {
        name: 'advertiser',
        key: 'advertiser',
        type: 'advertiser',
      },
    ],
    custom_fields: [
      {
        name: 'deal_type:direct',
        value: 'deal_type:direct',
      },
      {
        name: 'deal_type:3rd Party',
        value: 'deal_type:3rd_party',
      },
    ],
    user_fields: [
      {
        name: 'sr',
        key: 'sales_rep_id',
      },
      {
        name: 'sm',
        key: 'sales_manager_id',
      },
    ],
  }

  public filters = {
    product: 'all',
    region: 'all',
    ...FILTERS.LAST_4_WEEKS,
  }

  public groups = {
    view: 'region',
    period: 'week',
  }

  public widgets: SystemDashboardWidget[] = [
    new RegionProductFilterWidget(),
    new SoldOfPlanWidget(),
    new SoldOfForecastWidget(),
    new PipelineWidget(),
    new PipelineDeficitWidget(),
    new FYOverviewWidget(),

    new PipelineStatusFunnelWidget({
      title: 'Existing Business Pipeline',
      key: 'existing-business-pipeline',
      internalQuery: ['is:returning_business', 'use:pipeline_only', 'is:forecast', 'scope:agency'],
      resultsMapper: (item: any) => {
        if (item.status === 'pitched') {
          item.amount *= 0.1
        }
        return item
      },
    }),
    new PipelineStatusFunnelWidget({
      title: 'New Business Pipeline',
      key: 'new-business-pipeline',
      internalQuery: ['is:new_business', 'use:pipeline_only', 'is:forecast', 'scope:agency'],
    }),
    new RegionRevenueBarWidget(),
    new ClosedBusinessWidget(),
    new LeadershipMidFilterWidget(),
    new MeetingsChartWidget(),
    new MeetingsSummaryWidget(),
    new PitchedRevenueWidget(),
    new PitchedRevenueSummaryWidget(),
    new SoldRevenueWidget(),
    new SoldRevenuSummaryeWidget(),
  ]
}
