
import { Component, Vue, Prop } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import Api from '@/models/Api'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import WebMessage from '@/models/WebMessage'
import dashboards from './settings/dashboards'
import DashboardView from './components/DashboardView.vue'

@Component({
  components: {
    DashboardView,
  },
})
export default class ManagementDashboard extends ViewModel {
  public tabIndex: number = 0

  public get dashboards() {
    let { query } = this.$route
    return dashboards.filter(
      d =>
        (!this.user.isSystem && this.user.canOptions('management_dashboard', d.key))
        || (this.user.isSystem && query.tab === d.key),
    )
  }

  public mounted() {
    let { query } = this.$route

    let tab = 0
    if (query.tab) {
      let idx = this.dashboards.findIndex(t => t.key === query.tab)

      if (idx !== -1) {
        tab = idx
      }
    }

    setTimeout(() => {
      this.tabIndex = tab
    }, 250)
  }

  public onDownload(query: any) {
    let api = new Api()
    const instance_id = getModule(SystemtModule)._uuid
    const key = query.tab
    return api
      .post(`dashboard-view/export/${key}`, {
        instance_id,
        target: this.user.id,
        ...query,
        v2: true,
      })
      .then(() => WebMessage.success('Generating PDF Export, do not close this window!'))
  }
}
