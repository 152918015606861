import publisherMtdThroughYesterday from './hamilton/widgets/publisher-mtd-through-yesterday'
import publisherToday from './hamilton/widgets/publisher-today'
import publisherYesterday from './hamilton/widgets/publisher-yesterday'
import publisherYtdThroughYesterday from './hamilton/widgets/publisher-ytd-through-yesterday'

import propertyMtdThroughYesterday from './hamilton/widgets/property-mtd-through-yesterday'
import propertyToday from './hamilton/widgets/property-today'
import propertyYesterday from './hamilton/widgets/property-yesterday'
import propertyYtdThroughYesterday from './hamilton/widgets/property-ytd-through-yesterday'
import todayTotal from './hamilton/widgets/today-total'
import pieYesterdayTotal from './hamilton/widgets/pie-yesterday-total'
import pieMtdYesterdayTotal from './hamilton/widgets/pie-mtd-yesterday-total'
import pieYtdYesterdayTotal from './hamilton/widgets/pie-ytd-yesterday-total'
import advertiserToday from './hamilton/widgets/advertiser-today'
import advertiserYesterday from './hamilton/widgets/advertiser-yesterday'
import advertiserMtdThroughYesterday from './hamilton/widgets/advertiser-mtd-through-yesterday'
import advertiserYtdThroughYesterday from './hamilton/widgets/advertiser-ytd-through-yesterday'
import publisherShare from './hamilton/widgets/publisher-share'
import advertiserSales from './hamilton/widgets/advertiser-sales'
import salesChannel from './hamilton/widgets/sales-channel'
import orders from './hamilton/widgets/orders'
import ordersTopic from './hamilton/widgets/orders-topic'
import impressionsSource from './hamilton/widgets/impressions-source'
import impressionConsolidation from './hamilton/widgets/impression-consolidation'
import impressionMontlyConsolidation from './hamilton/widgets/impression-montly-consolidation'

export default [
  publisherToday,
  publisherYesterday,
  publisherMtdThroughYesterday,
  publisherYtdThroughYesterday,
  //
  propertyToday,
  propertyYesterday,
  propertyMtdThroughYesterday,
  propertyYtdThroughYesterday,
  /// /--
  todayTotal,
  pieYesterdayTotal,
  pieMtdYesterdayTotal,
  pieYtdYesterdayTotal,
  /// /
  advertiserToday,
  advertiserYesterday,
  advertiserMtdThroughYesterday,
  advertiserYtdThroughYesterday,
  // ////
  publisherShare,
  advertiserSales,
  salesChannel,
  orders,
  //--
  ordersTopic,
  impressionsSource,
  /// /--
  impressionConsolidation,
  impressionMontlyConsolidation,
]
