import Company from '@/models/Company'
import SystemDashboard from '@/models/SystemDashboard'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import RealTimeTodayWidget from '../widgets/channels/RealTimeTodayWidget'
import MLBDelivered from '../widgets/delivery/MLBDelivered'
import ActivityThisWeekWidget from '../widgets/finance/ActivityThisWeekWidget'
import PublisherShareWidget from '../widgets/finance/PublisherShareWidget'

export default class MLBModule extends SystemDashboard {
  public title: string = 'MLB'

  public key = 'mlb_dashboard'

  public logo: string = '/images/mlb.logo.png'

  public filters: any = {
    company_id: 'f37c9dd6-4776-4122-8a37-37d67f4ff47a',
  }

  constructor(args: any = null) {
    super()

    if (args && args.logo) {
      this.logo = args.logo
    }

    if (args && args.key) {
      this.key = args.key
    }

    if (args && args.title) {
      this.title = args.title
    }

    if (args && args.filters) {
      this.filters = { ...this.filters, ...args.filters }
    }
  }

  public get customRight() {
    return `<div class="mb-5 ml-3 text-right">
      <img src="${this.logo}" alt="Logo" class="invoiceLogo" height="80px" />
      <img
        style="border-left: 0.1rem solid #97a3ae"
        src="/images/castiron_media.logo.svg"
        alt="Logo"
        class="invoiceLogo pl-3 ml-3"
        height="80px"
      />
    </div>`
  }

  public widgets: SystemDashboardWidget[] = [
    new RealTimeTodayWidget(),
    new ActivityThisWeekWidget({
      title: 'Delivery Status Report',
      key: 'mlb-delivery-status',
      fields: [
        {
          key: 'period',
          label: 'Period',
          class: 'text-center align-middle',
          type: 'uppercase',
          show: true,
          thClass: 'align-middle text-center',
          sortable: false,
        },
        {
          key: 'impressions',
          label: 'Impressions',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'abbreviate',
          sortable: false,
          total: 'abbreviate',
        },
        {
          key: 'cpm',
          label: 'MLB CPM',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency',
          sortable: false,
          total: 'currency',
        },
        {
          key: 'earned_revenue',
          label: 'MLB Earned Revenue',
          class: 'text-center align-middle',
          show: true,
          thClass: 'align-middle text-center',
          type: 'currency_abbreviate',
          sortable: false,
          total: 'currency_abbreviate',
        },
      ],
      size: {
        lg: 8,
        md: 12,
      },
      noPagination: true,
      dataSourceRequest: () =>
        Company.activeGoalMetrics({
          id: this.filters.company_id,
        }),
    }),
    new MLBDelivered({
      title: 'Delivered vs Goal',
      key: 'mlb-gauge',
      dimensions: [],
      metrics: ['delivered', 'goal'],
      size: {
        lg: 4,
        md: 12,
      },
    }),

    new PublisherShareWidget({
      title: 'Last 30 days',
      key: 'last-30-days',
      subView: 'bar',
      date_dimension_format: 'YYYY-MM-DD',
      date_format_labels: 'MMM DD, YYYY',
      custom_label: 'Impressions',
      rightCol: [],
      size: {
        lg: 12,
        md: 12,
      },
      dimensions: ['date'],
      metrics: ['impressions'],
      dataSourceRequest: () =>
        Company.activeGoal30Days({
          id: this.filters.company_id,
        }),
    }),
  ]
}
