
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import SystemDashboardAction from '@/models/SystemDashboardAction'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import DatePickerDate from '@/models/DatePickerDate'
import moment from 'moment'
import FilterHeader from './FilterHeader.vue'

@Component({
  components: {
    DatePicker,
    FilterHeader,
  },
})
export default class DashboardAction extends ViewModel {
  @Prop({ required: true })
  public action!: SystemDashboardAction

  @Prop({ required: true })
  public value!: any

  @Prop()
  public secondaryValue!: any

  public localValue!: any

  public localSecondaryValue!: any

  public dateValue: DatePickerDate = new DatePickerDate()

  public get isDatePicker() {
    return [
      'date',
      'date_range',
      'datetime',
      'datetime_range',
      'year',
      'year_range',
      'time',
      'time_range',
    ].includes(this.action.type)
  }

  public get dateType() {
    if (this.action.type.includes('datetime')) {
      return 'datetime'
    }
    if (this.action.type.includes('time')) {
      return 'time'
    }
    if (this.action.type.includes('year')) {
      return 'year'
    }

    return 'date'
  }

  public get dateFormat() {
    if (this.action.type.includes('datetime')) {
      return 'YYYY-MM-DD HH:mm:ss'
    }
    if (this.action.type.includes('time')) {
      return 'HH:mm:ss'
    }
    return 'YYYY-MM-DD'
  }

  @Watch('value', { immediate: true })
  public onValueChange() {
    if (this.action.secondaryKey) {
      return
    }
    this.updateLocalValue()
  }

  @Watch('secondaryValue', { immediate: true })
  public onSecondaryValueChange() {
    if (!this.action.secondaryKey) {
      return
    }
    this.updateLocalValue()
  }

  protected updateLocalValue() {
    if (this.isDatePicker) {
      this.dateValue = new DatePickerDate(this.value, this.secondaryValue)
    } else {
      this.localValue = this.value
    }
  }

  /**
   * Build text label based on value
   */
  public getFilterLabel() {
    const value = this.action.options.find((option: any) => option.value === this.value)?.name
    if (!value) {
      return this.action.label
    }
    return `${this.action.label}: ${value}`
  }

  public onClick(value: any) {
    this.$emit('action', { action: this.action, value, refresh: true })
  }

  public onDateChange() {
    setTimeout(() => {
      this.$emit('action', {
        action: this.action,
        value: [
          moment(this.dateValue.start).format(this.dateFormat),
          moment(this.dateValue.end).format(this.dateFormat),
        ],
        refresh: true,
      })
    }, 100)
  }

  public onCheckboxChange() {
    let value: any[] = []

    this.action.options.forEach((key: any) => {
      value.push(this.localValue.includes(key.value))
    })

    this.$emit('action', {
      action: this.action,
      value,
      refresh: true,
    })
  }

  public mounted() {
    this.updateLocalValue()
  }
}
