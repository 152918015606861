import moment from 'moment'
import { groupBy } from 'lodash'
import BigNumber from 'bignumber.js'
import { RegionOptions } from './interface/Common'
import FinancialPlan from './FinancialPlan'
import WebMessage from './WebMessage'

type ProductItem = {
  id: any
  key: string
  product_name: string
  value: number
}

// make interface for {
//   product: 'ssl',
//   region: 'east',
// },
export type product_type = 'ssl' | 'ccl'
export type region_type = 'east' | 'west' | 'midwest' | 'national' | 'total' // add total for making everything easy to manage
export interface ProductRegion {
  product: product_type
  region: region_type
  cell_name: string
}

export default class FinancialPlanProductModule {
  public AVAILABLE_PRODUCTS = ['ssl', 'ccl'] // TODO add more products to this list if so, update the code where usings products

  public AVAILABLE_REGIONS: Array<any> = RegionOptions.map(op => op.value)

  public PRODUCT_SECTIONS = this.AVAILABLE_REGIONS.concat('total') // total, must be last index in array

  public region_order = ['east', 'west', 'midwest', 'national', 'total']

  public QARTERS = [
    'ssl_q1',
    'ssl_q2',
    'ssl_q3',
    'ssl_q4',
    'ssl_total',
    'ccl_q1',
    'ccl_q2',
    'ccl_q3',
    'ccl_q4',
    'ccl_total',
  ]

  public PRODUCT_REGIONS = [
    'ssl_east',
    'ssl_west',
    'ssl_midwest',
    'ssl_national',
    'ssl_total',
    'ccl_east',
    'ccl_west',
    'ccl_midwest',
    'ccl_national',
    'ccl_total',
  ]

  public PRODUCT_REGIONS_OBJ: ProductRegion[] = [
    {
      product: 'ssl',
      region: 'east',
      cell_name: 'ssl_east',
    },
    {
      product: 'ssl',
      region: 'west',
      cell_name: 'ssl_west',
    },
    {
      product: 'ssl',
      region: 'midwest',
      cell_name: 'ssl_midwest',
    },
    {
      product: 'ssl',
      region: 'national',
      cell_name: 'ssl_national',
    },
    {
      product: 'ssl',
      region: 'total',
      cell_name: 'ssl_total',
    },
    {
      product: 'ccl',
      region: 'east',
      cell_name: 'ccl_east',
    },
    {
      product: 'ccl',
      region: 'west',
      cell_name: 'ccl_west',
    },
    {
      product: 'ccl',
      region: 'midwest',
      cell_name: 'ccl_midwest',
    },
    {
      product: 'ccl',
      region: 'national',
      cell_name: 'ccl_national',
    },
    {
      product: 'ccl',
      region: 'total',
      cell_name: 'ccl_total',
    },
  ]

  public BLOCKED_CELLS: any = []

  public toggled_blocked_icons: any[] = []

  public blocked_icon(key: string, region_item: ProductRegion | any) {
    if (key === 'row') {
      return this.toggled_blocked_icons.includes(`${key}_${region_item}`)
    }
    return this.toggled_blocked_icons.includes(
      `${key}_${region_item.product}_${region_item.region}`,
    )
  }

  public toggleBlockIcon(key: string, region_item: ProductRegion | any) {
    let key_name: any = null
    if (key === 'row') {
      // region_item will be a index now
      key_name = `${key}_${region_item}`
    } else {
      key_name = `${key}_${region_item.product}_${region_item.region}`
    }

    let index = this.toggled_blocked_icons.findIndex((icon: any) => icon === key_name)
    if (index !== -1) {
      this.toggled_blocked_icons.splice(index, 1)
    } else {
      this.toggled_blocked_icons.push(key_name)
    }
  }

  public blocked(region_item: ProductRegion, month_index: number) {
    return this.BLOCKED_CELLS.some(
      (cell: any) =>
        cell.region === region_item.region
        && cell.product === region_item.product
        && cell.month === month_index,
    )
  }

  public toggleBlock(region_item: ProductRegion, month_index: number) {
    if (this.blocked(region_item, month_index)) {
      let index = this.BLOCKED_CELLS.findIndex((blocked: any) => (
        blocked.region === region_item.region
          && blocked.product === region_item.product
          && blocked.month === month_index
      ))
      this.BLOCKED_CELLS.splice(index, 1)
    } else {
      this.BLOCKED_CELLS.push({
        region: region_item.region,
        product: region_item.product,
        month: month_index,
      })
    }
  }

  public blockColumnInputs(region_item: ProductRegion) {
    for (let i = 0; i < 12; i++) {
      this.toggleBlock(region_item, i)
    }

    this.toggleBlockIcon('column', region_item)
  }

  public blockRowInputs(month_index: any) {
    for (let region_item of this.PRODUCT_REGIONS_OBJ) {
      this.toggleBlock(region_item, month_index)
    }

    this.toggleBlockIcon('row', month_index)
  }

  public month_goals: any = []

  public month_names: any = moment.months()

  public get total_of_cells() {
    return this.month_goals.reduce((acc: any, month: any) => {
      for (let product of this.AVAILABLE_PRODUCTS) {
        for (let item of month[product]) {
          if (item.key !== 'total') {
            acc++
          }
        }
      }
      return acc
    }, 0)
  }

  /**
   * Returns array of items for product
   *
   * ex:
   * product = ssl
   * product:[item, item, item]
   *
   * @param product
   * @returns
   */
  public createProductRegions(product: string): ProductItem[] {
    const items = this.PRODUCT_SECTIONS.map((key: string) => ({
      key,
      value: 0,
      product_name: product,
      id: null,
    }))

    // reorder position of products, check this.region_order
    let newOrder: any = []
    for (let item of items) {
      let indx = this.region_order.findIndex((o: any) => o === item.key)
      newOrder[indx] = item
    }

    return newOrder
  }

  /**
   *
   * Return array of objects per month
   *
   * Generate structure of the array
   *
   * Each month contains products with empty value
   *
   * @returns array of objects per month
   */
  public buildGoalPerMonthArray() {
    return this.month_names.map((month: string, index: number) => {
      let obj: any = {
        month,
        total: 0,
        position: index,
      }

      for (let product of this.AVAILABLE_PRODUCTS) {
        obj[product] = this.createProductRegions(product)
      }
      return obj
    })
  }

  public setProductValue(
    product: string,
    region: string,
    month: number,
    value: any,
    key: any = null,
  ) {
    let key_index = this.month_goals[month][product].findIndex(
      (item: ProductItem) => item.key === region,
    )
    if (key) {
      this.month_goals[month][product][key_index][key] = value
    } else {
      this.month_goals[month][product][key_index] = value
    }
  }

  /**
   * If the data is null it will return a base array of objects per month
   *
   * @param data
   * @returns
   */
  public getGoalPerMonth(data: any = null) {
    this.month_goals = this.buildGoalPerMonthArray()

    if (!data) return this.month_goals

    let products = groupBy(data, (item: FinancialPlan) => item.product)

    for (let [key, value] of Object.entries(products)) {
      for (let goal of value) {
        let month = moment(goal.period_end_at).month()
        this.setProductValue(key, goal.region, month, {
          key: goal.region,
          value: goal.goal,
          product_name: key,
          id: goal.id,
        })
      }
    }

    // return the goals sorted by month
    return this.month_goals.sort((a: any, b: any) => a.position - b.position)
  }

  public static init() {
    return new FinancialPlanProductModule()
  }

  public static read(data: any) {
    let instance = new FinancialPlanProductModule()
    instance.month_goals = data
    return instance
  }

  // month total of a product
  public calculateTotalPerMonth(payload: any, product: any) {
    // payload example {"month":"January","ccl":{"east":0,"west":0,"midwest":0,"national":0,"total":0},"ssl":{"east":0,"west":0,"midwest":0,"national":0,"total":0},"total":0}
    let prod = payload[product]
    return prod.east + prod.west + prod.midwest + prod.national
  }

  public calculateRowTotal(payload: any) {
    return this.calculateTotalPerMonth(payload, 'ccl') + this.calculateTotalPerMonth(payload, 'ssl')
  }

  public calculateColumnTotal(array: any[], product: string, region: string) {
    return array.reduce((acc: any, item: any) => {
      acc += item[product][region]
      return acc
    }, 0)
  }

  public calculateProductTotals(array: any[], product: product_type) {
    return array.reduce((acc: any, item: any) => {
      acc += this.calculateTotalPerMonth(item, product)
      return acc
    }, 0)
  }

  public calculateAllMonthTotals(array: any) {
    return array.reduce((acc: any, item: any) => {
      acc += this.calculateRowTotal(item)
      return acc
    }, 0)
  }

  public async distibuteGoal(
    months: any[],
    action: string,
    increment: any = null,
    value_use: any = 0,
  ) {
    return new Promise((resolve, reject) => {
      let updated: any = JSON.parse(JSON.stringify(months))

      // let example = [
      //   {
      //     month: 'January',
      //     ccl: { east: 0, west: 0, midwest: 0, national: 0, total: 0 },
      //     ssl: { east: 0, west: 0, midwest: 0, national: 0, total: 0 },
      //     total: 0,
      //   },
      // ]
      let record_count = 0
      let locked = 0
      let total = 0

      if (action === 'even') {
        for (let month_index = 0; month_index < months.length; month_index++) {
          let month = months[month_index]

          for (let product of this.AVAILABLE_PRODUCTS) {
            // region ex: { east: 0, west: 0, midwest: 0, national: 0, total: 0 }
            for (let region in month[product]) {
              if (region !== 'total') {
                // @ts-ignore
                if (!this.blocked({ product, region }, month_index)) {
                  record_count++
                } else {
                  locked += Number(month[product][region])
                }
              }
            }
          }
        }

        if (locked > value_use) {
          WebMessage.error('The amount you entered is less than the locked amount')
          reject()
          return
        }
        let amount = +new BigNumber(value_use).minus(locked).dividedBy(record_count).toFixed(2)

        let remaining = +new BigNumber(amount)
          .times(record_count)
          .plus(locked)
          .minus(value_use)
          .times(-1)
          .toFixed(2)

        let shared = Number((amount + remaining).toFixed(2))

        for (let month_index = 0; month_index < months.length; month_index++) {
          let month = months[month_index]

          for (let product of this.AVAILABLE_PRODUCTS) {
            // region ex: { east: 0, west: 0, midwest: 0, national: 0, total: 0 }
            for (let region in month[product]) {
              if (region !== 'total') {
                // @ts-ignore
                if (!this.blocked({ product, region, cell_name: '' }, month_index)) {
                  updated[month_index][product][region] = shared
                }
              }
            }
          }
        }
      } else if (action === 'proportional') {
        for (let month_index = 0; month_index < months.length; month_index++) {
          let month = months[month_index]

          for (let product of this.AVAILABLE_PRODUCTS) {
            // region ex: { east: 0, west: 0, midwest: 0, national: 0, total: 0 }
            for (let region in month[product]) {
              if (region !== 'total') {
                // @ts-ignore
                if (this.blocked({ product, region }, month_index)) {
                  locked += Number(month[product][region])
                }
                total += Number(month[product][region])
              }
            }
          }
        }

        if (locked > value_use) {
          WebMessage.error('The amount you entered is less than the locked amount')
          reject()
        }

        let remaining = total - locked
        let target = value_use - locked

        let applied = 0

        for (let month_index = 0; month_index < months.length; month_index++) {
          let month = months[month_index]

          for (let product of this.AVAILABLE_PRODUCTS) {
            // region ex: { east: 0, west: 0, midwest: 0, national: 0, total: 0 }
            for (let region in month[product]) {
              if (region !== 'total') {
                // @ts-ignore
                if (!this.blocked({ product, region }, month_index)) {
                  let originalitem = updated[month_index][product][region]
                  let new_value: any = new BigNumber(originalitem)
                    .div(remaining)
                    .times(target)
                    .toFixed(2)

                  if (!originalitem && !remaining) {
                    new_value = 0
                  }

                  updated[month_index][product][region] = new_value

                  applied += new_value
                }
              }
            }
          }
        }
      }
      setTimeout(() => {
        resolve(updated)
      }, 500)
    })
  }
}
