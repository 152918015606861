import ActiveInitialTermModule from '@/models/ActiveInitialTermModule'
import simpleCard from '@/pages/ManagementDashboard/scripts/simple-card'
import { FILTERS } from '@/pages/ManagementDashboard/shared/helpers'
import { EventBus } from '@/plugins/eventBus'
import moment from 'moment'
import numeral from 'numeral'

export default {
  title: '',
  key: 'initial-overview',
  source: 'request',
  type: 'custom',
  request: {
    module: new ActiveInitialTermModule(),
    exec: 'overview',
  },
  size: {
    custom: true,
    sm: 12,
    md: 12,
    lg: 12,
  },
  capitalize_labels: true,
  config: {
    no_widget: true,
    custom_data: (data: any, loading: boolean) => {
      EventBus.$emit('int-overview-data', data)
      return simpleCard(
        {
          revenue: numeral(data.revenue).format('$0.00a'),
          cost: numeral(data.cost).format('$0.00a'),
          profit: numeral(data.profit).format('$0.00a'),
          net_av: numeral(data.net_added_value).format('$0.00a'),
          'av_%_to_goal': numeral(data.av_percentage_to_goal).format('0.0%'),
        },
        {
          columnClass: 'col',
          rowClass: 'row-cols-5',
          overview: true,
          loading,
          valueColors: {
            cost: 'danger',
            profit: 'success',
            value: 'success',
          },
        },
      )
    },

    listen: [
      {
        'global:agency_id': (value: any, module: any) => {
          if (module.local_filters.hasOwnProperty('agency_id')) {
            module.local_filters.agency_id = value
          }
        },
      },
      {
        'global:year': (value: any, module: any) => {
          if (module.local_filters.hasOwnProperty('start')) {
            module.local_filters.start = value.start
            module.local_filters.end = value.end
          }
        },
      },
    ],
  },
  filters: {
    agency_id: '',
    start: moment().year(),
    end: moment().add(1, 'year').year(),
  },
}
