import ActiveInitialTermModule from '@/models/ActiveInitialTermModule'
import simpleCard from '@/pages/ManagementDashboard/scripts/simple-card'
import { FILTERS } from '@/pages/ManagementDashboard/shared/helpers'
import BigNumber from 'bignumber.js'
import moment from 'moment'
import dashboardSharedOptions from '../../../dashboard-shared-options'

export default {
  title: '',
  key: 'mediaplans',
  source: 'request',
  request: {
    module: new ActiveInitialTermModule(),
    exec: 'mediaPlans',
  },
  pre_load: {},
  type: 'table',
  size: {
    lg: 12,
    md: 12,
  },
  capitalize_labels: true,
  config: {
    perPage: 100,
    bordered: true,
    noTotal: true,
    fields: [
      {
        key: 'order_date',
        label: 'Order Date',
        class: 'text-center align-middle text-capitalize font-weight-bold',
        show: true,
        thClass: 'align-middle text-center',
        sortable: false,
        thStyle: 'width: 15em',
        type: 'date',
      },
      {
        key: 'order',
        label: 'Order',
        class: 'text-center align-middle text-capitalize font-weight-bold',
        show: true,
        thClass: 'align-middle text-center',
        sortable: false,
        thStyle: 'width: 15em',
        templateLink: true,
        template: (item: any) =>
          // return a link in a new tab
          `<a href="/app/sales/media_plan/${item.id}/view" target="_blank">${item.order}</a>`
        ,
      },
      {
        key: 'flight_start',
        label: 'Flight (Start-End)',
        class: 'text-center align-middle text-capitalize font-weight-bold',
        show: true,
        thClass: 'align-middle text-center',
        sortable: false,
        type: 'currency',
        thStyle: 'width: 15em',
        template: (item: any) => {
          let start = moment(item.flight_start).format('MMM, Do YYYY')
          let end = moment(item.flight_end).format('MMM, Do YYYY')
          return `${start} - ${end}`
        },
      },
      {
        key: 'value',
        label: 'Net Amount',
        class: 'text-center align-middle text-capitalize font-weight-bold',
        show: true,
        thClass: 'align-middle text-center',
        sortable: false,
        type: 'currency',
        thStyle: 'width: 15em',
      },
      {
        key: 'delivered',
        label: 'Delivered',
        class: 'text-center align-middle text-capitalize font-weight-bold',
        show: true,
        thClass: 'align-middle text-center',
        sortable: false,
        type: 'percentage',
        thStyle: 'width: 15em',
      },
      {
        key: 'revenue',
        label: 'Revenue',
        class: 'text-center align-middle text-capitalize font-weight-bold',
        show: false,
        thClass: 'align-middle text-center',
        sortable: false,
        type: 'currency',
        thStyle: 'width: 15em',
      },
      {
        key: 'cost',
        label: 'Cost *',
        class: 'text-center align-middle text-capitalize font-weight-bold',
        show: true,
        thClass: 'align-middle text-center',
        sortable: false,
        type: 'currency',
        thStyle: 'width: 15em',
      },
      {
        key: 'profit',
        label: 'Profit',
        class: 'text-center align-middle text-capitalize font-weight-bold',
        show: true,
        thClass: 'align-middle text-center',
        sortable: false,
        type: 'currency',
        thStyle: 'width: 15em',
      },
    ],
    listen: [
      {
        'global:agency_id': (value: any, module: any) => {
          if (module.local_filters.hasOwnProperty('agency_id')) {
            module.local_filters.agency_id = value
          }
        },
      },
      {
        'global:year': (value: any, module: any) => {
          if (module.local_filters.hasOwnProperty('start')) {
            module.local_filters.start = value.start
            module.local_filters.end = value.end
          }
        },
      },
    ],
  },
  metrics: [],
  dimensions: [],
  query: [],
  filters: {
    agency_id: '',
    start: moment().year(),
    end: moment().add(1, 'year').year(),
  },
}
