import LeadershipDashboardModule from '@/models/LeadershipDashboardModule'
import simpleCard from '@/pages/ManagementDashboard/scripts/simple-card'
import { FILTERS } from '@/pages/ManagementDashboard/shared/helpers'

export default {
  title: '',
  key: 'financial-plan-overview',
  source: 'request',
  type: 'custom',
  request: {
    module: new LeadershipDashboardModule(),
    exec: 'financialPlanOverview',
  },
  size: {
    custom: true,
    sm: 12,
    md: 12,
    lg: 12,
  },
  capitalize_labels: true,
  config: {
    no_widget: true,
    custom_data: (data: any, loading: boolean) =>
      simpleCard(data, {
        overview: true,
        loading,
        valueColors: {
          // sold_of_plan: 'primary',
          // 'sold_of_%%reforecast_name%%': 'primary',
          // pipeline: 'primary',
          pipeline_deficit_to_FYE: (value: string) =>
            (value.startsWith('-') ? 'danger' : 'success'),
        },
      }),
    listen: [
      {
        'leadership:product': (value: any, module: any) => {
          let query = module.query.filter((i: string) => !i.startsWith('product:'))

          query = [...query, `product:${value}`]

          module.local_query = query

          module.updateData()
        },
      },
      {
        'leadership:group_role': (value: any, module: any) => {
          if (module.local_filters.hasOwnProperty('group_role')) {
            module.local_filters.group_role = value
          }
        },
      },
      {
        'leadership:region': (value: any, module: any) => {
          if (module.local_filters.hasOwnProperty('region')) {
            module.local_filters.region = value
          }
        },
      },
    ],
  },
  filters: {
    dynamic_filter: {
      ...FILTERS.YEAR,
    },
    group_role: 'individual',
    region: '',
    product: 'all',
  },
}
