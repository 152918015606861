import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import moment from 'moment'
import { del } from 'vue'
import { clone } from 'lodash'
import Api from './Api'
import DatePickerDate from './DatePickerDate'
import Filter from './Filter'
import Model from './interface/Model'
import WebMessage from './WebMessage'
import User from './User'
import Company from './Company'

export default class ReportBuilder extends Model {
  protected api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'report_builder' as string | null,
      plural: 'report_builders' as string | null,
    },
  }

  // Save Only
  public id: any = null

  public name: any = null

  public frequency: string = 'none'

  public to: any = []

  public expiration_picker = new DatePickerDate(null, null, 'month')

  public message: string = ''

  // Base Settings
  public type: any = 'delivery'

  public dimensions: any = []

  public metrics: any = []

  public filters: any = {}

  public shared: boolean = false

  public period_filter: string = 'none'

  public period_picker: DatePickerDate = new DatePickerDate(null, null, 'month')

  public owner_id: string = ''

  public owner: User = new User()

  public company_id: string = ''

  public company: Company = new Company()

  public station_id: string = ''

  public station: Company = new Company()

  public get selected_filters() {
    return Object.keys(this.filters).map(f => `filter.${f}`)
  }

  public set formType(value: any) {
    if (value) {
      this.type = value
      this.dimensions = []
      this.metrics = []
    }
  }

  public get formType() {
    return this.type
  }

  public clearFilters() {
    this.filters = new Filter()
  }

  public get date_type_name() {
    switch (this.period_picker.shortcut) {
      case '-day':
        return 'Yesterday'
      case 'week':
        return 'This Week'
      case '-week':
        return 'Last Week'
      case '-7':
        return 'Last 7 days'
      case '-30':
        return 'Last 30 days'
      case 'month':
        return 'This Month'
      case '-month':
        return 'Last Month'
      case 'year':
        return 'This Year'
      case '-year':
        return 'Last Year'
      default:
        return 'Custom'
    }
  }

  public get apiData(): any {
    return {
      ...this.reportApiData,
      id: this.id,
      name: this.name,
      frequency: this.frequency,
      to: this.to,
      expiration_start_at: this.expiration_picker.start_date_string,
      expiration_end_at: this.expiration_picker.end_date_string,
      message: this.message,
      filters: this.filters,
      period: this.period_picker.shortcut,
      period_start_at: this.period_picker.start_date_string,
      period_end_at: this.period_picker.end_date_string,
      shared: this.shared,
    }
  }

  public get reportApiData(): any {
    let ret: any = {
      type: this.type,
      dimensions: this.dimensions,
      metrics: this.metrics,
      date_filter: this.period_filter,
      filters: {
        date: this.period_picker.shortcut,
        start_at: this.period_picker.start_date_string,
        end_at: this.period_picker.end_date_string,
        ...this.filters,
      },
    }
    if (this.period_picker.shortcut == null) {
      delete ret.filters.date
    } else {
      delete ret.filters.start_at
      delete ret.filters.end_at
    }

    return ret
  }

  public query() {
    const query: any = []
    const params: any = this.reportApiData()
    Object.keys(params).forEach((key: string) => {
      if (Array.isArray(params[key]) && params[key].length > 0) {
        params[key].forEach((i: string) => {
          query.push({
            name: key,
            value: i,
          })
        })
      } else if (params[key] && params[key] != null && params[key] != '') {
        query.push({
          name: key,
          value: params[key],
        })
      }
    })

    return query.reduce((q: any, i: any) => {
      if (q) {
        q += '&'
      }

      return `${q + i.name}=${i.value}`
    }, '')
  }

  public toObject(source: any) {
    let instance = this.clone()

    if (source.expiration_start_at && source.expiration_end_at) {
      instance.expiration_picker = new DatePickerDate(
        source.expiration_start_at,
        source.expiration_end_at,
      )
    }

    if (source.filters.date) {
      instance.period_picker = new DatePickerDate(null, null, source.filters.date)
    } else if (source.filters.start_at && source.filters.end_at) {
      instance.period_picker = new DatePickerDate(
        source.filters.start_at,
        source.filters.end_at,
        null,
        'YYYY-MM-DD',
      )
    }

    if (source.period) {
      instance.period_picker = new DatePickerDate(null, null, source.period)
    } else if (source.period_start_at && source.period_end_at) {
      instance.period_picker = new DatePickerDate(source.period_start_at, source.period_end_at)
    }

    if (source.owner) {
      instance.owner = User.toObject(source.owner)
      delete source.owner
    }

    if (source.company) {
      instance.company = Company.toObject(source.company)
      delete source.company
    }

    if (source.station) {
      instance.station = Company.toObject(source.station)
      delete source.station
    }

    if (source.date_filter) {
      instance.period_filter = source.date_filter
    }

    if (source.expiration_start_at) delete source.expiration_start_at
    if (source.expiration_end_at) delete source.expiration_end_at
    if (source.period) delete source.period
    if (source.period_start_at) delete source.period_start_at
    if (source.period_end_at) delete source.period_end_at
    if (source.instance_id) delete source.instance_id
    if (source.filters.date) delete source.filters.date
    if (source.filters.start_at) delete source.filters.start_at
    if (source.filters.end_at) delete source.filters.end_at

    Object.assign(instance, source)

    // added this here to fix the date picker - it's losing the class reference
    if (instance.period_picker) {
      instance.period_picker = new DatePickerDate(
        instance.period_picker.start ?? null,
        instance.period_picker.end ?? null,
        instance.period_picker.shortcut ?? null,
      )
    }

    return instance
  }

  public async loadReportSettings() {
    let api = new Api()
    return api.get('report-builder-options')
  }

  public async runReport(sync: boolean = false) {
    const instance_id = getModule(SystemtModule)._uuid
    let api = new Api()
    return api.post('run-report', { instance_id, ...this.reportApiData, sync })
  }

  public async download() {
    const instance_id = getModule(SystemtModule)._uuid
    let api = new Api()
    return api.post('run-report', { instance_id, ...this.reportApiData, output: 'download' })
  }

  public async send() {
    const instance_id = getModule(SystemtModule)._uuid
    let api = new Api()
    return api.post('run-report', {
      instance_id,
      ...this.reportApiData,
      name: this.name,
      message: this.message,
      to: this.to,
      output: 'mail',
    })
  }

  public static async getReport(hash: string) {
    let api = new Api()
    return api.get(`report/hash/${encodeURIComponent(hash)}`)
  }

  protected onSave(response: any, meta: any = null) {
    if (meta) {
      WebMessage.success('Report saved!')
    }

    return response
  }

  protected onDelete(response: any, meta: any = null) {
    if (meta) {
      WebMessage.success('Report deleted!')
    }

    return response
  }
}
