import SelectOption from '@/models/interface/SelectOption'
import SystemDashboardAction from '@/models/SystemDashboardAction'

export default class WeekOrMonthFilter extends SystemDashboardAction {
  // Target Property to be changed or event name
  public target: string = 'internalFilters'

  // Unique key for the action
  public key: string = 'period'

  // Label for the action
  public label: string = 'Group By'

  public type: string = 'radio'

  // Options for the action if applicable
  public options: SelectOption[] = [
    new SelectOption('This Week', 'this_week'),
    new SelectOption('This Month', 'this_month'),
  ]
}
