import Api from './Api'

export default class CimWorkloadModule {
  public queryAutomatedFilters(module: any) {
    let automated_filters: any = {}
    let query: any = []

    if (module.query && module.query.length) {
      query = [...module.query]
    }

    if (module.local_filters) {
      Object.entries(module.local_filters).forEach(([key, value]) => {
        if (key === 'dynamic_filter') {
          automated_filters.start = module.local_filters.dynamic_filter.start
          automated_filters.end = module.local_filters.dynamic_filter.end
          automated_filters.shortcut = module.local_filters.dynamic_filter.shortcut
        } else {
          automated_filters[key] = value
        }
      })
    }
    let metrics: any = []
    let dimensions: any = []

    if (module.local_query && module.local_query.length) {
      query = module.local_query
    }
    if (module.metrics) {
      metrics = module.metrics
    }
    if (module.dimensions) {
      dimensions = module.dimensions
    }

    if (module.region) {
      query.push(`region:${module.region}`)
    }

    if (module.sales_rep) {
      query.push(`sr:${module.sales_rep}`)
    }

    query = [...new Set(query)]

    return {
      query,
      metrics,
      dimensions,
      automated_filters,
    }
  }

  public pagination_settings(context: any) {
    return {
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...context.query],
      mode: 'exclusive',
    }
  }

  public async numberOfTaskRequest(payload: any) {
    let api = new Api()

    return api
      .get('cim-workload/number-of-task-request', payload)
      .then((response: any) => response.data)
      .catch(err => ({ data: [] }))
  }

  public async pendingTask(payload: any) {
    let api = new Api()

    return api
      .get('cim-workload/pending-tasks', payload)
      .then((response: any) => ({
        data: response.data.result,
        records: response.data.records,
      }))
      .catch(err => ({ data: [] }))
  }

  public async numberOfMediaplans(payload: any) {
    let api = new Api()
    return api
      .get('cim-workload/number-of-mediaplans', payload)
      .then((response: any) => response.data)
      .catch(err => ({ data: [] }))
  }

  public async numberOfRevenueAssigned(payload: any) {
    let api = new Api()
    return api
      .get('cim-workload/number-of-revenue-assigned', payload)
      .then((response: any) => response.data)
      .catch(err => ({ data: [] }))
  }

  public async numberOfMediaplansWon(payload: any) {
    const { query, automated_filters } = this.queryAutomatedFilters(module)

    let api = new Api()
    return api
      .get('cim-workload/number-of-mediaplans-won', payload)
      .then((response: any) => response.data)
      .catch(err => ({ data: [] }))
  }

  public async numberOfAdvertiser(payload: any) {
    let api = new Api()

    return api
      .get('cim-workload/number-of-advertisers', payload)
      .then((response: any) => response.data)
      .catch(err => ({ data: [] }))
  }

  public async numberOfAgencies(payload: any) {
    let api = new Api()

    return api
      .get('cim-workload/number-of-agencies', payload)
      .then((response: any) => response.data)
      .catch(err => ({ data: [] }))
  }
}
