import Api from '@/models/Api'
import FinancialPlan from '@/models/FinancialPlan'
import Opportunity from '@/models/Opportunity'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import { FILTERS, normalizeLabel } from '@/pages/ManagementDashboard/shared/helpers'
import moment from 'moment'
import numeral from 'numeral'

export default class ClosedThisWeek extends SystemDashboardWidget {
  public title: string = 'Closed This Week'

  public key: string = 'week-pipeline'

  public view: string = 'table'

  public noPagination: boolean = true

  public fields = [
    {
      key: 'advertiser',
      label: 'Advertiser',
      class: 'text-center align-middle',
      show: true,
      thClass: 'align-middle text-center',
      sortable: true,
    },
    {
      key: 'agency',
      label: 'Agency',
      class: 'text-center align-middle',
      show: true,
      thClass: 'align-middle text-center',
      sortable: true,
    },
    {
      key: 'east',
      label: 'East',
      class: 'text-center align-middle',
      show: true,
      thClass: 'align-middle text-center',
      type: 'currency_abbreviate',
      sortable: true,
      total: 'currency_abbreviate',
    },
    {
      key: 'midwest',
      label: 'Midwest',
      class: 'text-center align-middle',
      show: true,
      thClass: 'align-middle text-center',
      type: 'currency_abbreviate',
      sortable: true,
      total: 'currency_abbreviate',
    },
    {
      key: 'west',
      label: 'West',
      class: 'text-center align-middle',
      show: true,
      thClass: 'align-middle text-center',
      type: 'currency_abbreviate',
      sortable: true,
      total: 'currency_abbreviate',
    },
    {
      key: 'national',
      label: 'National',
      class: 'text-center align-middle',
      show: true,
      thClass: 'align-middle text-center',
      type: 'currency_abbreviate',
      sortable: true,
      total: 'currency_abbreviate',
    },
    {
      key: 'total',
      label: 'Total',
      class: 'text-center align-middle',
      show: true,
      thClass: 'align-middle text-center',
      type: 'currency_abbreviate',
      sortable: true,
      total: 'currency_abbreviate',
    },
  ]

  public metrics: string[] = ['goal', 'goal_pipeline', 'goal_sold', 'goal_achieved']

  public size: any = {
    lg: 8,
    md: 12,
  }

  public loaderSize: string = '350px'

  public get filterToQuery() {
    let query = []

    if (this.filters.product !== 'all') {
      query.push(`product:${this.filters.product}`)
    }

    if (this.filters.region !== 'all') {
      query.push(`region:${this.filters.region}`)
    }

    query.push(`year:${this.internalFilters.year}`)

    return query
  }

  public internalQuery: any = ['scope:finance_goal']

  public get payload() {
    const query = [...new Set([...this.query, ...this.internalQuery, ...this.filterToQuery])]

    const filters = {
      ...this.internalFilters,
    }

    return {
      metrics: this.metrics,
      dimensions: this.dimensions,
      query,
      filters,
    }
  }

  public internalFilters: any = {
    type: 'finance_goal',
    year: parseInt(moment().format('YYYY')),
  }

  public flattenPayload: boolean = true

  public dataSource() {
    return Opportunity.weekPipeline(this.payload).then((res: any) => res.data.result)
  }
}
