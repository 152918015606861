import { buildColorPool } from '@/models/Util'
import numeral from 'numeral'

export default {
  funnel: {
    chart: {
      type: 'bar',
      height: '100',
      width: '200',
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: true,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      dropShadow: {
        enabled: true,
      },
    },
    tooltip: {
      enabled: true,
      y: {},
    },
    legend: {
      position: 'bottom',
      show: true,
      showForSingleSeries: true,
    },
    xaxis: {},
    plotOptions: {
      bar: {
        borderRadius: 0,
        distributed: true,
        horizontal: true,
        barHeight: '80%',
        isFunnel: true,
      },
    },
  },
  // TODO Check pie settings
  pie: {
    chart: {
      height: 350,
      type: 'pie',
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: true,
      },
    },
    dataLabels: {
      dropShadow: {
        enabled: true,
      },
    },
    labels: [],
    tooltip: {
      enabled: true,
      y: {},
    },
    legend: {
      show: true,
      position: 'bottom',
    },
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360,
        expandOnClick: true,
      },
    },
  },
  line: {
    chart: {
      type: 'line',
      toolbar: false,
      stacked: false,
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: true,
      },
    },
    dataLabels: {
      dropShadow: {
        enabled: true,
      },
    },
    yaxis: {
      labels: {
        show: true,
      },
    },
    labels: [],
    tooltip: {
      enabled: true,
      y: {},
    },
    xaxis: {
      categories: [],
    },
    plotOptions: {
      bar: {
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: '13px',
              fontWeight: 900,
              color: '#000',
            },
          },
        },
      },
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'right',
      showForSingleSeries: true,
    },
  },
  bar: {
    chart: {
      type: 'bar',
      toolbar: false,
      stacked: true,
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: true,
      },
    },
    dataLabels: {
      dropShadow: {
        enabled: true,
      },
    },
    yaxis: {
      labels: {
        show: true,
      },
    },
    labels: [],
    tooltip: {
      enabled: true,
      y: {},
    },
    xaxis: {
      categories: [],
    },
    plotOptions: {
      bar: {
        dataLabels: {
          total: {
            enabled: false,
            style: {
              fontSize: '13px',
              fontWeight: 900,
              color: '#000',
            },
          },
        },
        isFunnel: false,
      },
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'right',
      showForSingleSeries: true,
    },
  },
  bar_stacked: {
    chart: {
      type: 'bar',
      toolbar: false,
      stacked: true,
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: true,
      },
    },
    dataLabels: {
      dropShadow: {
        enabled: true,
      },
    },
    yaxis: {
      labels: {
        show: true,
      },
    },
    labels: [],
    tooltip: {
      enabled: true,
      y: {},
    },
    xaxis: {
      categories: [],
    },
    plotOptions: {
      bar: {
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: '13px',
              fontWeight: 900,
              color: '#000',
            },
          },
        },
      },
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'right',
      showForSingleSeries: true,
    },
  },
  bar_horizontal: {
    chart: {
      type: 'bar',
      toolbar: false,
      stacked: true,
      // stackType: '100%',
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: true,
      },
    },
    dataLabels: {
      dropShadow: {
        enabled: true,
      },
    },
    yaxis: {
      labels: {
        show: true,
      },
    },
    labels: [],
    tooltip: {
      enabled: true,
      y: {},
    },
    xaxis: {
      categories: [],
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: '13px',
              fontWeight: 900,
              color: '#000',
            },
          },
        },
      },
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'right',
      showForSingleSeries: true,
    },
  },
  bar_marker: {
    chart: {
      type: 'bar',
      toolbar: false,
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: true,
      },
    },
    dataLabels: {
      dropShadow: {
        enabled: true,
      },
    },
    xaxis: {
      labels: {
        show: true,
      },
    },
    labels: [],
    tooltip: {
      enabled: true,
      y: {},
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'right',
      showForSingleSeries: true,
      customLegendItems: ['Sold', 'Plan'],
      markers: {
        fillColors: ['#00C1DE', '#b0ca36'],
      },
    },
  },
  bar_proportional: {
    chart: {
      type: 'bar',
      toolbar: false,
      stacked: true,
      stackType: '100%',
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: true,
      },
    },
    dataLabels: {
      dropShadow: {
        enabled: true,
      },
      total: {
        enabled: true,
        style: {
          fontSize: '13px',
          fontWeight: 900,
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
      },
    },
    labels: [],
    tooltip: {
      enabled: true,
      y: {},
    },
    xaxis: {
      categories: [],
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: '13px',
              fontWeight: 900,
              color: '#000',
            },
          },
        },
      },
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'right',
      showForSingleSeries: true,
    },
  },
  radialBar: {
    chart: {
      type: 'radialBar',
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: true,
      },
    },
    dataLabels: {
      dropShadow: {
        enabled: true,
      },
    },
    labels: [],
    tooltip: {
      enabled: true,
      y: {
        title: {},
      },
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: '30%',
          background: 'transparent',
          image: undefined,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
      },
    },
    legend: {
      show: true,
      floating: true,
      fontSize: '16px',
      position: 'left',
      offsetX: 0,
      offsetY: 5,
      showForSingleSeries: true,
      labels: {
        useSeriesColors: true,
      },
      markers: {
        size: 0,
      },
      itemMargin: {
        vertical: 3,
      },
    },
  },
  gauge: {
    chart: {
      type: 'radialBar',
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: true,
      },
    },
    dataLabels: {
      dropShadow: {
        enabled: true,
      },
      value: {
        fontSize: '30px',
        show: true,
      },
      style: {
        colors: ['#000000'],
      },
    },
    fill: {
      type: 'image',
      image: {
        src: ['/images/gradient_gauge_final.png'],
        width: 3812,
      },
    },
    stroke: {
      lineCap: 'butt',
    },
    labels: [],
    tooltip: {
      enabled: true,
      y: {
        title: {
          formatter: (seriesName: any) => '',
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        hollow: {
          margin: 5,
          size: '30%',
          background: 'transparent',
        },
        dataLabels: {
          name: {
            show: true,
            color: '#29323a',
          },
          value: {
            show: true,
          },
        },
      },
    },
    legend: {
      show: false,
      floating: true,
      fontSize: '16px',
      position: 'left',
      offsetX: 0,
      offsetY: 5,
      labels: {
        useSeriesColors: true,
      },
      markers: {
        size: 0,
      },
      itemMargin: {
        vertical: 3,
      },
    },
  },
  gauge_number: {
    chart: {
      type: 'radialBar',
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: true,
      },
    },
    dataLabels: {
      dropShadow: {
        enabled: true,
      },
      value: {
        fontSize: '30px',
        show: true,
      },
      style: {
        colors: ['#000000'],
      },
    },
    fill: {
      type: 'image',
      image: {
        src: ['/images/gradient_gauge_final.png'],
        width: 3812,
      },
    },
    stroke: {
      lineCap: 'butt',
    },
    labels: [],
    tooltip: {
      enabled: true,
      y: {
        title: {
          formatter: (seriesName: any) => '',
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        hollow: {
          margin: 5,
          size: '30%',
          background: 'transparent',
        },
        dataLabels: {
          name: {
            show: true,
            color: '#29323a',
          },
          value: {
            show: true,
          },
        },
      },
    },
    legend: {
      show: false,
      floating: true,
      fontSize: '16px',
      position: 'left',
      offsetX: 0,
      offsetY: 5,
      labels: {
        useSeriesColors: true,
      },
      markers: {
        size: 0,
      },
      itemMargin: {
        vertical: 3,
      },
    },
  },
  area: {
    chart: {
      type: 'area',
      height: '100',
      width: '200',
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: true,
      },
      toolbar: {
        show: false,
      },
      stacked: true,
      stackType: '100%',
    },
    dataLabels: {
      dropShadow: {
        enabled: true,
      },
    },
    stroke: {
      curve: 'smooth',
    },

    tooltip: {
      enabled: true,
      y: {},
      x: {
        show: false,
      },
    },
    legend: {
      position: 'bottom',
      show: true,
      showForSingleSeries: true,
    },
    plotOptions: {},
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      min: 0,
      max: 1,
      labels: {
        show: true,
      },
    },
  },
  treemap: {
    chart: {
      type: 'treemap',
      toolbar: false,
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: true,
      },
      events: {},
    },
    dataLabels: {
      dropShadow: {
        enabled: true,
      },
    },
    labels: [],
    tooltip: {
      enabled: true,
      y: {},
    },
    legend: {
      show: false,
    },
    plotOptions: {
      treemap: {
        distributed: true,
        enableShades: false,
        dataLabels: {
          format: 'scale',
        },
      },
    },
  },
}
