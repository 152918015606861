import HamiltonModule from '@/models/HamiltonModule'
import { buildColorPool } from '@/models/Util'
import { FILTERS, normalizeLabel } from '@/pages/ManagementDashboard/shared/helpers'

import numeral from 'numeral'

export default {
  title: 'Impressions',
  key: 'impression-consolidation',
  source: 'request',
  request: {
    module: new HamiltonModule(),
    exec: 'impressionsConsolidation',
  },
  pre_load: {},
  type: 'line',
  size: {
    lg: 6,
    md: 6,
    sm: 12,
  },
  config: {
    dynamic_date: true,
    date_dimension_format: 'YYYY-MM-DD',
    date_format_labels: 'MMM Do',
    totalFormat: '0.00a',
  },
  base_options_overwrite: false,
  metrics: ['impressions'],
  dimensions: ['created_at', 'agency'],
  filters: {
    dynamic_filter: {
      ...FILTERS.MONTH,
    },
  },
  query: [],
  order: {},
  labels: ['name'],
  dynamic_options: [{ target: 'local_filters', sub_target: 'dynamic_filter' }],
  formatters: {
    yaxis: {
      labels: {
        formatter: (value: number, labels: string[], series: number[], __: number, opt: any) =>
          `${numeral(value).format('0.00a')}`,
      },
    },
    dataLabelFormatter: (value: number, labels: string[], series: number[], __: number, opt: any) =>
      `${numeral(value).format('0.00a')}`,
    tooltipFormatter: (value: number, labels: string[], series: any[], total: number, opt: any) => [
      numeral(value).format('0.00a'),
    ],
  },
}
