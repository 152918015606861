import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import { startCase } from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import FinancialPlan from '@/models/FinancialPlan'

export default class RegionRevenueBarWidget extends SystemDashboardWidget {
  public title: string = 'Region Revenue vs Plan'

  public key: string = 'region-revenue-vs-plan'

  public view: string = 'chart'

  public subView: string = 'bar_marker'

  public loaderSize: string = '350px'

  public dimensions: string[] = ['region']

  public metrics: string[] = ['goal_sold', 'goal']

  public size: any = {
    sm: 12,
    lg: 4,
    md: 12,
  }

  // API Filters
  public filters: any = {
    region: 'all',
    product: 'all',
  }

  public internalQuery: any = ['scope:finance_goal']

  public internalFilters: any = {
    year: moment().format('YYYY'),
    type: 'finance_goal',
  }

  public formatters: any = {
    label: (_: SystemDashboardWidget, value: any) => [`${numeral(value).format('$0.00a')}`],
    tooltip: (_: SystemDashboardWidget, value: any, opt: any) => {
      let ret = [`${numeral(value).format('$0.00a')}`]

      return ret
    },
    xaxis: (_: SystemDashboardWidget, value: any) => numeral(value).format('$0.00a'),
  }

  public get filterToQuery() {
    let query = []

    if (this.filters.product !== 'all') {
      query.push(`product:${this.filters.product}`)
    }

    if (this.filters.region !== 'all') {
      query.push(`region:${this.filters.region}`)
    }

    query.push(`year:${this.internalFilters.year}`)

    return query
  }

  public get payload() {
    const query = [...new Set([...this.query, ...this.internalQuery, ...this.filterToQuery])]
    const filters = {
      ...this.internalFilters,
    }
    return {
      dimensions: this.dimensions,
      metrics: this.metrics,
      filters,

      order: {},
      query,
      snapshot: this.filters.snapshot || '',
    }
  }

  public dataSource() {
    return FinancialPlan.getPlanDashboard(this.payload).then((res: any) => {
      const data = res.data.result.sort((a: any, b: any) => b[this.metrics[0]] - a[this.metrics[0]])

      return data
    })
  }

  protected dataMapper(data: any): Promise<any> {
    const series: number[] = []
    const data_series: any[] = []
    const labels: any[] = []
    // const colors: string[] = []
    const series_ids: string[] = []
    let total = 0

    const groups: string[] = []

    let x_series: string[] = []

    // Init Series
    data.forEach((item: any, idx: number) => {
      if (item[this.dimensions[0]]) {
        const label = startCase(item[this.dimensions[0]])
        const group_index = groups.indexOf(label)
        if (group_index === -1) {
          groups.push(label)
          data_series.push({
            x: label,
            y: 0,
            goals: [],
          })
        }
      }
    })

    const colors = ['#00C1DE', '#b0ca36']

    data.forEach((item: any, idx: number) => {
      const val = Number(item[this.metrics[0]])
      const goal = Number(item[this.metrics[1]])
      total += val
      series.push(val)
      const group_label = startCase(item[this.dimensions[0]])
      const group_index = groups.indexOf(group_label)

      data_series[group_index].y = val
      data_series[group_index].goals.push({
        name: 'Plan',
        value: goal,
        strokeWidth: 3,
        strokeHeight: 10,
        strokeColor: '#b0ca36',
      })

      if (['product', 'region', 'deal_type'].includes(this.dimensions[1])) {
        series_ids[group_index] = item[this.dimensions[1]].toLowerCase().replaceAll(' ', '_')
      } else if (this.dimensions[2]) {
        series_ids[group_index] = item[this.dimensions[this.dimensions.length - 1]]
      }
    })

    // Udpate Settings
    this.chartSettings.colors = colors
    this.chartSettings.labels = labels

    return Promise.resolve([{ name: 'Sold', data: data_series }])
  }
}
