import SelectOption from '@/models/interface/SelectOption'
import SystemDashboardAction from '@/models/SystemDashboardAction'

export default class ColumnSelector extends SystemDashboardAction {
  // Target Property to be changed or event name
  public target: string = 'internalTableConfig' // to prevent error, but its not being used

  public title: string = 'Columns'

  // Unique key for the action
  public key: string = 'fields'

  public type: string = 'popover'

  public fields: any = []

  public id: string = 'column-selector'

  constructor(args: any = null) {
    super()

    if (args && args.fields) {
      this.fields = args.fields
    }

    if (args && args.title) {
      this.title = args.title
    }

    if (args && args.key) {
      this.key = args.key
    }

    if (args && args.target) {
      this.target = args.target
    }

    if (args && args.id) {
      this.id = args.id
    }
  }
}
