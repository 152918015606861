import SystemDashboard from '@/models/SystemDashboard'
import SystemDashboardWidget from '@/models/SystemDashboardWidget'
import moment from 'moment'
import numeral from 'numeral'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import User from '@/models/User'
import Opportunity from '@/models/Opportunity'
import BaseFilter from '../filters/BaseFilter'
import ActivityThisWeekWidget from '../widgets/finance/ActivityThisWeekWidget'
import AgencyDistributionYear from '../widgets/finance/AgencyDistributionYear'
import NewThisWeekOverviewWidget from '../widgets/finance/NewThisWeekOverviewWidget'
import NewThisWeekWidget from '../widgets/finance/NewThisWeekWidget'
import TouchPointSummaryWidget from '../widgets/finance/TouchPointSummaryWidget'
import RegionProductFilterWidget from '../widgets/others/RegionProductFilterWidget'
import SoldToPlanWidget from '../widgets/finance/SoldToPlanWidget'
import PerformanceToReforecastYear from '../widgets/finance/PerformanceToReforecastYear'

import PipelineToPlanYearWidget from '../widgets/finance/PipelineToPlanYearWidget'
import SalesRepOverviewWidget from '../widgets/finance/SalesRepOverviewWidget'
import WeeklyOverview from '../widgets/finance/WeeklyOverview'
import MonthlyProductDistribution from '../widgets/finance/MonthlyProductDistribution'
import SalesRepRevenueVSPlanWidget from '../widgets/finance/SalesRepRevenueVSPlanWidget'

import OpportunityStageBySalesRepWidget from '../widgets/finance/OpportunityStageBySalesRepWidget'
import CountByStageWidget from '../widgets/finance/CountByStageWidget'
import AmountByStageYearWidget from '../widgets/finance/AmountByStageYearWidget'

export default class SalesDashboardModule extends SystemDashboard {
  public title = 'Sales'

  public key: string = 'sales_dashboard'

  public filters = {
    product: 'all',
    region: 'all',
  }

  public querySettings = {
    company_fields: [
      {
        name: 'agency',
        key: 'agency',
        type: 'agency',
      },
      {
        name: 'advertiser',
        key: 'advertiser',
        type: 'advertiser',
      },
    ],
    custom_fields: [
      {
        name: 'product:ssl',
        value: 'product:ssl',
      },
      {
        name: 'product:ccl',
        value: 'product:ccl',
      },
      {
        name: 'deal_type:direct',
        value: 'deal_type:direct',
      },
      {
        name: 'deal_type:3rd Party',
        value: 'deal_type:3rd_party',
      },
    ],
  }

  public auth_user: any = getModule(SystemtModule).user

  public get soldToPlanWidgets() {
    return [
      new SoldToPlanWidget({ internalQueryPush: `sr:${this.auth_user.id}` }),
      ...Array.from({ length: 4 }, (_, i) => i + 1).map(quarter => new SoldToPlanWidget({
        title: `Q${quarter} Sold to Plan`,
        key: `sold-to-goal-q${quarter}`,
        size: {
          lg: 2,
          md: 6,
        },
        dimensions: ['quarter'],
        internalFilters: {
          quarter,
          type: 'finance_goal',
        },
        internalQueryPush: `sr:${this.auth_user.id}`,
      })),
      // new SoldToPlanWidget({
      //   title: 'Q1 Sold to Plan',
      //   key: 'sold-to-goal-q1',
      //   size: {
      //     lg: 2,
      //     md: 6,
      //   },
      //   dimensions: ['quarter'],
      //   internalFilters: {
      //     quarter: 1,
      //     type: 'finance_goal',
      //   },
      //   internalQueryPush: `sr:${this.auth_user.id}`,
      // }),
      // new SoldToPlanWidget({
      //   title: 'Q2 Sold to Plan',
      //   key: 'sold-to-goal-q2',
      //   size: {
      //     lg: 2,
      //     md: 6,
      //   },
      //   dimensions: ['quarter'],
      //   internalFilters: {
      //     quarter: 2,
      //     type: 'finance_goal',
      //   },
      //   internalQueryPush: `sr:${this.auth_user.id}`,
      // }),
      // new SoldToPlanWidget({
      //   title: 'Q3 Sold to Plan',
      //   key: 'sold-to-goal-q3',
      //   size: {
      //     lg: 2,
      //     md: 6,
      //   },
      //   dimensions: ['quarter'],
      //   internalFilters: {
      //     quarter: 3,
      //     type: 'finance_goal',
      //   },
      //   internalQueryPush: `sr:${this.auth_user.id}`,
      // }),
      // new SoldToPlanWidget({
      //   title: 'Q4 Sold to Plan',
      //   key: 'sold-to-goal-q4',
      //   size: {
      //     lg: 2,
      //     md: 6,
      //   },
      //   dimensions: ['quarter'],
      //   internalFilters: {
      //     quarter: 4,
      //     type: 'finance_goal',
      //   },
      //   internalQueryPush: `sr:${this.auth_user.id}`,
      // }),
    ]
  }

  public get soldToReforecastYearWidgets() {
    return [
      new SoldToPlanWidget({
        title: `Sold to Reforecast ${moment().format('YYYY')}`,
        key: 'sold-to-reforecast-year',
        dimensions: ['year'],
        metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
        internalFilters: {
          type: 'finance_goal',
          reforecast: 'latest',
        },
        internalQuery: ['scope:finance_goal', `sr:${this.auth_user.id}`],
        custom_data_fields: {
          target: 'sold',
          period: 'year',
          secondary_label: 'Reforecast',
        },
      }),

      ...Array.from({ length: 4 }, (_, i) => i + 1).map(quarter => new SoldToPlanWidget({
        title: `Q${quarter} Sold to Reforecast`,
        key: `sold-to-reforecast-q${quarter}`,
        dimensions: ['quarter'],
        metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
        internalFilters: {
          type: 'finance_goal',
          reforecast: 'latest',
          quarter,
        },
        internalQuery: ['scope:finance_goal', `sr:${this.auth_user.id}`],
        custom_data_fields: {
          target: 'sold',
          period: `Q${quarter}`,
          secondary_label: 'Reforecast',
        },
        size: {
          lg: 2,
          md: 6,
        },
      })),

      // new SoldToPlanWidget({
      //   title: 'Q1 Sold to Reforecast',
      //   key: 'sold-to-reforecast-q1',
      //   dimensions: ['quarter'],
      //   metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
      //   internalFilters: {
      //     type: 'finance_goal',
      //     reforecast: 'latest',
      //     quarter: 1,
      //   },
      //   internalQuery: ['scope:finance_goal'],
      //   custom_data_fields: {
      //     target: 'sold',
      //     period: 'Q1',
      //     secondary_label: 'Reforecast',
      //   },
      //   size: {
      //     lg: 2,
      //     md: 6,
      //   },
      // }),

      // new SoldToPlanWidget({
      //   title: 'Q2 Sold to Reforecast',
      //   key: 'sold-to-reforecast-q2',
      //   dimensions: ['quarter'],
      //   metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
      //   internalFilters: {
      //     type: 'finance_goal',
      //     reforecast: 'latest',
      //     quarter: 2,
      //   },
      //   internalQuery: ['scope:finance_goal'],
      //   custom_data_fields: {
      //     target: 'sold',
      //     period: 'Q2',
      //     secondary_label: 'Reforecast',
      //   },
      //   size: {
      //     lg: 2,
      //     md: 6,
      //   },
      // }),

      // new SoldToPlanWidget({
      //   title: 'Q3 Sold to Reforecast',
      //   key: 'sold-to-reforecast-q3',
      //   dimensions: ['quarter'],
      //   metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
      //   internalFilters: {
      //     type: 'finance_goal',
      //     reforecast: 'latest',
      //     quarter: 3,
      //   },
      //   internalQuery: ['scope:finance_goal'],
      //   custom_data_fields: {
      //     target: 'sold',
      //     period: 'Q3',
      //     secondary_label: 'Reforecast',
      //   },
      //   size: {
      //     lg: 2,
      //     md: 6,
      //   },
      // }),

      // new SoldToPlanWidget({
      //   title: 'Q4 Sold to Reforecast',
      //   key: 'sold-to-reforecast-q4',
      //   dimensions: ['quarter'],
      //   metrics: ['goal_sold', 'goal', 'last_year_goal', 'last_year_goal_sold'],
      //   internalFilters: {
      //     type: 'finance_goal',
      //     reforecast: 'latest',
      //     quarter: 4,
      //   },
      //   internalQuery: ['scope:finance_goal'],
      //   custom_data_fields: {
      //     target: 'sold',
      //     period: 'Q4',
      //     secondary_label: 'Reforecast',
      //   },
      //   size: {
      //     lg: 2,
      //     md: 6,
      //   },
      // }),
    ]
  }

  public get PerformanceToReforecastWidgets() {
    return [
      new PerformanceToReforecastYear({ internalQueryPush: `sr:${this.auth_user.id}` }),
      ...Array.from({ length: 4 }, (_, i) => i + 1).map(quarter => new PerformanceToReforecastYear({
        title: `Q${quarter} Performance to Forecast`,
        key: `performance-to-reforecast-q${quarter}`,
        dimensions: ['quarter'],
        size: {
          lg: 2,
          md: 6,
        },
        internalFilters: {
          quarter,
        },
        internalQueryPush: `sr:${this.auth_user.id}`,
      })),
      // new PerformanceToReforecastYear({
      //   title: 'Q1 Performance to Forecast',
      //   key: 'performance-to-reforecast-q1',
      //   dimensions: ['quarter'],
      //   size: {
      //     lg: 2,
      //     md: 6,
      //   },
      //   internalFilters: {
      //     quarter: 1,
      //   },
      // }),
      // new PerformanceToReforecastYear({
      //   title: 'Q2 Performance to Forecast',
      //   key: 'performance-to-reforecast-q2',
      //   dimensions: ['quarter'],
      //   size: {
      //     lg: 2,
      //     md: 6,
      //   },
      //   internalFilters: {
      //     quarter: 2,
      //   },
      // }),
      // new PerformanceToReforecastYear({
      //   title: 'Q3 Performance to Forecast',
      //   key: 'performance-to-reforecast-q3',
      //   dimensions: ['quarter'],
      //   size: {
      //     lg: 2,
      //     md: 6,
      //   },
      //   internalFilters: {
      //     quarter: 3,
      //   },
      // }),
      // new PerformanceToReforecastYear({
      //   title: 'Q4 Performance to Forecast',
      //   key: 'performance-to-reforecast-q4',
      //   dimensions: ['quarter'],
      //   size: {
      //     lg: 2,
      //     md: 6,
      //   },
      //   internalFilters: {
      //     quarter: 4,
      //   },
      // }),
    ]
  }

  public get has_cross_region_access() {
    return this.auth_user!.canOptions('management_dashboard', 'cross_region')
  }

  public widgets: SystemDashboardWidget[] = [
    new RegionProductFilterWidget(),
    new ActivityThisWeekWidget({
      title: 'Pipeline Clean-up',
      key: 'pipeline-cleanup',
      internalQuery: ['is:clean_up', `sr:${this.auth_user.id}`],
      metrics: [],
      size: {
        lg: 12,
        md: 12,
      },
      fields: [
        {
          key: 'start_at',
          label: 'Start At',
          class: 'text-center align-middle',
          show: true,
          type: 'date',
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Stage',
          class: 'text-center align-middle',
          show: true,
          type: 'capitalize_replace',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },
        {
          key: 'advertiser_id',
          label: 'Advertiser',
          class: 'text-center align-middle',
          show: true,
          type: 'object',
          object: {
            key: 'advertiser',
            property: 'name',
          },
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'agency_id',
          label: 'Agency',
          class: 'text-center align-middle',
          show: true,
          type: 'object',
          object: {
            key: 'agency',
            property: 'name',
          },
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'name',
          label: 'Name',
          class: 'text-center align-middle',
          show: true,
          type: 'text',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },
        {
          key: 'amount',
          label: 'Amount',
          class: 'text-center align-middle',
          show: true,
          type: 'currency_abbreviate',
          thClass: 'align-middle text-center',
          sortable: true,
          total: 'currency_abbreviate',
        },
      ],
      cellSlots: [
        {
          key: 'name',
          component: 'TableColLink',
          slotProps: (data: any) => ({
            link: `opportunity/${data.item.id}`,
            innerText: data.item.name,
          }),
        },
      ],
      dataSourceRequest: (payload: any) => Opportunity.paginate(payload).then((res: any) => res),
    }),
    new ActivityThisWeekWidget({
      title: 'Dormant Activity',
      key: 'pipeline-stale',
      internalQuery: ['is:stale', `sr:${this.auth_user.id}`],
      metrics: [],
      size: {
        lg: 12,
        md: 12,
      },
      fields: [
        {
          key: 'start_at',
          label: 'Start At',
          class: 'text-center align-middle',
          show: true,
          type: 'date',
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Stage',
          class: 'text-center align-middle',
          show: true,
          type: 'capitalize_replace',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },
        {
          key: 'advertiser_id',
          label: 'Advertiser',
          class: 'text-center align-middle',
          show: true,
          type: 'object',
          object: {
            key: 'advertiser',
            property: 'name',
          },
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'agency_id',
          label: 'Agency',
          class: 'text-center align-middle',
          show: true,
          type: 'object',
          object: {
            key: 'agency',
            property: 'name',
          },
          thClass: 'align-middle text-center',
          sortable: true,
        },
        {
          key: 'name',
          label: 'Name',
          class: 'text-center align-middle',
          show: true,
          type: 'text',
          thClass: 'align-middle text-center',
          sortable: true,
          filter: true,
        },
        {
          key: 'amount',
          label: 'Amount',
          class: 'text-center align-middle',
          show: true,
          type: 'currency_abbreviate',
          thClass: 'align-middle text-center',
          sortable: true,
          total: 'currency_abbreviate',
        },
      ],
      cellSlots: [
        {
          key: 'name',
          component: 'TableColLink',
          slotProps: (data: any) => ({
            link: `opportunity/${data.item.id}`,
            innerText: data.item.name,
          }),
        },
      ],
      dataSourceRequest: (payload: any) => Opportunity.paginate(payload).then((res: any) => res),
    }),
    new NewThisWeekOverviewWidget({ internalQueryPush: `sr:${this.auth_user.id}` }),
    new NewThisWeekWidget({ internalQueryPush: `sr:${this.auth_user.id}` }),
    new NewThisWeekWidget({
      title: 'Open Deals',
      key: 'large-deals',
      size: {
        lg: 12,
        md: 12,
      },
      internalFilters: {
        dynamic_filter: 'is:large_deal',
      },
      internalQuery: ['is:open', `sr:${this.auth_user.id}`],
      rightCol: [
        new BaseFilter({
          options: [
            { text: 'Large Deals', value: 'is:large_deal' },
            { text: 'All', value: '' },
          ],
        }),
      ],
      cellSlots: [
        {
          key: 'name',
          component: 'TableColLink',
          slotProps: (data: any) => ({
            link: `/app/opportunity/${data.item.id}`,
            innerText: data.item.name,
          }),
        },
      ],
    }),

    new TouchPointSummaryWidget({ internalQueryPush: `sr:${this.auth_user.id}` }),
    new TouchPointSummaryWidget({
      title: 'Activity Summary',
      key: 'this-week-summary',
      dimensions: ['activity'],
      metrics: ['count'],
      internalQueryPush: `sr:${this.auth_user.id}`,
    }),

    new ActivityThisWeekWidget({ internalQueryPush: `sr:${this.auth_user.id}` }),
    new ActivityThisWeekWidget({
      title: 'Activity Next Week',
      key: 'activity-next-week',
      internalQuery: ['is:next_week', `sr:${this.auth_user.id}`],
    }),

    ...this.soldToPlanWidgets,

    ...this.soldToReforecastYearWidgets,

    ...this.PerformanceToReforecastWidgets,

    new PipelineToPlanYearWidget({ internalQueryPush: `sr:${this.auth_user.id}` }),
    new SalesRepOverviewWidget({ internalQueryPush: `sr:${this.auth_user.id}` }),

    new WeeklyOverview({ internalQueryPush: `sr:${this.auth_user.id}` }),
    new AmountByStageYearWidget({ internalQueryPush: `sr:${this.auth_user.id}` }),

    new MonthlyProductDistribution({
      title: `Product Distribution ${moment().format('YYYY')}`,
      key: 'monthly-product-distribution',
      dimensions: ['quarter', 'product'],
      internalQueryPush: `sr:${this.auth_user.id}`,
      size: {
        lg: 8,
        md: 8,
        sm: 12,
      },
      internalFilters: {
        dynamic_filter: 'is:pipeline',
      },
      rightCol: [
        new BaseFilter({
          type: 'radio',
          target: 'internalFilters',
          key: 'dynamic_filter',
          options: [
            { text: 'Pipeline', value: 'is:pipeline' },
            { text: 'Sold', value: 'is:sold' },
          ],
        }),
        new BaseFilter({
          label: 'Group by',
          type: 'radio',
          target: 'dimensions',
          key: '0',
          options: [
            { text: 'Quarter', value: 'quarter' },
            { text: 'Month', value: 'month' },
          ],
        }),
      ],
      custom_formatters: {
        tooltip: ({ widget, value, opt }: any) => {
          const raw_value = widget.rawData[opt.dataPointIndex].amount
          const total = widget.rawData.reduce(
            (acc: number, item: any) => acc + item[widget.metrics[0]],
            0,
          )
          /**
           * TODO @vini
           * In Product Distribution V1 there are some bugs compare them to this formatting
           */
          return [
            `${numeral(raw_value).format('$0.00a')}`,
            ` ${numeral(raw_value / total).format('0.00%')}`,
          ]
        },
      },
    }),

    new MonthlyProductDistribution({
      title: `Sales Rep Distribution ${moment().format('YYYY')}`,
      key: 'monthly-sales-rep-distribution',
      dimensions: ['quarter', 'sales_rep', 'sales_rep_id'],
      size: {
        lg: 8,
        md: 12,
      },
      internalFilters: {
        dynamic_filter: 'is:pipeline',
      },
      rightCol: [
        new BaseFilter({
          type: 'radio',
          target: 'internalFilters',
          key: 'dynamic_filter',
          options: [
            { text: 'Pipeline', value: 'is:pipeline' },
            { text: 'Sold', value: 'is:sold' },
          ],
        }),
        new BaseFilter({
          label: 'Group by',
          type: 'radio',
          target: 'dimensions',
          key: '0',
          options: [
            { text: 'Quarter', value: 'quarter' },
            { text: 'Month', value: 'month' },
          ],
        }),
      ],
      custom_formatters: {
        tooltip: ({ widget, value, opt }: any) => {
          const raw_value = widget.rawData[opt.dataPointIndex].amount
          const total = widget.rawData.reduce(
            (acc: number, item: any) => acc + item[widget.metrics[0]],
            0,
          )
          /**
           * TODO @vini
           * In Product Distribution V1 there are some bugs compare them to this formatting
           */
          return [
            `${numeral(raw_value).format('$0.00a')}`,
            ` ${numeral(raw_value / total).format('0.00%')}`,
          ]
        },
      },
      internalQueryPush: `sr:${this.auth_user.id}`,
    }),

    new SalesRepRevenueVSPlanWidget({ internalQueryPush: `sr:${this.auth_user.id}` }),
    new OpportunityStageBySalesRepWidget({ internalQueryPush: `sr:${this.auth_user.id}` }),
    new CountByStageWidget({ internalQueryPush: `sr:${this.auth_user.id}` }),
    new AgencyDistributionYear({
      dimensions: ['agency', 'agency_id'],
      metrics: ['amount'],
      internalQueryPush: `sr:${this.auth_user.id}`,
      internalFilters: {
        dynamic_filter: 'is:pipeline',
      },
      rightCol: [
        new BaseFilter({
          type: 'radio',
          options: [
            { text: 'Pipeline', value: 'is:pipeline' },
            { text: 'Sold', value: 'is:sold' },
          ],
        }),
      ],
    }),
    new AgencyDistributionYear({
      title: `Advertiser Distribution ${moment().format('YYYY')}`,
      key: 'advertiser-revenue-distribution',
      dimensions: ['advertiser', 'advertiser_id'],
      metrics: ['amount'],
      internalQueryPush: `sr:${this.auth_user.id}`,
      internalFilters: {
        dynamic_filter: 'is:pipeline',
      },
      rightCol: [
        new BaseFilter({
          type: 'radio',
          options: [
            { text: 'Pipeline', value: 'is:pipeline' },
            { text: 'Sold', value: 'is:sold' },
          ],
        }),
      ],
    }),
  ]

  public async setup() {
    if (this.auth_user.region) {
      this.filters.region = this.auth_user.region
    } else if (!this.has_cross_region_access) {
      this.filters.region = 'all'
    }

    return new Promise(resolve => {
      setTimeout(() => {
        resolve(true)
      }, 300)
    })
  }
}
